import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { ComingSoonFeature } from '../../../App/components/Error'

const AuditReportDoc = () => {
	return (
		<Box padding={8} width="100%" height="100%">
			<ComingSoonFeature />
		</Box>
	)
}

export { AuditReportDoc }
