import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-multi-email/dist/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import { Card } from 'ui-lib/components/Card'
import { Label } from 'ui-lib/components/Label'
import LeftArrowIcon from 'ui-lib/icons/arrow_back.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
// import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/containers/Enquiries/Title'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import * as yup from 'yup'
import { getDateByFormat } from '../../../../utils/date'
import { Title } from './Title'
import FileUploadComponent from './upload'

const DocumentDetailsBlock = ({ t, values }) => {
	return (
		<Card
			style={{
				padding: '20px',
				position: 'relative',
				overflow: 'visible',
				background: '#FAFBFF',
				border: '1px solid #D9DEE5',
				borderRadius: '6px',
			}}
			textAlign="left"
		>
			<Box justifyContent="space-between" flex row>
				<Box width={270}>
					<Label
						style={{
							fontSize: '14px',
							color: '#242845',
							marginBottom: 8,
						}}
					>
						{t('generalDocument.date')}
					</Label>
					<Label>
						{getDateByFormat(getIn(values, ['meta', 'issueDate']))}
					</Label>
				</Box>
				<Box width={270}>
					<Label
						style={{
							fontSize: '14px',
							color: '#242845',
							marginBottom: 8,
						}}
					>
						{t('generalDocument.transactionNumber')}
					</Label>
					<Label>{getIn(values, ['transactionNumber'])}</Label>
				</Box>
				<Box width={270}>
					<Label
						style={{
							fontSize: '14px',
							color: '#242845',
							marginBottom: 8,
						}}
					>
						{t('generalDocument.associatedDocument')}
					</Label>
					<Label>
						{getIn(values, ['meta', 'referenceName']) || 'N/A'}
					</Label>
				</Box>
			</Box>
		</Card>
	)
}

const PreviewGeneralDocument = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(TradeDocDuc.selectors.location)
	const { payload } = location
	const { action } = payload
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const { fullName, orgRole } = loggedInUserProfile
	const partnerList = useSelector(AuthDuc.selectors.getAvailablePartners)
	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getGeneralDocumentActiveRecord
	)
	const [initialValues, setInitialValues] = useState({
		docType: 'doc-transaction',
		initiatingParty: {
			orgID: getIn(currentOrgDetails, ['id']),
			name: getIn(currentOrgDetails, ['name']),
		},
		receivingParty: {},
		meta: {
			receiverOrgIDs: [],
			receiverEmailIDs: [],
			authorization: {
				reportedBy: fullName,
				reportedByRole: orgRole,
				authorisedSignatory: fullName,
			},
		},
	})
	const validationSchema = yup.object().shape({
		initiatingParty: yup.object().shape({
			orgID: yup.string().required(),
			name: yup.string().required(),
		}),
		transactionNumber: yup
			.string()
			.required(t('generalDocument.transactionNumberError')),

		meta: yup.object().shape({
			authorization: yup.object().shape({
				reportedBy: yup
					.string()
					.required(t('generalDocument.reportedByError')),
				reportedByRole: yup
					.string()
					.required(t('generalDocument.roleError')),
				authorisedSignatory: yup
					.string()
					.required(t('generalDocument.authoriseError')),
			}),
		}),
	})

	const partners = []

	Object.values(partnerList).forEach(list => {
		partners.push({
			id: list.id,
			name: list.name,
			primaryAddress: list.primaryAddress,
		})
	})

	const {
		handleSubmit,
		values,
		submitForm,
		setFieldValue,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (_values, { setSubmitting }) => {
			if (action === 'preview-document') {
				dispatch(
					TradeDocDuc.creators.createTransaction(
						_values,
						t('common.updateSuccess'),
						{
							setSubmitting,
						}
					)
				)
			}
			if (action === 'edit-preview') {
				dispatch(
					TradeDocDuc.creators.updateTransaction(
						_values,
						t('common.updateSuccess'),
						{
							setSubmitting,
						}
					)
				)
			}
		},
	})

	useEffect(() => {
		setInitialValues(activeDocument)
	}, [activeDocument])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'general-document',
			isActive: true,
		},
		{
			label: t('generalDocument.shareNewDocument'),
			name: 'general-document',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'incoming',
					}
				)
			)
		}
	}

	const disableCTA =
		!isEmptyObject(errors) || values?.transactionNumber === ''

	const isEdit = action === 'edit-preview'

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box>
				<Title
					title={t('generalDocument.shareNewDocument')}
					icon={LeftArrowIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$GENERALACTION,
								{
									rootModule: 'general-document',
									action: 'listing',
								}
							)
						)
					}}
				/>
			</Box>
			<Box row={!isMobile} justifyContent="space-between">
				<Box
					style={{
						width: '100%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Label
							style={{
								width: '94%',
								color: theme.color.accent2,
								fontSize: '18px',
								fontWeight: 500,
							}}
						>
							{t('generalDocument.receipients')}
						</Label>

						<Box style={{ width: '100%' }}>
							<Box
								style={{ justifyContent: 'space-between' }}
								row
							>
								<Card
									style={{
										padding: '20px',
										position: 'relative',
										overflow: 'visible',
										background: '#FAFBFF',
										border: '1px solid #D9DEE5',
										borderRadius: '6px',
										borderTopRightRadius: '0px',
										borderBottomRightRadius: '0px',
										marginTop: '15px',
									}}
									textAlign="left"
								>
									<Box margin="0px 0px 20px 0px" width={350}>
										<Label
											style={{
												fontSize: '14px',
												color: '#242845',
												marginBottom: 8,
											}}
										>
											{t('generalDocument.recipientName')}
										</Label>

										{!isEdit && (
											<Box>
												{(
													getIn(values, [
														'meta',
														'receiverOrgIDs',
													]) || []
												).map(d => (
													<Label>
														{getIn(partnerList, [
															d,
															'name',
														]) || ''}
													</Label>
												))}
												{(
													getIn(values, [
														'meta',
														'receiverEmailIDs',
													]) || []
												).map(d => (
													<Label>{d}</Label>
												))}
											</Box>
										)}
										{isEdit && (
											<Box>
												<Label>
													{getIn(values, [
														'receivingParty',
														'name',
													])}
												</Label>
												<div>
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'line1',
													]) || ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'line1',
													])
														? ', '
														: ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'line2',
													]) || ''}
												</div>
												<div>
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'city',
													]) || ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'city',
													])
														? ', '
														: ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'state',
													]) || ''}
												</div>
												<div>
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'country',
													]) || ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'country',
													])
														? ', '
														: ''}
													{getIn(values, [
														'receivingParty',
														'PrimaryAddress',
														'postalCode',
													]) || ''}
												</div>
											</Box>
										)}
									</Box>
								</Card>
							</Box>
						</Box>

						<Spacer size={32} />
						<DocumentDetailsBlock values={values} t={t} />
						<FileUploadComponent
							isPreview
							files={values?.files}
							setFiles={files => {
								setFieldValue('files', files)
							}}
						/>
						<Spacer size={32} />
						<Label
							style={{
								width: '94%',
								color: theme.color.accent2,
								fontSize: '18px',
								fontWeight: 500,
							}}
						>
							{t('generalDocument.authorization')}
						</Label>
						<Card
							style={{
								padding: '20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
								marginTop: '15px',
							}}
							textAlign="left"
						>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmDetailsEntry.createdBy')}
										</Label>
										<Label>
											{getIn(values, [
												'meta',
												'authorization',
												'reportedBy',
											])}
										</Label>
									</Box>
								</Box>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmViewDocument.role')}
										</Label>
										<Label>
											{getIn(values, [
												'meta',
												'authorization',
												'reportedByRole',
											])}
										</Label>
									</Box>
								</Box>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t(
												'tdmPreviewDetails.authorisedSignatory'
											)}
										</Label>
										<Label>
											{getIn(values, [
												'meta',
												'authorization',
												'authorisedSignatory',
											])}
										</Label>
									</Box>
								</Box>
							</Box>
						</Card>

						<Spacer size={32} />
						<Box>
							<Label
								style={{
									width: '94%',
									color: theme.color.accent2,
									fontSize: '18px',
									fontWeight: 500,
									marginBottom: '15px',
								}}
							>
								{t('generalDocument.remarks')}
							</Label>
							<Label>{getIn(values, ['meta', 'remarks'])}</Label>
						</Box>
					</form>
				</Box>
			</Box>
			<Box row={!isMobile} justifyContent="flex-end" margin="36px 0 0">
				<Box
					direction="row"
					style={{
						flexDirection: 'row',
						marginTop: isMobile ? '20px' : '0px',
						alignItems: isMobile ? 'center' : 'normal',
					}}
				>
					<Button
						isLoading={isSubmitting}
						label={t('common.back')}
						primary
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.TRADE_DOCUMENT_MANAGER$GENERALACTION,
									{
										rootModule: 'general-document',
										action: 'create-doc',
									}
								)
							)
						}}
						extendStyles={{ width: 212, margin: '0px 16px' }}
					/>
					<Button
						isLoading={isSubmitting}
						label={
							action === 'edit-preview'
								? t('generalDocument.update')
								: t('common.submit')
						}
						primary
						disabled={disableCTA}
						onClick={() => submitForm()}
						extendStyles={{ width: 212, margin: '0px 16px' }}
					/>
				</Box>
			</Box>
		</>
	)
}

export { PreviewGeneralDocument }
