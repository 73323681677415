import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { DocumentTreeBlock } from './DocumentTreeBlock'

const AssociatedDocStacks = ({
	documents = {},
	activeDocumentKey = '',
	messages = [],
}) => {
	const dispatch = useDispatch()
	const [openKeys, setOpenKeys] = useState({})

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open-document') {
				dispatch(TradeDocDuc.creators.setActiveTab('original'))

				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types
							.TRADE_DOCUMENT_MANAGER$VIEWGENERALDOCREFERENCE,
						{
							rootModule: 'general-document',
							documentReference: meta.id,
						}
					)
				)
			}
		},
		[dispatch]
	)

	const toggleCollapse = id => {
		setOpenKeys(prevKeys => ({
			...prevKeys,
			[id]: !prevKeys[id],
		}))
	}

	const renderDocumentTree = doc => (
		<DocumentTreeBlock
			key={doc.id}
			isOpen={!!openKeys[doc.id]}
			config={doc}
			handleChange={handleOnChange}
			onCollapseAction={() => toggleCollapse(doc.id)}
			isActive={doc.id === activeDocumentKey}
			messages={messages}
		/>
	)

	return documents && renderDocumentTree(documents)
}

export { AssociatedDocStacks }
