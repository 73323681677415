import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { Box } from 'ui-lib/utils/Box'
import { Label } from 'ui-lib/components/Label'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Input } from 'ui-lib/components/Input'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Select, SelectSearch } from 'ui-lib/components/Select'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { TRANSACTION_TYPE } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import tradeDocIcon from 'ui-lib/icons/trade.svg'
import theme from 'ui-lib/utils/base-theme'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import { currencyCodeList } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'

const AttachmentSectionWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 10px;
`

const PaymentDetails = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { payload = {} } = locationState
	const { rootModule, documentReference } = payload
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)

	const paymentDetails =
		activeDocument?.meta?.paymentSlip?.length > 0
			? activeDocument?.meta?.paymentSlip[0]
			: {}

	const paymentSlipAttachments = {
		attachmentID: paymentDetails?.id,
		file: [paymentDetails?.file],
		paymentSlipData: paymentDetails,
	}

	const isEdit = paymentSlipAttachments?.attachmentID

	const [attachedFile, setAttachedFile] = useState([])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('tdmColumnHeaders.invoice'),
			name: 'invoice',
			isActive: true,
		},
		{
			label: t('tdmViewDocument.paymentDetails'),
			name: 'bank-statement',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'invoice') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
					{
						rootModule,
						documentReference,
					}
				)
			)
		}
	}

	const getTransactionType = [
		{
			name: `${t('tdmDocumentListing.choose')} ${t(
				'tdmViewDocument.paymentMethod'
			)}`,
			id: '',
		},
		{
			name: t(TRANSACTION_TYPE.cash),
			id: 'cash',
		},
		{
			name: t(TRANSACTION_TYPE.bankTransfer),
			id: 'bank-transfer',
		},
		{
			name: t(TRANSACTION_TYPE.creditCard),
			id: 'credit-card',
		},
		{
			name: t(TRANSACTION_TYPE.debitCard),
			id: 'debit-card',
		},
		{
			name: t(TRANSACTION_TYPE.onlinePayment),
			id: 'online-payment',
		},
		{
			name: t(TRANSACTION_TYPE.cheque),
			id: 'cheque',
		},
		{
			name: t(TRANSACTION_TYPE.others),
			id: 'others',
		},
	]

	const initialValues = {
		meta: {
			paymentSlip: {
				paymentMethod:
					paymentSlipAttachments?.paymentSlipData?.paymentMethod ||
					'',
				amount: paymentSlipAttachments?.paymentSlipData?.amount || '',
				currency:
					paymentSlipAttachments?.paymentSlipData?.currency || 'MYR',
				date: paymentSlipAttachments?.paymentSlipData?.date || '',
				status:
					paymentSlipAttachments?.paymentSlipData?.status || 'paid',
			},
		},
		file: paymentSlipAttachments?.file[0] || {},
	}

	const validationSchema = () => {
		const shape = yup.object().shape({
			meta: yup.object().shape({
				paymentSlip: yup.object().shape({
					paymentMethod: yup
						.string()
						.required(t('tdmViewDocument.transactionTypeErrorMsg')),
					amount: yup
						.number()
						.required(t('tdmViewDocument.amountErrorMsg')),
					date: yup
						.date()
						.required(t('tdmViewDocument.DateErrorMsg')),
					status: yup.string().required(),
				}),
			}),
		})

		return shape
	}

	const {
		handleSubmit,
		submitForm,
		values,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			setSubmitting(false)
			const data = { ..._values }
			const currentMeta = { ...data.meta }
			const currentPaymentSlip = { ...data.meta.paymentSlip }

			currentPaymentSlip.initiatorOrgID =
				activeDocument?.initiatingParty?.orgID || ''
			currentPaymentSlip.initiatorUserID = loggedInUserProfile?.id
			currentPaymentSlip.receiverOrgID =
				activeDocument?.receivingParty?.orgID
			currentPaymentSlip.receiverUserID =
				activeDocument?.receivingParty?.orgID
			currentPaymentSlip.file = {
				access: data?.file?.createdAt
					? [data?.file?.access]
					: data?.file?.access,
				meta: {
					createdby: data?.file?.meta.createdBy,
					fullurl: data?.file?.meta.fullURL,
					name: data?.file?.meta.name,
					other: data?.file?.meta.other,
				},
				id: data?.file?.id,
			}
			if (paymentSlipAttachments?.attachmentID) {
				currentPaymentSlip.id = paymentSlipAttachments?.attachmentID
			}
			currentMeta.category = 'payment-slip'
			currentMeta.paymentSlip = currentPaymentSlip

			data.meta = currentMeta
			if (isEdit) {
				dispatch(
					TradeDocDuc.creators.createEntityAttachment(
						rootModule,
						activeDocument.docID,
						activeDocument?.receivingParty,
						[data?.file],
						t('tdmViewDocument.paymentReportUpdated'),
						getCurrentOrgID,
						'paymentReportUpdate',
						data.meta
					)
				)
			} else {
				dispatch(
					TradeDocDuc.creators.createEntityAttachment(
						rootModule,
						activeDocument.docID,
						activeDocument?.receivingParty,
						[data?.file],
						t('tdmViewDocument.paymentReportAdded'),
						getCurrentOrgID,
						'paymentReport',
						data.meta
					)
				)
			}
		},
	})

	useEffect(() => {
		if (isEdit) {
			setAttachedFile(paymentSlipAttachments?.file)
		}
	}, [isEdit])

	const attachFilesBlock = () => {
		return (
			<Box>
				<Label
					required
					style={{
						width: '94%',
						marginBottom: '10px',
						color: theme.color.accent2,
						fontSize: '14px',
						fontWeight: 500,
					}}
				>
					{t('breadcrumb.attach')} {t('common.document')}
				</Label>
				<AttachmentSectionWrapper>
					<Box
						style={{
							padding: '1px',
							color: theme.color.blue8,
							backgroundColor: theme.color.white,
						}}
					>
						<FileUpload
							modalTriggerBtnLabel={t('common.upload')}
							type="private"
							name="attachments"
							size="2097152"
							supportedFileType=".pdf"
							newAlignment={!isMobile}
							disableUploadForOne
							width="auto"
							hideButtonOneUpload
							currentUploads={attachedFile}
							onDocumentChange={async documents => {
								if (documents && documents.length > 0) {
									setFieldValue(['file'], documents[0])
								} else {
									setFieldValue(['file'], {})
								}
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</Box>
				</AttachmentSectionWrapper>
			</Box>
		)
	}

	const disableCTA = !isEmptyObject(errors) || !values?.file?.meta?.name

	return (
		<>
			<Box row style={{ padding: '0 5' }} justifyContent="flex-start">
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box
				style={
					isMobile
						? { flexDirection: 'column' }
						: { flexDirection: 'row' }
				}
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Title
					title={t('tdmViewDocument.paymentDetails')}
					icon={tradeDocIcon} // change icon
				/>
			</Box>
			<Box
				style={{
					marginBottom: '30px',
					padding: '30px',
					backgroundColor: theme.color.white,
				}}
			>
				<form onSubmit={handleSubmit}>
					<Box>
						<Box>
							<div>
								<Box row>
									<Box width="50%" margin="8px 0">
										<Box>
											<Label
												required
												style={{
													width: '94%',
													marginBottom: '10px',
													color: theme.color.accent2,
													fontSize: '14px',
													fontWeight: 500,
												}}
											>
												{t(
													'tdmViewDocument.paymentMethod'
												)}
											</Label>
										</Box>
										<Box>
											<Select
												required
												name="paymentMethod"
												placeholder={`${t(
													'tdmDocumentListing.choose'
												)} ${t(
													'tdmViewDocument.paymentMethod'
												)}`}
												options={getTransactionType}
												error={
													touched.paymentMethod &&
													errors.paymentMethod
												}
												labelKey="name"
												valueKey="id"
												value={getIn(values, [
													'meta',
													'paymentSlip',
													'paymentMethod',
												])}
												onChange={value => {
													setFieldValue(
														[
															'meta',
															'paymentSlip',
															'paymentMethod',
														],
														value?.id
													)
												}}
												style={{
													border: 'none',
													width: '320px',
													marginBottom: '8px',
													borderBottom: `1px solid ${theme.color.blue8}`,
												}}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>
								<Box row>
									<Box width="50%" margin="8px 0">
										<Box>
											<Box>
												<Label
													required
													style={{
														width: '94%',
														marginBottom: '10px',
														color:
															theme.color.accent2,
														fontSize: '14px',
														fontWeight: 500,
													}}
												>
													{t(
														'tdmViewDocument.amount'
													)}
												</Label>
											</Box>
											<Box row>
												<Box width="220px">
													<Input
														value={
															values?.meta
																?.paymentSlip
																?.amount
														}
														name="amount"
														type="number"
														onChange={e => {
															setFieldValue(
																[
																	'meta',
																	'paymentSlip',
																	'amount',
																],
																parseInt(
																	e.target
																		.value,
																	10
																)
															)
														}}
														onBlur={handleBlur}
														error={
															touched.amount &&
															errors.amount
														}
														extendStyles={{
															border: 'none',
															width: '320px',
															borderRadius: '0px',
															borderBottom: `1px solid ${theme.color.blue8}`,
														}}
													/>
												</Box>
												<Box
													width="90px"
													style={{
														marginLeft: '10px',
													}}
												>
													<SelectSearch
														value={
															values?.meta
																?.paymentSlip
																?.currency
														}
														name="currency"
														options={currencyCodeList()}
														onChange={value => {
															setFieldValue(
																[
																	'meta',
																	'paymentSlip',
																	'currency',
																],
																value.name
															)
														}}
														returnOnlyValue
														labelKey="label"
														valueKey="name"
														style={{
															border: 'none',
															marginBottom: '8px',
															borderBottom: `1px solid ${theme.color.blue8}`,
														}}
													/>
												</Box>
											</Box>
										</Box>
									</Box>
									<Box width="320px" margin="8px 0">
										<Box>
											<Label
												required
												style={{
													width: '94%',
													marginBottom: '10px',
													color: theme.color.accent2,
													fontSize: '14px',
													fontWeight: 500,
												}}
											>
												{t('tdmViewDocument.date')}
											</Label>
										</Box>
										<DatePicker
											enableCurrentTime
											required
											noBorder
											hideError
											toDate
											name="date"
											maxDate={new Date()}
											value={
												values?.meta?.paymentSlip?.date
											}
											onChange={value => {
												setFieldValue(
													[
														'meta',
														'paymentSlip',
														'date',
													],
													value
												)
											}}
										/>
									</Box>
								</Box>
								<Box row>
									<Box width="50%" margin="8px 0">
										<Box>{attachFilesBlock()}</Box>
									</Box>
									<Box width="50%" margin="8px 0">
										<Box>
											<Label
												required
												style={{
													width: '94%',
													marginBottom: '10px',
													color: theme.color.accent2,
													fontSize: '14px',
													fontWeight: 500,
												}}
											>
												{t('binListing.status')}
											</Label>
										</Box>
										<Box>
											<Select
												required
												name="status"
												placeholder={`${t(
													'tdmDocumentListing.choose'
												)} ${t('binListing.status')}`}
												options={[
													{
														name: t(
															'tdmViewDocument.paid'
														),
														id: 'paid',
													},
												]}
												error={
													touched.status &&
													errors.status
												}
												labelKey="name"
												valueKey="id"
												value={getIn(values, [
													'meta',
													'paymentSlip',
													'status',
												])}
												onChange={value => {
													setFieldValue(
														[
															'meta',
															'paymentSlip',
															'status',
														],
														value?.id
													)
												}}
												style={{
													border: 'none',
													width: '320px',
													marginBottom: '8px',
													borderBottom: `1px solid ${theme.color.blue8}`,
												}}
												returnOnlyValue
											/>
										</Box>
									</Box>
								</Box>
							</div>
						</Box>
					</Box>
				</form>
				<Box
					padding="2px"
					row
					style={{
						marginTop: '25px',
						paddingTop: '25px',
						borderTop: `1px solid ${theme.color.grey4}`,
					}}
				>
					<Button
						disabled={disableCTA}
						label={
							isEdit
								? t('tdmViewDocument.updatePaymentReport')
								: t('tdmViewDocument.addPaymentReport')
						}
						primary
						rounded
						onClick={() => {
							submitForm()
						}}
						extendStyles={{
							marginRight: '25px',
							backgroundColor: disableCTA
								? theme.color.themeGrey1
								: theme.color.blue8,
							border: disableCTA
								? theme.color.themeGrey1
								: theme.color.blue8,
						}}
					/>
					<ButtonWithNoBorder
						label={t('common.cancel')}
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
									{
										rootModule,
										documentReference,
									}
								)
							)
						}}
					/>
				</Box>
			</Box>
		</>
	)
}

export { PaymentDetails }
