import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { addLast, merge } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { useTranslation } from 'react-i18next'
import { Modal } from 'ui-lib/components/Modal'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import { ShippingForm } from './ShippingForm'

const SORT_FIELD = 'shippingAddress'

const TopDownBlock = ({ name, description, last }) => {
	return (
		<Box>
			<div
				style={{
					fontSize: 14,
					fontWeight: 500,
					marginBottom: 5,
					color: theme.color.themeGrey1,
				}}
			>
				{name}
			</div>
			<div
				style={{
					fontSize: 16,
					fontWeight: 500,
					marginBottom: last ? 8 : 28,
					color: theme.color.accent2,
				}}
			>
				{description}
			</div>
		</Box>
	)
}

const ShippingStaticSection = ({ isMobile, activeDocument }) => {
	const { t } = useTranslation()
	const {
		shippingDetails = {},
		contractType = '',
		inspections = {},
	} = activeDocument
	const {
		destinationPort = '',
		ladingPort = '',
		shipment = {},
	} = shippingDetails

	const {
		billOfLading,
		mode,
		month,
		period,
		shipThrough,
		shipperNumber,
	} = shipment

	const { quantity = [], quality = [] } = inspections

	return (
		<Box row={!isMobile} justifyContent="space-between">
			<Box margin={8}>
				<TopDownBlock
					name={t('tdmPreviewDetails.shipThrough')}
					description={shipThrough || '---'}
				/>
				<TopDownBlock
					name={t('rfq.portOfLading')}
					description={ladingPort || '---'}
				/>
				<TopDownBlock
					name={t('rfq.preferredQuantityInspector')}
					description={quantity[0] || '---'}
					last
				/>
			</Box>
			<Box margin={8}>
				<TopDownBlock
					name={t('tdmPreviewDetails.shipperNumber')}
					description={shipperNumber || '---'}
				/>
				<TopDownBlock
					name={t('rfq.destinationPort')}
					description={destinationPort || '---'}
				/>
				<TopDownBlock
					name={t('rfq.preferredQualityInspector')}
					description={quality[0] || '---'}
					last
				/>
			</Box>
			<Box margin={8}>
				<TopDownBlock
					name={t('tdmPreviewDetails.billOfLading')}
					description={billOfLading || '---'}
				/>
				<TopDownBlock
					name={t('rfq.shipmentPeriod')}
					description={
						month && period
							? `${month}, ${period}`
							: month
							? `${month}`
							: period
							? `${period}`
							: '---'
					}
				/>
			</Box>
			<Box margin={8}>
				<TopDownBlock
					name={t('tdmPreviewDetails.modeOfTransport')}
					description={mode || '---'}
				/>
				<TopDownBlock
					name={t('rfq.typeOfContract')}
					description={contractType || '---'}
				/>
			</Box>
		</Box>
	)
}

const ShippingEditSection = ({ activeRow: _activeRow, onChange }) => {
	const dispatch = useDispatch()

	const locationState = useSelector(TradeDocDuc.selectors.location)
	const activePartner = useSelector(
		TradeDocDuc.selectors.getDocumentActivePartner
	)

	const { payload = {}, query = {} } = locationState
	const { rootModule } = payload

	const isInwardShipping = rootModule === 'purchase-order'
	const targetAddressSelector = isInwardShipping
		? AuthDuc.selectors.getCurrentOrganization
		: AuthDuc.selectors.getPartnerOrganizationByID

	const targetOrg = useSelector(state =>
		targetAddressSelector(state, activePartner.id)
	)

	const allAddresses = useMemo(() => {
		const { primaryAddress } = targetOrg || {}
		const addresses = primaryAddress ? [primaryAddress] : []

		return addLast(addresses, targetOrg.address || [])
	}, [targetOrg])

	const [activeRow, setActiveRow] = useState(_activeRow || {})
	const [activeSort, setActiveSort] = useState('')

	const [showAddShipping, setShowAddShipping] = useState(false)
	const handleChange = useCallback(
		(action, meta) => {
			if (action === 'initiate_sort') {
				const { type, payload: _payload, query: _query = {} } =
					locationState || {}

				const rootKey = `${rootModule}|${meta.field}`
				let key = `${rootModule}|${meta.field}|${meta.order}`

				if (meta.order === 'asc') {
					setActiveSort('asc')
				} else if (meta.order === 'desc') {
					setActiveSort('desc')
				} else {
					key = ''
					setActiveSort('')
				}

				// filter sorts
				let targetSorts = Array.isArray(_query.sort)
					? _query.sort
					: [_query.sort]
				targetSorts = targetSorts.filter(
					_key => _key && _key.indexOf(rootKey) < 0
				)
				if (key) targetSorts.push(key)

				const finalQuery = merge(_query, { sort: targetSorts })
				if (!targetSorts.length) delete finalQuery.sort

				dispatch(
					MainRouteDuc.creators.redirect(type, _payload, finalQuery, {
						skipRouteThunk: true,
					})
				)
			}

			if (action === 'set_active_selection') {
				const { row } = meta

				setActiveRow(row)
				onChange(row)
			}
		},
		[onChange, dispatch, rootModule, locationState]
	)

	useEffect(() => {
		if (isEmptyObject(activeRow) && allAddresses.length) {
			// if none select, then select primary address as the default
			setActiveRow(allAddresses[0])
			onChange(allAddresses[0])
		}
	}, [activeRow, allAddresses, onChange])

	useEffect(() => {
		if (!_activeRow || !_activeRow.id) onChange(activeRow)
	}, [_activeRow, activeRow, onChange])

	// extract if there is sort associated
	useEffect(() => {
		if (query.sort) {
			// extract the sort
			const rootKey = `${rootModule}|${SORT_FIELD}`
			let targetSorts = Array.isArray(query.sort)
				? query.sort
				: [query.sort]

			targetSorts = targetSorts.filter(key => key.indexOf(rootKey) > -1)
			const _target = targetSorts.pop()
			if (_target) {
				const [_rootModule, sortField, _value] = _target.split('|')
				if (
					sortField === SORT_FIELD &&
					_rootModule === rootModule &&
					_value !== activeSort
				) {
					// trigger the sort handle
					handleChange('initiate_sort', {
						rootModule,
						field: SORT_FIELD,
						order: _value,
					})
				}
			}
		}
	}, [activeSort, handleChange, rootModule, query.sort])

	return (
		<>
			<Modal
				closeable
				show={showAddShipping}
				noPadding
				size="large"
				body={
					<ShippingForm
						onSuccess={() => setShowAddShipping(false)}
						onClose={() => setShowAddShipping(false)}
					/>
				}
				hideButtons
				onClose={() => setShowAddShipping(false)}
			/>
		</>
	)
}

export { ShippingStaticSection, ShippingEditSection }
