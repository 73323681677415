/* eslint-disable radix */
import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import {
	WeighBridgeDuc,
	NAME_ALIASES,
} from 'ui-tdm-app/modules/WeighBridge/duc'
import { CheckBox } from 'ui-lib/components/Checkbox'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { KeyValueSegment } from 'ui-tdm-app/modules/TradeDocumentManager/components/KeyValueSegment'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { Button } from 'ui-lib/components/Button'
import { Input } from 'ui-lib/components/Input'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { isEmptyObject } from 'ui-tdm-app/utils/helpers'
import { useFormik } from 'formik'
import * as yup from 'yup'

const CreateSlip = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const actor = useSelector(AuthDuc.selectors.getActor)

	const locationState = useSelector(WeighBridgeDuc.selectors.location)
	const { payload = {} } = locationState
	const { action } = payload

	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)

	const { fullName, organization = {} } = loggedInUserProfile
	const { id: currentOrganizationID } = organization

	const initialFormValues = useSelector(
		WeighBridgeDuc.selectors.getInitialFormValues
	)

	const entityInfo = useSelector(WeighBridgeDuc.selectors.getEntityInfo)

	const {
		doNumber,
		dispatchDate,
		driverName,
		vehicleNumber,
		entityID,
		totalQty,
		productUOM,
		receivingPartyID,
	} = entityInfo

	const initialValues = {
		...initialFormValues,
		checkedBy: fullName,
		vehicleNum: vehicleNumber !== '---' ? vehicleNumber : '',
	}

	const productName = getIn(entityInfo, ['productInfo', 0, 'label'])

	const productID =
		getIn(entityInfo, ['productInfo', 0, 'id']) ||
		getIn(entityInfo, ['productInfo', 0, 'label'])

	const uom = actor.includes('palmoil_plantation') ? 'Nos' : productName

	const [uploadedTickets, setUploadedTickets] = useState([])
	const file = getIn(uploadedTickets, [0]) || {}

	const getBreadCrumbsList = useCallback(
		actionTitle => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'tradeDocManager',
				isActive: true,
			},
			{
				label: `${t(actionTitle)} ${t('common.weighbridge')}`,
				name: 'create-weighBridge',
				isActive: false,
			},
		],
		[t]
	)

	const actionTitle = NAME_ALIASES[action]
	const breadCrumbs = useMemo(() => getBreadCrumbsList(actionTitle), [
		actionTitle,
		getBreadCrumbsList,
	])

	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'tradeDocManager') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'incoming',
					},
					{},
					{},
					true
				)
			)
		}
	}

	const validationSchema = yup
		.object()
		.shape({
			netWeight: yup
				.number()
				.required(t('validation.netWeightRequired'))
				.positive(t('validation.positiveWght')),
			grossWeight: yup.number().positive(t('validation.positiveWght')),
			tareWeight: yup.number().when(['grossWeight', 'deductionWeight'], {
				is: (grossWeight, deductionWeight) =>
					grossWeight && deductionWeight,
				then: yup.number().positive(t('validation.positiveWght')),
				otherwise: yup
					.number()
					.positive(t('validation.positiveWght'))
					.lessThan(
						yup.ref('grossWeight'),
						t('validation.tareWghtLess')
					),
			}),
			deductionWeight: yup
				.number()
				.moreThan(-1, t('validation.positiveWght')),
			ticketNumber: yup
				.string()
				.matches(/^[a-zA-Z0-9-_ ]+$/, t('validation.tktFmtMismatch')),
			checkedBy: yup.string().required(t('validation.checkedByReq')),
			vehicleNum: yup.string(),
		})
		// eslint-disable-next-line func-names
		.test('sums', function(value) {
			if (!value) return true
			const {
				tareWeight,
				grossWeight,
				netWeight,
				deductionWeight,
			} = value
			let message = ''
			let valid = false

			if (grossWeight || tareWeight) {
				if (deductionWeight !== undefined) {
					valid =
						parseFloat(
							(parseFloat(grossWeight) || 0) -
								(parseFloat(tareWeight) || 0) -
								(parseFloat(deductionWeight) || 0)
						).toFixed(2) === parseFloat(netWeight).toFixed(2)
					message =
						'Nett Weight = Gross Weight - ( Tare Weight + Deduction weight)'
				} else {
					// DeductionWeight is not provided, calculate netWeight without it.
					valid =
						parseFloat(
							(parseFloat(grossWeight) || 0) -
								(parseFloat(tareWeight) || 0)
						).toFixed(2) === parseFloat(netWeight).toFixed(2)
					message = 'Nett Weight = Gross Weight - Tare Weight'
				}
			}

			return (
				valid ||
				this.createError({
					path: 'netWeight',
					message,
				})
			)
		})

	const {
		handleSubmit,
		submitForm,
		values,
		handleChange,
		handleBlur,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: _values => {
			const formValues = {
				..._values,
				productID,
				uom,
				entityID,
				file,
				vehicleNumber,
			}
			switch (action) {
				case 'create': {
					dispatch(
						WeighBridgeDuc.creators.createWeighBridgeSlip(
							formValues,
							currentOrganizationID === receivingPartyID,
							actor,
							t('createWeighBridgeSlip.createSlipSuccessToast')
						)
					)

					break
				}
				// edit is not available
				case 'edit': {
					const updatedValues = {
						...formValues,
						attachmentID: payload.attachmentID,
					}
					dispatch(
						WeighBridgeDuc.creators.editWeighBridgeSlip(
							updatedValues,
							t('createWeighBridgeSlip.updateSlipSuccessToast')
						)
					)

					break
				}

				default:
					break
			}
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.netWeight

	const isMillOrDealer =
		actor[0]?.includes('palmoil_mill') ||
		actor[0]?.includes('palmoil_ffbdealer') ||
		actor[0]?.includes('palmoil_ffbsubdealer')

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Title title={`${t(actionTitle)} ${t('weighbridge.wbSlip')}`} />
			</Box>
			<Spacer size={24} />
			<Box>
				<BoxShadowWrapper>
					<Box padding={16}>
						<KeyValueSegment
							name={t('createWeighBridgeSlip.consgnmntNum')}
							description={doNumber || '---'}
							widthProp="145px"
						/>
						<KeyValueSegment
							name={t('createWeighBridgeSlip.dispatchDate')}
							description={
								getDateByFormat(
									dispatchDate,
									null,
									activeLocale
								) || '---'
							}
							widthProp="145px"
						/>
						<Box
							style={{ borderTop: '1px solid #ddd' }}
							padding="16px 0"
						>
							<KeyValueSegment
								name={t('createWeighBridgeSlip.driver')}
								description={driverName || '---'}
								widthProp="145px"
							/>
							<KeyValueSegment
								name={t('createWeighBridgeSlip.vehicleNumber')}
								description={vehicleNumber || '---'}
								widthProp="145px"
							/>
							<KeyValueSegment
								name={t('createWeighBridgeSlip.product')}
								description={productName || '---'}
								widthProp="145px"
							/>
							<KeyValueSegment
								name={t('createWeighBridgeSlip.quantity')}
								description={
									`${totalQty} ${productUOM}` || '---'
								}
								widthProp="145px"
							/>
						</Box>
					</Box>
				</BoxShadowWrapper>
			</Box>
			<Spacer size={24} />

			<CollapsableSegment
				expand
				title={t('createWeighBridgeSlip.weightDetails')}
				hasError={disableCTA}
				open
				message={
					disableCTA
						? t('validation.collapsableSegmentWarning')
						: t('validation.collapsableSegmentSuccess')
				}
				onToggle={() => undefined}
			>
				<form onSubmit={handleSubmit}>
					<Box row={!isMobile}>
						<Box width={300}>
							<Input
								label={t('createWeighBridgeSlip.netWeight')}
								name="netWeight"
								value={values.netWeight}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.netWeight && errors.netWeight}
								required
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<Input
								label={t('createWeighBridgeSlip.grossWeight')}
								name="grossWeight"
								value={values.grossWeight}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.grossWeight && errors.grossWeight
								}
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<Input
								label={t('createWeighBridgeSlip.tareWeight')}
								name="tareWeight"
								value={values.tareWeight}
								type="number"
								onChange={handleChange}
								onBlur={handleBlur}
								error={touched.tareWeight && errors.tareWeight}
							/>
						</Box>
					</Box>
					<Box row={!isMobile}>
						{isMillOrDealer && (
							<>
								<Box width={300}>
									<Input
										label="Deductions (MT)"
										name="deductionWeight"
										value={values.deductionWeight}
										type="number"
										onChange={handleChange}
										onBlur={handleBlur}
										error={
											touched.deductionWeight &&
											errors.deductionWeight
										}
									/>
								</Box>
								<Spacer size={16} />
							</>
						)}
						<Box width={300}>
							<Input
								label={t('createWeighBridgeSlip.ticketNumber')}
								placeholder={t(
									'createWeighBridgeSlip.ticketNumPlaceholder'
								)}
								name="ticketNumber"
								value={values.ticketNumber}
								type="text"
								onChange={handleChange}
								onBlur={handleBlur}
								error={
									touched.ticketNumber && errors.ticketNumber
								}
							/>
						</Box>
						<Spacer size={16} />
						<Box width={300}>
							<div key={values.checkedBy}>
								<Input
									label={t('createWeighBridgeSlip.checkedBy')}
									name="checkedBy"
									value={values.checkedBy}
									type="text"
									onMouseOut={handleChange}
									onBlur={handleBlur}
									error={
										touched.checkedBy && errors.checkedBy
									}
									required
								/>
							</div>
						</Box>
					</Box>
					<Box row={!isMobile}>
						<Box width={300}>
							<div key={values.vehicleNum}>
								<Input
									label={t(
										'createWeighBridgeSlip.vehicleNumber'
									)}
									name="vehicleNum"
									value={values.vehicleNum}
									type="text"
									onMouseOut={handleChange}
									onBlur={handleBlur}
									error={
										touched.vehicleNum && errors.vehicleNum
									}
								/>
							</div>
						</Box>
					</Box>
					{vehicleNumber !== '---' &&
						currentOrganizationID === receivingPartyID && (
							<Box padding="0 0 24px">
								<CheckBox
									hideError
									onChange={handleChange}
									id="wrongVehicleNumber"
									name="wrongVehicleNumber"
									value={values.wrongVehicleNumber}
									label={t(
										'viewWeighBridgeSlip.truckNumberChanged'
									)}
								/>
							</Box>
						)}
					<Box>
						<FileUpload
							imageZoom
							type="private"
							modalTriggerBtnLabel={t('common.upload')}
							name="weighbridgeTickets"
							size="5242880"
							supportedFileType=".jpeg,.png,.jpg"
							currentUploads={uploadedTickets}
							onDocumentChange={documents => {
								setUploadedTickets(documents)
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</Box>
				</form>
			</CollapsableSegment>
			<Spacer size={16} />
			<Box row justifyContent="flex-end">
				<Box style={{ padding: 2 }} width="200px">
					<Button
						disabled={disableCTA}
						label={t('common.submit')}
						primary
						rounded
						onClick={() => submitForm()}
					/>
				</Box>
			</Box>
		</Box>
	)
}

export { CreateSlip }
