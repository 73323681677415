import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { Box } from 'ui-lib/utils/Box'
import { Label } from 'ui-lib/components/Label'
import { Button, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Input } from 'ui-lib/components/Input'
import { Modal } from 'ui-lib/components/Modal'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Select } from 'ui-lib/components/Select'
import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { DataDisplayBlock } from 'ui-tdm-app/modules/TradeDocumentManager/components/DataDisplayBlock'
import { MONTHS_FOR_BANK } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import tradeDocIcon from 'ui-lib/icons/trade.svg'
import theme from 'ui-lib/utils/base-theme'
import { isEmptyObject, getRandomNumber } from 'ui-tdm-app/utils/helpers'
import { TradeDocDuc } from '../../duc'

const AttachmentSectionWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 10px;
`

const BankStatements = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { isMobile, isSmallDesktopScreen, isTablet } = useSelector(
		AppDuc.selectors.detection
	)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const statements = useSelector(
		TradeDocDuc.selectors.getBankStatementDetails
	)
	const statementsQuery = useSelector(
		TradeDocDuc.selectors.getBankStatementQuery
	)

	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [deleteID, setDeleteID] = useState('')
	const [editID, setEditID] = useState('')
	const [isEdit, setIsEdit] = useState(false)
	const [refreshBool, setRefreshBool] = useState(false)
	const [attachfileArray, setAttachfileArray] = useState([])
	const [searchQuery, setSearchQuery] = useState({})
	const [queryRefresh, setQueryRefresh] = useState(false)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('tdmBankDetails.bankStatements'),
			name: 'bank-statement',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const attachFilesBlock = () => {
		return (
			<Box>
				<Label
					required
					style={{
						width: '94%',
						marginBottom: '10px',
						color: theme.color.accent2,
						fontSize: '14px',
						fontWeight: 500,
					}}
				>
					{t('breadcrumb.attach')} {t('tdmDetailsEntry.files')}
				</Label>
				<AttachmentSectionWrapper>
					<Box
						style={{
							padding: '1px',
							color: theme.color.blue8,
							backgroundColor: theme.color.white,
						}}
					>
						<FileUpload
							modalTriggerBtnLabel={t('common.upload')}
							type="private"
							name="attachments"
							size="2097152"
							supportedFileType=".pdf, .xlsx, .csv"
							newAlignment={!isMobile}
							disableUploadForOne
							width="auto"
							hideButtonOneUpload
							currentUploads={attachfileArray || []}
							onDocumentChange={async documents => {
								if (documents && documents.length > 0) {
									setFieldValue(
										['meta', 'file'],
										documents[0]
									)
								} else {
									setFieldValue(['meta', 'file'], {})
								}
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</Box>
				</AttachmentSectionWrapper>
			</Box>
		)
	}

	const getMonths = [
		{
			name: `${t('tdmDocumentListing.choose')} ${t(
				'tdmBankDetails.month'
			)}`,
			id: '',
		},
		{
			name: t(MONTHS_FOR_BANK[1]),
			id: 1,
		},
		{
			name: t(MONTHS_FOR_BANK[2]),
			id: 2,
		},
		{
			name: t(MONTHS_FOR_BANK[3]),
			id: 3,
		},
		{
			name: t(MONTHS_FOR_BANK[4]),
			id: 4,
		},
		{
			name: t(MONTHS_FOR_BANK[5]),
			id: 5,
		},
		{
			name: t(MONTHS_FOR_BANK[6]),
			id: 6,
		},
		{
			name: t(MONTHS_FOR_BANK[7]),
			id: 7,
		},
		{
			name: t(MONTHS_FOR_BANK[8]),
			id: 8,
		},
		{
			name: t(MONTHS_FOR_BANK[9]),
			id: 9,
		},
		{
			name: t(MONTHS_FOR_BANK[10]),
			id: 10,
		},
		{
			name: t(MONTHS_FOR_BANK[11]),
			id: 11,
		},
		{
			name: t(MONTHS_FOR_BANK[12]),
			id: 12,
		},
	]

	const initialValues = {
		bankName: '',
		year: '',
		month: '',
		meta: { file: {} },
	}
	const currentYear = new Date().getFullYear()
	const yearRegex = /^\d{4}$/
	const currentMonth = new Date().getMonth()

	const validationSchema = yup.object().shape({
		bankName: yup.string().required(t('tdmBankDetails.bankNameErrorMsg')),
		year: yup
			.string()
			.matches(yearRegex, t('tdmBankDetails.yearErrorMsg'))
			.test('is-valid-year', t('tdmBankDetails.yearErrorMsg'), function(
				value
			) {
				const year = parseInt(value, 10)

				return year >= 1900 && year <= currentYear
			})
			.required(t('tdmBankDetails.yearErrorMsg')),
		month: yup.number().required(t('tdmBankDetails.monthErrorMsg')),
	})

	const {
		handleSubmit,
		submitForm,
		resetForm,
		values,
		handleChange,
		handleBlur,
		setFieldValue,
		touched,
		errors,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			setSubmitting(false)
			const data = { ..._values }
			data.organizationID = getCurrentOrgID
			if (isEdit && editID) {
				const item = statements.find(item => item.id === editID)
				const currentItem = { ...item }
				currentItem.bankName = data.bankName
				currentItem.month = data.month
				currentItem.year = data.year
				currentItem.meta = data.meta
				currentItem.id = editID
				currentItem.organizationID = data.organizationID
				dispatch(
					TradeDocDuc.creators.updateBankStatement(
						currentItem,
						editID,
						t('tdmBankDetails.statementUpdateSuccess')
					)
				)
				setIsEdit(false)
				setRefreshBool(!refreshBool)
				setEditID('')
			} else {
				dispatch(
					TradeDocDuc.creators.addNewBankStatement(
						data,
						t('tdmBankDetails.statementCreateSuccess')
					)
				)
			}
			resetForm()
			setAttachfileArray([])
			setRefreshBool(!refreshBool)
		},
	})

	const handleOnChange = (key, change, id) => {
		switch (key) {
			case 'delete': {
				setShowDeleteModal(change)
				setDeleteID(id)

				break
			}
			case 'edit': {
				const currentItem = statements.find(item => item.id === id)
				setFieldValue(['month'], currentItem?.month)
				setFieldValue(['bankName'], currentItem?.bankName)
				setFieldValue(['year'], currentItem?.year)
				setFieldValue(['id'], currentItem?.id)
				setFieldValue(['meta', 'file'], currentItem?.meta.file)
				setAttachfileArray([currentItem?.meta.file])

				setIsEdit(change)
				setRefreshBool(!refreshBool)
				setEditID(id)

				break
			}

			default:
				break
		}
	}

	const disableCTA = !isEmptyObject(errors) || !values?.meta?.file?.id

	useEffect(() => {
		if (isEdit) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}
	}, [isEdit, editID])

	useEffect(() => {
		if (isEmptyObject(searchQuery)) {
			dispatch(TradeDocDuc.creators.setBankStatementQuery({}))
		}
	}, [searchQuery])

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('tdmBankDetails.deleteStatement')}
				body={<div>{t('tdmBankDetails.deleteStatementeNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowDeleteModal(false)
					setDeleteID('')
				}}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						TradeDocDuc.creators.deleteBankStatement(
							getCurrentOrgID,
							deleteID,
							t('toast.deleteSuccess')
						)
					)
					setDeleteID('')
				}}
			/>
			<Box row style={{ padding: '0 5' }} justifyContent="flex-start">
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box
				style={
					isMobile
						? { flexDirection: 'column' }
						: { flexDirection: 'row' }
				}
				justifyContent="space-between"
				alignItems="baseline"
			>
				<Title
					title={t('tdmBankDetails.bankStatements')}
					icon={tradeDocIcon} // change icon
				/>
			</Box>
			<Box style={{ marginBottom: '30px' }}>
				<CollapsableSegment
					expand={statements.length === 0 || editID}
					title={
						isEdit
							? t('tdmBankDetails.updateStatement')
							: t('onboarding.addNew')
					}
					hasError={disableCTA}
					open
					message={
						disableCTA
							? t('validation.collapsableSegmentWarning')
							: t('validation.collapsableSegmentSuccess')
					}
					noBorderColor
				>
					<form onSubmit={handleSubmit}>
						<Box>
							<Box>
								<div key={values}>
									<Box row>
										<Box width="50%" margin="8px 0">
											<Box>
												<Label
													required
													style={{
														width: '94%',
														marginBottom: '10px',
														color:
															theme.color.accent2,
														fontSize: '14px',
														fontWeight: 500,
													}}
												>
													{t(
														'tdmBankDetails.bankName'
													)}
												</Label>
											</Box>
											<Input
												key={refreshBool}
												value={values.bankName}
												name="bankName"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												error={
													touched.bankName &&
													errors.bankName
												}
												extendStyles={{
													border: 'none',
													width: '320px',
													borderRadius: '0px',
													borderBottom: `1px solid ${theme.color.blue8}`,
												}}
											/>
										</Box>
										<Box>
											<Box>
												<Label
													required
													style={{
														width: '94%',
														marginBottom: '10px',
														color:
															theme.color.accent2,
														fontSize: '14px',
														fontWeight: 500,
													}}
												>
													{t('tdmBankDetails.month')}
												</Label>
											</Box>
											<Box key={values?.month}>
												<Select
													required
													name="month"
													placeholder={`${t(
														'tdmDocumentListing.choose'
													)} ${t(
														'tdmBankDetails.month'
													)}`}
													options={getMonths}
													error={
														touched.month &&
														errors.month
													}
													labelKey="name"
													valueKey="id"
													value={getIn(values, [
														'month',
													])}
													onChange={value => {
														setFieldValue(
															['month'],
															value?.id
														)
														if (
															values?.year &&
															parseInt(
																values?.year,
																10
															) === currentYear
														) {
															setTimeout(() => {
																setFieldValue(
																	['month'],
																	value?.id <=
																		currentMonth +
																			1
																		? value?.id
																		: ''
																)
															}, 100)
														}
													}}
													style={{
														border: 'none',
														width: '320px',
														marginBottom: '8px',
														borderBottom: `1px solid ${theme.color.blue8}`,
													}}
													returnOnlyValue
												/>
											</Box>
										</Box>
									</Box>
									<Box row>
										<Box width="50%">
											<Box>{attachFilesBlock()}</Box>
										</Box>
										<Box width="50%" margin="8px 0">
											<Box>
												<Label
													required
													style={{
														width: '94%',
														marginBottom: '10px',
														color:
															theme.color.accent2,
														fontSize: '14px',
														fontWeight: 500,
													}}
												>
													{t('tdmBankDetails.year')}
												</Label>
											</Box>
											<Input
												key={refreshBool}
												value={values.year}
												name="year"
												type="text"
												onChange={e => {
													setFieldValue(
														['year'],
														parseInt(
															e.target.value,
															10
														)
													)
													if (
														parseInt(
															e.target.value,
															10
														) === currentYear &&
														values?.month >
															currentMonth + 1
													) {
														setFieldValue(
															['month'],
															''
														)
													}
												}}
												onBlur={handleBlur}
												error={
													touched.year && errors.year
												}
												extendStyles={{
													border: 'none',
													width: '320px',
													borderRadius: '0px',
													borderBottom: `1px solid ${theme.color.blue8}`,
												}}
											/>
										</Box>
									</Box>
								</div>
							</Box>
						</Box>
					</form>
					<Box
						padding="2px"
						row
						style={{
							marginTop: '25px',
							paddingTop: '25px',
							borderTop: `1px solid ${theme.color.grey4}`,
						}}
					>
						<Button
							disabled={disableCTA}
							label={
								isEdit
									? t('tdmBankDetails.updateStatement')
									: t('tdmBankDetails.addStatement')
							}
							primary
							rounded
							onClick={() => {
								submitForm()
							}}
							extendStyles={{
								marginRight: '25px',
								backgroundColor: disableCTA
									? theme.color.themeGrey1
									: theme.color.blue8,
								border: disableCTA
									? theme.color.themeGrey1
									: theme.color.blue8,
							}}
						/>
						<ButtonWithNoBorder
							label={t('common.cancel')}
							onClick={() => {
								setIsEdit(false)
								setRefreshBool(!refreshBool)
								setEditID('')
								resetForm()
								setAttachfileArray([])
							}}
						/>
					</Box>
				</CollapsableSegment>
			</Box>
			<Box
				style={{
					marginTop: '100px',
					marginBottom: '25px',
					padding: '30px 30px 0px',
					border: `1px solid ${theme.color.grey4}`,
				}}
			>
				<Box
					row={!isSmallDesktopScreen && !isMobile}
					justifyContent="space-between"
					key={queryRefresh}
				>
					<Box
						row={!isMobile && !isTablet}
						justifyContent="space-between"
						width={isSmallDesktopScreen ? '100%' : '80%'}
					>
						<Box>
							<Input
								name="bankNamefilter"
								type="text"
								placeholder={t('tdmBankDetails.bankName')}
								onChange={e => {
									if (e.target.value) {
										setSearchQuery({
											...searchQuery,
											bankName: e.target.value,
										})
									} else {
										const currentQuery = { ...searchQuery }
										delete currentQuery.bankName
										setSearchQuery(currentQuery)
									}
								}}
								extendStyles={{
									width: isSmallDesktopScreen
										? '200px'
										: '250px',
									borderRadius: '5px',
								}}
							/>
						</Box>
						<Box>
							<Select
								name="monthfilter"
								placeholder={t('tdmBankDetails.month')}
								options={getMonths}
								value=""
								labelKey="name"
								valueKey="id"
								onChange={value => {
									if (value?.id) {
										setSearchQuery({
											...searchQuery,
											month: value?.id,
										})
									} else {
										const currentQuery = { ...searchQuery }
										delete currentQuery.month
										setSearchQuery(currentQuery)
									}
								}}
								style={{
									width: isSmallDesktopScreen
										? '200px'
										: '250px',
									borderRadius: '5px',
									border: `1px solid ${theme.color.blue8}`,
								}}
								returnOnlyValue
							/>
						</Box>
						<Box>
							<Input
								name="yearfilter"
								type="number"
								placeholder={t('tdmBankDetails.year')}
								onChange={e => {
									if (e.target.value) {
										setSearchQuery({
											...searchQuery,
											year: e.target.value,
										})
									} else {
										const currentQuery = { ...searchQuery }
										delete currentQuery.year
										setSearchQuery(currentQuery)
									}
								}}
								extendStyles={{
									width: isSmallDesktopScreen
										? '200px'
										: '250px',
									borderRadius: '5px',
								}}
							/>
						</Box>
					</Box>
					<Box
						row
						style={{
							marginBottom: isSmallDesktopScreen ? '30px' : '0px',
						}}
					>
						<Box>
							<Button
								disabled={isEmptyObject(searchQuery)}
								label={t('schedulerListing.search')}
								primary
								rounded
								onClick={() => {
									dispatch(
										TradeDocDuc.creators.setBankStatementQuery(
											searchQuery
										)
									)

									dispatch(
										TradeDocDuc.creators.getBankStatementDetails()
									)
								}}
								extendStyles={{
									marginRight: '10px',
									backgroundColor: isEmptyObject(searchQuery)
										? theme.color.themeGrey1
										: theme.color.blue8,
									border: isEmptyObject(searchQuery)
										? theme.color.themeGrey1
										: theme.color.blue8,
								}}
							/>
						</Box>
						<Box>
							<ButtonWithNoBorder
								label={t('common.clear')}
								onClick={() => {
									setSearchQuery({})
									setQueryRefresh(!queryRefresh)
									if (!isEmptyObject(statementsQuery)) {
										dispatch(
											TradeDocDuc.creators.setBankStatementQuery(
												{}
											)
										)
										dispatch(
											TradeDocDuc.creators.getBankStatementDetails()
										)
									}
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box>
				{statements.length ? (
					(statements || []).map(statement => (
						<DataDisplayBlock
							isMobile={isMobile}
							id={statement.id}
							t={t}
							dataDetails={statement}
							isOnlyOneStatementAvailable={statement.length === 1}
							handleOnChange={(key, change, id) => {
								handleOnChange(key, change, id)
							}}
						/>
					))
				) : (
					<Box style={{ minHeight: 300 }}>
						<ErrorBlock
							hideButton
							status="empty"
							message={t('common.errorBlockMessage')}
						/>
					</Box>
				)}
			</Box>
		</>
	)
}

export { BankStatements }
