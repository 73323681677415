/* @flow */
import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	useContext,
} from 'react'
import styled from 'styled-components'
import { FormClose } from 'grommet-icons'
import {
	Button,
	Select as GrommSelect,
	CheckBox,
	Text,
	Box as GrommBox,
	ThemeContext,
} from 'grommet'
import { getIn } from 'timm'
import SelectWithCreate from 'react-select/creatable'
import { Box } from '../../utils/Box'
import { Label } from '../Label'
import { FieldText } from '../FieldText'
import request from '../../utils/request'
import themes from '../../utils/base-theme'

type Props = {
	value?: string,
	options: Array<string | { name: string, label: string }>,
	name: string,
	label?: string,
	returnOnlyValue?: boolean,
	onChange: (param: {} | string) => void,
	required?: boolean,
	placeholder?: string,
	error?: string,
	resetOption?: boolean,
	disabledIndexArr?: Array<number>,
	hideError?: boolean,
	noBorder?: boolean,
	style?: boolean,
	labelKey?: string,
	valueKey?: string,
	plain?: boolean,
	padLeft?: boolean,
	disabled?: boolean,
	customInputPadding?: number,
	buttonFontSize?: number,
	searchURL?: string,
	disableChooseOne?: boolean,
}

const PlaceHolderElement = ({ theme, placeholder }) => (
	<div style={{ marginTop: -1 }}>
		<span style={{ color: theme.color.grey7 }}>{placeholder}</span>
		<span style={{ color: theme.color.error }}> * </span>
	</div>
)

const SelectBox = styled(Box)(p => ({
	background: p.theme.color.white,
	'> svg': {
		marginLeft: 0,
		marginRight: 0,
	},
	'& input': {
		padding: p.customInputPadding || '10px',
		// textAlign: 'center',
	},
	'> button': {
		width: '100%',
		lineHeight: 1,
		fontSize: p.buttonFontSize || p.theme.fontSize.l,
		paddingRight: 6,
		border: 'none',
		paddingLeft: p.padLeft ? 6 : 'initial',
	},
	...(!p.plain &&
		(p.noBorder
			? {
					border: 'none',
					// borderBottom: '1px solid #0b20aa',
					borderRadius: 0,
			  }
			: { border: '1px solid #0b20aa', borderRadius: 4 })),
	...(p.style && {
		border: `1px solid ${p.theme.color.grey3}`,
		borderRadius: 0,
	}),
	...(p.colorFromOptions && {
		color: p.colorFromOptions,
	}),
}))
const Select = ({
	value: valueFromProp,
	options,
	placeholder,
	name,
	label,
	required,
	returnOnlyValue,
	onChange: onChangeProp,
	error,
	resetOption,
	disabledIndexArr,
	disabled,
	hideError,
	noBorder,
	labelKey,
	valueKey,
	padLeft,
	plain,
	customInputPadding,
	buttonFontSize,
	dropHeight = 'medium',
	style,
}: Props) => {
	const theme = useContext(ThemeContext)
	let _value = ''
	if (valueKey) {
		_value = // $FlowFixMe
			options.find(opt => opt[valueKey] === valueFromProp) || {} || ''
	} else if (options.includes(valueFromProp)) _value = valueFromProp

	const [value, setValue] = useState(_value)

	// useEffect(() => {
	// 	if (_value) setValue(_value)
	// }, [_value])

	const uniqueID = name || label || '---'

	const onChange = ({ option }) => {
		setValue(option)
		onChangeProp(returnOnlyValue ? option : { [uniqueID]: option })
	}

	let PlaceHolder = placeholder
	if (!label && required)
		PlaceHolder = (
			<PlaceHolderElement theme={theme} placeholder={placeholder} />
		)
	if (value) PlaceHolder = ''

	return (
		<Box overflow>
			{label && (
				<Label
					for={`select-drop-${uniqueID}`}
					required={required || false}
					style={{ marginBottom: 5 }}
					black
				>
					{label}
				</Label>
			)}
			<SelectBox
				id={`select-drop-${uniqueID}`}
				buttonFontSize={buttonFontSize}
				row
				noBorder={noBorder}
				style={style}
				plain={plain}
				padLeft={padLeft}
				customInputPadding={customInputPadding}
				colorFromOptions={value.color}
			>
				<GrommSelect
					id="select-list"
					placeholder={PlaceHolder}
					options={options}
					value={value}
					onChange={onChange}
					disabled={disabledIndexArr || disabled}
					plain={noBorder}
					labelKey={labelKey}
					valueKey={valueKey}
					dropHeight={dropHeight}
					dropProps={{
						styles: {
							border: '1px solid #ababab',
							lineHeight: 'initial',
						},
					}}
				/>
				{!disabled && resetOption && (
					<Button
						style={{ marginLeft: 5 }}
						onClick={() => onChange({ option: '' })}
						disabled={!value}
						plain
						label="Clear"
					/>
				)}
			</SelectBox>

			{!hideError && (
				<FieldText customLeftMargin="10px" error show={!!error}>
					{error}
				</FieldText>
			)}
		</Box>
	)
}

const RegexForStrip = /[-\\^$*+?.()|[\]{}]/g

const SelectSearch = ({
	value: valueFromProp,
	options: defaultOptions,
	placeholder,
	name,
	label,
	required = false,
	returnOnlyValue,
	onChange: onChangeProp,
	error,
	resetOption,
	disabledIndexArr,
	hideError,
	disabled,
	disableChooseOne,
	labelKey,
	valueKey,
	buttonFontSize,
	noBorder,
	plain,
	padLeft,
	customInputPadding,
	style,
	searchURL = '',
	parentStyle = {},
	dropHeight = 'medium',
	defaultLabel = 'Choose One',
}: Props) => {
	let _value = ''
	if (valueKey) {
		_value = // $FlowFixMe
			defaultOptions.find(opt => opt[valueKey] === valueFromProp) || ''
	} else if (defaultOptions.includes(valueFromProp)) _value = valueFromProp
	const [value, setValue] = useState(_value)

	const [options, setOptions] = useState(defaultOptions)
	const uniqueID = name || label || '---'

	const onChange = ({ option }) => {
		if (option[valueKey] === 'chooseOne') {
			setValue('')
			onChangeProp(returnOnlyValue ? '' : { [uniqueID]: '' })
		} else {
			setValue(option)
			onChangeProp(returnOnlyValue ? option : { [uniqueID]: option })
		}
	}

	useEffect(() => {
		if (_value) setValue(_value)
	}, [_value])
	useEffect(() => {
		const isCheck = getIn(defaultOptions, [0]) || {}

		const _key = valueKey || 'name'
		const _label = labelKey || 'label'
		if (!disableChooseOne) {
			if (isCheck[_key] !== 'chooseOne') {
				const obj = {}
				obj[_key] = 'chooseOne'
				obj[_label] = defaultLabel

				defaultOptions.splice(0, 0, obj)
				setOptions(defaultOptions)
			}
		}
	}, [defaultLabel, defaultOptions, disableChooseOne, labelKey, valueKey])

	const onSearch = useCallback(
		text => {
			const url = `${searchURL}?query=${text}`
			if (searchURL) {
				if (text.length > 3) {
					request(url).then(success => {
						const list = getIn(success, ['data', 'list']) || []

						const defaultList = list.map(({ name: _name, id }) => {
							const obj = {}
							const _key = valueKey || 'name'
							const _label = labelKey || 'label'

							obj[_key] = id
							obj[_label] = _name

							return obj
						})
						setOptions(defaultList)
					})
				}
			} else {
				// The line below escapes regular expression special characters:
				// [ \ ^ $ . | ? * + ( )
				const escapedText = text.replace(RegexForStrip, '\\$&')

				// Create the regular expression with modified value which
				// handles escaping special characters. Without escaping special
				// characters, errors will appear in the console
				const exp = new RegExp(escapedText, 'i')

				setOptions(
					defaultOptions.filter(o =>
						typeof o === 'string' ? exp.test(o) : exp.test(o.label)
					)
				)
			}
		},
		[searchURL, valueKey, labelKey, defaultOptions]
	)

	return (
		<Box overflow>
			{label && (
				<Label
					for={`select-drop-${uniqueID}`}
					required={required}
					style={{ marginBottom: 5 }}
				>
					{label}
				</Label>
			)}
			<Box row overflow style={parentStyle}>
				<SelectBox
					id={`select-drop-${uniqueID}`}
					buttonFontSize={buttonFontSize}
					row
					noBorder={noBorder}
					style={style}
					plain={plain}
					padLeft={padLeft}
					customInputPadding={customInputPadding}
				>
					<GrommSelect
						id="select-list"
						placeholder={placeholder}
						options={options}
						value={value}
						labelKey={labelKey}
						valueKey={valueKey}
						onChange={onChange}
						dropHeight={dropHeight}
						onClose={() => setOptions(defaultOptions)}
						disabled={disabledIndexArr || disabled}
						onSearch={onSearch}
					/>
				</SelectBox>
				{resetOption && (
					<Button
						style={{ marginLeft: 5 }}
						onClick={() => onChange({ option: '' })}
						disabled={!value}
						plain
						label="Clear"
					/>
				)}
			</Box>
			{!hideError && (
				<FieldText customLeftMargin="10px" error show={!!error}>
					{error}
				</FieldText>
			)}
		</Box>
	)
}

const CustomSelectSearch = ({
	value = [],
	options,
	placeholder,
	onChange: onChangeProp,
	emptySearchMessage,
	disabled,
	disabledArray,
}: Props) => {
	const [selectedContentPartners, setSelectedContentPartners] = useState(
		value
	)
	const [contentPartners, setContentPartners] = useState(options)
	const [searching, setSearching] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const selectRef = useRef()

	const clearContentPartners = () => {
		setSelectedContentPartners([])
	}

	useEffect(() => {
		const filterContentPartners = options.filter(
			s => s.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
		)

		setTimeout(() => {
			setSearching(false)
			setContentPartners(filterContentPartners)
		}, 500)
	}, [searching, searchQuery, options])

	const renderOption = ({ name }) => (
		<Box
			padding={8}
			direction="row"
			align="center"
			style={{
				display: '-webkit-inline-box',
			}}
		>
			<CheckBox
				tabIndex="-1"
				checked={selectedContentPartners.some(
					partner => partner.name === name
				)}
				label={<Text size="small">{name}</Text>}
				// onChange={() => {}}
			/>
		</Box>
	)

	const renderContentPartners = () => (
		<GrommBox
			direction="row"
			gap="xsmall"
			pad={{ left: 'small', vertical: 'small' }}
			align="center"
			flex
		>
			<GrommBox
				background="brand"
				round="medium"
				align="center"
				justify="center"
				pad={{ horizontal: 'xsmall' }}
				style={{ minWidth: '21px' }}
			>
				<Text size="small">{selectedContentPartners.length}</Text>
			</GrommBox>
			<GrommBox flex>
				<Text size="small" truncate>
					{selectedContentPartners.length > 1
						? 'multiple'
						: selectedContentPartners.map(({ name }) => name)}
				</Text>
			</GrommBox>
			<Button
				href="#"
				onFocus={event => event.stopPropagation()}
				onClick={event => {
					event.preventDefault()
					event.stopPropagation()
					clearContentPartners()
					selectRef.current.focus()
				}}
			>
				<GrommBox background="gray" round="full">
					<FormClose style={{ width: '20px', height: '20px' }} />
				</GrommBox>
			</Button>
		</GrommBox>
	)

	const sortContentPartners = selectedPartnerNames => (p1, p2) => {
		const p1Exists = selectedPartnerNames.includes(p1.name)
		const p2Exists = selectedPartnerNames.includes(p2.name)

		if (!p1Exists && p2Exists) {
			return 1
		}
		if (p1Exists && !p2Exists) {
			return -1
		}
		if (p1.name.toLowerCase() < p2.name.toLowerCase()) {
			return -1
		}

		return 1
	}

	const getIndex = () => {
		const arr = []
		if (disabledArray?.length > 0) {
			contentPartners.forEach((item, index) => {
				if (disabledArray.includes(item.id)) {
					arr.push(index)
				}
			})
		}

		return arr
	}

	return (
		<Box fill align="center" justify="center" width="medium">
			<GrommSelect
				disabled={getIndex(disabledArray) || disabled}
				ref={selectRef}
				closeOnChange={false}
				placeholder={placeholder}
				searchPlaceholder={placeholder}
				emptySearchMessage={emptySearchMessage}
				searching={searching}
				value={
					selectedContentPartners.length
						? renderContentPartners()
						: undefined
				}
				selected={selectedContentPartners.map(option =>
					contentPartners.indexOf(option)
				)}
				options={contentPartners}
				onChange={({ option }) => {
					const newSelectedPartners = [...selectedContentPartners]
					const seasonIndex = newSelectedPartners
						.map(({ name }) => name)
						.indexOf(option.name)
					if (seasonIndex >= 0) {
						newSelectedPartners.splice(seasonIndex, 1)
					} else {
						newSelectedPartners.push(option)
					}
					const selectedPartnerNames = newSelectedPartners.map(
						({ name }) => name
					)
					const sortedContentPartners = [...options].sort(
						sortContentPartners(selectedPartnerNames)
					)
					setSelectedContentPartners(newSelectedPartners)
					setContentPartners(sortedContentPartners)
					onChangeProp(newSelectedPartners)
				}}
				onSearch={query => {
					setSearching(true)
					setSearchQuery(query)
				}}
			>
				{renderOption}
			</GrommSelect>
		</Box>
	)
}

const MultiSelectWithSearch = ({
	value = [],
	options,
	placeholder,
	onChange: onChangeProp,
	emptySearchMessage,
	disabled,
	disabledIndexArr,
}: Props) => {
	const [selectedContentPartners, setSelectedContentPartners] = useState(
		value
	)
	const [contentPartners, setContentPartners] = useState(options)
	const [searching, setSearching] = useState(false)
	const [searchQuery, setSearchQuery] = useState('')

	const selectRef = useRef()

	const clearContentPartners = () => {
		setSelectedContentPartners([])
	}
	useEffect(() => {
		setSelectedContentPartners(value)
	}, [value])

	useEffect(() => {
		const filterContentPartners = options.filter(
			s => s.name.toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0
		)
		setTimeout(() => {
			setSearching(false)
			setContentPartners(filterContentPartners)
		}, 500)
	}, [searching, searchQuery, options])

	const renderOption = ({ id, name }) => {
		return (
			<Box
				padding={8}
				direction="row"
				align="center"
				style={{
					display: '-webkit-inline-box',
				}}
			>
				<CheckBox
					tabIndex="-1"
					checked={selectedContentPartners.some(
						partner => partner === id
					)}
					label={<Text size="small">{name}</Text>}
				/>
			</Box>
		)
	}

	const renderContentPartners = () => (
		<GrommBox
			direction="row"
			gap="xsmall"
			pad={{ left: 'small', vertical: 'small' }}
			align="center"
			flex
		>
			<GrommBox
				background="brand"
				round="medium"
				align="center"
				justify="center"
				pad={{ horizontal: 'xsmall' }}
				style={{ minWidth: '21px' }}
			>
				<Text size="small">{selectedContentPartners.length}</Text>
			</GrommBox>
			<GrommBox flex>
				<Text size="small" truncate>
					{selectedContentPartners.length > 1
						? 'multiple'
						: contentPartners.map(option =>
								selectedContentPartners.includes(option.id)
									? option.name
									: ''
						  )}
				</Text>
			</GrommBox>
			<Button
				href="#"
				onFocus={event => event.stopPropagation()}
				onClick={event => {
					event.preventDefault()
					event.stopPropagation()
					clearContentPartners()
					selectRef.current.focus()
				}}
			>
				<GrommBox background="gray" round="full">
					<FormClose style={{ width: '20px', height: '20px' }} />
				</GrommBox>
			</Button>
		</GrommBox>
	)

	return (
		<>
			<Box fill align="center" justify="center" width="medium">
				<GrommSelect
					disabled={disabledIndexArr || disabled}
					ref={selectRef}
					closeOnChange={false}
					placeholder={placeholder}
					searchPlaceholder={placeholder}
					emptySearchMessage={emptySearchMessage}
					searching={searching}
					value={
						selectedContentPartners.length
							? renderContentPartners()
							: undefined
					}
					selected={contentPartners.map(option =>
						selectedContentPartners.indexOf(option.id)
					)}
					options={contentPartners}
					onChange={({ option }) => {
						const newSelectedPartners = [...selectedContentPartners]
						const seasonIndex = newSelectedPartners.indexOf(
							option.id
						)

						if (seasonIndex >= 0) {
							newSelectedPartners.splice(seasonIndex, 1)
						} else {
							newSelectedPartners.push(option.id)
						}
						setSearchQuery('')
						setSelectedContentPartners(newSelectedPartners)
						onChangeProp(newSelectedPartners)
					}}
					onSearch={query => {
						setSearching(true)
						setSearchQuery(query)
					}}
				>
					{renderOption}
				</GrommSelect>
			</Box>
		</>
	)
}

const CreatableSelect = ({
	value: _value = '',
	options = [],
	onChange: onChangeProp,
	disabled,
	error,
	hideError,
	disableBoxShadow,
	minLenth,
	returnOnlyValue,
	singleBorderOnly = false,
}: Props) => {
	const val = options.find(c => c.value === _value)
	const [inputValue, setInputValue] = useState('')

	const noOptionsMessage = () =>
		minLenth && inputValue.length < minLenth
			? 'Search input must be at least 3 characters'
			: 'No options'

	const getBorder = (
		disabledForBorder,
		errorForBorder,
		singleBorderOnlyForBorder
	) => {
		let border = `1px solid ${themes.color.blue5}`
		if (disabledForBorder) {
			border = `1px solid ${themes.color.blue6}`
		} else if (errorForBorder) {
			border = `1px solid ${themes.color.red1}`
		} else if (singleBorderOnlyForBorder) {
			border = ''
		}

		return border
	}

	return (
		<>
			<SelectWithCreate
				isDisabled={disabled}
				components={{
					IndicatorSeparator: () => null,
				}}
				styles={{
					dropdownIndicator: base => ({
						...base,
						color: disabled
							? themes.color.blue6
							: themes.color.blue1,
						svg: {
							width: '24px',
							height: '24px',
						},
					}),
					menuList: base => ({
						...base,
						maxHeight: '170px',
					}),
					control: baseStyles => ({
						...baseStyles,
						background: disabled
							? `${themes.color.grey11} 0% 0% no-repeat padding-box`
							: `${themes.color.white} 0% 0% no-repeat padding-box`,
						boxShadow:
							!disableBoxShadow &&
							(disabled
								? '0px 6px 16px #05050707'
								: `0px 2px 8px ${themes.color.blue6}`),
						// eslint-disable-next-line no-nested-ternary
						border: getBorder(disabled, error, singleBorderOnly),
						borderStyle: singleBorderOnly ? 'hidden' : 'soild',
						borderBottom:
							singleBorderOnly &&
							`1px solid ${themes.color.blue5}`,
						borderRadius: '4px',
						opacity: 1,
						padding: '2px 0px 2px 2px',
						'&:hover': {
							// eslint-disable-next-line no-nested-ternary
							border: getBorder(
								disabled,
								error,
								singleBorderOnly
							),
							borderBottom:
								singleBorderOnly &&
								`1px solid ${themes.color.blue5}`,
						},
						maxHeight: '100px',
					}),
				}}
				options={
					minLenth && inputValue.length < minLenth ? [] : options
				}
				noOptionsMessage={noOptionsMessage}
				onInputChange={inputVal => {
					setInputValue(inputVal)
				}}
				value={val || { value: _value, label: _value }}
				onChange={option => {
					onChangeProp(returnOnlyValue ? option.value : option)
				}}
			/>
			{!hideError && (
				<FieldText
					customLeftMargin="10px"
					error
					show={!!error}
					minHeight={singleBorderOnly ? '0px' : '24px'}
				>
					{error}
				</FieldText>
			)}
		</>
	)
}

export {
	Select,
	SelectSearch,
	CustomSelectSearch,
	MultiSelectWithSearch,
	CreatableSelect,
}
