import React from 'react'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { useSelector } from 'react-redux'
import { UnexpectedErrorBlock } from 'ui-tdm-app/modules/App/components/Error'
import { AttachDetailsEntry } from './AttachDetailsEntry'
import { UploadDocuments } from './UploadDocuments'
import { NewPreview } from './NewPreview'

const CreateDocument = () => {
	const { payload = {} } = useSelector(TradeDocDuc.selectors.location)
	const { action, rootModule } = payload

	if (action === 'attach-details-confirm') {
		return <NewPreview />
	}

	if (action === 'attachDetails') {
		if (
			rootModule === 'upload-delivery-order' ||
			rootModule === 'upload-weighbridge'
		) {
			return <UploadDocuments />
		}

		return <AttachDetailsEntry />
	}

	return <UnexpectedErrorBlock status={401} />
}

export { CreateDocument }
