import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { initiateSort } from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import {
	getColumnConfig,
	rfqColumnConfig,
	quoteColumnConfig,
} from 'ui-tdm-app/modules/TradeDocumentManager/components/Columns'
import { Modal } from 'ui-lib/components/Modal'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { RemarksConfirmation } from 'ui-tdm-app/modules/TradeDocumentManager/components/RemarksConfirmation'
import { DRAFT_TAB_CONFIG } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { addFirst, getIn } from 'timm'
import { Label, P } from 'ui-lib/components/Typography'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'
import { Table } from 'ui-lib/components/Table'
import { ButtonWithDrop, Button } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { Box } from 'ui-lib/utils/Box'
import tradeDocIcon from 'ui-lib/icons/trade.svg'

const CTAWrapper = styled(Box)(p => ({
	padding: 20,
	minHeight: 62,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	border: `1px solid ${p.theme.color.grey4}`,
}))

const Tab = ({ activeTab, handleTabChange, t }) => {
	return (
		<Box row style={{ marginTop: '20px' }}>
			<Box
				onClick={() => handleTabChange('incoming')}
				style={{
					padding: '20px 40px',
					borderBottom:
						activeTab === 'incoming'
							? '2px solid #fff'
							: '2px solid transparent',
					borderRight: '1px solid rgb(204, 204, 204)',
					color:
						activeTab === 'incoming' ? '#fff' : 'rgb(38, 59, 151)',
					backgroundColor:
						activeTab === 'incoming' ? 'rgb(38, 59, 151)' : '#fff',
					cursor: 'pointer',
					boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
					borderRadius: '4px 0 0 4px',
					width: '50%',
					textAlign: 'center',
				}}
			>
				{t('common.incoming')}
			</Box>
			<Box
				onClick={() => handleTabChange('outgoing')}
				style={{
					padding: '20px 40px',
					borderBottom:
						activeTab === 'outgoing'
							? '2px solid #fff'
							: '2px solid transparent',
					borderRight: '1px solid rgb(204, 204, 204)',
					color:
						activeTab === 'outgoing' ? '#fff' : 'rgb(38, 59, 151)',
					backgroundColor:
						activeTab === 'outgoing' ? 'rgb(38, 59, 151)' : '#fff',
					cursor: 'pointer',
					boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
					borderRadius: '0 4px 4px 0',
					width: '50%',
					textAlign: 'center',
				}}
			>
				{t('common.outgoing')}
			</Box>
		</Box>
	)
}

const getTabComponents = ({
	type,
	sortsMap,
	rows,
	onChange,
	orgDetails,
	loadingStatus = [],
	activeLocale,
	actor,
	t,
	selectedCPID,
	getCurrentOrgID,
	isMobile,
	uploadDOAcceptedList = [],
	isIframeForTeamsApp = false,
}) => ({ name, title }) => () => {
	let columns
	columns = getColumnConfig({
		type,
		docType: name,
		sortsMap: sortsMap[name] || {},
		onChange,
		orgDetails,
		activeLocale,
		actor,
		t,
		selectedCPID,
		getCurrentOrgID,
		isMobile,
		uploadDOAcceptedList,
		isIframeForTeamsApp,
	})
	if (name === 'request-for-quote') {
		columns = rfqColumnConfig({
			t,
			type,
			actor,
			onChange,
		})
	}
	if (name === 'quote') {
		columns = quoteColumnConfig({
			t,
			type,
			actor,
			onChange,
		})
	}

	return (
		<Box
			style={{
				overflow: 'auto',
				position: 'relative',
				backgroundColor: '#fff',
			}}
		>
			{loadingStatus.includes(name) && <TransparentBgSpinner />}
			<Box overflow style={{ minHeight: 395 }}>
				<Table
					noRoundedBorder
					columnConfig={columns}
					rowData={rows[name] || []}
					mobileViewCSSOn={isMobile}
				/>
				{!(rows[name] || []).length && (
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				)}
			</Box>
			<CTAWrapper>
				{!(name === 'draft') && (
					<Label
						color="inherit"
						bold
						small
						link
						as="a"
						onClick={() =>
							onChange('open_listing', {
								type,
								docType: name,
							})
						}
					>{`${t('common.viewAll')} ${
						title ? title.toUpperCase() : ''
					}`}</Label>
				)}
			</CTAWrapper>
		</Box>
	)
}

/** Main Component */
export const MainDashboard = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [activeRecord, setActiveRecord] = useState({})
	const [showRemarkModal, setShowRemarkModal] = useState(false)

	/** Selectors to get from redux */
	const location = useSelector(TradeDocDuc.selectors.location)
	const { query = {} } = location
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)
	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const actor = actorArray[0] || []
	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const {
		incoming: incomingLoadingStates = [],
		outgoing: outgoingLoadingStates = [],
	} = useSelector(TradeDocDuc.selectors.getMainDashboardLoadingStates)
	const activeTabs = useSelector(TradeDocDuc.selectors.getActiveRootModules)
	const uploadDOAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDOAcceptedList
	)
	const uploadDODraftAcceptedList = useSelector(
		TradeDocDuc.selectors.getUploadDODraftAcceptedList
	)
	const activeTypes = useSelector(
		TradeDocDuc.selectors.getMainDashBoardActiveTabs
	)

	const partnerList = useSelector(AuthDuc.selectors.getTargetPartnersOfUser)
	const globalOrgs = useSelector(AuthDuc.selectors.getGlobalOrgs)

	const [fullOrgList, setFullOrgList] = useState([])
	const [outgoingActiveTabs, setOutgoingActiveTabs] = useState(activeTypes)
	const [
		currentActiveIncomingDocuments,
		setCurrentActiveIncomingDocuments,
	] = useState([])

	const [
		currentActiveOutgoingDocuments,
		setCurrentActiveOutgoingDocuments,
	] = useState([])
	const [showModal, setShowModal] = useState(false)
	const [showAcceptAllDODraftModal, setShowAcceptAllDODraftModal] = useState(
		false
	)
	const activeTab = useSelector(
		TradeDocDuc.selectors.getMainDashBoardActiveTab
	)

	const [incomingActiveTab, setIncomingActiveTab] = useState(
		activeTab.incoming
	)
	const [outgoingActiveTab, setOutgoingActiveTab] = useState(
		activeTab.outgoing
	)

	const activeIncomingDocuments = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveIncomingDocuments
	)

	const activeOutgoingDocuments = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveOutgoingDocuments
	)

	const activeCounts = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveCounts
	)

	const activeSorts = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveSorts
	)
	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)

	const [topLevelActiveTab, setTopLevelActiveTab] = useState('incoming')

	const hasIncoming = activeTabs.includes('incoming')

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'trade-doc-dashboard',
			isActive: false,
		},
	]

	const _outgoingActiveTab =
		outgoingActiveTab === ''
			? getIn(activeTypes, [0, 'code'])
			: outgoingActiveTab

	const _incomingActiveTab =
		incomingActiveTab === ''
			? getIn(activeTypes, [0, 'code'])
			: incomingActiveTab

	useEffect(() => {
		if (
			parseInt(activeCounts.outgoing.draft, 10) > 0 &&
			!isIframeForTeamsApp()
		) {
			setOutgoingActiveTabs(addFirst(activeTypes, DRAFT_TAB_CONFIG))
		}
		setOutgoingActiveTab(_outgoingActiveTab)
		setIncomingActiveTab(_incomingActiveTab)
	}, [
		activeTypes,
		activeCounts.outgoing.draft,
		_incomingActiveTab,
		_outgoingActiveTab,
	])

	useEffect(() => {
		setCurrentActiveIncomingDocuments({ ...activeIncomingDocuments })

		setCurrentActiveOutgoingDocuments({ ...activeOutgoingDocuments })
	}, [activeIncomingDocuments, activeOutgoingDocuments, hasIncoming])

	/** Helper for the components */
	const getTabs = useCallback(
		type => {
			const documentCounts =
				type === 'incoming'
					? activeCounts.incoming
					: activeCounts.outgoing

			const targetTypes = activeTypes

			return targetTypes.map(({ code, title }) => ({
				name: code,
				title: t(title),
				IndicatorComponent:
					parseInt(documentCounts[code], 10) > 0 &&
					(({ isActive }) => (
						<DefaultTabIndicator active={isActive}>
							{parseInt(documentCounts[code], 10)}
						</DefaultTabIndicator>
					)),
			}))
		},
		[activeTypes, activeCounts, t]
	)

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						TradeDocDuc.creators.fetchDashboardDocuments,
						location,
						meta.type
					)

					break
				}

				case 'open_document': {
					if (meta.row) {
						dispatch(TradeDocDuc.creators.setActiveTab('original'))

						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
								{
									rootModule: meta.row.docType,
									documentReference: meta.row.docID,
								},
								{
									activeTab: topLevelActiveTab,
								}
							)
						)
					}

					break
				}

				case 'change_status': {
					const { type, docType, row, newStatus } = meta || {}

					if (type && newStatus) {
						setActiveRecord({
							type,
							docType,
							document: row,
							newStatus,
						})
						setShowRemarkModal(true)
					}

					break
				}

				case 'open_listing': {
					const { type, docType } = meta || {}

					// get the active record
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$LISTING,
							{
								rootModule: type,
								submodule: docType,
							}
						)
					)
					break
				}

				case 'open_creation_Workflow': {
					const { docType } = meta || {}

					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$WACTION,
							{
								rootModule: docType,
								action: 'attachDetails',
							}
						)
					)

					break
				}

				case 'create_attachment': {
					const { docType } = meta || {}

					dispatch(
						docType === 'weighbridge-slip'
							? MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.WEIGHBRIDGE$WACTION,
									{
										action: 'create',
									},
									{
										parentDocRef: meta.row.id,
									}
							  )
							: MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.MILL$WACTION,
									{
										rootModule: docType,
										action: 'create',
									},
									{
										parentDocRef: meta.row.id,
									}
							  )
					)
					break
				}

				case 'handle_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDOAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'handle_draft_accept_checkbox': {
					const { checkboxData, entity } = meta || {}

					dispatch(
						TradeDocDuc.creators.setUploadDODraftAcceptedList(
							entity,
							checkboxData
						)
					)

					break
				}

				case 'handle_draft_change': {
					const { data, currentUploadDODraftAcceptedList = [] } =
						meta || {}

					const excelUploads = hasIncoming
						? [...currentActiveIncomingDocuments['excel-uploads']]
						: [...currentActiveOutgoingDocuments['excel-uploads']]
					const foundIndex = excelUploads.findIndex(
						x => x.id === data.id
					)
					excelUploads[foundIndex] = data
					if (currentUploadDODraftAcceptedList?.length > 0) {
						const tempArray = [...currentUploadDODraftAcceptedList]

						const foundIndexAccepted = tempArray.findIndex(
							x => x.id === data.id
						)
						tempArray[foundIndexAccepted] = data
						dispatch(
							TradeDocDuc.creators.setUploadDODraftAcceptedList(
								tempArray,
								{},
								true
							)
						)
					}
					if (hasIncoming) {
						setCurrentActiveIncomingDocuments({
							...currentActiveIncomingDocuments,
							'excel-uploads': excelUploads,
						})
					} else {
						setCurrentActiveOutgoingDocuments({
							...currentActiveOutgoingDocuments,
							'excel-uploads': excelUploads,
						})
					}

					break
				}

				case 'finalize_remark_status': {
					const { remarks } = meta
					const { docID, docNumber } = activeRecord.document || {}
					const documentStatusPropsList = {
						status: activeRecord.newStatus,
						remarks,
						docID,
						docNumber,
						docType: activeRecord.docType,
						successMsg: t('common.statusUpdateSuccess'),
						postChange: () => {
							// retrigger the current route so new data is fetched again.
							dispatch(
								MainRouteDuc.creators.redirect(
									location.type,
									location.payload,
									location.query
								)
							)
						},
					}
					// get the active record
					dispatch(
						TradeDocDuc.creators.changeDocumentStatus(
							documentStatusPropsList
						)
					)

					break
				}

				case 'reset_remark_status': {
					setActiveRecord({})

					break
				}

				case 'open_contract': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.MARKETPLACE$CONTRACTS,
								{
									rootModule:
										meta.type === 'incoming'
											? 'seller'
											: 'buyer',
									action: meta.action,
									documentReference: meta.row.id,
								},
								{},
								{},
								true
							)
						)
					}
					break
				}

				case 'view_quote': {
					if (meta.row) {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$RFQNQUOTEWDOCREFERENCE,
								{
									rootModule: meta.type,
									action: 'view-quote',
									documentReference: meta.row.docID,
								}
							)
						)
					}
					break
				}

				default:
					break
			}
		},
		[
			dispatch,
			location,
			topLevelActiveTab,
			hasIncoming,
			currentActiveIncomingDocuments,
			currentActiveOutgoingDocuments,
			activeRecord,
			t,
		]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const getItems = () => {
		const items = [
			{
				label: t('tdmColumnHeaders.purchase-order').toUpperCase(),
				name: 'purchase-order',
			},
			{
				label: t('tdmColumnHeaders.invoice').toUpperCase(),
				name: 'invoice',
			},
			{
				label: t('tdmColumnHeaders.delivery-order').toUpperCase(),
				name: 'delivery-order',
			},
			{
				label: t('tdmTabConfig.rfq').toUpperCase(),
				name: 'request-for-quote',
			},
		]

		return items
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}

	useEffect(() => {
		dispatch(TradeDocDuc.creators.setUploadDODraftAcceptedList(null, {}))
	}, [dispatch])

	useEffect(() => {
		const currentList = []
		if (partnerList?.length > 0) {
			partnerList.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.name,
						value: element?.id,
					})
				}
			})
		}
		if (globalOrgs?.length > 0) {
			globalOrgs.forEach(element => {
				if (!currentList.find(item => item.id === element?.id)) {
					currentList.push({
						id: element?.id,
						label: element?.label,
						value: element?.id,
					})
				}
			})
		}

		setFullOrgList(currentList)
	}, [partnerList, globalOrgs])

	const handleTabChange = tab => {
		setTopLevelActiveTab(tab)
	}

	useEffect(() => {
		if (query.activeTab) setTopLevelActiveTab(query.activeTab)
	}, [query.activeTab])

	return (
		<>
			<Box id="tdmMain" padding={8} width="100%" height="100%">
				<Modal
					closeable
					show={showModal}
					heading={t('topBar.logout')}
					body={
						<P large bold>
							{t('topBar.logoutMsg')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowModal(false)
					}}
					onConfirm={() => {
						dispatch(AuthDuc.creators.signOutUser())
					}}
					isMobile={isMobile}
				/>
				<Modal
					closeable
					show={showAcceptAllDODraftModal}
					heading={`${t('common.create')} ${t(
						'tdmTabConfig.delivery-orders'
					)}`}
					body={
						<P large bold>
							{t('tdmDocumentListing.acceptAllDOModalContent')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowAcceptAllDODraftModal(false)
					}}
					onConfirm={() => {
						dispatch(
							TradeDocDuc.creators.setDODraftAcceptAllLoading(
								true
							)
						)
						const documentPropsList = {
							rootModule: 'excel-uploads',
							document: uploadDODraftAcceptedList,
							currentDocId: '',
							t,
							doSuccessMsg: t('common.doSuccess'),
							actionType: 'submit',
							rootType: hasIncoming ? 'incoming' : 'outgoing',
						}
						dispatch(
							TradeDocDuc.creators.initiateDocumentSave(
								documentPropsList
							)
						)
						setShowAcceptAllDODraftModal(false)
					}}
					isMobile={isMobile}
				/>
				{!isIframeForTeamsApp() && (
					<>
						<Box
							row
							style={{ padding: '0 5' }}
							justifyContent="flex-start"
						>
							<Breadcrumb
								links={breadCrumbs}
								onClick={target =>
									handleBreadCrumbClick(target)
								}
							/>
						</Box>
						<Box
							style={
								isMobile
									? { flexDirection: 'column' }
									: { flexDirection: 'row' }
							}
							justifyContent="space-between"
							alignItems="baseline"
						>
							<Title
								title={t('mainDashboard.tradeDocManager')}
								icon={tradeDocIcon}
							/>
							<Box>
								{!selectedCPID && (
									<Box width={200} id="tdmCreate">
										<ButtonWithDrop
											disabled={
												incomingLoadingStates.length > 0
											}
											label={t(
												'common.createNew'
											).toUpperCase()}
											items={getItems()}
											onChange={active =>
												handleOnChange(
													'open_creation_Workflow',
													{
														docType: active,
													}
												)
											}
										/>
									</Box>
								)}
							</Box>
						</Box>
					</>
				)}
				{isIframeForTeamsApp() && (
					<div
						style={{
							justifyContent: isMobile ? 'center' : 'end',
							display: isMobile ? 'grid' : 'flex',
						}}
					>
						<Box
							style={{
								marginRight: '20px',
								marginBottom: isMobile ? '10px' : '0px',
							}}
						>
							<Box>
								{!selectedCPID && (
									<Box width={150} id="tdmCreate">
										<ButtonWithDrop
											disabled={
												incomingLoadingStates.length > 0
											}
											label={t(
												'common.createNew'
											).toUpperCase()}
											items={getItems()}
											onChange={active =>
												handleOnChange(
													'open_creation_Workflow',
													{
														docType: active,
													}
												)
											}
											noBackground
											borderRadius="5px"
											// backgroundColor: '#2a2364',
										/>
									</Box>
								)}
							</Box>
						</Box>
						<Box
							style={{
								marginRight: '20px',
								alignItems: 'center',
							}}
						>
							<Button
								extendStyles={{
									width: '100px',
									borderRadius: '4px',
									fontSize: '16px',
									background: '#2A2364',
									padding: '6px',
									textAlign: 'center',
									textDecoration: 'solid',
								}}
								onClick={() => setShowModal(true)}
								label={t('topBar.logout')}
								primary
								plain
							/>
						</Box>
					</div>
				)}

				<Box id="tdmListing">
					<Tab
						activeTab={topLevelActiveTab}
						handleTabChange={handleTabChange}
						t={t}
					/>

					{topLevelActiveTab === 'incoming' && (
						<Tabs
							id="TDMTabs"
							tabs={getTabs('incoming')}
							tabComponentsMap={getTabComponents({
								type: 'incoming',
								sortsMap: activeSorts.incoming,
								rows: currentActiveIncomingDocuments,
								onChange: handleOnChange,
								orgDetails,
								loadingStatus: incomingLoadingStates,
								activeLocale,
								actor,
								t,
								getCurrentOrgID,
								isMobile,
								setFullOrgList,
								selectedCPID,
								uploadDOAcceptedList,
								uploadDODraftAcceptedList,
								isIframeForTeamsApp: isIframeForTeamsApp(),
								fullOrgList,
								partnerList,
								allProducts,
							})}
							activeTabIndex={outgoingActiveTabs.findIndex(
								type => type.code === incomingActiveTab
							)}
							onChange={({ name }) => {
								setIncomingActiveTab(name)
								// dispatch(
								// 	TradeDocDuc.creators.setIncomingActiveTab(
								// 		name
								// 	)
								// )
							}}
							isMobile={isMobile}
						/>
					)}

					{topLevelActiveTab === 'outgoing' && (
						<Tabs
							id="TDMTabs"
							tabs={getTabs('outgoing')}
							tabComponentsMap={getTabComponents({
								type: 'outgoing',
								sortsMap: activeSorts.outgoing,
								rows: currentActiveOutgoingDocuments,
								onChange: handleOnChange,
								orgDetails,
								loadingStatus: outgoingLoadingStates,
								activeLocale,
								isMobile,
								setFullOrgList,
								actor,
								t,
								getCurrentOrgID,
								selectedCPID,
								uploadDODraftAcceptedList,
								isIframeForTeamsApp: isIframeForTeamsApp(),
								fullOrgList,
								partnerList,
								allProducts,
							})}
							activeTabIndex={outgoingActiveTabs.findIndex(
								type => type.code === outgoingActiveTab
							)}
							onChange={({ name }) => {
								setOutgoingActiveTab(name)
								dispatch(
									TradeDocDuc.creators.setOutgoingActiveTab(
										name
									)
								)
							}}
							isMobile={isMobile}
						/>
					)}
				</Box>
			</Box>
			<RemarksConfirmation
				showModal={showRemarkModal}
				handleOnConfirm={remarks => {
					setShowRemarkModal(false)
					handleOnChange('finalize_remark_status', { remarks })
				}}
				handleOnClose={() => {
					setShowRemarkModal(false)
					handleOnChange('reset_remark_status')
				}}
			/>
		</>
	)
}
