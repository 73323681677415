import React, { useState } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import NotificationIcon from 'ui-lib/icons/notification.svg'
import { SmallText } from 'ui-lib/components/Typography'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Modal } from 'ui-lib/components/Modal'
import { isEmptyObject } from 'ui-lib/utils/helpers'
import { Button } from 'ui-lib/components/Button'
import { SelectSearch } from 'ui-lib/components/Select'

export const UserProfile = styled.div(p => ({
	backgroundColor: p.messageModule
		? p.theme.color.pureRed
		: p.theme.color.white,
	borderRadius: p.messageModule ? '50%' : '4px',
	display: 'inline-block',
	verticalAlign: 'middle',
	fontSize: p.messageModule ? '20px' : '36px',
	lineHeight: p.messageModule ? '53px' : '74px',
	textAlign: 'center',
	backgroundPosition: 'unset',
	backgroundSize: 'cover',
	marginRight: '12px',
	height: p.messageModule ? '50px' : '74px',
	width: p.messageModule ? '50px' : '74px',
	overflow: 'hidden',
	...(p.bgUrl && { backgroundImage: `url(${p.bgUrl})` }),
	...(p.messageModule && { fontWeight: 600 }),
	...(p.messageModule && { color: p.theme.color.white }),
}))

export const OfflineToast = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	fontSize: theme.fontSize.s,
	lineHeight: '16px',
	background: '#ccc',
	alignItems: 'center',
	position: 'relative',
	paddingLeft: '1rem',
	paddingRight: '1rem',
	paddingTop: '0.25rem',
	paddingBottom: '0.25rem',
	backgroundColor: 'rgb(254, 215, 215)',
	overflow: 'hidden',
})

export const HeaderRightBlock = styled.div({
	height: 'inherit',
	display: 'flex',
	cursor: 'pointer',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	paddingRight: 20,
})

export const LogoSearch = styled.div({
	height: 'inherit',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
})

export const HeaderProfileText = styled.span(p => ({
	fontWeight: p.fontWeight ? p.fontWeight : 400,
	fontSize: p.fontSize ? p.fontSize : p.theme.fontSize.s,
	color: p.theme.color.white,
	title: p.children,
	display: 'block',
	maxWidth: p.fullMaxWidth ? '218px' : '128px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}))

export const UnOrderedList = styled.ul(p => ({
	transition: 'height 0.3s',
	listStyle: 'none',
	position: 'absolute',
	width: p.width ? p.width : '320px',
	height: p.height,
	top: p.top ? p.top : '70px',
	right: p.right ? p.right : 0,
	background: '#fefefe',
	margin: 'none',
	padding: 'none',
	borderRadius: '0 0 3px 3px',
	overflow: 'hidden',
	boxShadow: 'rgba(0, 0, 0, 0.1) -1px 2px 3px',
}))

export const ListItem = styled.li(p => ({
	margin: 'none',
	padding: p.padding ? p.padding : '16px',
	borderBottom: p.borderBottom ? p.borderBottom : '1px solid rgba(0,0,0,0.1)',
	':hover': {
		cursor: 'pointer',
		background: '#F0F0F1',
	},
	':last-child': {
		border: 'none',
	},
}))

const Counter = styled.span(p => ({
	position: 'absolute',
	right: '15px',
	top: '5px',
	width: '10px',
	height: '10px',
	background: p.theme.color.error,
	fontSize: p.theme.fontSize.xs,
	borderRadius: '50%',
	color: 'white',
	zIndex: '2',
}))

export const NotificationBadge = ({ next, count, size }) => {
	return (
		<>
			{count > 0 && <Counter />}
			<IconWrapper
				size={size}
				style={{
					position: 'relative',
					top: '-4px',
					right: '-10px',
				}}
			>
				{!next && <Icon glyph={NotificationIcon} color="#172B4D" />}
				{next && (
					<NotificationIcon
						color="#172B4D"
						width="inherit"
						height="inherit"
						fill="currentColor"
					/>
				)}
			</IconWrapper>
		</>
	)
}

export const NotificationItem = ({
	t,
	onClick,
	data,
	padding,
	handleUseDateByFormat,
}) => {
	return (
		<ListItem onClick={() => onClick(data)} padding={padding}>
			<SmallText
				fontSize={theme.fontSize.m}
				style={{
					lineHeight: '16px',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					width: '100%',
					display: 'inline-block',
					paddingBottom: '8px',
				}}
				link
				bold
				color="#172B4D"
			>
				{data.meta.messageID
					? t(`common.${data.meta.messageID}`)
					: `${t('breadcrumb.notifications')} - ${
							data.meta.componentRefID
					  }`}
			</SmallText>
			<SmallText
				fontSize={theme.fontSize.xs}
				style={{
					display: 'block',
					lineHeight: '16px',
					color: 'rgba(0, 0, 0, 0.5)',
				}}
			>
				{handleUseDateByFormat(data.createdAt, 'd LLL yyyy, hh:mm a')}
			</SmallText>
		</ListItem>
	)
}

export const SwitchProfile = ({
	isVisible = true,
	list = [],
	onClose = () => {},
	onSubmit = () => {},
	t,
}) => {
	const [showMobileModal, setShowMobileModal] = useState(false)

	const {
		handleSubmit,
		submitForm,
		values,
		handleBlur,
		errors,
		touched,
		isSubmitting,
		setFieldValue,
	} = useFormik({
		initialValues: {
			actor: '',
		},
		enableReinitialize: true,

		onSubmit: (_values, { setSubmitting }) => {
			setSubmitting(false)
			const { actor } = _values
			const creds = list.filter(d => d?.name === actor)
			const { 0: account } = creds
			const { type } = account
			if (type === 'mobile') {
				onClose(false)
				setShowMobileModal(true)
			} else {
				onSubmit(account)
			}
		},
	})

	const disableCTA = !isEmptyObject(errors) || !values.actor
	const options = list.map(d => ({
		name: d?.name,
		label: `${d?.name} - ${d?.type.toUpperCase()}`,
	}))

	return (
		<>
			<Modal
				closeable
				forceCloseviaButton
				size="medium"
				show={showMobileModal}
				body={<div>{t('login.mobileAccount')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowMobileModal(false)}
				onConfirm={() => {
					setShowMobileModal(false)
				}}
				hideButtons
			/>
			<Modal
				closeable
				forceCloseviaButton
				size="medium"
				show={isVisible}
				heading={t('login.welcomeAdmin')}
				body={
					<div>
						<Box style={{ padding: 2, alignItems: 'center' }}>
							<form onSubmit={handleSubmit}>
								<SelectSearch
									value={values.actor}
									name="actor"
									options={options}
									onChange={value => {
										setFieldValue('actor', value.name)
									}}
									returnOnlyValue
									labelKey="label"
									valueKey="name"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									onBlur={handleBlur}
									label="Select the actor"
									error={touched.actor && errors.actor}
								/>
							</form>

							<Button
								label={t('common.submit')}
								isLoading={isSubmitting}
								disabled={disableCTA}
								primary
								rounded
								onClick={() => submitForm()}
							/>
						</Box>
					</div>
				}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => onClose(false)}
				onConfirm={() => {
					onClose(false)
				}}
				hideButtons
			/>
		</>
	)
}

export function getAllowed(rules = [], allowed = [], denied = []) {
	return rules.reduce((agg, rule) => {
		const aggregator = agg

		const isAllowed = !denied.includes(rule) && allowed.includes(rule)

		aggregator[rule] = isAllowed

		return aggregator
	}, {})
}

export function isAtleastOneRuleActive(ruleMap = {}) {
	return Object.keys(ruleMap).some(_key => ruleMap[_key])
}
