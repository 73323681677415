import { getIn } from 'timm'
import { isMarketplaceHost } from '../utils/helpers'

const lookUpConfig = key =>
	getIn(window._env_, [key]) || getIn(process.env, [key]) || ''

// All the config env variables should starts with REACT_APP_
export const getAppVersion = () => lookUpConfig('REACT_APP_VERSION') || ''

export const getCurrentProject = () =>
	lookUpConfig('REACT_APP_PROJECT') ||
	lookUpConfig('NEXT_APP_PROJECT') ||
	'trace'

export const getIAMEndPoint = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_IAM_URL'
			: 'REACT_APP_IAM_URL'
	) || ''}/`

export const getMapBoxAccessToken = () =>
	lookUpConfig('REACT_APP_MAPBOX_ACCESS_TOKEN') || ''

export const getseparatedTDMHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_TDM_HOST'
			: 'REACT_APP_TDM_HOST'
	) ||
		lookUpConfig(
			isMarketplaceHost()
				? 'NEXT_APP_MARKETPLACE_TDM_HOST'
				: 'NEXT_APP_TDM_HOST'
		) ||
		'https://tdm.app.palmoil.dibizglobal.org'}`

export const getOriginHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_ORIGIN_HOST'
			: 'REACT_APP_ORIGIN_HOST'
	) ||
		lookUpConfig(
			isMarketplaceHost()
				? 'NEXT_APP_MARKETPLACE_ORIGIN_HOST'
				: 'NEXT_APP_ORIGIN_HOST'
		) ||
		'https://app.palmoil.dibizglobal.org'}`

export const getSeparatedMarketPlaceHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_MP_HOST'
			: 'REACT_APP_MP_HOST'
	) ||
		lookUpConfig(
			isMarketplaceHost()
				? 'NEXT_APP_MARKETPLACE_MP_HOST'
				: 'NEXT_APP_MP_HOST'
		) ||
		'https://mp.app.palmoil.dibizglobal.org'}`

export const getSeparatedLoginHost = () =>
	`${lookUpConfig(
		isMarketplaceHost()
			? 'REACT_APP_MARKETPLACE_LOGIN_HOST'
			: 'REACT_APP_LOGIN_HOST'
	) ||
		lookUpConfig(
			isMarketplaceHost()
				? 'NEXT_APP_MARKETPLACE_LOGIN_HOST'
				: 'NEXT_APP_LOGIN_HOST'
		) ||
		'https://login.app.palmoil.dibizglobal.org'}`
