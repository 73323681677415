import { Box, Button, CheckBox, Layer, Meter } from 'grommet'
import { Close, Trash } from 'grommet-icons'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Label } from 'ui-lib/components/Label'
import { P } from 'ui-lib/components/Typography'
import { getIAMEndPoint } from 'ui-tdm-app/config'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import theme from 'ui-lib/utils/base-theme'
import { Icon } from 'ui-lib/icons/components/Icon'
import Documents from 'ui-lib/icons/documents.svg'

const GridWrapper = styled(Box)`
	background: url('/images/backgroundfileIcon.png') center no-repeat;
	background-size: 200px 100px;
`

const GridHeader = styled(Box)`
	display: flex;
	flex-direction: row;
	background: #3f56c4;
`
const GridContent = styled(Box)`
	display: flex;
	border: 1px solid lightgray;
	height: 450px;
	overflow-y: auto;
`

const GridItem = styled(Box)`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	min-height: auto;
	flex-grow: ${({ flexGrow }) => flexGrow};
`

const DisclaimerBox = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #cbd2ef73;
	color: #3f56c4;
`

const EllipsisBox = styled(Box)`
	display: flex;
	flex-direction: row;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	align-self: normal;
	align-items: baseline;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: ${({ padding }) => padding || ' 12px 0'};
	flex-basis: ${({ basis }) => basis || '25%'};
	color: ${({ color }) => color || 'black'};
	border-right: ${({ borderRight }) => borderRight || '0px'};
`

const EllipsisContent = styled(Box)`
	display: block;
	overflow: hidden;
	width:95%
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; 
	padding-right:10px;
`

const StyledUploadBox = styled(Box)`
	padding: 20px;
	cursor: pointer;
	background: ${({ isActive }) => (isActive ? '#d7ddf7' : 'white')};
	text-align: center;
`

const AddIcon = styled.img(() => ({
	alignSelf: 'center',
	height: '22px',
	width: '22px',
	margin: '0px 2px',
}))
const UploadedIcon = styled.img(() => ({
	alignSelf: 'center',
	height: '22px',
	width: '22px',
	margin: '0px 6px',
}))

const TrashIcon = styled.img(() => ({
	alignSelf: 'center',
	height: '32px',
	width: '26px',
	margin: '0px 2px',
}))

const FileUploadComponent = ({
	files = [],
	setFiles,
	type,
	isPreview = false,
	isEdit = false,
	isView = false,
}) => {
	const { t } = useTranslation()
	const defaultType = type || 'private'
	const uploadUrl = `${getIAMEndPoint()}files/${defaultType}`

	const [selectedFiles, setSelectedFiles] = useState([])
	const [showUploadModal, setShowUploadModal] = useState(false)
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
	const [errorMessage, setErrorMessage] = useState([])
	const [uploads, setUploads] = useState([])

	const errorMap = {
		'file-invalid-type': 'generalDocument.invalidFileFormat',
		'file-too-large': 'generalDocument.fileTooLarge',
	}

	const transformFileStructure = (file, progress, status = 'uploading') => ({
		name: file.name,
		sizeBytes: file.size,
		progress,
		createdAt: new Date(),
		meta: {
			other: {
				filename: file.name,
			},
		},
		status,
	})

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleFileUploadError = (file, updatedFiles, reject) => {
		let updated = updatedFiles
		const failedFile = transformFileStructure(file, 0, 'failed')
		updated = updatedFiles.map(f => (f.name === file.name ? failedFile : f))
		if (!updated.some(f => f.name === file.name)) {
			updated = [...updated, failedFile]
		}
		setFiles([...updated])
		reject(new Error('Upload failed'))
	}

	const onDropAccepted = useCallback(
		async acceptedFiles => {
			setErrorMessage([])
			setShowUploadModal(false)

			// Initialize an array to track uploaded files in real time
			let updatedFiles = [...files]

			// Iterate over accepted files and handle upload individually
			const promises = acceptedFiles.map(file => {
				return new Promise((resolve, reject) => {
					const formData = new FormData()
					formData.append('file', file)
					formData.append('name', file.name)

					const xhr = new XMLHttpRequest()
					xhr.withCredentials = true // Enable cookies for the request

					// Update progress in real time
					xhr.upload.onprogress = event => {
						if (event.lengthComputable) {
							const progress =
								Math.round((event.loaded / event.total) * 100) -
								1
							const updatedFile = transformFileStructure(
								file,
								progress
							)

							// Update the file's progress
							updatedFiles = updatedFiles.map(f =>
								f.name === file.name ? updatedFile : f
							)

							// If it's a new file, add it to the list
							if (!updatedFiles.some(f => f.name === file.name)) {
								updatedFiles = [...updatedFiles, updatedFile]
							}
							setFiles([...updatedFiles])
						}
					}

					xhr.onload = () => {
						if (xhr.status === 200) {
							const { data } = JSON.parse(xhr.response)
							resolve(data)
						} else {
							handleFileUploadError(file, updatedFiles, reject)
						}
					}

					xhr.onerror = () => {
						handleFileUploadError(file, updatedFiles, reject)
					}
					xhr.open('POST', uploadUrl, true)
					xhr.send(formData)
				})
			})

			const responses = await Promise.all(promises)

			const finalUpdatedFiles = updatedFiles.map(file => {
				const matchingResponseIndex = responses.findIndex(
					response =>
						getIn(response, ['meta', 'other', 'filename']) ===
						getIn(file, ['meta', 'other', 'filename'])
				)

				// If a matching response is found, update the file with the response data at the matching index
				if (matchingResponseIndex !== -1) {
					return responses[matchingResponseIndex]
				}

				// If no matching response, return the original file
				return file
			})
			setFiles(finalUpdatedFiles)
		},
		[files, handleFileUploadError, setFiles, uploadUrl]
	)

	const onDropRejected = filesWithErrors => {
		setErrorMessage([])
		const errors = []
		filesWithErrors.forEach(fileWithError => {
			errors.push({
				path: fileWithError.file.path,
				code: fileWithError.errors[0].code,
				message: fileWithError.errors[0].message,
			})
		})
		setErrorMessage(errors)
	}

	const handleUpload = () => {
		setShowUploadModal(false)
		onDropAccepted(uploads)
		setUploads([])
	}

	const onAddFiles = acceptedFiles => {
		setUploads(prevUploads => [
			...prevUploads,
			...acceptedFiles.map(file =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			),
		])
	}
	const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
		maxSize: 100000000, // 100 MB
		onDropAccepted: showUploadModal ? onAddFiles : onDropAccepted,
		onDropRejected,
		accept: {
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
				'.docx',
			],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
				'.xlsx',
			],
			'application/vnd.ms-powerpoint': ['.ppt'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
				'.pptx',
			],
			'application/pdf': ['.pdf'],
			'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
		},
	})

	const removeFile = index => {
		setUploads(prevUploads => prevUploads.filter((_, i) => i !== index))
	}

	const toggleFileSelection = file => {
		setSelectedFiles(prev =>
			prev.includes(file) ? prev.filter(f => f !== file) : [...prev, file]
		)
	}

	const handleRemoveSelectedFiles = () => {
		if (isEdit) {
			const updatedFiles = files.map(file => {
				if (selectedFiles.includes(file)) {
					return { ...file, status: 'removed' } // Update the file status
				}

				return file
			})
			setFiles(updatedFiles)
		} else {
			setFiles(files.filter(file => !selectedFiles.includes(file)))
		}

		// Reset selections and hide confirmation modal
		setSelectedFiles([])
		setShowDeleteConfirmation(false)
	}

	const formatBytesToMB = (bytes, decimalPlaces = 2) => {
		if (bytes === 0) return '0 MB'
		const mb = bytes / (1024 * 1024)

		return `${mb.toFixed(decimalPlaces)} MB`
	}

	return (
		<Box margin={isView ? '0px' : '20px'}>
			{!isView && (
				<Label style={{ fontWeight: 'bold', fontSize: '18px' }}>
					{t('generalDocument.addDocuments')}
				</Label>
			)}
			{!isView && (
				<Box
					pad="10px 0px"
					flex
					align="center"
					direction="row"
					justify="between"
				>
					<Label style={{ color: '#ACB7CD' }}>
						{t('generalDocument.addDocDesc')}
					</Label>
					{!isPreview && (
						<Button
							plain
							label={t('generalDocument.addFiles')}
							icon={
								<Box direction="row">
									<AddIcon
										src="/images/addIcon.png"
										alt="addIcon"
									/>
									<AddIcon
										src="/images/fileIcon.png"
										alt="fileIcon"
									/>
								</Box>
							}
							onClick={() => setShowUploadModal(true)}
						/>
					)}
				</Box>
			)}

			{showUploadModal && (
				<Layer
					onEsc={() => setShowUploadModal(false)}
					onClickOutside={() => setShowUploadModal(false)}
				>
					<Box
						pad="medium"
						width="800px"
						height={{ max: '470px' }}
						gap="medium"
					>
						<Box direction="row" justify="end" align="center">
							<Button
								icon={<Close />}
								onClick={() => {
									setUploads([])
									setShowUploadModal(false)
								}}
							/>
						</Box>
						<Box justify="center" align="center">
							{/* <StyledUploadBox
								{...getRootProps()}
								isActive={isDragActive}
							>
								<input {...getInputProps()} />

								<Box
									justify="center"
									align="center"
									direction="row"
									border={`1px solid ${theme.color.grey4}`}
									pad="20px"
									style={{
										borderRadius: '8px',
									}}
								>
									<AddIcon
										src="/images/addIcon.png"
										alt="addIcon"
									/>
									<AddIcon
										src="/images/fileIcon.png"
										alt="fileIcon"
									/>
									<P
										style={{
											fontSize: '24px',
										}}
									>
										{isDragActive
											? t('generalDocument.dropfiles')
											: t('generalDocument.dragDrop')}
									</P>
								</Box>
							</StyledUploadBox> */}
							<Box
								height={{ max: '250px' }}
								style={{
									display: 'flex',
									flexDirection: 'column',
									overflowY: 'auto',
									gap: '10px',
									padding: '10px',
								}}
							>
								{uploads.map((file, index) => (
									<Box
										key={file.path}
										style={{
											display: 'flex',
											alignItems: 'center',

											flexDirection: 'row',
											marginBottom: '10px',
											flexShrink: 0,
										}}
									>
										<Box
											style={{
												marginRight: '16px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: '50px',
												height: '50px',
												backgroundColor:
													theme.color.themeGrey2,
												borderRadius: '4px',
												cursor: 'pointer',
											}}
											onClick={() =>
												window.open(
													file.preview,
													'_blank'
												)
											}
										>
											<Icon
												style={{
													height: '24px',
													width: '24px',
												}}
												glyph={Documents}
												color={theme.color.blue8}
											/>
										</Box>
										<Box
											style={{
												color: theme.color.themeRed3,
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												flexGrow: 1,
											}}
											onClick={() =>
												window.open(
													file.preview,
													'_blank'
												)
											}
										>
											{file.name}
											<Box
												style={{
													color:
														theme.color.themeGrey4,
													fontSize: '12px',
												}}
											>
												{formatBytesToMB(file.size)}
											</Box>
										</Box>
										<Button
											style={{
												marginLeft: '16px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												width: '24px',
												height: '24px',
												backgroundColor: 'red',
												borderRadius: '50%',
												cursor: 'pointer',
											}}
											icon={
												<Close
													size="14px"
													color="white"
												/>
											}
											onClick={() => removeFile(index)}
										/>
									</Box>
								))}
							</Box>

							<Box width="200px">
								<Button
									label={t('generalDocument.addFiles')}
									primary
									onClick={() => {
										open()
									}}
									extendStyles={{ width: 200 }}
								/>
							</Box>

							<Box>
								<Label style={{ padding: '12px' }}>
									{t('generalDocument.uploadFileType')}
								</Label>
								<Label style={{ padding: '12px' }}>
									{t('generalDocument.maxFileLimit')}
								</Label>
							</Box>
							<Box width="100%" direction="row" justify="end">
								<Button
									plain
									label={t('generalDocument.cancel')}
									onClick={() => {
										setUploads([])
										setShowUploadModal(false)
									}}
									extendStyles={{
										marginRight: 72,
										fontSize: theme.fontSize.l,
									}}
								/>
								<Button
									disabled={uploads.length === 0}
									primary
									label={t('generalDocument.upload')}
									onClick={() => handleUpload()}
									style={{
										fontSize: theme.fontSize.l,
										width: 140,
										marginLeft: '20px',
									}}
								/>
							</Box>
						</Box>
						{errorMessage.length > 0 && (
							<Box margin={{ vertical: 'small' }} pad="small">
								{errorMessage.map(err => {
									return (
										<P style={{ color: 'red' }}>
											{err.path} -{' '}
											{errorMap[err.code]
												? t(errorMap[err.code])
												: err.message}
										</P>
									)
								})}
							</Box>
						)}
					</Box>
				</Layer>
			)}

			{showDeleteConfirmation && (
				<Layer
					onEsc={() => setShowUploadModal(false)}
					onClickOutside={() => setShowUploadModal(false)}
				>
					<Box pad="medium" width="800px" height="300px" gap="medium">
						<Box direction="row" justify="end" align="center">
							<Button
								icon={<Close />}
								onClick={() => setShowDeleteConfirmation(false)}
							/>
						</Box>
						<Box justify="center" align="center">
							<TrashIcon
								src="/images/trashIcon.png"
								alt="trashIcon"
							/>

							<P
								style={{
									fontSize: '24px',
								}}
							>
								{t('generalDocument.removeWarning')}
							</P>

							<Box direction="row">
								<Box width="150px" pad="20px">
									<Button
										label={t('generalDocument.yes')}
										primary
										onClick={() => {
											handleRemoveSelectedFiles()
										}}
									/>
								</Box>
								<Box width="150px" pad="20px">
									<Button
										label={t('generalDocument.no')}
										outline
										onClick={() => {
											setShowDeleteConfirmation(false)
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Layer>
			)}
			{errorMessage.length > 0 && (
				<Box padding="10px 0px">
					{errorMessage.map(err => {
						return (
							<P style={{ color: 'red' }}>
								{err.path} -{' '}
								{errorMap[err.code]
									? t(errorMap[err.code])
									: err.message}
							</P>
						)
					})}
				</Box>
			)}

			<GridWrapper
				{...(!isPreview && !isView
					? getRootProps({
							className: 'dropzone',
							onClick: event => event.stopPropagation(),
					  })
					: {})}
			>
				<input {...getInputProps()} />
				{isDragActive && (
					<div
						className="overlay"
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							color: 'white',
							fontSize: '1.5em',
							borderRadius: 'inherit', // matches parent border-radius
							zIndex: 1,
						}}
					>
						{t('generalDocument.dropfiles')}
					</div>
				)}
				<GridHeader>
					{selectedFiles.length > 0 && !isPreview && (
						<Button
							icon={<Trash color="#FF7474" />}
							onClick={() => {
								setShowDeleteConfirmation(true)
							}}
							plain
							hoverIndicator
							style={{
								padding: '0px 10px',
							}}
						/>
					)}
					<EllipsisBox color="white">
						{t('generalDocument.name')}
					</EllipsisBox>
					<EllipsisBox color="white">
						{t('generalDocument.size')}
					</EllipsisBox>
					<EllipsisBox color="white">
						{t('generalDocument.dateOfUpload')}
					</EllipsisBox>
					<EllipsisBox color="white">
						{t('generalDocument.status')}
					</EllipsisBox>
				</GridHeader>
				<GridContent>
					{files.map((file, index) => {
						if (file.status === 'deleted') {
							return <></>
						}

						const isRemoved = file.status === 'removed'

						return (
							<GridItem
								flexGrow={index === files.length - 1 ? 1 : 0}
								key={file.name}
							>
								{!isPreview && !isView && (
									<Box
										style={{
											padding: '12px 0px 0px 12px',
										}}
									>
										<CheckBox
											disabled={isRemoved}
											checked={selectedFiles.includes(
												file
											)}
											onChange={() =>
												toggleFileSelection(file)
											}
										/>
									</Box>
								)}
								<EllipsisBox
									color={isRemoved ? 'grey' : 'black'}
									padding="14px 0px 0px 12px"
									justifyContent="flex-start"
									borderRight="1px solid #CAD2DD"
								>
									<EllipsisContent
										onClick={() => {
											const url = getIn(file, [
												'meta',
												'fullURL',
											])
											if (url) {
												window.open(url)
											}
										}}
									>
										{file?.meta?.other?.filename}
									</EllipsisContent>
								</EllipsisBox>
								<EllipsisBox
									color={isRemoved ? 'grey' : 'black'}
									borderRight="1px solid #CAD2DD"
								>
									{formatBytesToMB(file.sizeBytes)}
								</EllipsisBox>
								<EllipsisBox
									color={isRemoved ? 'grey' : 'black'}
									borderRight="1px solid #CAD2DD"
								>
									{getDateByFormat(file.createdAt)}
								</EllipsisBox>
								<EllipsisBox>
									{file.progress && file.progress < 100 ? (
										<Meter
											value={file.progress || 0}
											round
											size="150px"
											color="#3F57C6"
											thickness="small"
											background="#D7DDF7"
										/>
									) : (
										<>
											{file?.status !== 'failed' && (
												<Box
													direction="row"
													align="center"
													justify="center"
												>
													<P
														style={{
															fontSize: '16px',
															color: isRemoved
																? 'grey'
																: 'black',
														}}
													>
														{t(
															'generalDocument.uploaded'
														)}
													</P>
													<UploadedIcon
														src="/images/greenTick.png"
														alt="greenTick"
													/>
												</Box>
											)}
											{file?.status === 'failed' && (
												<Box
													direction="row"
													align="center"
													justify="center"
												>
													<P
														style={{
															fontSize: '16px',
															color: isRemoved
																? 'grey'
																: 'red',
														}}
													>
														{t(
															'generalDocument.uploadFailed'
														)}
													</P>
												</Box>
											)}
										</>
									)}
								</EllipsisBox>
							</GridItem>
						)
					})}

					{files.length === 0 && (
						<Box
							style={{
								flexDirection: 'row',
								minHeight: 'auto',
								flex: 1,
							}}
						>
							<EllipsisBox
								borderRight="1px solid #CAD2DD"
								basis="1/4"
							/>
							<EllipsisBox
								borderRight="1px solid #CAD2DD"
								basis="1/4"
							/>
							<EllipsisBox
								borderRight="1px solid #CAD2DD"
								basis="1/4"
							/>
							<EllipsisBox
								borderRight="1px solid #CAD2DD"
								basis="1/4"
							/>
						</Box>
					)}
				</GridContent>
			</GridWrapper>

			<DisclaimerBox>
				<Box flex>
					<P style={{ padding: '8px', color: '#3F56C4' }}>
						{t('generalDocument.uplaodDisclaimer')}
					</P>
				</Box>
				<Box width="350px" background="#5674E9">
					<P style={{ padding: '8px', color: '#F8F8FC' }}>
						{t('generalDocument.uploadFileType')}
					</P>
					<P style={{ padding: '8px', color: '#F8F8FC' }}>
						{t('generalDocument.maxFileLimit')}
					</P>
				</Box>
			</DisclaimerBox>
		</Box>
	)
}

export default FileUploadComponent
