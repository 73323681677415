import React, { useEffect, useRef, useState } from 'react'
import { Box } from 'ui-lib/utils/Box'
import { Box as GrommBox } from 'grommet'
import { Message } from './Message'

const MessagesGroup = ({
	msg,
	activeTab,
	documentId,
	docThreadID,
	userId,
	participantsMeta,
	handleClick,
	chatThreadId,
	partnerId,
	usersList,
}) => {
	const chatAreaRef = useRef(null)
	const replyClickRef = useRef([])
	const [currentReplyID, setCurrentReplyID] = useState(null)

	const lastRecieverIndex =
		msg &&
		msg.length > 0 &&
		msg.findLastIndex(item => item?.createdBy !== userId)

	const handleReplyClick = replyMessageID => {
		const currentIndex = msg.findIndex(
			ele => ele.messageID === replyMessageID
		)
		setCurrentReplyID(currentIndex)
		if (replyClickRef?.current[currentIndex]) {
			replyClickRef.current[currentIndex].scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			})
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (currentReplyID) {
				setCurrentReplyID(null)
			}
		}, 800)
	}, [currentReplyID])

	useEffect(() => {
		if (chatAreaRef && chatAreaRef.current) {
			chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight
		}
	}, [])

	return (
		<Box
			ref={chatAreaRef}
			style={{
				overflow: 'scroll',
				display: 'flex',
			}}
		>
			<GrommBox
				width="100%"
				pad="10px 0px"
				overflow="scroll"
				style={{ position: 'absolute', overflow: 'auto' }}
			>
				{(msg || []).map(
					(_message, index) =>
						_message.message !== '' && (
							<div
								key={index}
								ref={el => {
									replyClickRef.current[index] = el

									return null
								}}
							>
								<Message
									key={_message?.messageID}
									message={_message}
									canEditDelete={
										activeTab === docThreadID &&
										index > lastRecieverIndex // only allowed to edit
									}
									userId={userId}
									senderDisplayName={
										participantsMeta?.length > 0 &&
										participantsMeta?.find(
											item =>
												item?.userID ===
												_message?.createdBy
										).name
									}
									handleClick={(
										type,
										id,
										message,
										messageObj,
										partnerId,
										chatThreadId,
										assignedList
									) => {
										handleClick(
											type,
											id,
											message,
											messageObj,
											partnerId,
											chatThreadId,
											assignedList
										)
									}}
									participantsMeta={participantsMeta}
									handleReplyClick={replyMessageID =>
										handleReplyClick(replyMessageID)
									}
									focus={index === currentReplyID}
									chatThreadId={chatThreadId}
									partnerId={partnerId}
									usersList={usersList}
								/>
							</div>
						)
				)}
			</GrommBox>
		</Box>
	)
}

export { MessagesGroup }
