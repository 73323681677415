import { Box as GrommBox } from 'grommet'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { getIn } from 'timm'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import {
	ButtonIconWrapper,
	RoundedButton,
} from 'ui-lib/components/Button/index'
import { TextArea } from 'ui-lib/components/TextArea'
import { H2, P } from 'ui-lib/components/Typography'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { getDateByFormat, getRandomNumber } from 'ui-lib//utils/helpers'
import closeCircle from 'ui-lib/icons/circle-close.svg'

import SendSvg from 'ui-lib/icons/send.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import downArrow from 'ui-lib/icons/down.svg'
import upArrow from 'ui-lib/icons/upward-arrow.svg'
import { Tabs, DefaultTabIndicator } from 'ui-lib/components/Tabs'

import { MessagesGroup } from './MessagesGroup'

const BoxStyled = styled(Box)`
	&:hover {
		background-color: ${p =>
			p.showChatBox ? theme.color.grey1 : theme.color.blue2} !important;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
`

const MsgHeader = styled.div`
	display: flex;
	justify-content: space-between;
`

const LinkBlock = styled.span(({ color, marginRight }) => ({
	textAlign: 'right',
	color,
	fontSize: '12px',
	alignSelf: 'right',
	marginTop: '8px',
	marginRight,
}))

const ChatHeader = styled.div``

const SendButton = styled(RoundedButton)`
	border-radius: 150px;
	height: 48px;
	width: 48px;
	right: auto;
	bottom: auto;
	position: relative !important;
	background: ${p => p.theme.color.orange};
	box-shadow: none !important;
	color: ${p => (p.primary ? p.theme.color.white : p.theme.color.primary)};
`

const TabComponents = ({
	messages = [],
	activeTab,
	documentId,
	docThreadID,
	userId,
	participantsMeta,
	handleClick,
	chatThreadId,
	partnerId,
	usersList,
}) => {
	return (
		<MessagesGroup
			msg={messages}
			activeTab={activeTab}
			documentId={documentId}
			docThreadID={docThreadID}
			userId={userId}
			participantsMeta={participantsMeta}
			handleClick={(
				type,
				id,
				message,
				messageObj,
				partnerId,
				chatThreadId,
				assignedList
			) => {
				handleClick(
					type,
					id,
					message,
					messageObj,
					partnerId,
					chatThreadId,
					assignedList
				)
			}}
			chatThreadId={chatThreadId}
			partnerId={partnerId}
			usersList={usersList}
		/>
	)
}

const getTabComponents = (
	messages = [],
	activeTab,
	documentId,
	docThreadID,
	userId,
	participantsMeta,
	handleClick,
	chatThreadId,
	partnerId,
	usersList
) => () => (
	<TabComponents
		messages={messages}
		activeTab={activeTab}
		documentId={documentId}
		docThreadID={docThreadID}
		userId={userId}
		participantsMeta={participantsMeta}
		handleClick={(
			type,
			id,
			message,
			messageObj,
			partnerId,
			chatThreadId,
			assignedList
		) => {
			handleClick(
				type,
				id,
				message,
				messageObj,
				partnerId,
				chatThreadId,
				assignedList
			)
		}}
		chatThreadId={chatThreadId}
		partnerId={partnerId}
		usersList={usersList}
	/>
)

const ChatModalTab = ({
	documentId,
	sellerName,
	buyerName,
	sellerId,
	chatBoxData,
	isMobile,
	dispatch,
	handleTranslation,
	orgId,
	userId,
	MessagesDuc,
	TradeDocDuc,
	useDateByFormat,
	handleSendMsg,
	getNewMessageList,
	chatAssociatedList = [],
	messagesList = {},
	msgDataLoader = false,
	docType = 'rfq',
	partnerId = '',
	usersList = [],
	docThreadID = null,
}) => {
	const [chatText, setChatText] = useState('')
	const [editMsgIndex, setEditMsgIndex] = useState('')
	const [refreshCounter, setRefreshCounter] = useState(0)
	const [messages, setMessages] = useState([])
	const [showChatBox, setShowChatBox] = useState(true)
	const [textLength, setTextLength] = useState(0)
	const [sortedArrayList, setSortedArrayList] = useState([])
	const [textBoxKey, setTextBoxKey] = useState(0)
	const [replyMsgIndex, setReplyMsgIndex] = useState('')
	const [replyTextData, setReplyTextData] = useState('')

	const [unreadCount, setunreadCount] = useState(null)

	const currentDocRef =
		chatAssociatedList.length > 0 &&
		chatAssociatedList?.find(
			item => item.threadID === messagesList?.threadID
		)?.documentMeta?.name
	const chatTitleName = orgId === sellerId ? buyerName : sellerName

	const threadIdentifier = messagesList?.threadID || null
	const [activeTab, setActiveTab] = useState(messagesList?.threadID)
	const [currentDocNumber, setCurrentDocNumber] = useState(currentDocRef)
	const [disableButton, setDisableButton] = useState(false)

	const currentThreadID = chatAssociatedList?.find(
		item => item.threadID === activeTab
	)?.threadID

	useEffect(() => {
		if (currentThreadID) {
			const timeoutId = setTimeout(() => {
				if (messages?.length > 0) {
					getNewMessageList(currentThreadID)
				}
				setRefreshCounter(prevCounter => prevCounter + 1)
			}, 20000)

			// Cleanup function to clear the timeout if necessary
			return () => clearTimeout(timeoutId)
		}
	}, [currentThreadID, getNewMessageList, messages.length])

	const index = useCallback(() => {
		let indexCount = ''
		if (sortedArrayList && sortedArrayList.length > 0) {
			// eslint-disable-next-line no-shadow
			sortedArrayList.forEach((item, index) => {
				if (getIn(item, ['threadId']) === docThreadID) {
					indexCount = index
				}
			})
		}

		return indexCount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedArrayList])

	// eslint-disable-next-line react-hooks/exhaustive-deps

	useEffect(() => {
		const messagesArray = messagesList?.messages || []
		setMessages(messagesArray)
	}, [messagesList])

	const getTabs = useCallback(() => {
		const tabsArray = []
		if (sortedArrayList && sortedArrayList.length > 0) {
			sortedArrayList.forEach(key => {
				const docNumber = getIn(key, ['threadID']) // assumming there should be only one contract

				if (docNumber) {
					tabsArray.push({
						name: getIn(key, ['threadID']),
						title:
							getIn(key, ['documentMeta', 'name']) ||
							getIn(key, ['documentID']) ||
							'',
						IndicatorComponent:
							parseInt(sortedArrayList[docNumber], 10) > 0 &&
							(({ isActive }) => (
								<DefaultTabIndicator active={isActive}>
									{parseInt(currentChat, 10)}
								</DefaultTabIndicator>
							)),
					})
				}
			})
		}

		return tabsArray
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortedArrayList])

	useEffect(() => {
		setCurrentDocNumber(
			chatAssociatedList.length > 0 &&
				chatAssociatedList?.find(item => item.threadID === activeTab)
					?.documentMeta?.name
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab, messages])

	useEffect(() => {
		if (messagesList.threadID) {
			dispatch(
				MessagesDuc.creators.sendMessageAction(
					messagesList.threadID,
					'reset-unread'
				)
			)
			setunreadCount(0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showChatBox])

	useEffect(() => {
		if (activeTab !== messagesList?.threadID) {
			setMessages([])
			const currentThreadID = chatAssociatedList?.find(
				item => item.threadID === activeTab
			)?.threadID

			if (currentThreadID) {
				getNewMessageList(currentThreadID)
			}
			if (currentThreadID) {
				dispatch(
					MessagesDuc.creators.sendMessageAction(
						currentThreadID,
						'reset-unread'
					)
				)
				dispatch(
					MessagesDuc.creators.sendMessageAction(
						messagesList.threadID,
						'reset-unread'
					)
				)
				setunreadCount(0)

				setChatText('')
				setTextLength(0)
				if (editMsgIndex === '') {
					setTextBoxKey(getRandomNumber())
				}
				setEditMsgIndex('')
				setReplyMsgIndex('')
				setReplyTextData('')
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab])

	useEffect(() => {
		setActiveTab(threadIdentifier)
		setCurrentDocNumber(
			chatAssociatedList.length > 0 &&
				chatAssociatedList?.find(
					item => item.threadID === threadIdentifier
				)?.documentMeta?.name
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threadIdentifier, showChatBox])

	const sendMessage = () => {
		if (editMsgIndex !== '') {
			handleSendMsg('edit', chatText, messagesList.threadID, editMsgIndex)
		} else if (!messages || messages?.length === 0) {
			handleSendMsg('create', chatText)
		} else if (replyMsgIndex !== '') {
			handleSendMsg(
				'reply',
				chatText,
				messagesList.threadID,
				replyMsgIndex,
				replyTextData
			)
		} else {
			handleSendMsg('add', chatText, messagesList.threadID)
		}
		setEditMsgIndex('')
		setReplyMsgIndex('')
		setReplyTextData('')
		setChatText('')
		setTextLength(0)
		setTextBoxKey(getRandomNumber())
	}

	useEffect(() => {
		setSortedArrayList(chatAssociatedList)
		const currentUnread =
			chatAssociatedList.length > 0 &&
			chatAssociatedList?.find(item => item.threadID === docThreadID)
				?.unReadCount
		setunreadCount(currentUnread)
	}, [chatAssociatedList, docThreadID])

	const getLoader = () => {
		return msgDataLoader ? (
			<TransparentBgSpinner />
		) : (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					marginTop: '188px',
				}}
			>
				{handleTranslation('common.noChat')}
			</div>
		)
	}

	const getMeasure = (normalMeasure, measureForMobile) => {
		let measure = normalMeasure
		if (isMobile) {
			measure = measureForMobile
		}

		return measure
	}

	const getReplyToContent = participantsMeta => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Box
					style={{
						display: 'flex',
						height: '200px',
						background: theme.color.grey1,
						borderRadius: '5px',
						padding: '10px',
						width: '100%',
					}}
				>
					<MsgHeader>
						<div>
							<P
								style={{
									fontSize: '14px',
									fontWeight: '600',
								}}
								color="#4A5255"
							>
								{participantsMeta?.length > 0 &&
									participantsMeta?.find(
										item =>
											item?.userID ===
											replyTextData?.createdBy
									)?.name}
							</P>
						</div>
					</MsgHeader>
					<P
						style={{ marginTop: '8px', fontSize: '14px' }}
						color="#4A5255"
					>
						{replyTextData?.message}
					</P>
					<Box row justifyContent="flex-end">
						{replyTextData?.edited && (
							<LinkBlock
								alignSelf="end"
								size="13px"
								textAlign="right"
								color="#8F9394"
								marginRight="10px"
							>
								{handleTranslation('messages.edited')}
							</LinkBlock>
						)}
						<LinkBlock
							alignSelf="end"
							size="13px"
							textAlign="right"
							color="#8F9394"
						>
							{getDateByFormat(
								replyTextData?.editedAt ||
									replyTextData?.createdAt,
								'd LLL yyyy, hh:mm a'
							)}
						</LinkBlock>
					</Box>
				</Box>
				<div
					style={{
						marginTop: '-7px',
						cursor: 'pointer',
						position: 'absolute',
						marginRight: '-433px',
					}}
					tabIndex={0}
					onClick={() => {
						setReplyMsgIndex('')
						setReplyTextData('')
					}}
					role="button"
					onKeyPress={() => {
						setReplyMsgIndex('')
						setReplyTextData('')
					}}
				>
					<IconWrapper size={15}>
						<Icon
							color={theme.color.green}
							glyph={closeCircle}
							size={10}
						/>
					</IconWrapper>
				</div>
			</div>
		)
	}

	useEffect(() => {
		if (replyMsgIndex && messagesList?.messages?.length > 0) {
			const currentData = messagesList?.messages.find(
				item => item.messageID === replyMsgIndex
			)

			setReplyTextData(currentData)
		}
	}, [messagesList.messages, replyMsgIndex])

	return (
		<>
			<Box
				style={{
					minHeight: 515,
					padding: 10,
					paddingTop: 0,
					backgroundColor: 'white',
				}}
			>
				<Box
					backgroundColor="white"
					flex="1"
					width="100%"
					alignSelf="flex-start"
				>
					<>
						{messagesList?.messages &&
						messagesList?.messages.length > 0 ? (
							<Tabs
								tabHeaderwidth="100%"
								style={{
									flex: 1,
								}}
								extendStyles={{
									flex: 1,
									marginTop: '2px',
									position: 'relative',
									overflow: 'auto',
								}}
								id="TDMTabs"
								tabWidth="70px"
								maxWidth="100px"
								tabs={getTabs()}
								tabComponentsMap={() =>
									getTabComponents(
										messages,
										activeTab,
										documentId,
										docThreadID,
										userId,
										chatAssociatedList.length > 0 &&
											chatAssociatedList?.find(
												item =>
													item.threadID === activeTab
											)?.participantsMeta, // participantsMeta
										(
											type,
											id,
											message,
											messageObj = {},
											partnerId = '',
											chatThreadId = '',
											assignedList = []
										) => {
											if (type === 'edit') {
												setEditMsgIndex(id)
												setChatText(message)
												setTextBoxKey(getRandomNumber())
											} else if (type === 'assign') {
												dispatch(
													MessagesDuc.creators.assignTask(
														messageObj,
														partnerId,
														chatThreadId,
														assignedList
													)
												)
											} else {
												setReplyMsgIndex(id)
												setEditMsgIndex('')
												setChatText('')
											}
										},
										messagesList.threadID,
										partnerId,
										usersList
									)
								}
								activeTabIndex={index}
								onChange={({ name }) => {
									setActiveTab(name)
									const currTDID =
										chatAssociatedList.length > 0 &&
										chatAssociatedList?.find(
											item => item.threadID === name
										)?.threadID
									dispatch(
										TradeDocDuc.creators.setCurrentThreadID(
											currTDID
										)
									)
								}}
								selectedTab={index() + 1}
								headerOverFlow
							/>
						) : (
							getLoader()
						)}
					</>
				</Box>
				<Box
					backgroundColor="white"
					padding="14px 0px 0px 0px"
					row
					justifyContent="space-between"
					alignItems={replyMsgIndex ? 'center' : 'flex-start'}
					width="inherit"
				>
					<Box
						style={{
							flex: 1,
							marginRight: '20px',
							// height: textLength < 63 ? '60px' : '70px',
							height: 'auto',
						}}
					>
						<>
							<Box>
								{replyMsgIndex && (
									<Box
										style={{
											border: `1px solid ${theme.color.themeBlue16}`,
											borderBottom: 'none',
											borderRadius: '5px',
											marginLeft: '1px',
											marginRight: '1px',
											padding: '10px',
											borderBottomLeftRadius: 'unset',
											borderBottomRightRadius: 'unset',
										}}
									>
										{getReplyToContent(
											chatAssociatedList.length > 0 &&
												chatAssociatedList?.find(
													item =>
														item.threadID ===
														activeTab
												)?.participantsMeta
										)}
									</Box>
								)}
								<TextArea
									type="text"
									key={textBoxKey}
									placeholder={`${handleTranslation(
										'tdmDetailsEntry.typeHere'
									)}...`}
									name="name"
									onChange={text => {
										const lineLength = text.target.value.toString()
											.length
										setTextLength(lineLength)
										setDisableButton(false)
										setChatText(text.target.value)
									}}
									disabled={
										activeTab && activeTab !== docThreadID
									}
									value={chatText}
									customStyles={{
										height: textLength < 63 ? 44 : 64,
										fontSize: 15,
										...(replyMsgIndex
											? {
													borderTop: 'none',
													borderTopLeftRadius:
														'unset',
													borderTopRightRadius:
														'unset',
													boxShadow: 'none',
											  }
											: {}),
									}}
								/>
							</Box>
						</>
					</Box>
					<Box padding="0px 8px 0px 0px">
						<SendButton
							disabled={chatText.trim() === '' || disableButton}
							action
							primary
							rounded
							onClick={() => {
								sendMessage()
								setDisableButton(true)
							}}
							customIcon={
								<ButtonIconWrapper size="24px" width="24px">
									<Icon
										color={theme.color.white}
										glyph={SendSvg}
									/>
								</ButtonIconWrapper>
							}
						/>
					</Box>
				</Box>
				<Box row alignItems="center" justifyContent="center">
					{currentDocNumber && (
						<div style={{ display: 'flex' }}>
							<div
								style={{
									color: theme.color.grey10,
									fontWeight: 500,
								}}
							>
								{`${handleTranslation(
									'common.document'
								)} ${handleTranslation(
									'tdmDetailsEntry.reference'
								)} : `}{' '}
								&nbsp;
							</div>
							<div
								style={{
									color: theme.color.themeGrey1,
									fontWeight: 500,
								}}
							>
								{currentDocNumber || ''}
							</div>
						</div>
					)}
				</Box>
				<Box row alignItems="center" justifyContent="start">
					<div
						style={{
							fontSize: '10px',
							marginTop: '6px',
							marginLeft: '3px',
							color: theme.color.grey10,
						}}
					>
						<div>
							<span
								style={{
									color: theme.color.themeGrey1,
								}}
							>
								*
							</span>
							<span
								style={{
									marginLeft: '3px',
								}}
							>
								{handleTranslation(
									'common.dibizHasNovisibilityToTheMessages'
								)}
							</span>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'end',
								color: theme.color.grey10,
							}}
						>
							<span
								style={{
									color: theme.color.themeGrey1,
								}}
							>
								*
							</span>
							<span
								style={{
									marginLeft: '3px',
								}}
							>
								{handleTranslation(
									'common.senderAndReceiverAreResponsibleForTheMessageExchanges'
								)}
							</span>
						</div>
					</div>
				</Box>
			</Box>
		</>
	)
}

export { ChatModalTab }
