import React, { useMemo, useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { getIn, merge, mergeIn } from 'timm'
import { useFormik } from 'formik'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import {
	isEmptyObject,
	getRandomNumber,
	useDebouncedCallback,
	getProductTotal,
} from 'ui-tdm-app/utils/helpers'
import { Modal } from 'ui-lib/components/Modal'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { NAME_ALIASES } from 'ui-tdm-app/modules/TradeDocumentManager/config'
import * as yup from 'yup'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { P, SmallText, Text, H4, H3 } from 'ui-lib/components/Typography'
import { Label } from 'ui-lib/components/Label'
import { Input, SuggestionsInput } from 'ui-lib/components/Input'
import {
	SelectSearch,
	Select,
	MultiSelectWithSearch,
} from 'ui-lib/components/Select'
import { TextArea } from 'ui-lib/components/TextArea'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Card, BoxShadowWrapper } from 'ui-lib/components/Card'
import { ModuleIconMap } from 'ui-lib/utils/config'
import { Button } from 'ui-lib/components/Button'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import plusIcon from 'ui-lib/icons/plus.svg'
import collapseCircle from 'ui-lib/icons/collapse-circle.svg'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`
const Header = styled.div`
	font-size: ${props => (props.isMobile ? '25px' : '30px')};
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`
const RecipientBoxWrapper = styled.div`
	width: 100%;
	display: ${props => (props.isMobile ? 'block' : 'flex')};
	margin-top: 24px;
	border-radius: 5px;
	border: 1px solid ${theme.color.grey4};
`
const RecipientEntriesBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
	border-right: 1px solid ${theme.color.grey4};
`

const DocumentEntriesBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
`

const RecipientOptionsBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 5px' : '25px 45px 5px')};
`

const DocumentSecondBox = styled.div`
	width: ${props => (props.isMobile ? '100%' : '50%')};
	padding: ${props => (props.isMobile ? '25px 30px 0px' : '25px 45px 0px')};
	border-left: 1px solid ${theme.color.grey4};
`

const AttachmentSectionWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-top: 24px;
`

const ProductdetailsWrapper = styled.div`
	width: 100%;
	margin-top: 24px;
	padding: 25px 30px 0px;
	border-radius: 5px;
	border: 1px solid ${theme.color.grey4};
`

const ProductRowBox = styled.div`
	display: ${props => (props.isMobile ? 'block' : 'flex')};
`

const ProductColumnBox = styled.div`
	width: ${props =>
		props.widthProp
			? props.widthProp
			: '50%'}; // 33 if supplychain in products
	padding: 5px;
`
const FindProductText = styled.span`
	cursor: pointer;
`

const OptnHeader = styled.div`
	display: flex;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 10px;
	color: ${props =>
		props.selected ? theme.color.themeGrey1 : theme.color.themeBlue2};
`
const OptnSubHeader = styled.div`
	font-size: 13px;
	color: #6a6a6a;
`
const OptnWrapper = styled.div`
	cursor: ${props => (props.selected ? 'default' : 'pointer')};
	margin-top: 20px;
	margin-bottom: 35px;
`
const OtherOptnHeader = styled.div`
	font-size: 14px;
	color: ${theme.color.accent2};
`

const EmailLabel = ({ email, removeEmail, index }) => {
	return (
		<div data-tag key={index}>
			{email}
			<Text data-tag-handle onClick={() => removeEmail(index)}>
				×
			</Text>
		</div>
	)
}

const GetLabel = (email, index, removeEmail) => (
	<EmailLabel email={email} removeEmail={removeEmail} index={index} />
)

const getInitialValues = valuesList => {
	const {
		parentType,
		parentDocID,
		fullName,
		orgRole,
		_partnerID,
		documentStatus,
		source = {},
	} = valuesList
	const base = {
		contractType: '',
		docNumber: '',
		inspections: {
			quantity: [],
			quality: [],
		},
		key: getRandomNumber(),
		meta: {
			authorization: {
				reportedBy: fullName,
				reportedByRole: orgRole,
				authorisedSignatory: fullName,
			},
			payment: '',
			issueDate: new Date(),
			referenceName: parentType,
			referenceValue: parentDocID,
			termsAndConditions: '',
			receiverOrgIDs: [],
			receiverEmailIDs: [],
		},
		product: [],
		receivingParty: {
			orgID: _partnerID || '',
		},
		senderRequirements: '',
		shippingDetails: {
			destinationPort: '',
			ladingPort: '',
			shipment: {
				shipThrough: '',
				shipperNumber: '',
				billOfLading: '',
				mode: '',
				month: '',
				period: '',
			},
		},
		targetProducts:
			documentStatus &&
			documentStatus === 'clone' &&
			source?.individualProductEntry
				? source.individualProductEntry
				: [],
	}

	const _mainSource = mergeIn(source, ['meta'], {
		...base.meta,
		...source.meta,
	})

	if (parentDocID) {
		_mainSource.docNumber = ''
	}

	let details = {}

	details = merge(base, _mainSource)

	return details
}

const AttachDetailsEntry = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const globalOrganizationList = useSelector(
		TradeDocDuc.selectors.getGlobalOrganizations
	)

	const partnersForMultiSelect = []
	const partnersForSingleSelect = []

	globalOrganizationList.forEach(list => {
		partnersForMultiSelect.push({
			id: list.id,
			name: list.name,
			primaryAddress: list.primaryAddress,
		})
	})

	globalOrganizationList.forEach(list => {
		partnersForSingleSelect.push({
			name: list.id,
			label: list.name,
			primaryAddress: list.primaryAddress,
		})
	})

	const actorArray = useSelector(AuthDuc.selectors.getActor)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)

	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	const parentDocRef = useSelector(
		TradeDocDuc.selectors.getDocumentParentDocRef
	)

	const { name: _partnerID } =
		useSelector(TradeDocDuc.selectors.getDocumentActivePartner) || ''

	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)
	const { results = [], refMessage } = useSelector(
		TradeDocDuc.selectors.getEntityRefResults
	)
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)
	const actor = actorArray && actorArray.length > 0 ? actorArray[0] : ''
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const { fullName, orgRole } = loggedInUserProfile
	const { payload = {}, query = {} } = locationState
	const { parentDocRef: _query = '' } = query
	const { rootModule, action, documentStatus } = payload
	const TitleIcon = ModuleIconMap[rootModule]
	const title = NAME_ALIASES[rootModule]

	const [parentDocType, parentDocID] = _query.split('~')

	const isPO = rootModule === 'purchase-order'
	const isInvoice = rootModule === 'invoice'
	const isDO = rootModule === 'delivery-order'
	const isRFQ = rootModule === 'request-for-quote'
	const isQuote = rootModule === 'quote'

	const [showConfirmationModal, setShowConfirmationModal] = useState(false)
	const [showFindProductModal, setShowFindProductModal] = useState(false)
	const [attachfileArray, setAttachfileArray] = useState([])

	const [productChange, setProductChange] = useState(false)
	const [activePartnerError, setActivePartnerError] = useState(false)
	const [entityRefKey, setEntityRefKey] = useState(0)
	const [quantityError, setQuantityError] = useState(false)

	const validationSchema = () => {
		const shape = yup.object().shape({
			receivingParty: yup.object().shape({
				orgID: yup.string(),
			}),
			docNumber: yup.string().when([], {
				is: () => !isQuote,
				then: yup.string().required(),
				otherwise: yup.string().notRequired(),
			}),
			meta: yup.object().shape({
				issueDate: yup
					.date()
					.required(t('validation.issuingDateRequired')),
				receiverEmailIDs: yup.array(),
				receiverOrgIDs: yup.array(),
			}),
			product: yup
				.array()
				.of(
					yup.object().shape({
						name: yup
							.string()
							.required(t('validation.addAtleast1Product')),
					})
				)
				.required(t('validation.addAtleast1Product')),
		})

		return shape
	}

	const getBreadCrumbsList = useCallback(
		() => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'trade-doc-dashboard',
				isActive: true,
			},
			...(action === 'details'
				? [
						{
							label: `${t('breadcrumb.new')} ${t(
								isPO ? t('breadcrumb.salesOrder') : title
							)} - ${t('breadcrumb.details')}`,
							name: 'document-details',
							isActive: false,
						},
				  ]
				: [
						{
							label: `${t('breadcrumb.new')} ${t(
								isPO ? t('breadcrumb.salesOrder') : title
							)}`,
							name: 'document-attach',
							isActive: false,
						},
				  ]),
		],
		[action, isPO, t, title]
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const docCheck = useCallback(() => {
		let check = true
		if (locationState?.query?.parentDocRef?.includes('~')) {
			const type = locationState.query.parentDocRef.split('~')
			if (type[0] !== rootModule) {
				check = false
			}
		}

		return check
	})

	const initialValues = useMemo(() => {
		const valuesList = {
			type: rootModule,
			parentType: parentDocType,
			parentDocID,
			fullName,
			orgRole,
			parentDocRef,
			_partnerID,
			actor,
			documentStatus,
			source: activeDocument,
		}

		return getInitialValues(valuesList)
	}, [
		rootModule,
		parentDocType,
		parentDocID,
		fullName,
		orgRole,
		parentDocRef,
		_partnerID,
		actor,
		documentStatus,
		activeDocument,
	])

	const breadCrumbs = useMemo(() => getBreadCrumbsList(), [
		getBreadCrumbsList,
	])

	const {
		handleSubmit,
		values,
		errors,
		handleBlur,
		setFieldValue,
		isSubmitting,
	} = useFormik({
		initialValues,
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema,
		onSubmit: (_values, { setSubmitting }) => {
			setSubmitting(false)
			const data = { ..._values }

			if (isDO) {
				const currProduct = { ...data.product[0] }
				currProduct._submitted = true
				data.product = [currProduct]
				data.targetProduct = [currProduct]
			}

			dispatch(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema: data,
				})
			)

			const currentData = { ...data }
			if (documentStatus && documentStatus === 'draft') {
				currentData.fromDraft = true
			}

			if (documentStatus && documentStatus === 'clone') {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'attach-details-confirm',
							documentStatus: 'edit',
							baseValueSchema: currentData,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						locationState.type,
						{
							...locationState.payload,
							action: 'attach-details-confirm',
							baseValueSchema: currentData,
							forceScrolltoTop: true,
						},
						locationState.query
					)
				)
			}

			dispatch(WebTourDuc.creators.setActiveTourModule(''))
		},
	})

	/** Handlers */

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		} else if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}

	const [productCount, setProductCount] = useState(
		values?.product?.length > 0 ? values.product.length : 1
	)

	const isEdit =
		documentStatus &&
		(documentStatus === 'clone' || documentStatus === 'edit')

	const disableIndex = partnersForSingleSelect.findIndex(
		x => x.name === values.receivingParty.orgID
	)
	const disableArrayIndex = [0]
	partnersForSingleSelect.forEach((_, index) => {
		if (index !== disableIndex) {
			disableArrayIndex.push(index + 1)
		}
	})

	const getProduct = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>{`${t('tdmViewDocument.product')} ${t(
								'createAsset.name'
							)}`}</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Input
					name="productID"
					id={`product${productCount}`}
					key={`product${productCount}`}
					value={getIn(values, ['product', index, 'name']) || ''}
					type="text"
					onChange={e => {
						if (values && !values.product) {
							values.product = []
						}
						setFieldValue(
							['product', [index], 'name'],
							e.target.value
						)
					}}
					extendStyles={{
						border: 'none',
						borderRadius: '0px',
						borderBottom: `1px solid ${theme.color.blue8}`,
					}}
					error={errors.productID}
				/>
			</ProductColumnBox>
		)
	}

	const getQuantity = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.quantity')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`quantity${productCount}`}
						key={
							getIn(values, ['product', index, 'name']) ||
							`quantity${productCount}`
						}
						name="quantity"
						type="number"
						value={
							getIn(values, ['product', index, 'quantity']) || ''
						}
						disabled={!getIn(values, ['product', index, 'name'])}
						onChange={e => {
							if (e.target.value !== '') {
								setFieldValue(
									['product', [index], 'quantity'],
									parseFloat(e.target.value)
								)
								setProductChange(false)
							} else {
								setFieldValue(
									['product', [index], 'quantity'],
									''
								)
								setProductChange(false)
							}
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.quantity'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getCurrency = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								Currency
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Select
						placeholder=""
						key={`currency${productCount}`}
						value={
							getIn(values, [
								'product',
								index,
								'currency',
								'code',
							]) || ''
						}
						disabled={!getIn(values, ['product', index, 'name'])}
						name="currency"
						options={[
							{
								label: 'USD',
								name: 'USD',
							},
							{
								label: 'MYR',
								name: 'MYR',
							},
							{
								label: 'INR',
								name: 'INR',
							},
						]}
						labelKey="label"
						valueKey="name"
						onChange={e =>
							setFieldValue(
								['product', [index], 'currency', 'code'],
								e.name
							)
						}
						returnOnlyValue
						style={{
							border: 'none',
							marginBottom: '8px',
							borderBottom:
								getIn(values, ['product', index, 'name']) &&
								`1px solid ${theme.color.blue8}`,
						}}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getUnitOfMeasurement = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('viewAsset.uom')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						name="uom"
						id={`uom${productCount}`}
						key={`uom${productCount}`}
						value={getIn(values, ['product', index, 'uom']) || ''}
						disabled={!getIn(values, ['product', index, 'name'])}
						type="text"
						onChange={e => {
							if (values && !values.product) {
								values.product = []
							}
							setFieldValue(
								['product', [index], 'uom'],
								e.target.value
							)
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						error={errors.uom}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getUnitPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.unitPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`price${productCount}`}
						key={
							getIn(values, ['product', index, 'name']) ||
							`price${productCount}`
						}
						name="price"
						type="number"
						value={getIn(values, ['product', index, 'price']) || ''}
						disabled={!getIn(values, ['product', index, 'name'])}
						onChange={e => {
							setFieldValue(
								['product', [index], 'price'],
								e.target.value !== ''
									? parseFloat(e.target.value)
									: ''
							)
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.unitPrice'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getTaxPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								required
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmDetailsEntry.taxPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				<Box>
					<Input
						id={`tax${productCount}`}
						key={
							getIn(values, ['product', index, 'name']) ||
							`tax${productCount}`
						}
						name="tax"
						type="number"
						value={
							getIn(values, [
								'product',
								index,
								'taxes',
								0,
								'value',
							]) || ''
						}
						disabled={!getIn(values, ['product', index, 'name'])}
						onChange={e => {
							setFieldValue(
								`product[${index}].taxes.[0].value`,
								e.target.value !== ''
									? parseFloat(e.target.value)
									: ''
							)
						}}
						extendStyles={{
							border: 'none',
							borderRadius: '0px',
							borderBottom: `1px solid ${theme.color.blue8}`,
						}}
						placeholder={`${t('tdmDetailsEntry.enter')} ${t(
							'tdmDetailsEntry.taxPrice'
						)}`}
					/>
				</Box>
			</ProductColumnBox>
		)
	}

	const getTotalPrice = index => {
		return (
			<ProductColumnBox widthProp="100%">
				{(isMobile || index === 0) && (
					<ProductColumnBox widthProp="100%">
						<Box>
							<Label
								style={{
									width: '94%',
									marginBottom: '10px',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
								}}
							>
								{t('tdmColumnHeaders.totalPrice')}
							</Label>
						</Box>
					</ProductColumnBox>
				)}
				{getProductTotal(getIn(values, ['product', index]) || {}) >
					0 && (
					<Box>
						<Label
							style={{
								padding: 5,
								marginTop: 5,
							}}
						>
							{getProductTotal(
								getIn(values, ['product', index]) || {}
							)}
						</Label>
					</Box>
				)}
			</ProductColumnBox>
		)
	}

	const getText = () => {
		let text = ''

		if (isPO) {
			text = t('tdmDetailsEntry.sendPurchaseOrderToYourSeller')
		} else if (isInvoice) {
			text = t('tdmDetailsEntry.sendInvoiceToYourBuyer')
		} else if (isDO) {
			text = t('tdmDetailsEntry.SendDeliveryOrderToYourBuyer')
		} else if (isRFQ) {
			text = 'Send RFQ'
		} else if (isQuote) {
			text = 'Send Quote'
		}

		return text
	}

	const disableSubmit = () => {
		if (
			values?.product?.length > 0 &&
			values.product[productCount - 1] &&
			values.product[productCount - 1].name !== ''
		) {
			if (isDO) {
				if (
					values.product[0].quantity === '' ||
					values.product[0].quantity === 0
				) {
					return true
				}
			}
			if (
				!(isDO || isRFQ) &&
				!(
					values.product[productCount - 1].price &&
					values.product[productCount - 1].price !== ''
				)
			) {
				return true
			}
		}

		return false
	}

	const disableCTA =
		!isEmptyObject(errors) ||
		disableSubmit() ||
		productChange ||
		!(
			values?.receivingParty?.orgID ||
			values?.meta?.receiverOrgIDs?.length > 0 ||
			values?.meta?.receiverEmailIDs?.length > 0
		)

	const entityrefList = type => {
		const list = []
		if (getIn(results, [type])) {
			getIn(results, [type]).forEach(item => {
				list.push({ label: item.docNumber, value: item.docNumber })
			})
		}

		return list
	}

	const getSelectedAssociatedRef = type => {
		let data = ''
		if (values?.meta?.docReference?.length > 0) {
			const selectedDoc = values.meta.docReference.find(
				item => item.referenceDocType === type
			)
			if (selectedDoc?.referenceValue) {
				data = selectedDoc.referenceValue
			}
		}

		return data
	}

	const setAssociatedReference = (type, value) => {
		const getList = () => {
			const list = []
			getIn(values, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType !== type) {
					list.push(item)
				}
			})

			return list
		}
		const list =
			getIn(values, ['meta', 'docReference']) &&
			getIn(values, ['meta', 'docReference']).length > 0
				? getList()
				: []
		if (
			value === '' &&
			getIn(values, ['meta', 'docReference']) &&
			getIn(values, ['meta', 'docReference']).length > 0
		) {
			if (getIn(values, ['meta', 'docReference']).length === 1) {
				setFieldValue(['meta', 'docReference'], [])
			} else {
				const newArray = getIn(values, ['meta', 'docReference']).filter(
					item => item.referenceDocType !== type
				)
				setFieldValue(['meta', 'docReference'], newArray)
			}
		} else if (getIn(results, [type])) {
			const selectedItem = getIn(results, [type]).find(
				item => item.docNumber === value
			)
			list.push({
				referenceID: selectedItem?.docID ? selectedItem.docID : '',
				referenceDocType: type,
				referenceValue: selectedItem?.docNumber
					? selectedItem.docNumber
					: '',
			})
			if (list && list.length > 0) {
				setFieldValue(['meta', 'docReference'], list)
			}
		}
	}

	const handleSearch = useDebouncedCallback((value, docType) => {
		if (value === '') {
			setAssociatedReference(docType, '')
		} else {
			dispatch(TradeDocDuc.creators.searchEntityRef(value, docType))
		}
	}, 800)

	const breadCrumbBlock = () => {
		return (
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
		)
	}

	const headerBlock = () => {
		return (
			<Box
				row
				justifyContent="space-between"
				alignItems="baseline"
				style={{
					padding: '10px 0 15px',
				}}
			>
				<HeaderWrapper>
					<SubWrapper>
						<IconWrapper
							size={24}
							style={{
								color: theme.color.blue8,
								cursor: 'pointer',
							}}
						>
							<Icon glyph={TitleIcon} />
						</IconWrapper>
					</SubWrapper>

					<Header isMobile={isMobile}>{getText()}</Header>
				</HeaderWrapper>
			</Box>
		)
	}

	const recipientBlock = () => {
		return (
			<Box>
				<Box
					style={{
						color: theme.color.accent2,
						fontSize: '18px',
						fontWeight: 500,
					}}
				>
					{t('tdmDetailsEntry.recipient')}
				</Box>
				<RecipientBoxWrapper isMobile={isMobile}>
					<RecipientEntriesBox isMobile={isMobile}>
						<Label
							required
							style={{
								marginBottom: '10px',
								color: theme.color.accent2,
								fontSize: '14px',
								fontWeight: 500,
							}}
						>{`${t('tdmDocumentListing.select')} ${t(title)} ${t(
							'tdmDetailsEntry.recipient'
						)}`}</Label>

						<div
							onBlur={() => {
								setTimeout(() => {
									setActivePartnerError(true)
								}, 100)
							}}
						>
							{isRFQ ? (
								<MultiSelectWithSearch
									selected={
										values?.meta?.receiverOrgIDs || [
											values.receivingParty.orgID,
										]
									}
									value={
										values?.meta?.receiverOrgIDs || [
											values.receivingParty.orgID,
										]
									}
									options={partnersForMultiSelect}
									onChange={value => {
										setFieldValue(
											['meta', 'receiverOrgIDs'],
											value
										)
									}}
									returnOnlyValue
								/>
							) : (
								<SelectSearch
									name="receivingParty"
									defaultLabel={t(
										'common.selectDefaultLabel'
									)}
									placeholder={`${t(
										'tdmDocumentListing.choose'
									)} ${t('tdmDocumentListing.one')}`}
									key={values?.receivingParty?.orgID}
									value={values?.receivingParty?.orgID}
									options={partnersForSingleSelect}
									error={
										activePartnerError &&
										errors.activePartner
									}
									onChange={value => {
										dispatch(
											TradeDocDuc.creators.setActivePartner(
												value
											)
										)

										setFieldValue('product', [])
										setFieldValue(['docNumber'], '')
										setFieldValue(['targetProducts'], [])
										setProductCount(0)
										setEntityRefKey(getRandomNumber())
										setFieldValue(
											['receivingParty', 'orgID'],
											value.name
										)
									}}
									labelKey="label"
									valueKey="name"
									returnOnlyValue
									style={{
										width: '445px',
										border: 'none',
										marginBottom: '8px',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									disabledIndexArr={
										(!!_query &&
											[
												'delivery-order',
												'invoice',
												'purchase-order',
											].includes(parentDocType)) ||
										(documentStatus &&
											(documentStatus === 'edit' ||
												documentStatus === 'clone'))
											? disableArrayIndex
											: []
									}
								/>
							)}
						</div>
					</RecipientEntriesBox>
					<RecipientOptionsBox isMobile={isMobile}>
						<OtherOptnHeader>
							{t('tdmDetailsEntry.otherOptions')}
						</OtherOptnHeader>
						<OptnWrapper id="emailAddOption">
							<OptnHeader>
								{`${t('common.add')} ${t('login.email')}`}
							</OptnHeader>
							<OptnSubHeader>
								{t('tdmDetailsEntry.addEmailSubText')}
							</OptnSubHeader>
							<Spacer size={20} />
							<ReactMultiEmail
								emails={values?.meta?.receiverEmailIDs}
								onChange={value => {
									setFieldValue(
										['meta', 'receiverEmailIDs'],
										value
									)
								}}
								validateEmail={email => {
									return isEmail(email)
								}}
								getLabel={GetLabel}
							/>
						</OptnWrapper>
					</RecipientOptionsBox>
				</RecipientBoxWrapper>
			</Box>
		)
	}

	const participantDetailsBlock = () => {
		return (
			<RecipientBoxWrapper>
				<RecipientEntriesBox>
					<OtherOptnHeader
						style={{ color: theme.color.primary, fontWeight: 500 }}
					>
						Issued To
					</OtherOptnHeader>
					<OptnWrapper>
						<H3>{values?.receivingParty?.name}</H3>
						<H4 style={{ color: theme.color.grey5 }}>
							{
								values?.receivingParty?.categories?.[0]
									?.industryType
							}
						</H4>
					</OptnWrapper>
					<Box
						row
						justifyContent="space-between"
						margin="20px 0 35px"
					>
						<p
							style={{
								color: theme.color.grey5,
								fontWeight: 500,
							}}
						>
							Address
						</p>
						<Box>
							{[
								values?.receivingParty?.PrimaryAddress?.street1,
								values?.receivingParty?.PrimaryAddress?.street2,
								values?.receivingParty?.PrimaryAddress?.city,
								values?.receivingParty?.PrimaryAddress?.pincode,
							]
								.filter(Boolean) // Filters out any undefined or falsy values
								.join(', ')}
						</Box>
					</Box>
					<Box
						row
						justifyContent="space-between"
						margin="20px 0 35px"
					>
						<p
							style={{
								color: theme.color.grey5,
								fontWeight: 500,
							}}
						>
							State & Country
						</p>
						<Box>
							{[
								values?.receivingParty?.PrimaryAddress?.state,
								values?.receivingParty?.PrimaryAddress?.country,
							]
								.filter(Boolean) // Filters out any undefined or falsy values
								.join(', ')}
						</Box>
					</Box>
				</RecipientEntriesBox>
				<RecipientEntriesBox>
					<OtherOptnHeader
						style={{ color: theme.color.primary, fontWeight: 500 }}
					>
						Issued By
					</OtherOptnHeader>
					<OptnWrapper>
						<H3>{values?.initiatingParty?.name}</H3>
						<H4
							style={{
								color: theme.color.grey5,
								fontWeight: 500,
							}}
						>
							{
								values?.initiatingParty?.categories?.[0]
									?.industryType
							}
						</H4>
					</OptnWrapper>
					<Box
						row
						justifyContent="space-between"
						margin="20px 0 35px"
					>
						<p
							style={{
								color: theme.color.grey5,
								fontWeight: 500,
							}}
						>
							Address
						</p>
						<Box>
							{[
								values?.initiatingParty?.PrimaryAddress
									?.street1,
								values?.initiatingParty?.PrimaryAddress
									?.street2,
								values?.initiatingParty?.PrimaryAddress?.city,
								values?.initiatingParty?.PrimaryAddress
									?.pincode,
							]
								.filter(Boolean) // Filters out any undefined or falsy values
								.join(', ')}
						</Box>
					</Box>
					<Box
						row
						justifyContent="space-between"
						margin="20px 0 35px"
					>
						<p
							style={{
								color: theme.color.grey5,
								fontWeight: 500,
							}}
						>
							State & Country
						</p>
						<Box>
							{[
								values?.initiatingParty?.PrimaryAddress?.state,
								values?.initiatingParty?.PrimaryAddress
									?.country,
							]
								.filter(Boolean) // Filters out any undefined or falsy values
								.join(', ')}
						</Box>
					</Box>
				</RecipientEntriesBox>
			</RecipientBoxWrapper>
		)
	}

	const attachFilesBlock = () => {
		return (
			<Box
				style={{
					marginTop: '30px',
				}}
			>
				<Box
					style={{
						color: theme.color.accent2,
						fontSize: '18px',
						fontWeight: 500,
					}}
				>
					<span>
						{t('breadcrumb.attach')} {t('tdmDetailsEntry.files')}
					</span>
				</Box>
				<AttachmentSectionWrapper>
					<Box
						style={{
							padding: '1px',
							color: theme.color.blue8,
							backgroundColor: theme.color.white,
						}}
					>
						<FileUpload
							modalTriggerBtnLabel={t('common.upload')}
							type="private"
							name="attachments"
							size="5242880"
							supportedFileType=".pdf"
							newAlignment={!isMobile}
							disableUploadForOne
							width={
								values?.meta?.attachedEntity?.id
									? '320px'
									: '265px'
							}
							currentUploads={attachfileArray || []}
							onDocumentChange={async documents => {
								if (documents && documents.length > 0) {
									setFieldValue(
										['meta', 'attachedEntity'],
										documents[0]
									)
									setFieldValue(
										['meta', 'attachmentFlow'],
										true
									)
								}
							}}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</Box>
				</AttachmentSectionWrapper>
			</Box>
		)
	}

	const documentDetailsBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('common.document')} {t('breadcrumb.details')}
				</Box>
				<RecipientBoxWrapper isMobile={isMobile}>
					<DocumentEntriesBox isMobile={isMobile}>
						<>
							<Box
								width={445}
								overflow
								style={{ display: 'block' }}
							>
								<Label
									required
									style={{
										marginBottom: '10px',
										marginTop:
											rootModule === 'delivery-order'
												? '-160px'
												: 0,
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{`${t(title)} ${t(
										'tdmViewDocument.number'
									)}`}
								</Label>
								<Input
									name="docNumber"
									value={values.docNumber}
									onChange={e =>
										setFieldValue(
											['docNumber'],
											e.target.value
										)
									}
									placeholder={`${t(
										'tdmDetailsEntry.enter'
									)} ${t('tdmDetailsEntry.reference')} ${t(
										'tdmViewDocument.number'
									)}`}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop: '7px',
									}}
									disabled={isEdit}
									key={entityRefKey}
								/>
								<Spacer size={4} />
							</Box>
							<BoxShadowWrapper
								style={{
									backgroundColor: 'white',
									border: 'none',
									padding: '0px',
								}}
							>
								<Box id="input-date-picker">
									<Box width={445} overflow>
										<DatePicker
											enableCurrentTime
											toDate
											required
											noBorder
											name="issueDate"
											error={errors.issueDate}
											maxDate={new Date()}
											value={values.meta.issueDate}
											onChange={keyValue => {
												setFieldValue(
													['meta', 'issueDate'],
													keyValue
												)
											}}
											label={t('common.date')}
										/>
									</Box>
								</Box>
								<Spacer size={24} />
							</BoxShadowWrapper>
						</>
					</DocumentEntriesBox>
					{!(isPO || isRFQ) ? (
						<DocumentSecondBox isMobile={isMobile}>
							<Box
								style={{
									marginBottom: '10px',
								}}
							>
								<Label
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>{`${t('tdmDetailsEntry.associated')} ${t(
									'tdmColumnHeaders.purchase-order'
								)} ${t('tdmDetailsEntry.reference')}`}</Label>
								<SuggestionsInput
									key={getSelectedAssociatedRef(
										'purchase-order'
									)}
									value={getSelectedAssociatedRef(
										'purchase-order'
									)}
									placeholder={`${t(
										'schedulerListing.search'
									)} ${t('tdmDocumentListing.one')}`}
									onChange={e =>
										handleSearch(
											e.target.value,
											'purchase-order'
										)
									}
									suggestions={entityrefList(
										'purchase-order'
									)}
									onSelect={selected =>
										setAssociatedReference(
											'purchase-order',
											selected.value
										)
									}
									extendStyles={{
										width: '445px',
										border: 'none',
										borderBottom: `1px solid ${theme.color.blue8}`,
									}}
									error={refMessage?.['purchase-order']}
								/>
							</Box>
							<Box>
								<Label
									style={{
										marginBottom: '10px',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>{`${t('tdmDetailsEntry.associated')} ${
									isInvoice
										? t('tdmColumnHeaders.delivery-order')
										: t('tdmColumnHeaders.invoice')
								} ${t('tdmDetailsEntry.reference')}`}</Label>
								{isInvoice ? (
									<SuggestionsInput
										key={getSelectedAssociatedRef(
											'delivery-order'
										)}
										value={getSelectedAssociatedRef(
											'delivery-order'
										)}
										placeholder={`${t(
											'schedulerListing.search'
										)} ${t('tdmDocumentListing.one')}`}
										onChange={e =>
											handleSearch(
												e.target.value,
												'delivery-order'
											)
										}
										suggestions={entityrefList(
											'delivery-order'
										)}
										onSelect={selected =>
											setAssociatedReference(
												'delivery-order',
												selected.value
											)
										}
										extendStyles={{
											width: '445px',
											border: 'none',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										error={refMessage?.['delivery-order']}
									/>
								) : (
									<SuggestionsInput
										key={getSelectedAssociatedRef(
											'invoice'
										)}
										value={getSelectedAssociatedRef(
											'invoice'
										)}
										placeholder={`${t(
											'schedulerListing.search'
										)} ${t('tdmDocumentListing.one')}`}
										onChange={e =>
											handleSearch(
												e.target.value,
												'invoice'
											)
										}
										suggestions={entityrefList('invoice')}
										onSelect={selected =>
											setAssociatedReference(
												'invoice',
												selected.value
											)
										}
										extendStyles={{
											width: '445px',
											border: 'none',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										error={refMessage?.invoice}
									/>
								)}
							</Box>
						</DocumentSecondBox>
					) : (
						''
					)}
				</RecipientBoxWrapper>
			</Box>
		)
	}

	const getProductList = () => {
		return (
			<Box>
				{[...Array(productCount).keys()].map((value, index) => (
					<div key={`productList-${value}`}>
						<ProductRowBox isMobile={isMobile}>
							{getProduct(index)}
							{getQuantity(index)}
							{!(isDO || isRFQ) && getCurrency(index)}
							{getUnitOfMeasurement(index)}
							{!(isDO || isRFQ) && getUnitPrice(index)}
							{!(isDO || isRFQ) && getTaxPrice(index)}
							{!(isDO || isRFQ) && getTotalPrice(index)}
							{productCount > 1 ? (
								<div
									key={`icon${productCount}`}
									style={{
										display: 'flex',
										marginBottom: 20,
										justifyContent: 'end',
										alignItems:
											index === 0 ? 'center' : 'start',
									}}
								>
									<IconWrapper
										id={`icon${productCount}`}
										size={18}
										onClick={() => {
											const currentProdList = [
												...values.product,
											]
											currentProdList.splice(index, 1)
											setProductCount(productCount - 1)
											setFieldValue(
												'product',
												currentProdList
											)
											setProductChange(false)
										}}
										style={{
											cursor: 'pointer',
											paddingTop: isMobile ? 0 : 17,
											color: theme.color.themeRed1,
										}}
									>
										<Icon glyph={collapseCircle} />
									</IconWrapper>
								</div>
							) : (
								''
							)}
						</ProductRowBox>
					</div>
				))}
			</Box>
		)
	}

	const addProductBlock = () => {
		return (
			<>
				{!isDO ? (
					<ProductRowBox
						style={{
							fontWeight: 500,
							color: theme.color.themeBlue2,
							marginBottom: '20px',
							display: 'flex',
							justifyContent: 'end',
						}}
					>
						<IconWrapper
							size={18}
							style={{
								color: theme.color.themeBlue2,
								marginRight: '10px',
							}}
						>
							<Icon glyph={plusIcon} />
						</IconWrapper>
						<FindProductText
							onClick={() => {
								if (
									values?.product?.length > 0 &&
									values.product[productCount - 1] &&
									values.product[productCount - 1].name !==
										'' &&
									values.product[productCount - 1].quantity &&
									values.product[productCount - 1]
										.quantity !== ''
								) {
									setProductCount(productCount + 1)
								}
							}}
						>
							{`${t('common.add')} ${t('breadcrumb.products')}`}
						</FindProductText>
					</ProductRowBox>
				) : (
					''
				)}
			</>
		)
	}

	const quantityErrorBlock = () => {
		return (
			<>
				{quantityError && (
					<div
						style={{
							padding: '8px',
							margin: '4px 0',
							borderRadius: '4px',
							color: theme.color.error,
							border: `1px solid ${theme.color.error}`,
						}}
					>
						{t('tdmDetailsEntry.quantityEmpty')}
					</div>
				)}
			</>
		)
	}

	const productBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmViewDocument.product')} {t('breadcrumb.details')}
				</Box>
				<ProductdetailsWrapper>
					<div>{getProductList()}</div>
					{addProductBlock()}
					{quantityErrorBlock()}
				</ProductdetailsWrapper>
			</Box>
		)
	}

	const shippingBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmDetailsEntry.shippingAndDelivery')}
				</Box>
				<Box
					style={{
						width: '100%',
						marginTop: '24px',
						padding: '25px 30px 0px',
						borderRadius: '5px',
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.dispatchedThrough')}
								</Label>
								<Input
									name="shipThrough"
									value={getIn(values, [
										'shippingDetails',
										'shipment',
										'shipThrough',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											[
												'shippingDetails',
												'shipment',
												'shipThrough',
											],
											value.shipThrough
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t(
										'tdmDetailsEntry.shipperReferenceNumber'
									)}
								</Label>
								<Input
									name="shipperNumber"
									value={getIn(values, [
										'shippingDetails',
										'shipment',
										'shipperNumber',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											[
												'shippingDetails',
												'shipment',
												'shipperNumber',
											],
											value.shipperNumber
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.billOfLading')}
								</Label>
								<Input
									name="billOfLading"
									value={getIn(values, [
										'shippingDetails',
										'shipment',
										'billOfLading',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											[
												'shippingDetails',
												'shipment',
												'billOfLading',
											],
											value.billOfLading
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmDetailsEntry.modeOfTransport')}
								</Label>
								<Input
									name="mode"
									value={getIn(values, [
										'shippingDetails',
										'shipment',
										'mode',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											[
												'shippingDetails',
												'shipment',
												'mode',
											],
											value.mode
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('rfq.portOfLading')}
								</Label>
								<Input
									name="ladingPort"
									value={getIn(values, [
										'shippingDetails',
										'ladingPort',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											['shippingDetails', 'ladingPort'],
											value.ladingPort
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('rfq.destinationPort')}
								</Label>
								<Input
									name="destinationPort"
									value={getIn(values, [
										'shippingDetails',
										'destinationPort',
									])}
									returnKeyValue
									onChange={value => {
										setFieldValue(
											[
												'shippingDetails',
												'destinationPort',
											],
											value.destinationPort
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
					</Box>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
										marginBottom: 8,
									}}
								>
									{t('rfq.typeOfContract')}
								</Label>
								<Select
									value={values.contractType}
									options={[
										{
											label: t('marketPlace.fob'),

											name: 'fob',
										},

										{
											label: t('marketPlace.cif'),

											name: 'cif',
										},

										{
											label: t(
												'marketPlace.local-delivery'
											),

											name: 'local-delivery',
										},
									]}
									key={values.contractType}
									labelKey="label"
									valueKey="name"
									onChange={e =>
										setFieldValue('contractType', e.name)
									}
									returnOnlyValue
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
										marginBottom: 8,
									}}
								>
									{t('rfq.shipmentPeriod')}
								</Label>

								<Select
									value={
										values.shippingDetails.shipment.month
									}
									options={[
										{
											label: t('marketPlace.jan'),
											name: 'january',
										},
										{
											label: t('marketPlace.feb'),
											name: 'february',
										},
										{
											label: t('marketPlace.mar'),
											name: 'march',
										},
										{
											label: t('marketPlace.apr'),
											name: 'april',
										},
										{
											label: t('marketPlace.may'),
											name: 'may',
										},
										{
											label: t('marketPlace.jun'),
											name: 'june',
										},
										{
											label: t('marketPlace.jul'),
											name: 'july',
										},
										{
											label: t('marketPlace.aug'),
											name: 'august',
										},
										{
											label: t('marketPlace.sep'),
											name: 'september',
										},
										{
											label: t('marketPlace.oct'),
											name: 'october',
										},
										{
											label: t('marketPlace.nov'),
											name: 'november',
										},
										{
											label: t('marketPlace.dec'),
											name: 'december',
										},
									]}
									key={values.shippingDetails.shipment.month}
									labelKey="label"
									valueKey="name"
									onChange={value =>
										setFieldValue(
											[
												'shippingDetails',
												'shipment',
												'month',
											],
											value.name
										)
									}
									returnOnlyValue
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Label
								style={{
									width: '94%',
									color: theme.color.accent2,
									fontSize: '14px',
									fontWeight: 500,
									marginBottom: 28,
								}}
							/>
							<Select
								value={values.shippingDetails.shipment.period}
								options={[
									{
										label: t('marketPlace.first-half'),

										name: 'first-half',
									},
									{
										label: t('marketPlace.second-half'),

										name: 'second-half',
									},
									{
										label: t('marketPlace.entire-month'),

										name: 'entire-month',
									},
								]}
								key={values.shippingDetails.shipment.period}
								labelKey="label"
								valueKey="name"
								onChange={value =>
									setFieldValue(
										[
											'shippingDetails',
											'shipment',
											'period',
										],
										value.name
									)
								}
								returnOnlyValue
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const otherDetailsBlock = () => {
		return (
			<Card
				style={{
					padding: '0px 20px',
					position: 'relative',
					overflow: 'visible',
					border: '1px solid #D9DEE5',
					borderRadius: '6px',
				}}
				textAlign="left"
			>
				<SmallText
					style={{
						position: 'absolute',
						fontSize: theme.fontSize.l,
						color: theme.color.grey6,
						top: '-10px',
						left: '20px',
						display: 'inline-block',
						backgroundColor: '#fff',
						padding: '0 8px',
					}}
				>
					Other Details
				</SmallText>
				<Box
					row={!isMobile}
					style={{ width: '100%', marginTop: '20px' }}
					justifyContent="space-between"
				>
					<Box width="44%">
						<Box style={{ width: '100%' }}>
							<Label
								style={{
									fontSize: theme.fontSize.m,
									color: '#242845',
									margin: '0 0 8px',
								}}
							>
								{t('rfq.preferredQuantityInspector')}
							</Label>
							<Box style={{ width: '100%' }}>
								<Input
									name="quantity"
									type="text"
									returnKeyValue
									value={
										getIn(values, [
											'inspections',
											'quantity',
											0,
										]) || ''
									}
									onChange={value => {
										if (!values?.inspections?.quantity) {
											values.inspections.quantity = []
										}
										setFieldValue(
											['inspections', 'quantity', 0],
											value.quantity
										)
									}}
								/>
							</Box>
						</Box>
					</Box>

					<Box width="44%">
						<Box style={{ width: '100%' }}>
							<Label
								style={{
									fontSize: theme.fontSize.m,
									color: '#242845',
									margin: '0 0 8px',
								}}
							>
								{t('rfq.preferredQualityInspector')}
							</Label>
							<Input
								name="quality"
								type="text"
								returnKeyValue
								value={
									getIn(values, [
										'inspections',
										'quality',
										0,
									]) || ''
								}
								onChange={value => {
									if (!values?.inspections?.quality) {
										values.inspections.quality = []
									}
									setFieldValue(
										['inspections', 'quality', 0],
										value.quality
									)
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Card>
		)
	}

	const termsAndConditionsBlock = () => {
		return (
			<Box>
				<Label>
					<Label
						style={{
							fontWeight: 400,
							fontSize: '16px',
							color: theme.color.accent2,
						}}
					>
						{t('rfq.termsAndConditions')}
					</Label>
				</Label>
				<TextArea
					name="termsAndConditions"
					value={values.meta.termsAndConditions}
					onBlur={handleBlur}
					customStyles={{
						border: '1px solid #D9DEE5',
					}}
					onChange={e => {
						setFieldValue(
							['meta', 'termsAndConditions'],
							e.target.value
						)
					}}
				/>
			</Box>
		)
	}

	const paymentTermsBlock = () => {
		return (
			<Box>
				<Label
					style={{
						fontWeight: 400,
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('rfq.paymentTerms')}
				</Label>
				<TextArea
					name="payment"
					value={values.meta.payment}
					onBlur={handleBlur}
					onChange={e => {
						setFieldValue(['meta', 'payment'], e.target.value)
					}}
					customStyles={{
						border: '1px solid #D9DEE5',
					}}
				/>
			</Box>
		)
	}

	const additionalDetailsBlock = () => {
		return (
			<Box>
				<Label
					style={{
						fontWeight: 400,
						fontSize: '16px',
						color: theme.color.accent2,
					}}
				>
					{t('rfq.additionalDetails')}
				</Label>
				<TextArea
					name="senderRequirements"
					value={values.senderRequirements}
					onBlur={handleBlur}
					onChange={e => {
						setFieldValue('senderRequirements', e.target.value)
					}}
					customStyles={{
						border: '1px solid #D9DEE5',
					}}
				/>
			</Box>
		)
	}

	const authorizationBlock = () => {
		return (
			<Box>
				<Box
					style={{
						fontWeight: 500,
						fontSize: '18px',
						marginTop: '30px',
						color: theme.color.accent2,
					}}
				>
					{t('tdmDetailsEntry.authorization')}
				</Box>
				<Box
					style={{
						width: '100%',
						marginTop: '24px',
						padding: '25px 30px 0px',
						borderRadius: '5px',
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<Box row={!isMobile} justifyContent="space-between">
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.reportedBy')}
								</Label>
								<Input
									name="reportedBy"
									value={getIn(values, [
										'meta',
										'authorization',
										'reportedBy',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'reportedBy',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.role')}
								</Label>
								<Input
									name="reportedByRole"
									value={getIn(values, [
										'meta',
										'authorization',
										'reportedByRole',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'reportedByRole',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
						<Box width={isMobile ? '100%' : '30%'}>
							<Box>
								<Label
									required
									style={{
										width: '94%',
										color: theme.color.accent2,
										fontSize: '14px',
										fontWeight: 500,
									}}
								>
									{t('tdmPreviewDetails.authorisedSignatory')}
								</Label>
								<Input
									name="authorisedSignatory"
									value={getIn(values, [
										'meta',
										'authorization',
										'authorisedSignatory',
									])}
									onChange={e => {
										setFieldValue(
											[
												'meta',
												'authorization',
												'authorisedSignatory',
											],
											e.target.value
										)
									}}
									extendStyles={{
										border: 'none',
										borderRadius: '0px',
										borderBottom: `1px solid ${theme.color.blue8}`,
										marginTop:
											rootModule === 'delivery-order'
												? '12px'
												: '7px',
									}}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		)
	}

	const submitBlock = () => {
		return (
			<Box row justifyContent={isMobile ? 'center' : 'flex-end'}>
				<Button
					label={t('common.cancel')}
					type="submit"
					rounded
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
								{
									rootModule: 'incoming',
								}
							)
						)
					}}
				/>
				<Button
					label={`${t('common.preview')} & ${t('common.send')}`}
					type="submit"
					disabled={disableCTA}
					isLoading={isSubmitting}
					primary
					rounded
					extendStyles={{
						marginLeft: 32,
						width: '232px',
						opacity: 'unset',
						backgroundColor: disableCTA
							? theme.color.themeGrey1
							: theme.color.blue8,
						border: disableCTA
							? theme.color.themeGrey1
							: theme.color.blue8,
					}}
				/>
			</Box>
		)
	}

	useEffect(() => {
		if (productCount < 1) {
			setProductCount(1)
		}
	}, [productCount])

	useEffect(() => {
		if (values?.meta?.attachedEntity && docCheck()) {
			const array = []
			array.push(values.meta.attachedEntity)
			setAttachfileArray(array)
		}
		if (
			values?.product?.length > 0 &&
			isDO &&
			(values.product[0].quantity === '' ||
				values.product[0].quantity === 0)
		) {
			setQuantityError(true)
		} else {
			setQuantityError(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values])

	useEffect(() => {
		setProductCount(
			values?.product?.length > 0 ? values?.product?.length : 1
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.docID])

	useEffect(() => {
		if (locationState?.query?.parentDocRef?.includes('~')) {
			const type = locationState.query.parentDocRef.split('~')
			if (type[0] !== rootModule) {
				setFieldValue(['meta', 'attachedEntity'], {})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootModule])

	useEffect(() => {
		if (
			locationState?.payload?.documentReference &&
			locationState?.payload?.documentStatus
		) {
			dispatch(
				TradeDocDuc.creators.fetchDocumentAttachments({
					documentReference: locationState.payload.documentReference,
				})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationState])

	useEffect(() => {
		if (tourModule === 'DOCreate') {
			if (isPO) {
				dispatch(WebTourDuc.creators.setActiveTourModule('POCreate'))
			}
			if (isDO) {
				dispatch(WebTourDuc.creators.setActiveTourModule('DOCreate2'))
			}
			if (isInvoice) {
				dispatch(
					WebTourDuc.creators.setActiveTourModule('invoiceCreate')
				)
			}
		}
	}, [tourModule, dispatch, rootModule, isPO, isDO, isInvoice])

	useEffect(() => {
		if (
			attachmentDoc &&
			attachmentDoc.length > 0 &&
			docCheck() &&
			!(documentStatus && documentStatus === 'edit')
		) {
			const array = []
			attachmentDoc.forEach(doc => {
				if (
					getIn(doc, ['type']) === 'file' &&
					getIn(doc, ['organizationID']) === getCurrentOrgID
				) {
					array.push(getIn(doc, ['file']))
					setAttachfileArray(array)
					setFieldValue(['meta', 'attachedEntity'], doc.file)
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc?.length, getCurrentOrgID])

	return (
		<div key={values.key}>
			<Box padding={8} width="100%" height="100%">
				{isLoading && <TransparentBgSpinner lockToScreen />}
				<Modal
					forceCloseviaButton
					size="large"
					show={showConfirmationModal}
					heading={t('tdmDetailsEntry.confirm')}
					body={
						<div>
							<P
								style={{
									fontSize: '24px',
									fontWeight: 500,
									marginBottom: '10px',
								}}
							>
								{t('tdmDetailsEntry.confirmModalMainText')}
							</P>
							<P
								style={{
									fontSize: '14px',
								}}
							>
								{t('tdmDetailsEntry.confirmModalSubText')}
							</P>
						</div>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => setShowConfirmationModal(false)}
					onConfirm={() => {
						const data = values
						data.product = []
						dispatch(
							TradeDocDuc.creators.setActiveDocument({
								rootModule,
								baseSchema: data,
							})
						)
						if (documentStatus && documentStatus === 'clone') {
							dispatch(
								MainRouteDuc.creators.switchPage(
									locationState.type,
									{
										...locationState.payload,
										action: 'attach-details-confirm',
										documentStatus: 'edit',
										baseValueSchema: data,
										forceScrolltoTop: true,
									},
									locationState.query
								)
							)
						} else {
							dispatch(
								MainRouteDuc.creators.switchPage(
									locationState.type,
									{
										...locationState.payload,
										action: 'attach-details-confirm',
										baseValueSchema: data,
										forceScrolltoTop: true,
									},
									locationState.query
								)
							)
						}
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowConfirmationModal(false)
					}}
				/>
				<Modal
					size="large"
					show={showFindProductModal}
					body={
						<div
							style={{
								fontSize: '24px',
								fontWeight: 500,
								marginBottom: '10px',
							}}
						>
							{t('tdmDetailsEntry.contactYourAdmin')}
						</div>
					}
					confirmlabel={t('common.ok')}
					onClose={() => setShowFindProductModal(false)}
					onConfirm={() => {
						setShowFindProductModal(false)
					}}
				/>
				{breadCrumbBlock()}
				{headerBlock()}
				<Spacer size={8} />
				<Box
					style={{
						backgroundColor: theme.color.white,
						padding: '35px',
						border: `1px solid ${theme.color.grey4}`,
						borderRadius: '5px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Box>
							{!isQuote && recipientBlock()}
							{isQuote && participantDetailsBlock()}
							{attachFilesBlock()}
							{!isQuote && documentDetailsBlock()}
							{productBlock()}
							<Spacer size={32} />
							{!isInvoice && shippingBlock()}
							<Spacer size={32} />
							{!isInvoice && otherDetailsBlock()}
							<Spacer size={32} />
							{termsAndConditionsBlock()}
							<Spacer size={16} />
							{paymentTermsBlock()}
							<Spacer size={16} />
							{additionalDetailsBlock()}
							<Spacer size={16} />
							{authorizationBlock()}
							<Spacer size={32} horizontal={!isMobile} />
							{submitBlock()}
						</Box>
					</form>
				</Box>
			</Box>
			{(tourModule === 'DOCreate2' ||
				tourModule === 'POCreate' ||
				tourModule === 'invoiceCreate') && <WebTour />}
		</div>
	)
}

export { AttachDetailsEntry }
