/* @flow */
import React from 'react'
import { getIn } from 'timm'
import styled from 'styled-components'
import {
	STATEMENT_ALIASES_KEYS,
	statementKeys1,
	statementKeys2,
	paymentSlipkeys1,
	paymentSlipkeys2,
	MONTHS_FOR_BANK,
	PAYMENT_SLIP_ALIASES_KEYS,
	TRANSACTION_TYPE_READ,
} from 'ui-tdm-app/modules/TradeDocumentManager/config'
import { Icon } from 'ui-lib/icons/components/Icon'
import { Spacer } from 'ui-lib/utils/Spacer'
import { FileLists } from 'ui-lib/components/FileUpload'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { SmallText } from 'ui-lib/components/Typography'
import { KeyValueSegment } from 'ui-tdm-app/modules/Admin/components/KeyValueSegment'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import EditIcon from 'ui-lib/icons/edit.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import theme from 'ui-lib/utils/base-theme'

const GreenPill = styled(Box)({
	height: '30px',
	width: '100px',
	fontWeight: 500,
	marginTop: '6px',
	alignItems: 'center',
	borderRadius: '10px',
	justifyContent: 'center',
	color: theme.color.accent2,
	border: `1px solid ${theme.color.green}`,
	backgroundColors: theme.color.themeGreen2,
})

const DataDisplayBlock = ({
	id,
	dataDetails,
	t,
	handleOnChange,
	isOnlyOneStatementAvailable = false,
	isMobile = false,
	type = '',
	noEdit = false,
	noDelete = false,
}) => {
	const getLabel = key => {
		let label = t(STATEMENT_ALIASES_KEYS[key])
		if (type === 'payment-slip') {
			label = t(PAYMENT_SLIP_ALIASES_KEYS[key])
		}

		return label
	}

	const getKeys = number => {
		let key = number === 1 ? statementKeys1 : statementKeys2
		if (type === 'payment-slip') {
			key = number === 1 ? paymentSlipkeys1 : paymentSlipkeys2
		}

		return key
	}

	const getValue = key => {
		if (key === 'attachment') {
			return (
				<FileLists
					documents={
						type === 'payment-slip'
							? [dataDetails?.file[0]] || []
							: [dataDetails?.meta?.file] || []
					}
					isReadOnly
				/>
			)
		} else if (key === 'status') {
			return (
				<GreenPill>
					{dataDetails[key]
						? dataDetails[key]?.toUpperCase()
						: 'PAID'}
				</GreenPill>
			)
		} else if (type === 'payment-slip' && key === 'paymentMethod') {
			return t(TRANSACTION_TYPE_READ[dataDetails[key]])
		} else if (type === 'payment-slip' && key === 'amount') {
			return `${dataDetails[key]} ${dataDetails.currency}`
		} else {
			return dataDetails[key]
		}
	}

	return (
		<>
			<Box row={!isMobile} padding="20px 0px">
				<Card
					style={{
						padding: '20px',
						// border: '1px solid',
						overflow: 'visible',
						position: 'relative',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<SmallText
						style={{
							position: 'absolute',
							fontSize: theme.fontSize.m,
							color: theme.color.accent2,
							top: '-10px',
							left: '20px',
							display: 'inline-block',
							backgroundColor: theme.color.white,
							padding: '0 8px',
						}}
					>
						{type === 'payment-slip'
							? id
							: getIn(dataDetails, ['bankName'])}
					</SmallText>
					<Box row justifyContent="flex-end">
						{!noDelete && (
							<ButtonWithNoBorder
								label={t('common.delete')}
								action
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={DeleteIcon} />
									</ButtonIconWrapper>
								}
								onClick={() => {
									handleOnChange('delete', true, id)
								}}
							/>
						)}
						<Spacer size={16} />
						{!noEdit && (
							<ButtonWithNoBorder
								label={t('common.edit')}
								action
								rounded
								customIcon={
									<ButtonIconWrapper lightBG>
										<Icon glyph={EditIcon} />
									</ButtonIconWrapper>
								}
								onClick={() => {
									handleOnChange('edit', true, id)
								}}
							/>
						)}
					</Box>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							{getKeys(1).map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="40%"
										label={getLabel(key)}
										value={getValue(key)}
									/>
								)
							})}
						</Box>
						<Box style={{ width: '50%' }}>
							{getKeys(2).map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="40%"
										label={getLabel(key)}
										value={
											key === 'month'
												? t(
														MONTHS_FOR_BANK[
															dataDetails[key]
														]
												  )
												: getValue(key)
										}
									/>
								)
							})}
						</Box>
					</Box>
				</Card>
			</Box>
		</>
	)
}

DataDisplayBlock.defaultProps = {
	id: '',
	dataDetails: {},
	handleOnChange: () => {},
	isOnlyOneStatementAvailable: false,
	isMobile: false,
}

export { DataDisplayBlock }
