import React, { useState } from 'react'
import { Modal } from 'ui-lib/components/Modal'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { TextArea } from 'ui-lib/components/TextArea'

const statusMap = ['hold', 'rejected']

const RemarksConfirmation = ({
	showModal,
	handleOnConfirm,
	handleOnClose,
	handleOnSkip,
	currentStatus,
}) => {
	/** Local states to manage few things. */
	const [remarks, setRemarks] = useState('')
	const [errorRemarks, setErrorRemarks] = useState('')
	const { t } = useTranslation()
	const skipStatus = statusMap.includes(currentStatus)

	return (
		<Modal
			closeable
			show={showModal}
			heading={t('tdmColumnHeaders.modalHeading')}
			body={
				<Box width="inherit" style={{ padding: 4 }}>
					<TextArea
						name="remarks-input"
						required
						value={remarks}
						onChange={e => {
							setErrorRemarks('')
							setRemarks(e.target.value)
						}}
						error={errorRemarks}
					/>
				</Box>
			}
			skiplabel={t('common.skip')}
			forceSkipviaButton={!skipStatus}
			confirmlabel={t('common.submit')}
			onClose={() => {
				setErrorRemarks('')
				setRemarks('')
				handleOnClose()
			}}
			onSkip={() => {
				setRemarks('')
				handleOnSkip()
			}}
			onConfirm={() => {
				if (!remarks) {
					setErrorRemarks(t('validation.remarksRequired'))

					return true // this will prevent the modal from closing
				}
				setErrorRemarks('')
				setRemarks('')
				handleOnConfirm(remarks)
			}}
		/>
	)
}

RemarksConfirmation.defaultProps = {
	handleOnConfirm: () => {},
	handleOnClose: () => {},
	handleOnSkip: () => {},
}

export { RemarksConfirmation }
