import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { ButtonIconWrapper, ButtonWithNoBorder } from 'ui-lib/components/Button'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { FileUpload } from 'ui-lib/components/FileUpload'
import { Input } from 'ui-lib/components/Input'
import { Modal } from 'ui-lib/components/Modal'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { Timeline } from 'ui-lib/components/Timeline/index'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import backArrow from 'ui-lib/icons/left-arrow.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { scrollIntoView } from 'ui-lib/utils/scroll-helper'
import { Spacer } from 'ui-lib/utils/Spacer'
import { CookieDuc } from 'ui-tdm-app/modules/App/cookieDuc'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { MessagesDuc } from 'ui-tdm-app/modules/Messages/duc'
import { DocumentLeadCard } from 'ui-tdm-app/modules/TradeDocumentManager/components/DocumentLead'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { RemarksConfirmation } from '../../components/RemarksConfirmation'
import { Note } from '../../components/Title'
//  import { MessagesBlock } from '../../Shared/MessagesBlock'
import { NAME_ALIASES } from '../../config'
// import { CompleteTraceReport } from './CompleteTraceReport'
import { ChatModalComponentTab } from '../../../Messages/components/ChatModalComponentTab'
import { GeneralDocumentView } from '../../components/GeneralDocumentView'
import { MessagesBlock } from '../DocumentControl/Shared/MessagesBlock'
import { AssociatedDocStacks } from './components/AssociatedDocStacks'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`

const Header = styled.div`
	font-size: 30px;
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`
const SubText = styled.div`
	padding-top: 3px;
	padding-left: 10px
	color: ${theme.color.blue8};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`

const ViewGeneralDocument = () => {
	const dispatch = useDispatch()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { fullName } = useSelector(AuthDuc.selectors.getUserProfile)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getGeneralDocumentActiveRecord
	)

	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const selectedCPID = useSelector(CookieDuc.selectors.getSelectedCPID)

	const initiatingPartyDetails = getIn(activeDocument, ['initiatingParty'])

	const isSupplyChainModelEnforced = useSelector(
		TradeDocDuc.selectors.getSupplyChainModelEnforcedStatus
	)

	const receivingPartyDetails = getIn(activeDocument, ['receivingParty'])
	const usersList = useSelector(AuthDuc.selectors.getUsersData)

	const documentTrace = useSelector(
		TradeDocDuc.selectors.getActiveAssociatedDocs
	)
	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const weighBridgeTickets = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'weighbridge')
		: []

	const { id: loggedinOrgID } = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)
	const messages = useSelector(TradeDocDuc.selectors.getRemarks)

	const loggedInOrgWeighBridgeTicket = weighBridgeTickets.filter(
		wbt => wbt.ticketInfo.organizationID === loggedinOrgID
	)

	const notLoggedInOrgWeighBridgeTicket = weighBridgeTickets.filter(
		wbt => wbt.ticketInfo.organizationID !== loggedinOrgID
	)

	const loggedinOrgNetWeight = getIn(loggedInOrgWeighBridgeTicket, [
		0,
		'ticketInfo',
		'netWeight',
	])

	const notLoggedinOrgNetWeight = getIn(notLoggedInOrgWeighBridgeTicket, [
		0,
		'ticketInfo',
		'netWeight',
	])

	const netWeight = loggedinOrgNetWeight || notLoggedinOrgNetWeight

	const billOfLadingAttachments = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'bill-of-lading')
		: []
	const paymentSlipAttachments = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'payment-slip')
		: []
	const otherAttachments = attachmentDoc
		? attachmentDoc.filter(doc => doc.category === 'documents')
		: []
	const { initiatingParty = {}, receivingParty = {} } = activeDocument

	const [showRemarkModal, setShowRemarkModal] = useState(false)
	const [showFileUploadModal, setShowFileUploadModal] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [selectedFile, setSelectedFile] = useState({})
	const [newFileName, setNewFileName] = useState('')
	const [renameModal, setRenameModal] = useState(false)
	const [attachfile, setAttachfile] = useState({})
	const [currentAttachmentType, setCurrentAttachmentType] = useState(null)
	const [targetActiveStatus, setTargetActiveStatus] = useState('')
	const [isDOEditable, setIsDOEditable] = useState(false)
	const [showWeighbridgeModal, setShowWeighbridgeModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const hasInitiatedByCurrentOrg =
		getCurrentOrgID === initiatingPartyDetails?.orgID
	const receivingOrgCategory =
		getIn(receivingPartyDetails, ['categories', 0, 'id']) || '---'
	const initiatingOrgCategory =
		getIn(initiatingPartyDetails, ['categories', 0, 'id']) || '---'

	const receivingOrgParentName =
		getIn(receivingPartyDetails, [
			'meta',
			'parentOrganization',
			0,
			'name',
		]) || ''
	const initiatingOrgParentName =
		getIn(initiatingPartyDetails, [
			'meta',
			'parentOrganization',
			0,
			'name',
		]) || ''

	let receivingOrgName =
		getIn(receivingPartyDetails, ['name']) ||
		getIn(activeDocument, ['meta', 'receiverEmailID']) ||
		getIn(activeDocument, ['meta', 'globalReceiver', 'name']) ||
		'---'
	let initiatingOrgName =
		getIn(initiatingPartyDetails, ['name']) ||
		getIn(activeDocument, ['meta', 'globalInitiator', 'name']) ||
		'---'

	const timelineData = getIn(activeDocument, ['statusLog']) || []

	receivingOrgName =
		receivingOrgCategory === 'palmoil-collectionPoint'
			? `${receivingOrgParentName} - ${receivingOrgName}`
			: receivingOrgName

	initiatingOrgName =
		initiatingOrgCategory === 'palmoil-collectionPoint'
			? `${initiatingOrgParentName} - ${initiatingOrgName}`
			: initiatingOrgName

	const { payload = {} } = locationState
	const { rootModule } = payload
	const _activeTab = useSelector(TradeDocDuc.selectors.getDocumentActiveTab)

	const chatWithName = hasInitiatedByCurrentOrg
		? receivingOrgName
		: initiatingOrgName

	const chatAssociatedList = useSelector(
		TradeDocDuc.selectors.getChatAssociatedList
	)
	const messagesList = useSelector(TradeDocDuc.selectors.getChatMessagesList)
	const documentId = getIn(activeDocument, ['docID'])
	const currentUnread =
		chatAssociatedList.length > 0 &&
		chatAssociatedList?.find(item => item.documentID === documentId)
			?.unReadCount
	const [currentUnreadCount, setCurrentUnreadCount] = useState(
		currentUnread || 0
	)

	const VIEW_TAB_CONFIG = [
		{
			name: 'original',
			label: t('tdmViewDocument.original'),
		},
		{
			name: 'chat',
			label: `${t('common.messages')} ${
				currentUnreadCount > 0 ? `(${currentUnreadCount})` : ''
			}`,
		},
		{
			name: 'associated',
			label: t('tdmViewDocument.associatedDocs'),
		},
		{
			name: 'timeline',
			label: t('tdmViewDocument.timeline'),
		},

		{
			name: 'attachments',
			label: t('tdmViewDocument.attachments'),
		},
		...(rootModule === 'invoice'
			? [
					{
						name: 'paymentReport',
						label: t('tdmViewDocument.paymentReport'),
					},
			  ]
			: ''),
		{
			name: 'version',
			label: t('tdmViewDocument.version'),
		},
	]

	const activeTabs = useMemo(() => {
		let tabs = [...VIEW_TAB_CONFIG]
		if (!activeDocument.versions) {
			tabs = tabs.filter(tab => tab.name !== 'version')
		}

		return tabs
	}, [VIEW_TAB_CONFIG, activeDocument.versions])

	const [
		originalTabKey,
		chatTabKey,
		associatedTabKey,
		timelineTabKey,
		attachmentTabKey,
	] = VIEW_TAB_CONFIG.map(k => k.name)
	const [activeTab, setActiveTab] = useState(_activeTab)

	useEffect(() => {
		setActiveTab(_activeTab)
	}, [_activeTab])

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'generalDocument',
				isActive: true,
			},
			{
				label: `${t(title)}`,
				name: 'document-view',
				isActive: false,
			},
		],
		[t]
	)

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			if (isIframeForTeamsApp()) {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule:
								breadCrumRootModule === 'incoming' ||
								breadCrumRootModule === 'outgoing' ||
								breadCrumRootModule === 'all'
									? getIn(locationState, [
											'prev',
											'payload',
											'rootModule',
									  ])
									: 'incoming',
						}
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.DASHBOARD
					)
				)
			}
		}
		if (target === 'generalDocument') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERALACTION,
					{
						rootModule: 'general-document',
						action: 'listing',
					}
				)
			)
		}
	}
	const messagesBlockRef = useRef(null)
	const isOriginalTabActive = activeTab === originalTabKey
	const isChatTabActive = activeTab === chatTabKey

	const isTimelineTabActive = activeTab === timelineTabKey
	const isHistoryTabActive = activeTab === associatedTabKey
	const isAttachmentTabActive = activeTab === attachmentTabKey
	const entityFiles = getIn(activeDocument, ['meta', 'files']) || []
	const handleMessagesScroll = useCallback(() => {
		if (!isOriginalTabActive) {
			setActiveTab(originalTabKey)
		}
		setTimeout(() => {
			scrollIntoView(messagesBlockRef.current || {}, -80)
		}, 300)
	}, [isOriginalTabActive, originalTabKey])

	const remarksTtl = getIn(activeDocument, ['remarks']) || []
	const remarkCount = remarksTtl.reduce((n, x) => {
		const testArray = 'comment' in x

		return n + (testArray === true)
	}, 0)
	const handleOnChangeStatus = remarks => {
		setShowRemarkModal(false)
		if (remarks) {
			dispatch(
				TradeDocDuc.creators.updateTransactionRemarks(
					documentId,
					remarks
				)
			)
		}

		dispatch(
			TradeDocDuc.creators.updateTransaction(
				{
					...activeDocument,
					status: targetActiveStatus,
				},
				t('production.plotInputSuccessToast'),
				{ statusUpdate: true }
			)
		)
	}

	const note =
		!hasInitiatedByCurrentOrg &&
		rootModule === 'purchase-order' &&
		(activeDocument.docStatus !== 'complete' ||
			activeDocument.docStatus !== 'accepted')
			? 'tdmViewDocument.purchaseOrderNote'
			: ''

	const addAttachmentToRFQ = () => {
		setUploadedFiles([])
		dispatch(
			TradeDocDuc.creators.createEntityAttachment(
				rootModule,
				activeDocument.id,
				receivingPartyDetails,
				uploadedFiles,
				'Success',
				currentAttachmentType
			)
		)
	}
	const editFileName = () => {
		const { attachmentID, entityID } = selectedFile
		const newFiles = {
			...selectedFile,
			meta: {
				...selectedFile?.meta,
				name: newFileName,
			},
		}
		dispatch(
			TradeDocDuc.creators.updateEntityAttachment(
				rootModule,
				entityID,
				attachmentID,
				newFiles,
				'updated Success'
			)
		)
	}

	const getNameById = id => {
		let name = ''

		if (id === getIn(receivingParty, ['orgID'])) {
			name = getIn(receivingParty, ['name'])
		} else if (id === getIn(initiatingParty, ['orgID'])) {
			name = getIn(initiatingParty, ['name'])
		}

		return name
	}

	const getTimelineDataArray = () => {
		const timelineArray = []

		if (timelineData && timelineData.length > 0) {
			timelineData.forEach(item => {
				timelineArray.push({
					date: getDateByFormat(
						item.createdAt,
						'dd MMM yyyy hh:mm a',
						activeLocale
					),
					status: (getIn(item, ['state']) || '')?.toUpperCase(),
					statusDetail: item.createdBy
						? `by ${item.createdBy}, ${getNameById(
								item.organizationID
						  )}`
						: '',

					version: item.version === 0 ? '0' : item.version,
					versionPath: `/trade-document-manager/${item.entityType}/${item.entityID}`,
				})
			})
		}

		return timelineArray
	}

	const getAssociatedRef = type => {
		let data = ''
		if (
			getIn(activeDocument, ['meta', 'docReference']) &&
			getIn(activeDocument, ['meta', 'docReference']).length > 0
		) {
			getIn(activeDocument, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType === type) {
					data = item.referenceValue
				}
			})
		}

		return data
	}

	const getAttachedFiles = data => {
		const files = []
		data.forEach(document => {
			if (
				getIn(document, ['type']) === 'user-input' ||
				getIn(document, ['organizationID']) === getCurrentOrgID
			) {
				files.push(
					<Box overflow>
						{/* <AttachmentMenu
							key={document?.id}
							data={document}
							currentOrgID={getCurrentOrgID}
							selectedCPID={selectedCPID}
							handleEdit={() => {
								setSelectedFile(document)
							}}
							handleRename={() => {
								setRenameModal(true)
							}}
							handleDelete={() => {
								deleteFileAttachment(selectedFile)
							}}
						/> */}
					</Box>
				)
			}
		})

		return files
	}
	const getReceiverEmail = () => {
		let receiver = ''

		if (hasInitiatedByCurrentOrg) {
			receiver =
				receivingPartyDetails && receivingPartyDetails.id
					? ''
					: getIn(activeDocument, ['meta', 'receiverEmailID'])
		}

		return receiver
	}

	useEffect(() => {
		if (
			rootModule !== 'delivery-order' ||
			(((activeDocument?.id &&
				activeDocument?.meta?.enforceSupplyChainModel ===
					isSupplyChainModelEnforced) ||
				actor.includes('palmoil_plantation')) &&
				!getIn(activeDocument, ['meta', 'incomingVirtualDO']))
		) {
			setIsDOEditable(true)
		}
	}, [activeDocument, actor, isSupplyChainModelEnforced, rootModule])

	useEffect(() => {
		if (attachmentDoc && attachmentDoc.length > 0) {
			attachmentDoc.forEach(item => {
				if (
					getIn(item, ['type']) === 'file' &&
					getIn(item, ['organizationID']) === getCurrentOrgID
				) {
					setAttachfile(getIn(item, ['files']))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc])

	useEffect(() => {
		let hasWeighbridgeAttachment = false
		if (attachmentDoc && activeDocument.id) {
			hasWeighbridgeAttachment = weighBridgeTickets.length > 0
			if (
				getIn(activeDocument, ['meta', 'incomingVirtualDO']) &&
				!hasWeighbridgeAttachment
			) {
				setShowWeighbridgeModal(true)
			} else {
				setShowWeighbridgeModal(false)
			}
		}
	}, [activeDocument, attachmentDoc, weighBridgeTickets.length])

	useEffect(() => {
		return () => {
			dispatch(AppDuc.creators.hideGlobalLoader('document-view'))
		}
	}, [dispatch])

	return (
		<>
			<RemarksConfirmation
				showModal={showRemarkModal}
				handleOnConfirm={remarks => {
					handleOnChangeStatus(remarks)
				}}
				handleOnSkip={() => {
					handleOnChangeStatus()
				}}
				handleOnClose={() => {
					setShowRemarkModal(false)()
				}}
			/>

			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('tdmViewDocument.deletePaymentReport')}
				body={<div>{t('tdmViewDocument.deletePaymentReportNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowDeleteModal(false)
				}}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						TradeDocDuc.creators.deleteEntityAttachment(
							rootModule,
							activeDocument.id,
							paymentSlipAttachments[0].attachmentID,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>
			<Modal
				closeable={false}
				show={showWeighbridgeModal}
				body={<div>{t('tdmDetailsEntry.wantWeighBridgeSlip')}</div>}
				secondaryButtonLabel={t('common.no')}
				confirmlabel={t('common.yes')}
				onConfirm={() => {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types.WEIGHBRIDGE$WACTION,
							{
								action: 'create',
							},
							{
								parentDocRef: activeDocument.id,
							}
						)
					)
					setShowWeighbridgeModal(false)
				}}
				secondaryButtonClick={() => {
					const formValues = {
						checkedBy: fullName,
						grossWeight: '',
						netWeight: getIn(activeDocument, [
							'products',
							0,
							'quantity',
						]),
						tareWeight: '',
						ticketNumber: '',
						productID: getIn(activeDocument, ['products', 0, 'id']),
						uom: getIn(activeDocument, ['products', 0, 'uom']),
						entityID: activeDocument.id,
						file: {},
					}
					dispatch(
						TradeDocDuc.creators.createWBSlip(
							formValues,
							getCurrentOrgID === activeDocument.receivingPartyID,
							actor
						)
					)
					setShowWeighbridgeModal(false)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={renameModal}
				heading="Rename File!"
				body={
					<Box>
						<Input
							name="uploadedFileName"
							value={
								selectedFile?.file?.meta?.name
									? selectedFile?.file?.meta?.name
									: ''
							}
							onChange={event =>
								setNewFileName(event.target.value)
							}
							required
						/>
					</Box>
				}
				closelabel={t('common.back')}
				confirmlabel="Rename"
				onClose={() => {
					setRenameModal(false)
				}}
				onConfirm={() => {
					setRenameModal(false)
					editFileName()
				}}
			/>
			<Modal
				forceCloseviaButton
				size="large"
				show={showFileUploadModal}
				heading={
					currentAttachmentType === 'bill-of-lading'
						? `${t('breadcrumb.attach')} ${t(
								'tdmDetailsEntry.billOfLading'
						  )}`
						: t('common.import')
				}
				body={
					<>
						{currentAttachmentType === 'bill-of-lading' &&
						billOfLadingAttachments &&
						billOfLadingAttachments.length > 0 ? (
							<div>
								{t(
									'tdmPreviewDetails.youHaveAlreadyAttachedOneBillOfLading'
								)}
							</div>
						) : (
							<FileUpload
								type="private"
								name="file"
								size="5242880"
								label="Upload Attachment"
								required
								supportedFileType=".jpg,.png,.pdf"
								outline
								modalTriggerBtnLabel={t('common.upload')}
								currentUploads={uploadedFiles}
								onDocumentChange={async uploadedDoc => {
									setUploadedFiles(uploadedDoc)
								}}
								disableUpload={
									currentAttachmentType === 'bill-of-lading'
								}
								onUploadFailure={({ message }) => {
									dispatch(
										AppDuc.creators.showToast({
											messageType: 'error',
											message,
										})
									)
								}}
							/>
						)}
					</>
				}
				confirmlabel={t('common.proceed')}
				isDisabled={uploadedFiles.length === 0}
				closelabel={t('common.close')}
				onClose={() => {
					setUploadedFiles([])
					setShowFileUploadModal(false)
				}}
				onConfirm={() => {
					setShowFileUploadModal(false)
					addAttachmentToRFQ()
				}}
			/>
			<Box
				width="100%"
				height="100%"
				style={{ marginLeft: isIframeForTeamsApp() ? '20px' : 'auto' }}
			>
				{isLoading && <TransparentBgSpinner />}
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					justifyContent="space-between"
					alignItems="baseline"
					style={{
						padding: '10px 0 15px',
					}}
				>
					<HeaderWrapper>
						<SubWrapper
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER$GENERALACTION,
										{
											rootModule: 'general-document',
											action: 'listing',
										}
									)
								)
							}
						>
							<IconWrapper
								size={24}
								style={{
									color: theme.color.blue8,
									cursor: 'pointer',
								}}
							>
								<Icon glyph={backArrow} />
							</IconWrapper>
							<SubText>{t('common.back')}</SubText>
						</SubWrapper>

						<Header>{t('generalDocument.viewDocument')}</Header>
					</HeaderWrapper>
				</Box>

				<Box
					row
					justifyContent="flex-end"
					alignItems="baseline"
					wrap
					style={{ paddingRight: isMobile ? '10px' : '0px' }}
				>
					<Box row overflow>
						<>
							{isDOEditable &&
								hasInitiatedByCurrentOrg &&
								!selectedCPID && (
									<>
										<ButtonWithNoBorder
											label={t('common.edit')}
											action
											rounded
											customIcon={
												<ButtonIconWrapper lightBG>
													<Icon glyph={EditIcon} />
												</ButtonIconWrapper>
											}
											extendStyles={{
												display:
													activeDocument.docStatus ===
														'complete' ||
													activeDocument.docStatus ===
														'accepted' ||
													activeDocument.docStatus ===
														'rejected' ||
													activeDocument.docStatus ===
														'delivered' ||
													activeDocument.docStatus ===
														'hold' ||
													activeDocument.docStatus ===
														'intransit'
														? 'none'
														: '',
											}}
											onClick={() => {
												dispatch(
													TradeDocDuc.creators.setActiveGeneralDocuments(
														activeDocument
													)
												)

												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.TRADE_DOCUMENT_MANAGER$GENERALACTION,
														{
															rootModule,
															action: 'edit-doc',
															documentReference: getIn(
																activeDocument,
																['id']
															),
															documentStatus:
																'clone',
														}
													)
												)
											}}
										/>
									</>
								)}

							<Spacer size={30} horizontal />
						</>
					</Box>
				</Box>
				<Spacer size={8} />

				<Box>
					<Box
						alignItems="flex-end"
						style={{
							paddingLeft: isMobile ? '10px' : '0px',
							paddingRight: isMobile ? '10px' : '0px',
						}}
					>
						<Note
							noteColor={theme.color.error}
							fontSize={theme.fontSize.l}
						>
							{t(note)}
						</Note>
					</Box>
					<Spacer size={4} />
					<div
						style={{
							paddingLeft: isMobile ? '10px' : '0px',
							paddingRight: isMobile ? '10px' : '0px',
						}}
					>
						<DocumentLeadCard
							rootModule={rootModule}
							isDocumentOwner={hasInitiatedByCurrentOrg}
							partner={
								hasInitiatedByCurrentOrg
									? receivingOrgName
									: initiatingOrgName
							}
							// patch this to proper key once backend gives amount.
							amount={getIn(activeDocument, ['meta', 'amount'])}
							sentDate={
								getIn(activeDocument, ['meta', 'issueDate']) ||
								'---'
							}
							actionedDate={activeDocument.updatedAt || '---'}
							docStatus={activeDocument.status}
							netWeight={netWeight}
							messagesCount={remarkCount}
							onMessagesLinkClick={() => {
								handleMessagesScroll()
							}}
							onActionChange={newStatus => {
								setTargetActiveStatus(newStatus)
								setShowRemarkModal(true)
							}}
						/>
					</div>
				</Box>
				<Box center padding="20px 0px">
					<TabButtons
						fontSize={theme.fontSize.xl}
						list={activeTabs}
						key={activeTab}
						id="DOAttachments"
						activeIndex={VIEW_TAB_CONFIG.findIndex(
							q => q.name === activeTab
						)}
						onChange={({ name }) => {
							setActiveTab(name)
							if (name === 'chat' && messagesList?.threadID) {
								setCurrentUnreadCount(0)
								dispatch(
									MessagesDuc.creators.sendMessageAction(
										messagesList.threadID,
										'reset-unread'
									)
								)
							}
							dispatch(TradeDocDuc.creators.setActiveTab(name))
						}}
						borderRadius={10}
						isMobile={isMobile}
					/>
				</Box>
				{isOriginalTabActive && (
					<>
						<GeneralDocumentView
							rootModule={rootModule}
							partner={
								hasInitiatedByCurrentOrg
									? receivingPartyDetails
									: initiatingPartyDetails
							}
							brokerEmailID={
								getIn(activeDocument, [
									'meta',
									'brokerEmailID',
								]) || ''
							}
							receiverEmailID={getReceiverEmail() || ''}
							associatedPORef={
								getAssociatedRef('purchase-order') || ''
							}
							associatedDORef={
								getAssociatedRef('delivery-order') || ''
							}
							associatedInvoiceRef={
								getAssociatedRef('invoice') || ''
							}
							productDetails={
								getIn(activeDocument, ['products']) || []
							}
							receiverTraceIDs={getIn(activeDocument, [
								'receiverTraceIDs',
							])}
							tankDetails={
								getIn(activeDocument, ['tankDetails']) || []
							}
							user={
								getIn(activeDocument, [
									'meta',
									'authorization',
									'reportedBy',
								]) || ''
							}
							role={
								getIn(activeDocument, [
									'meta',
									'authorization',
									'reportedByRole',
								]) || ''
							}
							issueDate={
								getIn(activeDocument, ['meta', 'issueDate']) ||
								''
							}
							attachedFiles={
								getIn(activeDocument, ['files']) || []
							}
							status={
								getIn(activeDocument, ['status'])
									? getIn(activeDocument, [
											'status',
									  ]).toUpperCase() || ''
									: ''
							}
							documentNumber={
								getIn(activeDocument, [
									'meta',
									'referenceName',
								]) || 'N/A'
							}
							transactionNumber={
								getIn(activeDocument, ['transactionNumber']) ||
								'N/A'
							}
							activeDocument={activeDocument}
							isMobile={isMobile}
							actor={actor}
							hasInitiatedByCurrentOrg={hasInitiatedByCurrentOrg}
							remarks={
								getIn(activeDocument, ['meta', 'remarks']) || ''
							}
						/>
						<MessagesBlock messages={messages} readOnly />
					</>
				)}
				{isChatTabActive && (
					<ChatModalComponentTab
						documentId={getIn(activeDocument, ['docID'])}
						documentNumber={
							getIn(activeDocument, ['transactionNumber']) || ''
						}
						sellerName={initiatingOrgName}
						buyerName={receivingOrgName}
						buyerId={getIn(activeDocument, [
							'receivingParty',
							'orgID',
						])}
						sellerId={getIn(activeDocument, [
							'initiatingParty',
							'orgID',
						])}
						docThreadID={messagesList?.threadID || null}
						tradeId={getIn(activeDocument, ['transactionId'])}
						docType="doc-transaction"
						isMobile={isMobile}
						usersList={usersList}
					/>
				)}
				{isTimelineTabActive && (
					<div
						style={{
							zIndex: 0,
							borderRadius: 10,
							padding: isMobile
								? '30px 7px 100px'
								: '30px 50px 100px',
							background: theme.color.white,
							marginLeft: isMobile ? '10px' : '0px',
							marginRight: isMobile ? '10px' : '0px',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<Timeline
							title={t('tdmViewDocument.historyOfEvents')}
							data={getTimelineDataArray()}
							isMobile={isMobile}
						/>
					</div>
				)}
				<br />
				{isHistoryTabActive && (
					<>
						{Object.keys(documentTrace).length > 0 && (
							<BoxShadowWrapper
								style={{
									background: theme.color.white,
									padding: isMobile ? '10px' : '16px',
								}}
							>
								<AssociatedDocStacks
									documents={documentTrace}
									activeDocumentKey={activeDocument.id}
									messages={messages}
								/>
							</BoxShadowWrapper>
						)}
						<Spacer size={24} />
					</>
				)}
				{billOfLadingAttachments &&
				billOfLadingAttachments.length > 0 &&
				isAttachmentTabActive ? (
					<>
						<div
							style={{
								fontSize: 20,
								marginLeft: 30,
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmPreviewDetails.billOfLading')}
						</div>
						<Box row>
							{isAttachmentTabActive &&
								billOfLadingAttachments.length > 0 &&
								getAttachedFiles(billOfLadingAttachments)}
						</Box>
					</>
				) : (
					''
				)}
				{isAttachmentTabActive && otherAttachments.length > 0 ? (
					<>
						<div
							style={{
								fontSize: 20,
								marginLeft: 30,
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmPreviewDetails.attachments')}
						</div>
						<Box row style={{ flexWrap: 'wrap' }}>
							{isAttachmentTabActive &&
								otherAttachments.length > 0 &&
								getAttachedFiles(otherAttachments)}
						</Box>
					</>
				) : (
					''
				)}

				{isAttachmentTabActive &&
					attachmentDoc.length === 0 &&
					entityFiles.length === 0 && (
						<ErrorBlock
							hideButton
							small
							status="empty"
							message={t('tdmViewDocument.noAttachmentFound')}
						/>
					)}
			</Box>
			{(tourModule === 'tdmDOView' ||
				tourModule === 'tdmPOView' ||
				tourModule === 'tdmInvoiceView') && <WebTour />}
		</>
	)
}

export { ViewGeneralDocument }
