import Theme from 'ui-lib/utils/base-theme'
import * as yup from 'yup'

export const SortableKeys = ['createdAt', 'updatedAt']
export const TABLE_ALIAS = {
	createdAt: 'tdmColumnHeaders.receivedDate',
	supplyChainModel: 'tdmColumnHeaders.supplyChainModel',
	generatedDate: 'tdmColumnHeaders.generatedDate',
	partner: 'tdmColumnHeaders.partner',
	status: 'binListing.status',
	updatedAt: 'tdmColumnHeaders.updatedAt',
	docType: 'tdmColumnHeaders.docType',
	partnerAddress: 'tdmColumnHeaders.partnerAddress',
	myAddress: 'Receiving Address',
	productCode: 'tdmColumnHeaders.productCode',
	productName: 'tdmColumnHeaders.productName',
	quantity: 'tdmColumnHeaders.quantity',
	defaultUOM: 'tdmColumnHeaders.uom',
	uom: 'tdmColumnHeaders.quantity',
	count: 'workListing.ffbCount',
	unitPrice: 'tdmColumnHeaders.unitPrice',
	tax: 'tdmColumnHeaders.tax',
	metricUnitPrice: 'tdmColumnHeaders.unitPrice',
	totalPrice: 'tdmColumnHeaders.totalPrice',
	dateSent: 'tdmColumnHeaders.dateSent',
	dateReceived: 'tdmColumnHeaders.dateReceived',
	sellerName: 'tdmColumnHeaders.sellerName',
	buyerName: 'tdmColumnHeaders.buyerName',
	broker: 'tdmColumnHeaders.broker',
	contractType: 'tdmColumnHeaders.contractType',
	product: 'tdmColumnHeaders.product',
	quantityWithUOM: 'tdmColumnHeaders.quantityWithUOM',
	deliveryPeriod: 'tdmColumnHeaders.deliveryPeriod',
	statusLabel: 'tdmColumnHeaders.statusLabel',
	issueDate: 'tdmColumnHeaders.issuedDate',
	transactionNumber: 'tdmColumnHeaders.transactionNumber',
	documentNumber: 'tdmColumnHeaders.documentNumber',
}

export const STATUS_COLOR = {
	draft: Theme.color.grey7,
	submitted: Theme.color.blue4,
	received: Theme.color.blue4,
	acknowledged: Theme.color.purple,
	accepted: Theme.color.purple,
	rejected: Theme.color.error,
	delayed: Theme.color.warn,
	intransit: Theme.color.info,
	hold: Theme.color.warn,
	delivered: Theme.color.success,
	complete: Theme.color.primary,
	partial: Theme.color.primary,
	'receiver-accepted': Theme.color.success,
	'receiver-rejected': Theme.color.error,
	pending: Theme.color.warn,
}

export const RECEIVER_ACTION_LABELS = {
	rejected: 'entityActionStatus.rejected',
	hold: 'entityActionStatus.hold',
	delivered: 'entityActionStatus.delivered',
	acknowledged: 'entityActionStatus.acknowledged',
}

export const SENDER_ACTION_LABELS = {
	accepted: 'entityActionStatus.accepted',
	rejected: 'entityActionStatus.rejected',
	hold: 'entityActionStatus.hold',
	intransit: 'entityActionStatus.intransit',
	complete: 'entityActionStatus.complete',
	acknowledged: 'entityActionStatus.acknowledged',
}

export const RECEIVER_STATUS_ALIASES = {
	submitted: 'entityStatusLabel.received',
	received: 'entityStatusLabel.received',
	acknowledged: 'entityStatusLabel.acknowledged',
	accepted: 'entityStatusLabel.accepted',
	rejected: 'entityStatusLabel.rejected',
	delayed: 'entityStatusLabel.delayed',
	intransit: 'entityStatusLabel.intransit',
	hold: 'entityStatusLabel.hold',
	delivered: 'entityStatusLabel.delivered',
	complete: 'entityStatusLabel.complete',
	partial: 'entityStatusLabel.partial',
	pending: 'partnerStatusLabel.pending',
	'receiver-accepted': 'partnerStatusLabel.receiver-accepted',
	'receiver-rejected': 'partnerStatusLabel.receiver-rejected',
	deactivated: 'partnerStatusLabel.deactivated',
	blacklisted: 'partnerStatusLabel.blacklisted',
	viewed: 'viewed',
}

export const SENDER_STATUS_ALIASES = {
	submitted: 'entityStatusLabel.submitted',
	received: 'entityStatusLabel.received',
	acknowledged: 'entityStatusLabel.acknowledged',
	accepted: 'entityStatusLabel.accepted',
	rejected: 'entityStatusLabel.rejected',
	delayed: 'entityStatusLabel.delayed',
	intransit: 'entityStatusLabel.intransit',
	hold: 'entityStatusLabel.hold',
	delivered: 'entityStatusLabel.delivered',
	complete: 'entityStatusLabel.complete',
	partial: 'entityStatusLabel.partial',
	viewed: 'viewed',
}

export const DASHBOARD_STATS_ALIASES = {
	submitted: 'Submitted',
	received: 'Received',
	acknowledged: 'Acknowledged',
	accepted: 'Accepted',
	rejected: 'Rejected',
	delayed: 'Delayed',
	intransit: 'Intransit',
	hold: 'On Hold',
	delivered: 'Delivered',
	complete: 'Complete',
	partial: 'Partial',
}

export const ALL_DOC_STATUSES = [
	'submitted',
	'received',
	'acknowledged',
	'accepted',
	'rejected',
	'delayed',
	'intransit',
	'hold',
	'delivered',
	'complete',
	'partial',
	'goods-dispatched',
]

export const STATUS_ALIAS = {
	hold: 'On Hold',
}

export const COLUMN_ALIASES = {
	createdAt: 'Received Date',
	partner: 'Partner Name',
	amount: 'Amount',
	number: 'Reference Number',
	state: 'Status',
	actionToTake: 'Action',
	updatedAt: 'Action Date',
}

export const INITIAL_TYPES = [
	'purchase-order',
	'invoice',
	'delivery-order',
	'request-for-quote',
	'quote',
]

export const NAME_ALIASES = {
	'purchase-order': 'tdmColumnHeaders.purchase-order',
	invoice: 'tdmColumnHeaders.invoice',
	draft: 'tdmColumnHeaders.draft',
	'delivery-order': 'tdmColumnHeaders.delivery-order',
	'incoming-delivery-order': 'tdmColumnHeaders.incoming-delivery-order',
	'work-entry': 'tdmColumnHeaders.workEntry',
	'weighbridge-slip': 'tdmColumnHeaders.weighBridgeSlip',
	weighbridge: 'tdmViewDocument.weighbridge',
}

export const STATS_TYPE_ALIAS = {
	deliveryOrders: 'delivery-order',
	invoices: 'invoice',
	purchaseOrders: 'purchase-order',
}

export const ALL_MODULES = ['incoming', 'outgoing']

export const LabelsMap = {
	incoming: 'tdmSubDashboard.incoming',
	outgoing: 'tdmSubDashboard.outgoing',
}

export const PRODUCT_TRACE_FILTERS_CONFIG = [
	{
		name: 'monthly',
		label: 'Monthly',
	},
	{
		name: 'quarterly',
		label: 'Quarterly',
	},
	{
		name: 'half yearly',
		label: 'Half Yearly',
	},
	{
		name: 'yearly',
		label: 'Yearly',
	},
]

export const PRODUCT_TRACE_TABLE_ALIAS = {
	createdAt: 'Date',
	partner: 'Supplier',
	docNumber: 'Document Number',
	product: 'Product',
	quantity: 'Quantity (Kg)',
}

export const AUDIT_REPORTS_FILTERS_CONFIG = [
	{
		name: 'monthly',
		label: 'Monthly',
	},
	{
		name: 'quarterly',
		label: 'Quarterly',
	},
	{
		name: 'half yearly',
		label: 'Half Yearly',
	},
	{
		name: 'yearly',
		label: 'Yearly',
	},
]

export const AUDIT_REPORTS_TABLE_ALIAS = {
	createdAt: 'Date',
	partner: 'Partner',
	quantityTransacted: 'Quantity Transacted',
	uom: 'UOM',
}

export const excludedReadableSections = []

export const DRAFT_TAB_CONFIG = {
	code: 'draft',
	title: 'tdmTabConfig.draft',
}

export const TEAMS_APP_TABS = [
	{
		code: 'purchase-order',
		title: 'tdmTabConfig.purchase-orders',
	},
	{
		code: 'invoice',
		title: 'tdmTabConfig.invoices',
	},
	{
		code: 'delivery-order',
		title: 'tdmTabConfig.delivery-orders',
	},
	{
		code: 'request-for-quote',
		title: 'tdmTabConfig.rfq',
	},
	{
		code: 'quote',
		title: 'tdmTabConfig.quote',
	},
]

export const TABS_CONFIG = [
	{
		code: 'purchase-order',
		title: 'tdmTabConfig.purchase-orders',
	},
	{
		code: 'invoice',
		title: 'tdmTabConfig.invoices',
	},
	{
		code: 'delivery-order',
		title: 'tdmTabConfig.delivery-orders',
	},
	{
		code: 'request-for-quote',
		title: 'tdmTabConfig.rfq',
	},
	{
		code: 'quote',
		title: 'tdmTabConfig.quote',
	},
]

export const initialState = {
	activeModule: 'Error',
	loading: false,
	error: false,
	receiverTraceIDs: [],
	rules: {
		activeModules: ['incoming', 'outgoing'],
		allowedTypes: [
			'purchase-order',
			'invoice',
			'delivery-order',
			'request-for-quote',
			'quote',
		],
	},
	product: {
		loading: false,
		list: [],
		details: {},
	},
	modules: {
		mainDashboard: {
			loading: {
				incoming: [],
				outgoing: [],
			},
			activeTimeOffset: 45, // 5days
			allTypes: INITIAL_TYPES,
			activeTypes: [],
			activeTab: {
				incoming: '',
				outgoing: '',
			},
			activeSorts: {
				incoming: {},
				outgoing: {},
			},
			activeDocuments: {
				incoming: {},
				outgoing: {},
			},
		},
		subDashboard: {
			status: {
				incoming: {},
				outgoing: {},
			},
		},
		listing: {
			status: {
				incoming: {},
				outgoing: {},
			},
			loading: false,
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeTab: 'any',
			activeDocuments: [],
			activeSorts: {},
			activeFilters: {},
		},
		document: {
			loading: false,
			hasError: false,
			isSaving: false,
			activeSections: [],
			readOnlySections: [],
			activePartner: {},
			activeRecord: {
				meta: {},
			},
			activeAssociatedDoc: {},
			parentDocRef: {},
			participantingUsers: {},
			associatedDocRemarks: {},
			documentTrace: [],
			attachments: [],
			backwardTrace: {},
			activeTab: 'original',
			remarks: [],
		},
		generalDocuments: {
			incoming: {},
			outgoing: {},
			activeDocument: {},
			activeAssociatedDocs: {},
			associatedDocs: {}, // associatedDocs search
			loading: false,
			activeTab: 'incoming',
		},
		reports: {
			loading: false,
			activeDocuments: [],
			activeSorts: {},
			pagination: {
				activeIndex: 0, // total, pageCount
				limit: 0,
				total: 0,
				nextCursor: '',
			},
			activeFilters: {},
			certificateDetails: [],
		},
		generalReports: {
			ffbDealer: {
				details: {},
			},
		},
		initialRFQDetails: {
			product: [],
			quantityInspector: '',
			qualityInspector: '',
			destinationPort: '',
			ladingPort: '',
			payment: '',
			requirementDetails: '',
			termsAndConditions: '',
			contractType: '',
			initiatingParty: {
				orgID: '',
			},
			receiverOrgIDs: [],
			receiverEmailIDs: [],
			month: '',
			period: '',
			attachments: [],
		},
		rfqDetails: {},
		quotes: {
			viewQuote: {},
		},
		globalOrganizations: [],
	},
}

export const plantationDocSources = ['plantation', 'storage']

export const getSchemaDefinition = (t, type) => {
	const Schema = {
		activePartner: yup.string().required(),
		meta: yup.object().shape({
			docNumber: yup.string(),
			issueDate: yup.date().required(t('validation.issuingDateRequired')),
			authorization: yup.object().shape({
				reportedBy: yup
					.string()
					.required(t('validation.reportedByNameRequired')),
				reportedByRole: yup
					.string()
					.required(t('validation.reporterRoleRequired')),
				authorisedSignatory: yup
					.string()
					.required(t('validation.authorisedSignatoryReq')),
			}),
			expectedDeliveryDate: yup
				.date()
				.required(t('validation.expectedDateRequired')),
			...(type === 'delivery-order' && {
				vehicleNumber: yup.string(),
				driverName: yup
					.string()
					.matches(/[a-zA-Z]/, t('validation.driverNameCheck')),
			}),
			...((type === 'purchase-order' || type === 'invoice') && {
				supplyChainModel: yup.string().required(),
			}),
		}),
		product: yup
			.array()
			.of(
				yup.object().shape({
					id: yup
						.string()
						.required(t('validation.addAtleast1Product')),
				})
			)
			.required(),
		additionalInfo: yup.object().shape({
			remarks: yup.string(),
			attachments: yup.array().of(
				yup.object().shape({
					url: yup.string().url(),
					name: yup.string(),
				})
			),
		}),
	}

	return yup.object().shape(Schema)
}

/** ROLE CONSTANTS */
export const ROLE_ACCESSES = {
	canReadIncoming: 'fe.tdm.incoming.r',
	canReadOutgoing: 'fe.tdm.outgoing.r',
	// canReadExcel: 'fe.tdm.delivery-order.r',  Removing the tab
	canReadDeliveryOrder: 'fe.tdm.delivery-order.r',
	canControlDeliveryOrder: 'fe.tdm.delivery-order.c',
	canControlIncomingDeliveryOrder: 'fe.tdm.delivery-order.c',
	canControlUploadDeliveryOrder: 'fe.tdm.delivery-order.c',
	canReadPurchaseOrder: 'fe.tdm.purchase-order.r',
	canControlPurchaseOrder: 'fe.tdm.purchase-order.c',
	canReadInvoice: 'fe.tdm.invoice.r',
	canControlInvoice: 'fe.tdm.invoice.c',
	canReadContracts: 'fe.marketplace.contracts.r',
}

/**
 * Given a array of types and role access boolean flags, the below return the active document types
 * array as response.
 * @param {array} types
 * @param {object} roleFlags
 */
export const getActiveTypesBasedOnRole = (types = [], roleFlags = {}) =>
	types.filter(key => {
		return Object.keys(roleFlags)
			.filter(_key => _key.match(new RegExp(key.replace(/-/g, ''), 'i')))
			.every(flag => !!roleFlags[flag])
	})

export const mostQtyColors = [
	'#00A505',
	'#00A505CC',
	'#00A50599',
	'#00A50566',
	'#00A50533',
]

export const leastQtyColors = [
	'#FF000033',
	'#FF000066',
	'#FF000099',
	'#FF0000CC',
	'#FF0000',
]

export const ffbQualityKeys = [
	'ripeBunches',
	'underripeBunches',
	'overripeBunches',
	'unripeBunches',
	'damaged',
]

export const FFB_QUALITY_ALIASES_KEYS = {
	ripeBunches: 'dealerGeneralReports.ripeBunches',
	underripeBunches: 'dealerGeneralReports.underripeBunches',
	overripeBunches: 'dealerGeneralReports.overripeBunches',
	unripeBunches: 'dealerGeneralReports.unripeBunches',
	damaged: 'dealerGeneralReports.damaged',
}

export const MARKETPLACE_TABLE_ALIAS = {
	dateSent: 'marketPlace.dateSent',
	dateReceived: 'marketPlace.dateReceived',
	seller: 'marketPlace.seller',
	product: 'marketPlace.product',
	quantity: 'marketPlace.quantity',
	status: 'marketPlace.status',
	buyer: 'marketPlace.buyer',
	country: 'marketPlace.country',
	productName: 'marketPlace.productName',
	qtyTBP: 'marketPlace.qtyTBP',
}

export const RFQ_STATUS = {
	pending: 'marketPlace.pending',
	accepted: 'marketPlace.accepted',
	review: 'marketPlace.under-review',
	rejected: 'marketPlace.regret',
	completed: 'marketPlace.completed',
	complete: 'marketPlace.completed',
}

export const TRANSACTION_STATUS = {
	submitted: 'entityStatusLabel.submitted',
	pending: 'marketPlace.pending',
	accepted: 'marketPlace.accepted',
	review: 'marketPlace.under-review',
	rejected: 'marketPlace.regret',
	completed: 'marketPlace.completed',
	complete: 'marketPlace.completed',
	viewed: 'generalDocument.viewed',
	draft: 'generalDocument.draft',
}

export const STATUS_TEXT_COLOR = {
	submitted: '#F09112',
	pending: '#F09112',
	accepted: '#228686',
	review: '#574D9B',
	rejected: '#BF3333',
	completed: '#158853',
	complete: '#158853',
	draft: '#ffffff',
}

export const STATUS_BACKGROUND = {
	submitted: '#FFEED4',
	pending: '#FFEED4',
	accepted: '#A1E6E6',
	review: '#D0CCFA',
	rejected: '#F8BFBF',
	completed: '#C9F7C3',
	complete: '#C9F7C3',
	viewed: '#A1E6E6',
	draft: 'rgb(119, 124, 134)',
}

export const MONTHS = {
	january: 'marketPlace.jan',
	february: 'marketPlace.feb',
	march: 'marketPlace.mar',
	april: 'marketPlace.apr',
	may: 'marketPlace.may',
	june: 'marketPlace.jun',
	july: 'marketPlace.jul',
	august: 'marketPlace.aug',
	september: 'marketPlace.sep',
	october: 'marketPlace.oct',
	november: 'marketPlace.nov',
	december: 'marketPlace.dec',
}

export const CONTRACT_TYPE = {
	fob: 'marketPlace.fob',
	cif: 'marketPlace.cif',
	'local-delivery': 'marketPlace.local-delivery',
}

export const MONTHS_FOR_BANK = {
	1: MONTHS.january,
	2: MONTHS.february,
	3: MONTHS.march,
	4: MONTHS.april,
	5: MONTHS.may,
	6: MONTHS.june,
	7: MONTHS.july,
	8: MONTHS.august,
	9: MONTHS.september,
	10: MONTHS.october,
	11: MONTHS.november,
	12: MONTHS.december,
}

export const TRANSACTION_TYPE = {
	cash: 'tdmViewDocument.cash',
	internetBanking: 'tdmViewDocument.internetBanking',
	upi: 'tdmViewDocument.upi',
	others: 'tdmViewDocument.others',
	bankTransfer: 'tdmViewDocument.bankTransfer',
	creditCard: 'tdmViewDocument.creditCard',
	debitCard: 'tdmViewDocument.debitCard',
	onlinePayment: 'tdmViewDocument.onlinePayment',
	cheque: 'tdmViewDocument.cheque',
}

export const TRANSACTION_TYPE_READ = {
	cash: 'tdmViewDocument.cash',
	internetBanking: 'tdmViewDocument.internetBanking',
	upi: 'tdmViewDocument.upi',
	others: 'tdmViewDocument.others',
	'bank-transfer': 'tdmViewDocument.bankTransfer',
	'credit-card': 'tdmViewDocument.creditCard',
	'debit-card': 'tdmViewDocument.debitCard',
	'online-payment': 'tdmViewDocument.onlinePayment',
	cheque: 'tdmViewDocument.cheque',
}

export const SHIPMENT_PERIOD = {
	'first-half': 'marketPlace.first-half',
	'second-half': 'marketPlace.second-half',
	'entire-month': 'marketPlace.entire-month',
}

export const STATEMENT_ALIASES_KEYS = {
	bankName: 'tdmBankDetails.bankName',
	id: 'tdmBankDetails.ID',
	statementNumber: 'tdmBankDetails.bankName',
	year: 'tdmBankDetails.year',
	month: 'tdmBankDetails.month',
	attachment: 'tdmBankDetails.attachment',
}

export const PAYMENT_SLIP_ALIASES_KEYS = {
	paymentMethod: 'tdmViewDocument.paymentMethod',
	amount: 'tdmViewDocument.amount',
	date: 'tdmViewDocument.date',
	status: 'binListing.status',
	attachment: 'tdmBankDetails.attachment',
}

export const statementKeys1 = ['bankName', 'attachment']

export const statementKeys2 = ['month', 'year']

export const paymentSlipkeys1 = ['paymentMethod', 'amount', 'attachment']
export const paymentSlipkeys2 = ['', 'date', 'status']
