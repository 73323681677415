import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import theme from 'ui-lib/utils/base-theme'
import { Collapsible } from 'grommet'
import { DocumentViewLeadCard } from './DocumentLead'

const DocumentTreeBlock = ({
	isOpen: isOpenFromProp,
	children,
	isActive,
	handleChange,
	onCollapseAction,
	config = {},
}) => {
	const hasChildren = !!children

	return (
		<>
			<Box>
				<Box overflow padding={4}>
					<div>
						<DocumentViewLeadCard
							config={config}
							onClick={handleChange}
							isActive={isActive}
							isOpen={isOpenFromProp}
							hasChildren={hasChildren}
							onCollapseAction={onCollapseAction}
						/>
					</div>
				</Box>
			</Box>
			{children && (
				<Collapsible open={isOpenFromProp}>
					<div
						style={{
							display: isOpenFromProp ? 'block' : 'none',
							paddingLeft: 12,
							borderLeft: `2px dotted ${theme.color.grey18}`,
							marginLeft: 28,
						}}
					>
						{children}
					</div>
				</Collapsible>
			)}
		</>
	)
}

DocumentTreeBlock.defaultProps = {
	handleChange: () => {},
	getExplorerLink: () => {},
	config: {},
}

export { DocumentTreeBlock }
