import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { Modal } from 'ui-lib/components/Modal'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { Table } from 'ui-lib/components/Table'
import { DefaultTabIndicator, Tabs } from 'ui-lib/components/Tabs'
import { P } from 'ui-lib/components/Typography'
import tradeDocIcon from 'ui-lib/icons/trade.svg'
import { Box } from 'ui-lib/utils/Box'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { generalDocumentColumnConfig } from 'ui-tdm-app/modules/TradeDocumentManager/components/Columns'
import { Title } from 'ui-tdm-app/modules/TradeDocumentManager/components/Title'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'

const getTabComponents = ({
	type,
	rows,
	onChange,
	loadingStatus = [],
	t,
	isMobile,
}) => ({ name }) => () => {
	const columns = generalDocumentColumnConfig({
		type,
		onChange,
		t,
	})
	const rowData = getIn(rows, [name, 'list']) || []

	return (
		<Box
			style={{
				overflow: 'auto',
				position: 'relative',
				backgroundColor: '#fff',
			}}
		>
			{loadingStatus.includes(name) && <TransparentBgSpinner />}
			<Box overflow style={{ minHeight: 395 }}>
				<Table
					noRoundedBorder
					columnConfig={columns}
					rowData={rowData || []}
					mobileViewCSSOn={isMobile}
				/>
				{!(rowData || []).length && (
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				)}
			</Box>
		</Box>
	)
}

/** Main Component */
export const GeneralDocListing = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { incoming: incomingLoadingStates = [] } = useSelector(
		TradeDocDuc.selectors.getMainDashboardLoadingStates
	)
	const currentActiveIncomingDocuments = useSelector(
		TradeDocDuc.selectors.getGeneralDocumentsIncoming
	)
	const currentActiveOutgoingDocuments = useSelector(
		TradeDocDuc.selectors.getGeneralDocumentsOutgoing
	)
	const activeTab = useSelector(
		TradeDocDuc.selectors.generalDocumentActiveTab
	)

	const activeSorts = useSelector(
		TradeDocDuc.selectors.getMainDashboardActiveSorts
	)

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'trade-doc-dashboard',
			isActive: false,
		},
	]

	const VIEW_TAB_CONFIG = [
		{
			code: 'incoming',
			title: t('common.incoming'),
		},
		{
			code: 'outgoing',
			title: t('common.outgoing'),
		},
	]
	/** Helper for the components */
	const getTabs = useCallback(() => {
		return VIEW_TAB_CONFIG.map(({ code, title }) => ({
			name: code,
			title: t(title),
			IndicatorComponent:
				parseInt(code, 10) > 0 &&
				(({ isActive }) => (
					<DefaultTabIndicator active={isActive}>
						{parseInt(code, 10)}
					</DefaultTabIndicator>
				)),
		}))
	}, [VIEW_TAB_CONFIG, t])

	/** Handlers to react on actions */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'preview-draft': {
					dispatch(
						TradeDocDuc.creators.setActiveGeneralDocuments(meta.row)
					)

					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$GENERALACTION,
							{
								rootModule: 'general-document',
								action: 'draft-edit',
								documentStatus: 'clone',
							}
						)
					)

					break
				}

				case 'view_share_doc': {
					if (meta.row) {
						dispatch(TradeDocDuc.creators.setActiveTab('original'))
						dispatch(
							TradeDocDuc.creators.setActiveGeneralDocuments({})
						)
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$VIEWGENERALDOCREFERENCE,
								{
									rootModule: 'general-document',
									documentReference: meta.row.docID,
								}
							)
						)
					}

					break
				}

				default:
					break
			}
		},
		[dispatch]
	)

	return (
		<>
			<Box id="tdmMain" padding={8} width="100%" height="100%">
				<Modal
					closeable
					show={showModal}
					heading={t('topBar.logout')}
					body={
						<P large bold>
							{t('topBar.logoutMsg')}
						</P>
					}
					closelabel={t('common.no')}
					confirmlabel={t('common.yes')}
					onClose={() => {
						setShowModal(false)
					}}
					onConfirm={() => {
						dispatch(AuthDuc.creators.signOutUser())
					}}
					isMobile={isMobile}
				/>

				<Box row style={{ padding: '0 5' }} justifyContent="flex-start">
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>
				<Box
					style={
						isMobile
							? { flexDirection: 'column' }
							: { flexDirection: 'row' }
					}
					justifyContent="space-between"
					alignItems="baseline"
				>
					<Title
						title={t('mainDashboard.tradeDocManager')}
						icon={tradeDocIcon}
					/>
					<Box>
						<Box width={200} id="tdmCreate">
							<Button
								label={t('common.create')}
								primary
								onClick={() => {
									dispatch(
										TradeDocDuc.creators.setActiveGeneralDocuments(
											{}
										)
									)
									dispatch(
										MainRouteDuc.creators.switchPage(
											MainRouteDuc.types
												.TRADE_DOCUMENT_MANAGER$GENERALACTION,
											{
												rootModule: 'general-document',
												action: 'create-doc',
											}
										)
									)
								}}
								extendStyles={{ width: 212 }}
							/>
						</Box>
					</Box>
				</Box>

				<Tabs
					id="TDMTabs"
					tabs={getTabs()}
					tabComponentsMap={getTabComponents({
						type: 'general-document',
						sortsMap: activeSorts.incoming,
						rows: {
							incoming: currentActiveIncomingDocuments,
							outgoing: currentActiveOutgoingDocuments,
						},
						onChange: handleOnChange,
						loadingStatus: incomingLoadingStates,
						t,
						isMobile,
					})}
					activeTabIndex={VIEW_TAB_CONFIG.findIndex(
						q => q.code === activeTab
					)}
					onChange={({ name }) => {
						dispatch(
							TradeDocDuc.creators.setActiveTabGeneralDocument(
								name
							)
						)
					}}
					isMobile={isMobile}
				/>
			</Box>
		</>
	)
}
