import React, { useState, useRef, useEffect } from 'react'
import { Drop } from 'grommet'
import { Box } from 'ui-lib/utils/Box'
import { useTranslation } from 'react-i18next'
import { getDateByFormat } from 'ui-lib//utils/helpers'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { Label } from 'ui-lib/components/Label'
import { MultiSelectWithSearch } from 'ui-lib/components/Select'
import { P } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button/index'
import styled from 'styled-components'
import theme from 'ui-lib/utils/base-theme'
import EditNoteSvg from 'ui-lib/icons/edit_note.svg'
import assignTaskSvg from 'ui-lib/icons/assign_task.svg'
import replyBack from 'ui-lib/icons/reply_back.svg'

const LinkBlock = styled.span(({ color }) => ({
	textAlign: 'right',
	color,
	fontSize: '12px',
	alignSelf: 'right',
	marginTop: '8px',
}))

const MsgHeader = styled.div`
	display: flex;
	justify-content: space-between;
`

const ThreeDots = styled.div`
	display: block;
	margin-top: -6px;
	font-weight: 600;
	color: ${p => (p.textColor ? p.textColor : theme.color.black)};
	&:hover {
		font-weight: 900;
		cursor: pointer;
	}
`

const Message = ({
	message: {
		message: msg,
		messageID,
		edited,
		editedAt,
		createdBy,
		createdAt,
		replyTo,
		taskID,
		organizationID,
	},
	canEditDelete = true,
	userId,
	senderDisplayName,
	handleClick,
	participantsMeta,
	handleReplyClick,
	focus,
	partnerId,
	chatThreadId,
	usersList,
}) => {
	const { t } = useTranslation()
	const senderOrgID =
		participantsMeta?.length > 0 &&
		participantsMeta?.find(ele => ele.userID === userId).organizationID
	const isSender = organizationID === senderOrgID
	const bgColor = isSender ? '#E8E7E7' : '#3C6BD9'
	const transitionColor = isSender ? '#fffefe' : '#6283d0'
	const textColor = isSender ? '#4A5255' : 'white'
	const dateColor = isSender ? '#8F9394' : '#BBCCF2'
	const [showMenu, setShowMenu] = useState(false)
	const [showAssignContent, setShowAssignContent] = useState(false)
	const [assignedList, setAssignedList] = useState([])
	const [assignedListOptns, setAssignedListOptns] = useState([])
	const ref = useRef()
	const assignRef = useRef()

	const getReplyToContent = () => {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					cursor: 'pointer',
				}}
				tabIndex={0}
				role="button"
				onKeyPress={() => {
					handleReplyClick(replyTo?.messageID)
				}}
				onClick={() => {
					handleReplyClick(replyTo?.messageID)
				}}
			>
				<Box
					style={{
						background: theme.color.white,
						borderRadius: '5px',
						padding: '10px',
						width: '100%',
						opacity: 0.7,
					}}
				>
					<MsgHeader>
						<div>
							<P
								style={{
									fontSize: '14px',
									fontWeight: '600',
								}}
								color="#4A5255"
							>
								{participantsMeta?.length > 0 &&
									participantsMeta?.find(
										item =>
											item?.userID === replyTo?.createdBy
									)?.name}
							</P>
						</div>
					</MsgHeader>
					<P
						style={{ marginTop: '8px', fontSize: '14px' }}
						color="#4A5255"
					>
						{replyTo?.message}
					</P>
					<Box row justifyContent="flex-end">
						{replyTo?.edited && (
							<LinkBlock
								alignSelf="end"
								size="13px"
								textAlign="right"
								color="#8F9394"
								marginRight="10px"
							>
								{t('messages.edited')}
							</LinkBlock>
						)}
						<LinkBlock
							alignSelf="end"
							size="13px"
							textAlign="right"
							color="#8F9394"
						>
							{getDateByFormat(
								replyTo?.editedAt || replyTo?.createdAt,
								'd LLL yyyy, hh:mm a'
							)}
						</LinkBlock>
					</Box>
				</Box>
			</div>
		)
	}

	useEffect(() => {
		const list = []

		const users =
			usersList?.length > 0 &&
			usersList?.filter(
				ele =>
					ele?.organization?.id === senderOrgID &&
					ele.id !== userId &&
					ele?.status?.state !== 'deleted'
			)
		if (users?.length > 0) {
			users.forEach(item => {
				list.push({
					name: item?.fullName,
					id: item?.id,
				})
			})
		}

		setAssignedListOptns(list)
	}, [senderOrgID])

	return (
		<Box key={messageID}>
			{showMenu && (
				<Box
					style={{
						width: '44px',
					}}
				>
					<Drop
						stretch={false}
						onEsc={() => setShowMenu(false)}
						onClickOutside={() => {
							if (!showAssignContent) {
								setShowMenu(false)
							}
						}}
						target={ref.current}
						align={{
							top: 'bottom',
							right: 'right',
						}}
					>
						<Box
							row
							alignItems="center"
							padding="0px 4px"
							style={{
								borderBottom: '1px solid #D9DEE5',
							}}
							onClick={() => {
								if (showAssignContent) {
									setShowAssignContent(false)
									setAssignedList([])
								}
							}}
						>
							<IconWrapper size={15}>
								<Icon
									color={theme.color.green}
									glyph={replyBack}
									size={10}
								/>
							</IconWrapper>
							<Label
								color="#fff"
								small
								key=",s"
								style={{
									padding: '5px 8px',
									cursor: 'pointer',
									color: theme.color.green,
								}}
								onClick={() => {
									setShowMenu(false)
									handleClick('reply', messageID, msg)
								}}
							>
								{t('messages.reply')}
							</Label>
						</Box>
						{isSender && canEditDelete && (
							<Box
								row
								alignItems="center"
								padding="0px 4px"
								style={{
									borderBottom: '1px solid #D9DEE5',
								}}
								onClick={() => {
									if (showAssignContent) {
										setShowAssignContent(false)
										setAssignedList([])
									}
								}}
							>
								<IconWrapper size={20}>
									<Icon
										color={theme.color.green}
										glyph={EditNoteSvg}
										size={10}
									/>
								</IconWrapper>
								<Label
									color="#fff"
									small
									key=",s"
									style={{
										padding: '5px 8px',
										cursor: 'pointer',
										color: theme.color.green,
									}}
									onClick={() => {
										setShowMenu(false)
										handleClick('edit', messageID, msg)
									}}
								>
									{t('common.edit')}
								</Label>
							</Box>
						)}
						{!taskID && (
							<Box
								row
								alignItems="center"
								padding="0px 4px"
								style={{
									fontSize: '15px',
									borderBottom: '1px solid #D9DEE5',
								}}
							>
								<IconWrapper size={15}>
									<Icon
										color={theme.color.green}
										glyph={assignTaskSvg}
										size={10}
									/>
								</IconWrapper>
								<div>
									{showAssignContent && (
										<Drop
											stretch={false}
											onEsc={() => {
												setShowAssignContent(false)
												setAssignedList([])
											}}
											// onClickOutside={() => {
											// 	setShowAssignContent(false)
											// 	setAssignedList([])
											// }}
											target={assignRef.current}
											align={{
												top: 'top',
												right: 'left',
											}}
											margin="small"
											round="small"
										>
											<Button
												primary
												onClick={() => {
													handleClick(
														'assign',
														messageID,
														msg,
														{
															message: msg,
															messageID,
															edited,
															editedAt,
															createdBy,
															createdAt,
															replyTo,
														},
														partnerId,
														chatThreadId,
														assignedList
													)

													setShowAssignContent(false)
													setShowMenu(false)
												}}
												disabled={
													assignedList?.length === 0
												}
												label={t('messages.assign')}
											/>
											<Box style={{ margin: 20 }}>
												<div>
													<MultiSelectWithSearch
														selected={assignedList}
														value={assignedList}
														options={
															assignedListOptns
														}
														onChange={value => {
															setAssignedList(
																value
															)
														}}
														returnOnlyValue
													/>
												</div>
											</Box>
										</Drop>
									)}
									<div ref={assignRef}>
										<Label
											color="#fff"
											small
											key=",s"
											style={{
												padding: '5px 8px',
												cursor: 'pointer',
												color: theme.color.green,
											}}
											onClick={() => {
												if (showAssignContent) {
													setShowAssignContent(false)
													setAssignedList([])
												} else {
													setShowAssignContent(true)
												}
											}}
										>
											{t('messages.assignTask')}
										</Label>
									</div>
								</div>
							</Box>
						)}
					</Drop>
				</Box>
			)}
			<Box
				style={{
					background: focus
						? `linear-gradient(to right, ${bgColor}, ${transitionColor}, ${bgColor})`
						: bgColor,
					borderRadius: isSender
						? '10px 0px 10px 10px'
						: '0px 10px 10px 10px',
					transition: 'background 0.7s',
				}}
				padding="10px"
				margin={isSender ? '0px 0px 10px 50px' : '0px 50px 10px 0px'}
			>
				<MsgHeader>
					<P
						style={{
							fontSize: '14px',
							fontWeight: '600',
						}}
						color={textColor}
					>
						{senderDisplayName}
					</P>
					<ThreeDots
						textColor={textColor}
						ref={ref}
						onClick={() => (!showMenu ? setShowMenu(true) : null)}
					>
						<span>.</span>
						<span>.</span>
						<span>.</span>
					</ThreeDots>
				</MsgHeader>
				{replyTo && replyTo.messageID && getReplyToContent()}
				<P
					style={{ marginTop: '8px', fontSize: '14px' }}
					color={textColor}
				>
					{msg}
				</P>
				<Box justifyContent="flex-end">
					{edited && (
						<LinkBlock
							alignSelf="end"
							size="13px"
							textAlign="right"
							color={dateColor}
							marginRight="10px"
						>
							{t('messages.edited')}
						</LinkBlock>
					)}
					<LinkBlock
						alignSelf="end"
						size="13px"
						textAlign="right"
						color={dateColor}
					>
						{getDateByFormat(
							editedAt || createdAt,
							'd LLL yyyy, hh:mm a'
						)}
					</LinkBlock>
				</Box>
			</Box>
		</Box>
	)
}

export { Message }
