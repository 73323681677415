import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getIn } from 'timm'
import { Icon } from 'ui-lib/icons/components/Icon'
import Documents from 'ui-lib/icons/documents.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import FileUploadComponent from '../containers/GeneralDocument/upload'

const GeneralDocumentView = ({
	partner = {},
	receiverEmailID = '',
	user = '',
	role = '',
	issueDate = '',
	attachedFiles = [],
	status = '',
	transactionNumber = 'N/A',
	documentNumber = 'N/A',
	remarks = '',
	isMobile = false,
	hasInitiatedByCurrentOrg = false,
}) => {
	const { t } = useTranslation()
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const displayedRole = !hasInitiatedByCurrentOrg
		? t('tdmDetailsEntry.sender')
		: t('tdmDetailsEntry.recipient')

	const getAttachedDocuments = () => {
		// const docArray = []

		// attachedFiles.forEach(file => {
		// 	if (file.status === 'deleted') {
		// 		return
		// 	}
		// 	const isRemoved = file.status === 'removed'
		// 	const isNewlyAdded = file.status === 'newlyAdded'
		// 	docArray.push(
		// 		<div
		// 			key={file.id || file.meta?.name}
		// 			style={{
		// 				display: 'flex',
		// 				padding: '10px 0px 10px 20px',
		// 				cursor: 'pointer',
		// 			}}
		// 		>
		// 			<div
		// 				style={{
		// 					marginRight: 23,
		// 					padding: 16,
		// 					cursor: 'pointer',
		// 					alignItems: 'center',
		// 					display: 'flex',
		// 					justifyContent: 'center',
		// 					backgroundColor: theme.color.themeGrey2,
		// 				}}
		// 				title={getIn(file, ['meta', 'name']) || ''}
		// 				aria-hidden="true"
		// 				onClick={() =>
		// 					window.open(getIn(file, ['meta', 'fullURL']))
		// 				}
		// 			>
		// 				<Icon
		// 					style={{
		// 						height: 46,
		// 						width: 33,
		// 					}}
		// 					glyph={Documents}
		// 					color={theme.color.blue8}
		// 				/>
		// 			</div>
		// 			<div
		// 				style={{
		// 					display: 'flex',
		// 					alignItems: 'center',
		// 					color: isRemoved ? 'grey' : theme.color.themeRed3,
		// 				}}
		// 				aria-hidden="true"
		// 				onClick={() =>
		// 					window.open(getIn(file, ['meta', 'fullURL']))
		// 				}
		// 			>
		// 				{getIn(file, ['meta', 'other', 'filename']) ||
		// 					getIn(file, ['meta', 'name']) ||
		// 					''}
		// 			</div>
		// 			{isNewlyAdded && (
		// 				<div
		// 					style={{
		// 						display: 'flex',
		// 						alignItems: 'center',
		// 						color: 'green',
		// 						paddingLeft: '6px',
		// 					}}
		// 					aria-hidden="true"
		// 				>
		// 					({t('generalDocument.newlyAdded')})
		// 				</div>
		// 			)}
		// 			{isRemoved && (
		// 				<div
		// 					style={{
		// 						display: 'flex',
		// 						alignItems: 'center',
		// 						color: 'red',
		// 						paddingLeft: '6px',
		// 					}}
		// 					aria-hidden="true"
		// 				>
		// 					({t('generalDocument.removed')})
		// 				</div>
		// 			)}
		// 		</div>
		// 	)
		// })

		// return docArray
		return <FileUploadComponent isView files={attachedFiles} />
	}

	return (
		<Box
			style={{
				border: `1px solid ${theme.color.grey4}`,
				background: theme.color.white,
				padding: '35px',
			}}
		>
			<div
				style={{
					display: isMobile ? 'block' : 'flex',
					borderRadius: 5,
					background: theme.color.white,
					justifyContent: 'space-between',
				}}
			>
				<div id="left" style={{ width: isMobile ? '100%' : '45%' }}>
					<div
						style={{
							marginBottom: 8,
							fontWeight: 500,
							color: theme.color.themeGrey1,
						}}
					>
						{displayedRole}
					</div>

					<div
						id="recipient"
						style={{
							marginBottom: 24,
							borderRadius: 10,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								padding: 20,
							}}
						>
							{getIn(partner, ['orgID']) ? (
								<>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{displayedRole}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 600,
											marginBottom: 8,
											color: theme.color.accent2,
										}}
									>
										{getIn(partner, ['name']) || ''}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										<div>
											{getIn(partner, [
												'PrimaryAddress',
												'line1',
											]) || ''}
											{getIn(partner, [
												'PrimaryAddress',
												'line1',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'PrimaryAddress',
												'line2',
											]) || ''}
										</div>
										<div>
											{getIn(partner, [
												'PrimaryAddress',
												'city',
											]) || ''}
											{getIn(partner, [
												'PrimaryAddress',
												'city',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'PrimaryAddress',
												'state',
											]) || ''}
										</div>
										<div>
											{getIn(partner, [
												'PrimaryAddress',
												'country',
											]) || ''}
											{getIn(partner, [
												'PrimaryAddress',
												'country',
											])
												? ', '
												: ''}
											{getIn(partner, [
												'PrimaryAddress',
												'postalCode',
											]) || ''}
										</div>
									</div>
									<Spacer size={10} />{' '}
								</>
							) : (
								''
							)}

							{receiverEmailID !== '' ? (
								<>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{t('tdmDetailsEntry.emailAdded')}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 400,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										{receiverEmailID}
									</div>
									<Spacer size={10} />
								</>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<div id="right" style={{ width: isMobile ? '100%' : '45%' }}>
					<div
						style={{
							fontWeight: 500,
							marginBottom: 8,
							marginTop: isMobile ? 24 : 0,
							color: theme.color.themeGrey1,
						}}
					>
						{t('generalDocument.transactionNumber')}
					</div>
					<div
						id="status"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom: 24,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{transactionNumber || '-'}
						</div>
					</div>

					<div
						style={{
							marginBottom: 8,
							fontWeight: 500,
							color: theme.color.themeGrey1,
						}}
					>
						{t('generalDocument.associatedDocument')}
					</div>
					<div
						id="status"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom: 24,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 18,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeRed3,
							}}
						>
							{documentNumber}
						</div>
					</div>
					<div
						style={{
							fontWeight: 500,
							marginBottom: 8,
							marginTop: isMobile ? 24 : 0,
							color: theme.color.themeGrey1,
						}}
					>
						{t('generalDocument.documentStatus')}
					</div>
					<div
						id="status"
						style={{
							padding: 20,
							borderRadius: 10,
							marginBottom: 24,
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{status || ''}
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					marginBottom: 8,
					fontWeight: 500,
					color: theme.color.themeGrey1,
				}}
			>
				{t('tdmDetailsEntry.documentAttached')}
			</div>
			<div
				id="status"
				style={{
					display: 'flex',
					borderRadius: 10,

					//	border: `1px solid ${theme.color.grey4}`,
					flexWrap: 'wrap',
					flexDirection: 'column',
				}}
			>
				{getAttachedDocuments()}
			</div>
			<div>
				<div
					style={{
						marginTop: 24,
						marginBottom: 8,
						fontWeight: 500,
						color: theme.color.themeGrey1,
					}}
				>
					{t('tdmDetailsEntry.createdBy')}
				</div>
				<div
					id="createdBy"
					style={{
						padding: 20,
						display: 'flex',
						flexDirection: 'row',
						marginBottom: 24,
						borderRadius: 10,
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<div
						style={{
							flex: 1,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.user')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{user || '- - -'}
						</div>
					</div>
					<div
						style={{
							flex: 1,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.role')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								marginBottom: 12,
								color: theme.color.accent2,
							}}
						>
							{role || '- - -'}
						</div>
					</div>
					<div
						style={{
							flex: 1,
						}}
					>
						<div
							style={{
								fontSize: 14,
								fontWeight: 500,
								marginBottom: 5,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tripsListing.date')}
						</div>
						<div
							style={{
								fontSize: 16,
								fontWeight: 500,
								color: theme.color.accent2,
							}}
						>
							{getDateByFormat(
								issueDate,
								'dd MMM yyyy',
								activeLocale
							)}
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					flexDirection: 'column',

					display: isMobile ? 'block' : 'flex',
					borderRadius: 5,
					background: theme.color.white,
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						fontWeight: 500,
						marginBottom: 8,
						marginTop: isMobile ? 24 : 0,
						color: theme.color.themeGrey1,
					}}
				>
					{t('generalDocument.remarks')}
				</div>
				<div
					id="status"
					style={{
						padding: 20,
						borderRadius: 10,
						marginBottom: 24,
						border: `1px solid ${theme.color.grey4}`,
					}}
				>
					<div
						style={{
							fontSize: 14,
							fontWeight: 500,
							color: theme.color.accent2,
						}}
					>
						{remarks || ''}
					</div>
				</div>
			</div>
		</Box>
	)
}

GeneralDocumentView.defaultProps = {
	editable: false,
	onChange: () => {},
}

export { GeneralDocumentView }
