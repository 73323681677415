import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import styled from 'styled-components'
import { Label, TableTitle } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { getIn } from 'timm'
import { SortDown, SortReset } from 'ui-lib/components/Table'
import { Input } from 'ui-lib/components/Input'
import { getNextSortOrder } from 'ui-tdm-app/utils/helpers'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import {
	NAME_ALIASES,
	SortableKeys,
	TABLE_ALIAS,
	MARKETPLACE_TABLE_ALIAS,
	RFQ_STATUS,
	STATUS_TEXT_COLOR,
	STATUS_BACKGROUND,
	TRANSACTION_STATUS,
} from '../config'
import { Status } from './Status'

const LinkLabel = styled(Label)({
	color: '#0000EE',
	textDecoration: 'underline',
	'&:hover': {
		color: '#551A8B',
	},
})

export const getColumnConfig = ({
	type,
	docType,
	sortsMap,
	onChange,
	activeLocale,
	t,
	isMobile,
}) => {
	const partnerKey =
		type === 'incoming' ? 'initiatingPartyID' : 'receivingPartyID'

	return [
		...(!isMobile
			? [
					{
						property: 'createdAt',
						header: (
							<Box
								overflow
								row
								alignItems="center"
								style={{ cursor: 'pointer', width: '120px' }}
								onClick={() => {
									if (SortableKeys.includes('createdAt')) {
										// call onChange with appropriate invocation
										onChange('initiate_sort', {
											type,
											docType,
											field: 'createdAt',
											order: getNextSortOrder(
												sortsMap.createdAt
											),
										})
									}
								}}
							>
								{SortableKeys.includes('createdAt') && (
									<IconWrapper
										width={10}
										margin="0 5px"
										sortUp={sortsMap.createdAt === 'asc'}
										sortDown={sortsMap.createdAt === 'desc'}
									>
										<Icon
											rotate180={
												sortsMap.createdAt === 'asc'
											}
											glyph={
												sortsMap.createdAt
													? SortDown
													: SortReset
											}
										/>
									</IconWrapper>
								)}
								<TableTitle>
									{type === 'incoming'
										? t(TABLE_ALIAS.createdAt)
										: t(TABLE_ALIAS.issueDate)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const { createdAt } = datum
							const issueDate =
								getIn(datum, ['meta', 'issueDate']) || createdAt

							return (
								<Label as="span">
									{getDateByFormat(
										issueDate,
										null,
										activeLocale
									)}
								</Label>
							)
						},
						size: '15%',
					},

					{
						property: partnerKey,
						header: (
							<Box
								overflow
								row
								alignItems="center"
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (SortableKeys.includes(partnerKey)) {
										// call onChange with appropriate invocation
										onChange('initiate_sort', {
											type,
											docType,
											field: partnerKey,
											order: getNextSortOrder(
												sortsMap[partnerKey]
											),
										})
									}
								}}
							>
								{SortableKeys.includes(partnerKey) && (
									<IconWrapper
										width={10}
										margin="0 5px"
										sortUp={sortsMap[partnerKey] === 'asc'}
										sortDown={
											sortsMap[partnerKey] === 'desc'
										}
									>
										<Icon
											rotate180={
												sortsMap[partnerKey] === 'asc'
											}
											glyph={
												sortsMap[partnerKey]
													? SortDown
													: SortReset
											}
										/>
									</IconWrapper>
								)}
								<TableTitle>
									{t(TABLE_ALIAS.partner)}
								</TableTitle>
							</Box>
						),
						render: datum => {
							const orgName =
								type === 'incoming'
									? datum?.initiatingParty?.name
									: datum?.receivingParty?.name

							return <Label as="span">{orgName || '- - -'}</Label>
						},
						size: '20%',
					},
			  ]
			: ''),
		{
			property: 'id',
			primary: true,
			header: (
				<Box overflow row>
					<TableTitle>{`${t(NAME_ALIASES[docType])} #`}</TableTitle>
				</Box>
			),
			render: datum => (
				<LinkLabel
					as="span"
					link
					onClick={() => {
						onChange('open_document', { row: datum })
					}}
				>
					{datum.docNumber}
				</LinkLabel>
			),
			size: isMobile ? '10%' : '20%',
		},
		{
			property: 'product',
			primary: true,
			header: (
				<Box overflow row>
					<TableTitle>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				return datum?.product?.map(({ name, quantity, uom }) => (
					<Label as="span">{`${name} ${quantity} (${uom})`}</Label>
				))
			},
			size: isMobile ? '15%' : '18%',
		},
		{
			property: 'status',
			header: (
				<Box overflow row style={{ cursor: 'pointer', width: '100px' }}>
					<TableTitle>{t(TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Status
						currentStatus={status}
						editable={type === 'incoming' && status === 'submitted'}
						isDocumentOwner={type === 'outgoing'}
						onChange={active => {
							onChange('change_status', {
								type,
								docType,
								row: datum,
								newStatus: active,
							})
						}}
					/>
				)
			},
			size: isMobile ? '10%' : '15%',
		},
	]
}

export const rfqColumnConfig = ({ t, onChange, type }) => {
	return [
		{
			property: 'dateSent',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.dateReceived)
							: t(MARKETPLACE_TABLE_ALIAS.dateSent)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const dateSent = getIn(datum, ['createdAt'])

				return <Label>{getDateByFormat(dateSent)}</Label>
			},
		},
		{
			property: 'seller-buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.buyer)
							: t(MARKETPLACE_TABLE_ALIAS.seller)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['initiatingParty', 'name'])
				const seller = getIn(datum, ['receivingParty', 'name'])

				const org = type === 'incoming' ? buyer : seller

				return <Label>{org}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				return datum?.product?.map(({ name, quantity, uom }) => (
					<Label as="span">{`${name} ${quantity} (${uom})`}</Label>
				))
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(MARKETPLACE_TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('open_document', { row: datum })
						}}
					/>
				)
			},
		},
	]
}

export const quoteColumnConfig = ({ t, onChange, type }) => {
	return [
		{
			property: 'dateSent',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.dateSent)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const dateSent = getIn(datum, ['createdAt'])

				return <Label>{getDateByFormat(dateSent)}</Label>
			},
		},
		{
			property: 'buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t(MARKETPLACE_TABLE_ALIAS.seller)
							: t(MARKETPLACE_TABLE_ALIAS.buyer)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['receivingParty', 'name'])
				const seller = getIn(datum, ['initiatingParty', 'name'])

				const org = type === 'incoming' ? seller : buyer

				return <Label>{org}</Label>
			},
		},
		{
			property: 'country',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.country)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const country =
					getIn(datum, [
						'receivingParty',
						'PrimaryAddress',
						'country',
					]) || '---'

				return <Label>{country}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.product)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				return datum?.product?.map(({ name, quantity, uom }) => (
					<Label as="span">{`${name} ${quantity} (${uom})`}</Label>
				))
			},
		},
		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(MARKETPLACE_TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(RFQ_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							onChange('open_document', { row: datum })
						}}
					/>
				)
			},
		},
	]
}

export const generalDocumentColumnConfig = ({ t, onChange, type }) => {
	return [
		{
			property: 'dateSent',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{t(MARKETPLACE_TABLE_ALIAS.dateSent)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const dateSent = getIn(datum, ['meta', 'issueDate'])

				return <Label>{getDateByFormat(dateSent)}</Label>
			},
		},
		{
			property: 'buyer',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>
						{type === 'incoming'
							? t('tdmDetailsEntry.sender')
							: t('tdmDetailsEntry.recipient')}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const buyer = getIn(datum, ['receivingParty', 'name'])
				const seller = getIn(datum, ['initiatingParty', 'name'])

				const org = type === 'incoming' ? seller : buyer

				return <Label>{org}</Label>
			},
		},

		{
			property: 'documentNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.documentNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const referenceValues = getIn(datum, ['meta', 'referenceName'])

				return <Label>{referenceValues || 'N/A'}</Label>
			},
		},
		{
			property: 'transactionNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(TABLE_ALIAS.transactionNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const transactionNumber = getIn(datum, ['transactionNumber'])

				return <Label>{transactionNumber || 'N/A'}</Label>
			},
		},

		{
			property: 'status',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle>{t(MARKETPLACE_TABLE_ALIAS.status)}</TableTitle>
				</Box>
			),
			render: datum => {
				const status = getIn(datum, ['status'])

				return (
					<Box
						style={{
							backgroundColor: STATUS_BACKGROUND[status],
							padding: '8px 16px',
							margin: '0 0 8px',
							borderRadius: '5px',
							opacity: 1,
							textAlign: 'center',
						}}
					>
						<Label
							style={{
								color: STATUS_TEXT_COLOR[status],
							}}
						>
							{t(TRANSACTION_STATUS[status]).toUpperCase()}
						</Label>
					</Box>
				)
			},
		},
		{
			property: 'callToAction',
			render: datum => {
				const { status = '' } = datum

				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn').toUpperCase()}
						onClick={() => {
							if (status === 'draft') {
								onChange('preview-draft', {
									row: datum,
									type,
								})
							} else {
								onChange('view_share_doc', {
									row: datum,
									type,
								})
							}
						}}
					/>
				)
			},
		},
	]
}

export const storageUnitColumnConfig = storageUnitColomnProps => {
	const {
		storageUnits,
		onChange,
		storageUnitData,
		isEdit,
		baseProduct,
		targetProducts,
		t,
		isMobile,
		attachFlow = false,
	} = storageUnitColomnProps
	const tableArray = []
	const getStorageValue = (storageUnitID, quantityData = false) => {
		let quantity = 0
		const product = quantityData ? targetProducts : baseProduct
		if (isEdit && product && product.length > 0) {
			product.forEach(item => {
				if (item.storageUnitID === storageUnitID) {
					// eslint-disable-next-line prefer-destructuring
					quantity = item.quantity
				}
			})
		}

		return quantity
	}

	if (!isMobile) {
		tableArray.push(
			{
				property: 'storageUnit',
				header: (
					<Box center style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							{t('storage.storageUnit')}
						</Label>
					</Box>
				),
				render: datum => {
					const { storageUnitID } = datum
					const storageUnit = storageUnits.filter(
						unit => unit.id === storageUnitID
					)

					const storageUnitName = getIn(storageUnit, [0, 'name'])

					return (
						<Box center>
							<Label as="span">{storageUnitName}</Label>
						</Box>
					)
				},
			},
			{
				property: 'availableQty',
				header: (
					<Box center style={{ cursor: 'pointer' }}>
						<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
							{`${t('tdmDetailsEntry.availableQty')} (MT)`}
						</Label>
					</Box>
				),
				render: datum => {
					const { storageUnitID } = datum
					const availableQty = getIn(datum, ['availableQty']) || 0

					return (
						<Box center>
							<Label as="span">
								{
									(
										availableQty +
										getStorageValue(storageUnitID)
									)
										.toString()
										.match(/^-?\d+(?:\.\d{0,3})?/)[0]
								}
							</Label>
						</Box>
					)
				},
			}
		)
	}
	tableArray.push({
		property: 'quantity',
		header: (
			<Box
				center
				style={{
					cursor: 'pointer',
					width: isMobile ? '214px' : 'auto',
				}}
			>
				<Label bold style={{ color: '#4B4552', fontSize: 16 }}>
					{`${t('tdmDetailsEntry.quantity')} (MT)`}
				</Label>
			</Box>
		),
		render: datum => {
			const { productID, storageUnitID, availableQty = 0 } = datum
			const storageUnit = storageUnitData.filter(
				st => st.storageUnitID === storageUnitID
			)
			const storageUnitForName = storageUnits.filter(
				unit => unit.id === storageUnitID
			)

			const quantityEntered = getIn(storageUnit, [0, 'quantity']) || 0
			const storageUnitName = getIn(storageUnitForName, [0, 'name'])

			const errorMessage =
				'Should be less than or equal to available quantity'
			const maxQuantity = availableQty + getStorageValue(storageUnitID)
			const getWidth = (currentWidth, mobileWidth) => {
				let width = currentWidth
				if (isMobile) {
					width = mobileWidth
				}

				return width
			}

			return (
				<Box
					center
					style={{
						width: attachFlow ? getWidth('auto', '214px') : '285px',
					}}
				>
					{isMobile && (
						<>
							<Box center style={{ marginBottom: '10px' }}>
								<Label
									bold
									style={{ color: '#4B4552', fontSize: 16 }}
								>
									{`${t('storage.storageUnit')} : `}
								</Label>
								<Label as="span">{storageUnitName}</Label>
							</Box>
							<Box center style={{ marginBottom: '10px' }}>
								<Label
									bold
									style={{ color: '#4B4552', fontSize: 16 }}
								>
									{`${t(
										'tdmDetailsEntry.availableQty'
									)} (MT) : `}
								</Label>
								<Label as="span">
									{availableQty +
										getStorageValue(storageUnitID)}
								</Label>
							</Box>
						</>
					)}
					<Input
						name="quantity"
						type="number"
						error={quantityEntered > maxQuantity && errorMessage}
						hideError={quantityEntered <= maxQuantity}
						value={getStorageValue(storageUnitID, true)}
						onChange={e => {
							onChange('storage_unit', {
								productID,
								storageUnitID,
								availableQty: maxQuantity,
								quantity: Number(e.target.value),
							})
						}}
						extendStyles={{
							width: isMobile ? 150 : 220,
							marginLeft: isMobile ? 33 : 50,
							marginRight: attachFlow ? 26 : 'auto',
						}}
					/>
				</Box>
			)
		},
	})

	return tableArray
}
