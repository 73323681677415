import { all, takeLatest, select, put, call } from 'redux-saga/effects'
import LogHelper from 'ui-tdm-app/utils/logger'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import {
	getCoreEndPoint,
	getPlantationEndPoint,
	getIAMEndPoint,
	getMirrorNodeBaseUrl,
	getExplorerBaseurl,
	getMessagingEndPoint,
} from 'ui-tdm-app/config'
import request from 'ui-tdm-app/utils/request'
import {
	CallWithRefreshCheck,
	getOrgIDFromLoggedUser,
	getUserProfileFromLoggedUser,
	fetchOrgsDetails,
	fetchCurrentOrgsDetail,
} from 'ui-tdm-app/modules/Auth/AuthSaga'
import { createWeighBridgeSlip } from 'ui-tdm-app/modules/WeighBridge/WeighBridgeSaga'
import querySerializer from 'query-string'
import { getIn, omit, merge, addLast, set, setIn } from 'timm'
import { isEmptyObject, pickBy } from 'ui-tdm-app/utils/helpers'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { Toast } from 'ui-lib/components/Toast'
import { TradeDocDuc } from './duc'
import {
	INITIAL_TYPES,
	excludedReadableSections,
	ROLE_ACCESSES,
	getActiveTypesBasedOnRole,
} from './config'
import {
	extractOrgIDsFromResponses,
	responseDocsMapper,
	extractSortQueries,
	transformFilterStringsToBEQueries,
	extractFilterQueries,
	otherAttachmentData,
	billOfLadingAttachmentData,
} from './helpers'

const logger = LogHelper('client:tradeDocManagerSaga')

function* handleActiveTDMRulesFetch() {
	try {
		yield getUserProfileFromLoggedUser()
		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const activeModules = []

		const { canReadIncoming, canReadOutgoing } = rolesMap
		const location = yield select(TradeDocDuc.selectors.location)
		const { payload = {} } = location || {}
		const { rootModule } = payload
		if (canReadIncoming && rootModule === 'incoming')
			activeModules.push('incoming')
		if (canReadOutgoing && rootModule === 'outgoing')
			activeModules.push('outgoing')
		if (rootModule === 'bankStatements')
			activeModules.push('bankStatements')

		yield put(
			TradeDocDuc.creators.setDashboardActiveTDMRules(
				activeModules,
				getActiveTypesBasedOnRole(INITIAL_TYPES, rolesMap)
			)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchUserDocuments(action) {
	try {
		const { docType = 'all', locationState = {}, skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-documents'))

		let requestUrl

		yield put(AuthDuc.creators.fetchActor())
		yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))

		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar

		/* This depends on profile and by this time we should have user roles */
		const orgID = yield getOrgIDFromLoggedUser()

		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const targetTypes = getActiveTypesBasedOnRole(
			docType && docType !== 'all' ? [docType] : INITIAL_TYPES,
			rolesMap
		)

		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})
		yield put(TradeDocDuc.creators.setUploadDOAcceptedList())

		// atleast one type should be allowed for the user or if its draft fetch, let the user
		if (!targetTypes.length && docType !== 'draft') {
			yield put(MainRouteDuc.creators.switch401())

			return
		}

		const { canReadIncoming, canReadOutgoing } = rolesMap

		const hasIncoming = canReadIncoming
		const hasOutgoing = canReadOutgoing
		let allOrgIDs = []

		// add the type of doc when requested
		if (hasIncoming) {
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))

			// set respective loading statuses
			yield put(
				TradeDocDuc.creators.setDashboardLoading(
					'incoming',
					targetTypes
				)
			)

			const _incomingQuery = existingQueryFromUrl.incoming || []
			const _sortKeys = Array.isArray(_incomingQuery)
				? _incomingQuery
				: [_incomingQuery]

			// based on the selection create the blocks to call.
			const callsPipeLine = targetTypes.map(_type => {
				requestUrl = `${getCoreEndPoint()}documentlist?docType=${_type}&receivingParty.orgID=${orgID}&transactiontype=incoming&limit=20`

				return CallWithRefreshCheck(requestUrl)
			})

			const origResponse = yield all(callsPipeLine)

			const responses = responseDocsMapper(targetTypes, origResponse)

			// if there are active sorts, then set them in the url.
			yield put(
				TradeDocDuc.creators.fetchDashboardDocumentsSuccess(
					'incoming',
					responses
				)
			)

			// fetch org ids
			const orgIDs = extractOrgIDsFromResponses(origResponse)

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)

			// update the queries as applied in backend to stay in sync
			const sortQueriesFromBE = extractSortQueries(responses, _sortKeys)
			if (sortQueriesFromBE.length) {
				frontendTargetQuery.incoming = sortQueriesFromBE
			}
		}

		if (hasOutgoing) {
			// set respective loading statuses
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))
			yield put(
				TradeDocDuc.creators.setDashboardLoading(
					'outgoing',
					targetTypes
				)
			)

			// based on the selection create the blocks to call.
			const callsPipeLine = targetTypes.map(_type => {
				requestUrl = `${getCoreEndPoint()}documentlist?docType=${_type}&initiatingParty.orgID=${orgID}&transactiontype=outgoing&limit=20`

				return CallWithRefreshCheck(requestUrl)
			})

			const origResponse = yield all(callsPipeLine)
			const responses = responseDocsMapper(targetTypes, origResponse)

			// fetch org ids
			const orgIDs = extractOrgIDsFromResponses(addLast(origResponse, {}))

			yield put(
				TradeDocDuc.creators.fetchDashboardDocumentsSuccess(
					'outgoing',
					responses
				)
			)

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)
			// update the queries as applied in backend to stay in sync
		}
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))

		// fetch org info
		if (allOrgIDs.length)
			yield put(AuthDuc.creators.fetchOrgDetails(allOrgIDs))

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(TradeDocDuc.creators.setActiveSorts(frontendTargetQuery))
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))
		yield put(TradeDocDuc.creators.tdmLoadingStatus(false))
		yield put(TradeDocDuc.creators.resetDashboardLoading())
	}
}

function* fetchDraftOrganizationDetails(payload) {
	try {
		const transactions = getIn(payload, ['list'], [])

		const updatedTransactions = []

		for (const transaction of transactions) {
			if (transaction.status === 'draft') {
				const receiverOrgIDs = getIn(
					transaction,
					['meta', 'receiverOrgIDs'],
					[]
				)
				const organizationNames = []

				for (const organizationID of receiverOrgIDs) {
					const organizationDetail = yield select(state =>
						AuthDuc.selectors.getPartnerOrganizationByID(
							state,
							organizationID
						)
					)

					if (organizationDetail && organizationDetail.name) {
						organizationNames.push(organizationDetail.name)
					}
				}

				const receivingPartyName = organizationNames.join(', ')

				const updatedTransaction = setIn(
					transaction,
					['receivingParty', 'name'],
					receivingPartyName
				)

				updatedTransactions.push(updatedTransaction)
			} else {
				updatedTransactions.push(transaction)
			}
		}

		return set(payload, ['list'], updatedTransactions)
	} catch (e) {
		logger.log(e)
	}
}

function* fetchGeneralDocuments(action) {
	try {
		const { locationState = {}, skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-documents'))

		let requestUrl

		yield put(AuthDuc.creators.fetchActor())
		yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))

		const { type, payload, query } = locationState
		const existingQueryFromUrl = query || {}
		const frontendTargetQuery = {} // this would appear in search bar

		/* This depends on profile and by this time we should have user roles */
		const orgID = yield getOrgIDFromLoggedUser()

		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		yield fetchCurrentOrgsDetail({
			orgID,
			returnResponse: true,
		})

		const { canReadIncoming, canReadOutgoing } = rolesMap

		const hasIncoming = canReadIncoming
		const hasOutgoing = canReadOutgoing
		let allOrgIDs = []

		const options = {
			method: 'GET',
		}
		// add the type of doc when requested
		if (hasIncoming) {
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))

			const _incomingQuery = existingQueryFromUrl.incoming || []
			const _sortKeys = Array.isArray(_incomingQuery)
				? _incomingQuery
				: [_incomingQuery]

			requestUrl = `${getCoreEndPoint()}transactionlist?docType=doc-transaction&receivingParty.orgID=${orgID}&limit=20`

			const { data: origResponse } = yield call(
				request,
				requestUrl,
				options
			)

			// if there are active sorts, then set them in the url.
			yield put(
				TradeDocDuc.creators.fetchGeneralDocumentsSuccess(
					'incoming',
					origResponse
				)
			)

			// fetch org ids
			const orgIDs = extractOrgIDsFromResponses([origResponse])

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)

			// update the queries as applied in backend to stay in sync
			const sortQueriesFromBE = extractSortQueries(
				origResponse,
				_sortKeys
			)
			if (sortQueriesFromBE.length) {
				frontendTargetQuery.incoming = sortQueriesFromBE
			}
		}

		if (hasOutgoing) {
			// set respective loading statuses
			yield put(TradeDocDuc.creators.tdmLoadingStatus(true))

			requestUrl = `${getCoreEndPoint()}transactionlist?docType=doc-transaction&initiatingParty.orgID=${orgID}&limit=20`

			const { data: origResponse } = yield call(
				request,
				requestUrl,
				options
			)

			const orgIDs = extractOrgIDsFromResponses([origResponse])
			const resp = yield call(fetchDraftOrganizationDetails, origResponse)

			yield put(
				TradeDocDuc.creators.fetchGeneralDocumentsSuccess(
					'outgoing',
					resp
				)
			)

			if (orgIDs.length && orgIDs[0] !== undefined)
				allOrgIDs = addLast(allOrgIDs, orgIDs)
			// update the queries as applied in backend to stay in sync
		}
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))

		// fetch org info
		if (allOrgIDs.length)
			yield put(AuthDuc.creators.fetchOrgDetails(allOrgIDs))

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(TradeDocDuc.creators.setActiveSorts(frontendTargetQuery))
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-documents'))
		yield put(TradeDocDuc.creators.tdmLoadingStatus(false))
		yield put(TradeDocDuc.creators.resetDashboardLoading())
	}
}

function* changeDocumentStatus(action) {
	try {
		const {
			docType,
			successMsg,
			postChange,
			docNumber,
			remarks,
			errorMsg,
			docID = '',
			status = null,
		} = action.documentStatusPropsList

		if (!status) {
			throw new Error(errorMsg)
		}

		const currentUser = yield getUserProfileFromLoggedUser()
		const tenantID = getIn(currentUser, ['organization', 'id'])

		const requestUrl = `${getCoreEndPoint()}document/events`

		const options = {
			method: 'POST',
			body: JSON.stringify({
				tenantID,
				events: [
					{
						event: 'state-change-document',
						data: {
							payload: {
								docID,
								docType,
								docNumber,
								status,
							},
						},
					},
				],
			}),
		}

		// make the api call to change status in backend.
		yield call(request, requestUrl, options)

		yield put(TradeDocDuc.creators.addNewRemark(remarks, docID, docType))

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successMsg,
			})
		)

		if (postChange) postChange()
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TradeDocDuc.creators.setDOAcceptAllLoading(false))
	}
}

function* fetchDashboardStatuses(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('dashboard-status'))
		const { rootModule, submodule, query } = action
		const checkFilter = getIn(query, ['createdAt']) || []
		const orgID = yield getOrgIDFromLoggedUser()

		let requestUrl = `${getCoreEndPoint()}document/stats?docType=${
			submodule === 'excel-uploads' ? 'drafts' : submodule
		}&${
			rootModule === 'incoming'
				? 'receivingParty.orgID'
				: 'initiatingParty.orgID'
		}=${orgID}`
		if (checkFilter.length > 0) {
			const filterQueries = transformFilterStringsToBEQueries({
				createdAt: query.createdAt,
			})
			requestUrl += `&createdAt=${filterQueries.createdAt}`
		}
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(
			TradeDocDuc.creators.fetchDashboardStatsSuccess(rootModule, data)
		)
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-status'))
	}
}

const PAGINATION_LIMIT = 20

function* fetchDocumentListing(action) {
	try {
		const {
			rootModule,
			submodule,
			locationState = {},
			skipGlobalLoader,
		} = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('dashboard-listing'))

		const { type, payload, query } = locationState
		yield put(
			TradeDocDuc.creators.fetchDashboardStats(
				rootModule,
				submodule,
				query
			)
		)
		// yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))
		const existingQueryFromUrl = query || {}
		let frontendTargetQuery = {} // this would appear in search bar
		let backendTargetQuery = {} // this would go to backend api call
		// handle the sort conditions based on what's selected here.
		// show local loader
		yield put(TradeDocDuc.creators.fetchDocumentLoading(true))
		const orgID = yield getOrgIDFromLoggedUser()
		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: Math.min(
				existingQueryFromUrl.limit || PAGINATION_LIMIT,
				PAGINATION_LIMIT
			),
			nextIndex: existingQueryFromUrl.nextIndex,
		}

		// prepare backend query based on the pagination factors
		if (paginationQuery.limit) {
			backendTargetQuery.limit = paginationQuery.limit
		}

		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		const searchQuery = existingQueryFromUrl.q || ''

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}
		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}

		const __query = {
			...backendTargetQuery,
			[rootModule === 'incoming'
				? 'receivingParty.orgID'
				: 'initiatingParty.orgID']: orgID,
		}
		const requestUrl = `${getCoreEndPoint()}documentlist?docType=${submodule}&transactiontype=${rootModule}&${querySerializer.stringify(
			{
				...__query,
				...(searchQuery && { search: searchQuery }),
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || {}
		// server pagination query
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? paginationQuery.activeIndex
				: 0,
			limit: Math.min(getIn(response, ['pageSize']) || PAGINATION_LIMIT),
			nextIndex: getIn(response, ['lastID']),
		}
		// extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(response, ['pageSize']),
				getIn(response, ['total']),
				getIn(response, ['prevStartID']),
				getIn(response, ['nextStartID'])
			)
		)

		// extract filter queries
		const { queryTree, stateTree } = extractFilterQueries(response)

		yield put(TradeDocDuc.creators.setActiveListingFilters(stateTree))

		frontendTargetQuery = merge(
			frontendTargetQuery,
			queryTree,
			serverPaginationQuery
		)

		yield put(TradeDocDuc.creators.fetchDocumentListingSuccess(response))

		if (!isEmptyObject(frontendTargetQuery)) {
			yield put(
				TradeDocDuc.creators.setActiveListingSorts(frontendTargetQuery)
			)
			// we have some query, lets update the url to reflect that.
			yield put(
				MainRouteDuc.creators.redirect(
					type,
					payload,
					frontendTargetQuery,
					{
						skipRouteThunk: true,
					}
				)
			)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(TradeDocDuc.creators.fetchDocumentLoading(false))
		yield put(AppDuc.creators.hideGlobalLoader('dashboard-listing'))
	}
}

function* initiateDocumentStep() {
	try {
		const location = yield select(TradeDocDuc.selectors.location)
		const { payload = {} } = location || {}

		const { action, rootModule } = payload

		// fetch the current org details
		const currentOrgID = yield getOrgIDFromLoggedUser()
		// get accesses based on the user roles here
		const { allowed, denied } = yield select(
			AuthDuc.selectors.getCurrentUserRoles
		)

		// check for the user roles and if permitted to move further
		const rolesMap = AuthDuc.options.helpers.getAllowedAccesses(
			allowed,
			denied,
			ROLE_ACCESSES
		)

		const allowedTypes = getActiveTypesBasedOnRole([rootModule], rolesMap)

		if (!allowedTypes.length) {
			// user is not authorized to see this module.
			yield put(TradeDocDuc.creators.documentLoading(false, false, true))

			return
		}

		// Check if there is already associated partner, if yes, fetch the details and move to details view
		const activeDocument = yield select(
			TradeDocDuc.selectors.getDocumentActiveRecord
		)

		// fetch the relevant entity references
		const {
			initiatingPartyID,
			initiatingUserID,
			receivingUserID,
			receivingPartyID,
		} = activeDocument

		const activePartner = yield select(
			TradeDocDuc.selectors.getDocumentActivePartner
		)

		if (receivingPartyID) {
			const docStatus = getIn(activeDocument, ['docStatus'])

			// check if draft during one of the edit flow
			if (
				docStatus &&
				payload.documentStatus !== 'clone' &&
				payload.documentStatus !== 'edit' &&
				docStatus !== 'draft' &&
				action
			) {
				// The user somehow landed on an editing page when the document is
				// not a draft anymmore. So show error during thi
				yield put(
					TradeDocDuc.creators.documentLoading(false, false, true)
				)

				return
			}

			// set active partner
			if (!activePartner.id) {
				const partner =
					currentOrgID === receivingPartyID
						? initiatingPartyID
						: receivingPartyID

				yield put(
					TradeDocDuc.creators.setActivePartner({
						name: partner,
					})
				)
			}
		}

		if (action === 'details' || action === 'attachDetails') {
			yield put(AuthDuc.creators.fetchPartnerOrgs(false, false))

			// fetch the partner address details
			if (activePartner.id && rootModule !== 'purchase-order')
				yield put(
					AuthDuc.creators.fetchOrgDetails([activePartner.id], false)
				)

			// fetch all the products
			// yield put(AuthDuc.creators.fetchAllProducts(rootModule))
			yield put(AuthDuc.creators.fetchCertificates())
			// yield put(AuthDuc.creators.fetchAllStorageUnits())
		}

		// if we do not have the org details, fetch it.
		if (initiatingPartyID && receivingPartyID) {
			const organizations = yield select(
				AuthDuc.selectors.getAvailableOrgs
			)
			if (
				!organizations[initiatingPartyID] ||
				!organizations[receivingPartyID]
			) {
				yield fetchDocumentPartipants({
					initiatingPartyID,
					initiatingUserID,
					receivingUserID,
					receivingPartyID,
				})
			}
		}
		// hide the loader
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		TradeDocDuc.creators.documentLoading(false, false, true)
	}
}

const transformDataForDocTrace = origResponse => {
	const docTraceData = {}
	docTraceData.id = origResponse.entity.id
	docTraceData.entityName =
		origResponse.entity.type &&
		origResponse.entity.type.replace('-', ' ').toUpperCase()
	docTraceData.entityType = origResponse.entity.type
	docTraceData.currentEntityID = origResponse.entity.id
	docTraceData.version = origResponse.entity.version || '---'
	docTraceData.entityID = origResponse.entity.number
	docTraceData.sentDate = origResponse.entity.createdAt
	docTraceData.actionDate =
		origResponse.entity.status && origResponse.entity.status.updatedAt
	docTraceData.status =
		origResponse.entity.status && origResponse.entity.status.state
	docTraceData.messages = origResponse.entity.remarks || 0

	if (origResponse.trace) {
		docTraceData.children = origResponse.trace
		origResponse.trace.forEach((data, index) => {
			docTraceData.children[index] = transformDataForDocTrace(data)
		})
	}

	return docTraceData
}

const docTraceData = origResponse => {
	origResponse.forEach((ob, index) => {
		// eslint-disable-next-line no-param-reassign
		origResponse[index] = transformDataForDocTrace(ob)
	})

	return { origResponse }
}

function* fetchDocumentAttachments(action) {
	try {
		const { documentReference } = getIn(action, ['action']) || {}

		const attachmentRequestUrl = `${getCoreEndPoint()}entities/${documentReference}/attachments`
		const { data: _data } = yield CallWithRefreshCheck(attachmentRequestUrl)

		const attachments = []

		_data.forEach(attachment => {
			if (attachment.meta.category === 'documents') {
				attachments.push(otherAttachmentData(attachment))
			} else if (attachment.meta.category === 'bill-of-lading') {
				attachments.push(billOfLadingAttachmentData(attachment))
			}
		})

		yield put(TradeDocDuc.creators.setAttachmentDoc(attachments))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('attachment-status'))
	}
}

function* initiateDocumentView(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('document-view'))
		yield put(TradeDocDuc.creators.setActiveTab('original'))

		const { documentReference, rootModule } = action || {}

		yield put(
			TradeDocDuc.creators.fetchActiveDocument(
				documentReference,
				rootModule
			)
		)

		const orgID = yield getOrgIDFromLoggedUser()

		yield fetchOrgsDetails({
			orgIDs: [orgID],
			returnResponse: true,
		})

		const documentTraceURL = `${getCoreEndPoint()}documentTrace/${rootModule}/${documentReference}`

		const { data: response } = yield CallWithRefreshCheck(documentTraceURL)

		const remarksUrl = `${getCoreEndPoint()}document/${rootModule}/${documentReference}/remarks`
		const { data = [] } = yield CallWithRefreshCheck(remarksUrl)

		yield put(TradeDocDuc.creators.setRemarks(data))

		yield put(TradeDocDuc.creators.setDocumentTrace(response))

		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('document-view'))
	}
}

function* initiateGeneralDocumentView(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('general-document-view'))
		yield put(TradeDocDuc.creators.setActiveTab('original'))
		yield put(TradeDocDuc.creators.setActiveAssociatedDocs({}))

		const orgID = yield getOrgIDFromLoggedUser()

		yield fetchOrgsDetails({
			orgIDs: [orgID],
			returnResponse: true,
		})

		const { documentReference } = action || {}

		const entityRequestUrl = `${getCoreEndPoint()}transaction/doc-transaction/${documentReference}`

		const { data: origResponse } = yield CallWithRefreshCheck(
			entityRequestUrl
		)
		const { meta = {} } = origResponse
		const { referenceValue } = meta

		if (referenceValue) {
			const referenceRequestUrl = `${getCoreEndPoint()}transaction/doc-transaction/${referenceValue}`

			const { data: refResponse } = yield CallWithRefreshCheck(
				referenceRequestUrl
			)
			yield put(TradeDocDuc.creators.setActiveAssociatedDocs(refResponse))
		}

		const remarksUrl = `${getCoreEndPoint()}document/doc-transaction/${documentReference}/remarks`
		const { data = [] } = yield CallWithRefreshCheck(remarksUrl)

		yield put(TradeDocDuc.creators.setRemarks(data))

		yield put(TradeDocDuc.creators.setActiveGeneralDocuments(origResponse))
		const docID = getIn(origResponse, ['docID'])
		const transactionID = getIn(origResponse, ['transactionId'])
		const docNumber = getIn(origResponse, ['transactionNumber'])
		const docType = getIn(origResponse, ['docType'])

		if (docID && transactionID) {
			const chatAssociatedListURL = `${getMessagingEndPoint()}chatlist/transaction/${transactionID}`
			const chatThreadsOptions = {
				method: 'GET',
			}

			const chatAssociatedListResponse = yield call(
				request,
				chatAssociatedListURL,
				chatThreadsOptions
			)
			yield put(
				TradeDocDuc.creators.setChatAssociatedList(
					chatAssociatedListResponse?.data
				)
			)
			const currentThread =
				chatAssociatedListResponse?.data?.length > 0 &&
				chatAssociatedListResponse?.data.filter(
					item =>
						getIn(item, ['documentMeta', 'name']) === docNumber &&
						getIn(item, ['documentMeta', 'type']) === docType
				)
			const threadID = getIn(currentThread, [0, 'threadID'])
			if (threadID) {
				yield put(TradeDocDuc.creators.getChatMessagesList(threadID))
			}
		}

		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('general-document-view'))
	}
}

function* createTransaction(action) {
	const {
		details,
		successToast,
		helpers: { setSubmitting },
	} = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('create-transaction'))
		const requestUrl = `${getCoreEndPoint()}transaction`

		const options = {
			method: 'POST',
			body: JSON.stringify(details),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(TradeDocDuc.creators.setActiveTabGeneralDocument('outgoing'))

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERALACTION,
				{
					rootModule: 'general-document',
					action: 'listing',
				}
			)
		)
	} catch (err) {
		setSubmitting(false)
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('create-transaction'))
	}
}

function* saveAsDraftTransaction(action) {
	const { details, successToast } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('save-draft-transaction'))
		const requestUrl = `${getCoreEndPoint()}transactionDraft`

		const options = {
			method: 'POST',
			body: JSON.stringify(details),
		}

		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		yield put(TradeDocDuc.creators.setActiveTabGeneralDocument('outgoing'))

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERALACTION,
				{
					rootModule: 'general-document',
					action: 'listing',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('save-draft-transaction'))
	}
}

function* updateTransaction(action) {
	const {
		details,
		successToast,
		helpers: { setSubmitting, statusUpdate = false },
	} = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('update-transaction'))

		const requestUrl = statusUpdate
			? `${getCoreEndPoint()}transactionStatus`
			: `${getCoreEndPoint()}transaction`

		const options = {
			method: 'PUT',
			body: JSON.stringify(details),
		}

		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: successToast,
			isMobile: isMobile || isTablet,
		})
		const module = statusUpdate ? 'incoming' : 'outgoing'
		yield put(TradeDocDuc.creators.setActiveTabGeneralDocument(module))

		yield put(TradeDocDuc.creators.setActiveGeneralDocuments(data))

		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERALACTION,
				{
					rootModule: 'general-document',
					action: 'listing',
				}
			)
		)
	} catch (err) {
		if (setSubmitting) {
			setSubmitting(false)
		}
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-transaction'))
	}
}

function* updateTransactionRemarks(action) {
	const { docID, remarks } = action
	try {
		yield put(AppDuc.creators.showGlobalLoader('update-transaction'))

		const requestUrl = `${getCoreEndPoint()}transaction/doc-transaction/${docID}/remarks`

		const options = {
			method: 'PUT',
			body: JSON.stringify([
				{
					comment: remarks,
				},
			]),
		}

		yield call(request, requestUrl, options)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-transaction'))
	}
}

function* searchAssociatedDocs(action) {
	try {
		const { query, orgID } = action
		const requestUrl = `${getCoreEndPoint()}transactionSearch/${orgID}?transactionnumber=${query}`

		if (query.length < 3) {
			yield put(
				TradeDocDuc.creators.setAssociatedDocs({
					results: [],
					refMessage: 'expected atleast 3 characters in search query',
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data']) || []

		yield put(
			TradeDocDuc.creators.setAssociatedDocs({
				results: list,
				refMessage:
					list.length === 0 ? 'No Entity Reference found' : '',
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* getHederaMessages(action) {
	const { entityIDs } = action
	try {
		const requestUrl = `${getCoreEndPoint()}trace/hedera/transactions?refid=${entityIDs}`
		const { data } = yield call(request, requestUrl)
		yield put(TradeDocDuc.creators.setHederaMessages({ data }))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchProduct(action = {}) {
	try {
		const { productID, fetchPrice, fetchTax } = action
		yield put(AuthDuc.creators.fetchProductsStatus(true))

		if (productID) {
			let pricesList
			let taxesList

			if (fetchPrice) {
				// fetch prices
				const requestUrl = `${getCoreEndPoint()}products/${productID}/price`
				const { data = {} } = yield CallWithRefreshCheck(requestUrl)

				pricesList = getIn(data, ['list']) || []
			}

			if (fetchTax) {
				// fetch prices
				const requestUrl = `${getCoreEndPoint()}products/${productID}/tax`
				const { data = {} } = yield CallWithRefreshCheck(requestUrl)

				taxesList = getIn(data, ['list']) || []
			}

			const requestUrl = `${getCoreEndPoint()}products/${productID}`
			const { data = {} } = yield CallWithRefreshCheck(requestUrl)

			if (data.id)
				yield put(
					AppDuc.creators.fetchAProductSuccess(
						data.id,
						data,
						pricesList,
						taxesList
					)
				)
		}
	} catch (e) {
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(AuthDuc.creators.fetchProductsStatus(false, true))
	} finally {
		yield put(AuthDuc.creators.fetchProductsStatus(false))
	}
}

const transformDocumentForState = (response = {}, parentDocResponse = {}) => {
	let readOnlySections = []
	let parentParsedSchema = {}

	const baseSchema = {
		meta: {},
		shippingDetails: {},
		product: [],
		authorization: {},
	}

	const docStatus = getIn(response, ['status'])
	if (docStatus) baseSchema.docStatus = docStatus

	if (!isEmptyObject(parentDocResponse)) {
		const { baseSchema: _baseSchema } = transformDocumentForState(
			parentDocResponse
		)

		parentParsedSchema = _baseSchema

		readOnlySections = addLast(
			readOnlySections,
			Object.keys(parentParsedSchema)
		)
	}

	const meta =
		getIn(response, ['meta']) || getIn(parentDocResponse, ['meta']) || {}
	const parentType = getIn(response, ['docType']) || ''
	if (meta) {
		baseSchema.meta = meta
		baseSchema.meta.entityReference = getIn(response, ['docNumber']) || ''

		if (docStatus === 'draft') {
			baseSchema.meta.issueDate = new Date()
			baseSchema.meta.expectedDeliveryDate = new Date()
		}

		if (parentType && docStatus !== 'draft') {
			baseSchema.meta.referenceName = getIn(response, ['docType'])
			baseSchema.meta.referenceValue = getIn(response, ['docID'])
		}
	} else if (parentParsedSchema.meta) {
		baseSchema.meta = parentParsedSchema.meta
	}

	const shippingDetails = getIn(response, ['shippingDetails'])
	if (shippingDetails) {
		baseSchema.shippingDetails = shippingDetails
	} else if (parentParsedSchema.shippingDetails) {
		baseSchema.shippingDetails = parentParsedSchema.shippingDetails
	}
	baseSchema.senderRequirements =
		getIn(parentDocResponse, ['senderRequirements']) || {}

	baseSchema.contractType = getIn(parentDocResponse, ['contractType']) || ''
	baseSchema.initiatingPartyID =
		getIn(parentDocResponse, ['initiatingParty', 'orgID']) || ''
	baseSchema.receivingPartyID =
		getIn(parentDocResponse, ['receivingParty', 'orgID']) || ''

	if (parentDocResponse?.docType === 'request-for-quote') {
		baseSchema.initiatingParty =
			getIn(parentDocResponse, ['receivingParty']) || {}
		baseSchema.receivingParty =
			getIn(parentDocResponse, ['initiatingParty']) || {}
	}

	baseSchema.docID = getIn(parentDocResponse, ['docID'])
	baseSchema.product = getIn(parentDocResponse, ['product'])
	baseSchema.inspections = getIn(parentDocResponse, ['inspections'])
	const authorization = getIn(response, ['meta', 'authorization'])

	if (authorization) {
		baseSchema.meta.authorization = authorization
	}

	const parentDocRef = getIn(response, ['meta', 'other', 'parentDocRef'])

	if (parentDocRef) {
		baseSchema.parentDocRef = parentDocRef
	} else if (!isEmptyObject(parentDocResponse)) {
		if (INITIAL_TYPES.includes(parentDocResponse.type)) {
			// when its standard doc, only keep imp references
			baseSchema.parentDocRef = pickBy(
				parentDocResponse,
				excludedReadableSections
			)
		} else {
			// when its non-standard doc, keep all attributes
			baseSchema.parentDocRef = parentDocResponse
		}
	}

	const readOnlySectionsInDoc =
		getIn(response, ['meta', 'other', 'readOnlySections']) || []

	// filter out sections that we cannot inherit as read only
	readOnlySections = readOnlySections.filter(
		key => !excludedReadableSections.includes(key)
	)

	return {
		baseSchema: pickBy(baseSchema),
		readOnlySections: readOnlySectionsInDoc || readOnlySections,
	}
}

function* fetchDocumentPartipants({ initiatingPartyID, receivingPartyID }) {
	try {
		if (initiatingPartyID || receivingPartyID) {
			yield put(
				AuthDuc.creators.fetchOrgDetails(
					[initiatingPartyID, receivingPartyID].filter(a => a),
					false,
					true
				)
			)
		}

		const targetParticipantProfiles = {}
		// sync the participants
		yield put(
			TradeDocDuc.creators.setDocumentParticipants(
				targetParticipantProfiles
			)
		)
	} catch (e) {
		logger.log(e)
		throw e
	}
}

function* getChatMessagesList(action) {
	try {
		yield put(TradeDocDuc.creators.setMsgDataLoader(true))

		const { threadID } = action
		const currentTDID = yield select(
			TradeDocDuc.selectors.getCurrentThreadID
		)
		const chatRequestUrl = `${getMessagingEndPoint()}chat/threadid/${threadID}`
		const chatThreadsOptions = {
			method: 'GET',
		}

		if (!currentTDID || threadID === currentTDID) {
			const chatResponse = yield call(
				request,
				chatRequestUrl,
				chatThreadsOptions
			)

			yield put(
				TradeDocDuc.creators.setChatMessageList(chatResponse?.data)
			)
		}
	} catch (e) {
		logger.log(e)
		throw e
	} finally {
		yield put(TradeDocDuc.creators.setMsgDataLoader(false))
	}
}

function* fetchActiveDocument(action) {
	const { returnValue } = action
	try {
		const { rootModule, entityID } = action || {}
		yield put(TradeDocDuc.creators.documentLoading(true))

		if (entityID) {
			const requestUrl = `${getCoreEndPoint()}document/${rootModule}/${entityID}`

			const { data = {} } = yield CallWithRefreshCheck(requestUrl)

			if (returnValue) {
				return data
			}

			// fetch the relevant entity references
			const { initiatingParty, receivingParty } = data

			const initiatingPartyID = initiatingParty.orgID
			const receivingPartyID = receivingParty.orgID

			// need to check if initiating & receiving user is required or not
			yield fetchDocumentPartipants({
				initiatingPartyID,
				receivingPartyID,
			})

			yield put(
				TradeDocDuc.creators.setActiveDocument({
					rootModule,
					baseSchema: data,
				})
			)

			const docID = getIn(data, ['docID'])
			const transactionID = getIn(data, ['transactionId'])
			const docNumber = getIn(data, ['docNumber'])
			const docType = getIn(data, ['docType'])

			if (docID && transactionID) {
				const chatAssociatedListURL = `${getMessagingEndPoint()}chatlist/transaction/${transactionID}`
				const chatThreadsOptions = {
					method: 'GET',
				}

				const chatAssociatedListResponse = yield call(
					request,
					chatAssociatedListURL,
					chatThreadsOptions
				)
				yield put(
					TradeDocDuc.creators.setChatAssociatedList(
						chatAssociatedListResponse?.data
					)
				)
				const currentThread =
					chatAssociatedListResponse?.data?.length > 0 &&
					chatAssociatedListResponse?.data.filter(
						item =>
							getIn(item, ['documentMeta', 'name']) ===
								docNumber &&
							getIn(item, ['documentMeta', 'type']) === docType
					)
				const threadID = getIn(currentThread, [0, 'threadID'])
				if (threadID) {
					yield put(
						TradeDocDuc.creators.getChatMessagesList(threadID)
					)
				}
			}

			yield put(TradeDocDuc.creators.initiateDocumentStep())
		} else {
			throw new Error('Not a valid request')
		}
	} catch (e) {
		logger.log(e)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* fetchParentSource(action) {
	try {
		const { rootModule, parentDocRef } = action || {}

		const [parentType, _parentDocRef] = parentDocRef.split('~')
		const shouldFetchParentDoc = parentType && _parentDocRef
		if (shouldFetchParentDoc) {
			yield put(TradeDocDuc.creators.documentLoading(true))

			let parentDoc = {}

			if (INITIAL_TYPES.includes(parentType)) {
				// our regular document
				parentDoc = yield fetchActiveDocument({
					returnValue: true,
					rootModule: parentType,
					entityID: _parentDocRef,
				})
			} else {
				// call the document types based on what's sent as reference
				parentDoc = yield fetchSupportiveDocument({
					parentType,
					parentDocRef: _parentDocRef,
					returnValue: true,
				})
			}
			if (parentDoc) {
				// successfully done
				const {
					baseSchema,
					readOnlySections,
				} = transformDocumentForState({}, parentDoc)

				yield put(
					TradeDocDuc.creators.setActiveDocument({
						rootModule,
						baseSchema,
						readOnlySections:
							baseSchema.type === rootModule // if same type, then its a clone and not inheriting
								? []
								: readOnlySections,
					})
				)
				yield put(TradeDocDuc.creators.initiateDocumentStep())
			} else {
				throw new Error('Unable to fetch the linked source')
			}
		}
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		logger.log(e)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* fetchSupportiveDocument(action = {}) {
	const { returnValue } = action
	try {
		yield put(TradeDocDuc.creators.documentLoading(true))
		const { parentType, parentDocRef } = action

		let document = {}

		if (parentType === 'work-entry') {
			const requestUrl = `${getPlantationEndPoint()}plantation/tickets/${parentDocRef}`

			const response = yield CallWithRefreshCheck(requestUrl)

			document = getIn(response, ['data']) || {}

			// temporary should be delete when tkt-read returns product information
			const readProducturl = `${getCoreEndPoint()}clients/organizations/${
				document.organizationID
			}/products/${document.meta.productID}`
			const productResponse = yield CallWithRefreshCheck(readProducturl)
			const productInfo = getIn(productResponse, ['data']) || {}

			document.products = [
				{
					id: productInfo.product.id,
					quantity: document.ffbCount,
					code: productInfo.product.code,
					name: productInfo.product.name,
					description: productInfo.product.description,
					uom: productInfo.product.uom,
				},
			]

			if (document.id && !document.type) {
				document.type = parentType
			}

			// if doc has organizationID fetch it
			if (document.organizationID || document.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: document.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[document.organizationID],
						false,
						true
					)
				)
			}
		} else if (parentType === 'storage') {
			const requestUrl = `${getCoreEndPoint()}clients/organizations/_/tracegroups/${parentDocRef}`
			const response = yield CallWithRefreshCheck(requestUrl)
			document = getIn(response, ['data']) || {}
			const combineProducts = {}

			document.products = Object.values(combineProducts)
			document.traceGroupID = parentDocRef
			if (document.id && !document.type) {
				document.type = parentType
			}

			// if doc has organizationID fetch it
			if (document.organizationID || document.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: document.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[document.organizationID],
						false,
						true
					)
				)
			}
		} else if (parentType === 'plantation') {
			const orgID = yield getOrgIDFromLoggedUser()
			const requestProductUrl = `${getCoreEndPoint()}clients/organizations/${orgID}/products?limit=100`
			const productResponse = yield CallWithRefreshCheck(
				requestProductUrl
			)
			const productList = getIn(productResponse, ['data', 'list']) || []

			const requestUrl = `${getPlantationEndPoint()}plantation/ticketgroups/${parentDocRef}`
			const { data } = yield CallWithRefreshCheck(requestUrl)
			document = data

			const totalQuantity = data.tickets
				.map(ticket => ticket.ffbCount)
				.reduce((ttl, ticket) => ticket + ttl)

			const traces = data.tickets.map((ticket, index) => {
				const traceID =
					getIn(data, ['tickets', index, 'meta', 'traceID']) || ''

				return {
					traceID,
					quantity: ticket.ffbCount,
				}
			})
			const productID =
				getIn(data, ['tickets', 0, 'meta', 'productID']) || ''

			const productInfo = productList.filter(
				product => product.product.id === productID
			)
			const selectedProduct = getIn(productInfo, [0, 'product']) || {}

			document.products = [
				{
					id: productID,
					code: selectedProduct.code,
					name: selectedProduct.name,
					description: selectedProduct.description || '',
					uom: selectedProduct.defaultUOM || selectedProduct.uom,
					count: totalQuantity,
				},
			]
			if (data.id) {
				document.type = parentType
				document.traces = traces
				document.name = data.name
				document.meta = {
					issueDate: new Date(),
					expectedDeliveryDate: new Date(),
				}
			}
			// if doc has organizationID fetch it
			if (data.organizationID || data.createdBy) {
				yield fetchDocumentPartipants({
					initiatingPartyID: data.organizationID,
				})
				yield put(
					AuthDuc.creators.fetchOrgDetails(
						[data.organizationID],
						false,
						true
					)
				)
			}
		}

		yield put(TradeDocDuc.creators.documentLoading(false))

		if (returnValue) return document
	} catch (e) {
		if (returnValue) throw e
		const { message } = e
		logger.log(e)
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false, true))
	}
}

function* saveDocument(action) {
	const { documentPropsList = {} } = action
	const {
		rootModule,
		document,
		poSuccessMsg,
		invoiceSuccessMsg,
		doSuccessMsg,
		event,
		docID = '',
	} = documentPropsList

	try {
		const {
			contractType,
			inspections = {},
			meta = {},
			product = [],
			receivingParty = {},
			senderRequirements,
			shippingDetails,
			docNumber = '',
		} = document

		const { receiverOrgIDs = [], receiverEmailIDs = [] } = meta

		const requestUrl = `${getCoreEndPoint()}document/events`

		const currentOrgID = yield getOrgIDFromLoggedUser()

		const requestPayload = {
			tenantID: currentOrgID,
			events: [
				{
					event,
					data: {
						payload: {
							contractType,
							docNumber,
							docType: rootModule,
							initiatingParty: {
								orgID: currentOrgID,
							},
							inspections,
							meta: {
								...meta,
							},
							product,
							receivingParty,
							senderRequirements,
							shippingDetails,
						},
					},
				},
			],
		}

		if (receiverOrgIDs.length > 0 && receiverEmailIDs.length > 0) {
			// Both receiverOrgIDs and receiverEmailIDs have at least one element
			requestPayload.events[0].data.payload.meta.receiverOrgIDs = receiverOrgIDs
			requestPayload.events[0].data.payload.meta.receiverEmailIDs = receiverEmailIDs
		} else if (receiverOrgIDs.length > 0 && receiverEmailIDs.length === 0) {
			// Only receiverOrgIDs has at least one element
			if (receiverOrgIDs.length === 1) {
				// Single receiving organization ID
				const [orgID] = receiverOrgIDs
				requestPayload.events[0].data.payload.receivingParty = {
					...requestPayload.events[0].data.payload.receivingParty,
					orgID,
				}
				delete requestPayload.events[0].data.payload.meta.receiverOrgIDs
			} else {
				// Multiple receiving organization IDs
				requestPayload.events[0].data.payload.meta.receiverOrgIDs = receiverOrgIDs
			}
		} else if (receiverOrgIDs.length === 0 && receiverEmailIDs.length > 0) {
			// Only receiverEmailIDs has at least one element
			requestPayload.events[0].data.payload.meta.receiverEmailIDs = receiverEmailIDs
		}

		if (event === 'update-document') {
			requestPayload.events[0].data.payload.docID = docID
		}

		yield put(TradeDocDuc.creators.documentLoading(true, true))

		const options = {
			method: 'POST',
			body: JSON.stringify(requestPayload),
		}

		const { data } = yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		if (rootModule === 'purchase-order') {
			yield Toast({
				type: 'success',
				message: poSuccessMsg,
				isMobile: isMobile || isTablet,
			})
		} else if (rootModule === 'invoice') {
			yield Toast({
				type: 'success',
				message: invoiceSuccessMsg,
				isMobile: isMobile || isTablet,
			})
		} else if (rootModule === 'delivery-order') {
			yield Toast({
				type: 'success',
				message: doSuccessMsg,
				isMobile: isMobile || isTablet,
			})
		}

		if (receiverOrgIDs.length > 1 || receiverEmailIDs.length > 0) {
			yield put(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'outgoing',
					},
					{},
					{},
					true
				)
			)
		} else {
			yield put(
				MainRouteDuc.creators.redirect(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
					{
						rootModule,
						documentReference: getIn(data, [
							'events',
							0,
							'data',
							'payload',
							'docID',
						]),
					},
					{
						forceScrolltoTop: true,
					}
				)
			)
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false, false))
	} finally {
		yield put(TradeDocDuc.creators.documentLoading(false, false))
	}
}

function* createEntityAttachment(action) {
	try {
		const {
			rootModule,
			entityID,
			receivingParty,
			files,
			toastMessage,
			currentOrgID,
			attachType,
			paymentMeta,
		} = action

		yield put(AppDuc.creators.showGlobalLoader('attach-file'))

		const getEvent = () => {
			let event = 'create-attachments'
			if (attachType === 'paymentReportUpdate') {
				event = 'update-attachments'
			} else if (attachType === 'paymentReportDelete') {
				event = 'delete-attachments'
			}

			return event
		}

		const attachmentDetails = {
			events: [
				{
					data: {
						payload: {
							docID: entityID,
							docType: rootModule,
							receivingParty,
							...(attachType &&
							(attachType === 'paymentReport' ||
								attachType === 'paymentReportUpdate' ||
								attachType === 'paymentReportDelete')
								? {
										meta: {
											attachmentType: 'payment-slip',
											paymentSlip:
												[paymentMeta?.paymentSlip] ||
												{},
										},
								  }
								: {
										attachments: [
											{
												access: [files[0].access],
												meta: {
													createdby:
														files[0].meta.createdBy,
													fullurl:
														files[0].meta.fullURL,
													name: files[0].meta.name,
													other: files[0].meta.other,
												},
												id: files[0]?.id,
											},
										],
										meta: {
											attachmentType: 'attachment',
										},
								  }),
						},
					},
					event: getEvent(),
				},
			],
			tenantID: currentOrgID,
		}

		const requestUrl = `${getCoreEndPoint()}document/events`
		const options = {
			method: 'POST',
			body: JSON.stringify(attachmentDetails),
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.redirect(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: entityID,
				},
				{
					forceScrolltoTop: true,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('attach-file'))
	}
}

function* updateEntityAttachment(action) {
	try {
		const {
			rootModule,
			attachmentID,
			entityID,
			files,
			toastMessage,
			attachmentType,
			payloadMeta,
		} = action

		const attachmentDetails =
			attachmentType === 'paymentReport'
				? {
						entityID,
						file: files,
						meta: payloadMeta,
						type: 'user-input',
				  }
				: {
						entityID,
						file: files,
				  }

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(attachmentDetails),
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* createWBSlip(action) {
	try {
		yield createWeighBridgeSlip(action)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deleteEntityAttachment(action) {
	try {
		const { rootModule, attachmentID, entityID, toastMessage } = action

		const requestUrl = `${getCoreEndPoint()}entities/${entityID}/attachments/${attachmentID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)

		const { isMobile, isTablet } = yield select(AppDuc.selectors.detection)

		Toast({
			type: 'success',
			message: toastMessage,
			isMobile: isMobile || isTablet,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
				{
					rootModule,
					documentReference: entityID,
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchTimelineData(action) {
	try {
		const { tradeId } = action
		const requestUrl = `${getCoreEndPoint()}entities/tradeid/${tradeId}/states`
		const options = {
			method: 'GET',
		}
		const response = yield call(request, requestUrl, options)

		// update the document status in store
		let userString = ''
		let orgString = ''
		if (response && response.data && response.data.length > 0) {
			const userArray = []
			const orgArray = []
			response.data.forEach(item => {
				if (item && item.userID && item.userID.includes('@')) {
					const user = item.userID.split('@')
					userArray.push(user[0])
				} else {
					userArray.push(item.userID)
				}

				orgArray.push(item && item.createdBy)
			})
			if (userArray.length > 1) {
				userString = String(userArray)
				orgString = String(orgArray)
			} else if (userArray.length === 1) {
				userString = userArray[0] && userArray[0].toString()
				orgString = orgArray[0] && orgArray[0].toString()
			}
		}
		const userListUrl = `${getIAMEndPoint()}clients/users/list/no-pagination?orgIDs=${orgString}&userIDs=${userString}`
		const userResponse = yield call(request, userListUrl, options)
		const finalResponse = []
		response.data.forEach(item => {
			const user = item.userID.split('@')
			const data =
				userResponse &&
				userResponse.data.find(key => key.id === user[0])
			finalResponse.push({ ...item, userName: data ? data.fullName : '' })
		})

		yield put(TradeDocDuc.creators.setTimelineData(finalResponse))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* addNewRemark(action) {
	try {
		const { remark, docID, docType } = action
		const requestUrl = `${getCoreEndPoint()}document/${docType}/${docID}/remarks`

		yield put(TradeDocDuc.creators.documentLoading(true))

		const details = [
			{
				comment: remark,
			},
		]
		const options = {
			method: 'PUT',
			body: JSON.stringify(details),
		}
		const { data = [] } = yield CallWithRefreshCheck(requestUrl, options)

		// update the document status in store
		yield put(TradeDocDuc.creators.setRemarks(data))
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (e) {
		logger.log(e)
		const { message = 'Unable to update remark. Please try again.' } = e
		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
		yield put(TradeDocDuc.creators.documentLoading(false))
	}
}

const transformReportForState = (response = [], orgList = []) => {
	const responseArray = response.map(res => {
		const baseSchema = {
			issuedTo:
				orgList.filter(
					obj => obj.id === `${getIn(res, ['receivingPartyID'])}`
				).length !== 0
					? orgList.filter(
							obj =>
								obj.id === `${getIn(res, ['receivingPartyID'])}`
					  )[0].name
					: '---',
			orgCategory:
				orgList.filter(
					obj => obj.id === `${getIn(res, ['receivingPartyID'])}`
				).length !== 0
					? orgList.filter(
							obj =>
								obj.id === `${getIn(res, ['receivingPartyID'])}`
					  )[0].categories[0].name
					: '---',

			transaction: [
				{ date: getDateByFormat(getIn(res, ['updatedAt'])) || '---' },
				{ contract: '---' },
				{ doNumber: getIn(res, ['number']) || '---' },
				{ ticket: '---' },
			],
			product: [
				{
					productName:
						`${getIn(res, ['products', 0, 'name'])}` || '---',
				},

				{
					quantity:
						`${getIn(res, ['products', 0, 'quantity'])} ${getIn(
							res,
							['products', 0, 'uom']
						)} ` || '---',
				},
				{ binNumber: '---' },
				{ blockNo: '---' },
				{ fieldNo: '---' },
			],
			transporter: [
				{
					driverName:
						getIn(res, ['meta', 'transporter', 'driverName']) ||
						'---',
				},
				{
					vehicleNumber:
						getIn(res, ['meta', 'transporter', 'vehicleNumber']) ||
						'---',
				},
				{
					vehicleType: '---',
				},
				{
					dispatchTime:
						getDateByFormat(getIn(res, ['createdAt'])) || '---',
				},
				{
					deliveredTime:
						getDateByFormat(getIn(res, ['updatedAt'])) || '---',
				},
			],
		}

		return baseSchema
	})

	return {
		baseSchema: responseArray,
	}
}

function* appendAuditReport(action) {
	try {
		const { locationState = {}, skipGlobalLoader } = action
		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('audit-reports'))
		const { query } = locationState
		const existingQueryFromUrl = query || {}
		let backendTargetQuery = {} // this would go to backend api call

		const paginationQuery = {
			activeIndex: existingQueryFromUrl.activeIndex
				? existingQueryFromUrl.activeIndex
				: 0,
			limit: existingQueryFromUrl.limit,
			nextIndex: existingQueryFromUrl.nextIndex,
		}
		// prepare backend query based on the pagination factors
		// using activeIndex to set limit
		if (paginationQuery.limit) {
			backendTargetQuery.limit =
				paginationQuery.limit * (1 + paginationQuery.activeIndex)
		}
		if (paginationQuery.activeIndex > 0 && paginationQuery.nextIndex) {
			backendTargetQuery.startID = paginationQuery.nextIndex
		}

		// prepare the filter query
		const filterQueries =
			omit(existingQueryFromUrl, [
				'sort',
				'q',
				'activeIndex',
				'limit',
				'nextIndex',
			]) || {}
		if (!isEmptyObject(filterQueries)) {
			// form the backend queries form the object
			backendTargetQuery = merge(
				backendTargetQuery,
				transformFilterStringsToBEQueries(filterQueries)
			)
		}

		const __query = {
			...backendTargetQuery,
		}

		// need to add the status of delivery order to 'delivered'
		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&${querySerializer.stringify(
			{
				...__query,
			}
		)}`

		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []
		const serverPaginationQuery = {
			activeIndex: paginationQuery.activeIndex // &&
				? // We should have this check so the sequence of pagination is right.
				  // getIn(response, ['startID']) === backendTargetQuery.startID
				  paginationQuery.activeIndex
				: 0,
			limit: Math.min(
				getIn(origResponse, ['data', 'pageSize']) || PAGINATION_LIMIT
			),
			nextIndex: getIn(origResponse, ['data', 'prevStartID']),
		} // extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setAuditPaginationEntries(
				serverPaginationQuery.activeIndex,
				getIn(origResponse, ['data', 'pageSize']),
				getIn(origResponse, ['data', 'total']),
				getIn(origResponse, ['data', 'nextStartID']),
				getIn(origResponse, ['data', 'prevStartID'])
			)
		)
		// extract filter queries
		const { stateTree } = extractFilterQueries(response)
		yield put(TradeDocDuc.creators.setActiveAuditFilters(stateTree))

		// get all orgIDs
		const orgIDList = response.list.map(org => org.receivingPartyID)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations?limit=100&id=${orgIDList.join(
			','
		)}`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)
		const orgDetails = getIn(orgData, ['list'])

		const { baseSchema } = transformReportForState(
			response.list,
			orgDetails
		)

		// successfully done
		yield put(TradeDocDuc.creators.fetchAuditReportSuccess(baseSchema))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports'))
	}
}

function* fetchAuditReport(action) {
	try {
		yield put(TradeDocDuc.creators.flushAuditReport())
		const { locationState = {} } = action
		// if (!skipGlobalLoader)
		yield put(AppDuc.creators.showGlobalLoader('audit-reports'))

		const { query } = locationState

		const existingQueryFromUrl = query || {}
		let backendTargetQuery = {} // this would go to backend api call
		backendTargetQuery = merge(
			backendTargetQuery,
			transformFilterStringsToBEQueries(existingQueryFromUrl)
		)
		const __query = {
			...backendTargetQuery,
		}
		const requestUrl = `${getCoreEndPoint()}entities?type=delivery-order&${querySerializer.stringify(
			{
				...__query,
			}
		)}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const response = getIn(origResponse, ['data']) || []

		// get all orgIDs
		const orgIDList = response.list.map(org => org.receivingPartyID)

		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations?id=${orgIDList.join(
			','
		)}`
		const { data: orgData = {} } = yield CallWithRefreshCheck(orgDetailsUrl)
		const orgDetails = getIn(orgData, ['list'])

		const { baseSchema } = transformReportForState(
			response.list,
			orgDetails
		)

		const certificationsURL = `${getIAMEndPoint()}clients/organizations/-/documents`
		const certResponse = yield CallWithRefreshCheck(certificationsURL)

		const certificateList = (
			getIn(certResponse, ['data', 'list']) || []
		).filter(cert => getIn(cert, ['type']) === 'certificate')

		// extract pagination queries from response
		yield put(
			TradeDocDuc.creators.setAuditPaginationEntries(
				0, // ActiveIndex
				getIn(origResponse, ['data', 'pageSize']),
				getIn(origResponse, ['data', 'total']),
				getIn(origResponse, ['data', 'nextStartID']),
				getIn(origResponse, ['data', 'prevStartID'])
			)
		)

		// extract filter queries
		const { stateTree } = extractFilterQueries(response)

		yield put(TradeDocDuc.creators.setActiveAuditFilters(stateTree))

		// successfully done
		yield put(TradeDocDuc.creators.fetchAuditReportSuccess(baseSchema))
		yield put(TradeDocDuc.creators.setCertificateDetails(certificateList))
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports'))
	}
}

function* saveAuditReportAsPDF(action) {
	try {
		yield put(TradeDocDuc.creators.flushAuditReport())

		const { filterValue, skipGlobalLoader } = action

		if (!skipGlobalLoader)
			yield put(
				AppDuc.creators.showGlobalLoader('audit-reports-download')
			)

		const requestUrl = `${getPlantationEndPoint()}reports/pdf?startDate=${
			filterValue.startDate
		}&endDate=${filterValue.endDate}`

		const temp = document.createElement('a')
		temp.setAttribute('download', 'auditreport.pdf')
		temp.setAttribute('href', requestUrl)
		temp.target = '_blank'
		document.body.appendChild(temp)
		temp.click()
		document.body.removeChild(temp)
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('audit-reports-download'))
	}
}

function* fetchDealerGeneralReports(action) {
	try {
		const { filters } = action
		const { startDate, endDate } = filters
		const requestUrl = `${getPlantationEndPoint()}reports/ffbdealergeneral?startDate=${startDate}&endDate=${endDate}`
		yield put(TradeDocDuc.creators.documentLoading(true))
		const { data } = yield CallWithRefreshCheck(requestUrl)
		yield put(TradeDocDuc.creators.setDealerGeneralReports(data))
		yield put(TradeDocDuc.creators.documentLoading(false))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* searchEntityRef(action) {
	try {
		const { query, docType } = action

		const requestUrl = `${getCoreEndPoint()}documentSearch?docnumber=${query}`

		if (query.length < 3) {
			yield put(
				TradeDocDuc.creators.setEntityRefDetails({
					results: { [docType]: [] },
					refMessage: {
						[docType]:
							'expected atleast 3 characters in search query',
					},
				})
			)

			return
		}

		const response = yield CallWithRefreshCheck(requestUrl)

		const list = getIn(response, ['data']) || []

		yield put(
			TradeDocDuc.creators.setEntityRefDetails({
				results: { [docType]: list },
				refMessage: {
					[docType]:
						list.length === 0 ? 'No Entity Reference found' : '',
				},
			})
		)
	} catch (err) {
		logger.log(err)
	}
}

function* deleteDraftEntity(action) {
	try {
		const { entityID, toastMessage } = action
		const requestUrl = `${getPlantationEndPoint()}entities/${entityID}/draft`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: toastMessage,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'outgoing',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchProductDetailsBasedOnSupplyChainModel(action) {
	try {
		const {
			productID,
			supplyChainModel,
			certificationType,
			reservedFor,
			actor,
			prodList,
		} = action
		yield put(TradeDocDuc.creators.setStorageTankLoadingStatus(true))

		const inventoryType =
			actor.startsWith('palmoil_trader') ||
			actor.startsWith('palmoil_manufacturer')
				? 'incoming'
				: 'outgoing'

		let requestUrl = `${getCoreEndPoint()}clients/organizations/-/products/${productID}/
		stats?supplyChainModel=${supplyChainModel}&inventoryType=${inventoryType}&certification=${certificationType}`

		if (
			!actor.startsWith('palmoil_trader') &&
			!actor.startsWith('palmoil_manufacturer')
		)
			requestUrl += `&reservedFor=${reservedFor}`

		const { data } = yield CallWithRefreshCheck(requestUrl)
		const finalList =
			data && data.list && data.list.length > 0 ? data.list : false

		if (prodList && prodList.length > 0) {
			let newArray = []
			if (data && data.list && data.list.length > 0) {
				newArray = prodList.filter(item => {
					return !data.list.some(ele => {
						return item.storageUnitID === ele.storageUnitID
					})
				})
			} else {
				newArray = prodList
			}

			if (newArray && newArray.length > 0) {
				newArray.forEach(item => {
					const newProd = {
						availableQty: 0,
						certification: item.certification,
						inventoryType: [item.inventoryType],
						productID: item.id,
						storageUnitID: item.storageUnitID,
						supplyChainModel: [item.supplyChainModel],
						updatedAt: '',
					}
					finalList.push(newProd)
				})
			}
		}

		yield put(
			TradeDocDuc.creators.setDetailsBasedOnSupplyChainModel(finalList)
		)
	} catch (err) {
		logger.log(err)
	} finally {
		yield put(TradeDocDuc.creators.setStorageTankLoadingStatus(false))
	}
}

function* checkIfSupplyChainIsEnforced() {
	try {
		yield put(AppDuc.creators.showGlobalLoader('check-if-sc-enforced'))
		const orgID = yield getOrgIDFromLoggedUser()
		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield CallWithRefreshCheck(requestUrl, options)
		const status = getIn(data, ['meta', 'enforceSupplyChainModel'])
		yield put(TradeDocDuc.creators.isSupplyChainModelEnforced(status))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-company-info'))
	}
}

function* fetchMirrorNodeData(action) {
	try {
		const { topicID, topicSequenceNumber } = action
		yield put(AppDuc.creators.showGlobalLoader('mirror-node-data'))
		const mirrorUrl = `${getMirrorNodeBaseUrl()}api/v1/topics/${topicID}/messages/${topicSequenceNumber}`
		const options = {
			method: 'GET',
			credentials: 'same-origin',
		}
		const mirrorData = yield call(request, mirrorUrl, options)

		if (
			mirrorData &&
			getIn(mirrorData, [
				'chunk_info',
				'initial_transaction_id',
				'transaction_valid_start',
			]) &&
			mirrorData.consensus_timestamp &&
			mirrorData.payer_account_id
		) {
			const validSplit = getIn(mirrorData, [
				'chunk_info',
				'initial_transaction_id',
				'transaction_valid_start',
			]).split('.')
			const validStartId = validSplit.join('-')

			const explorerUrl = `${getExplorerBaseurl()}transaction/${
				mirrorData.consensus_timestamp
			}?tid=${mirrorData.payer_account_id}-${validStartId}`

			window.open(explorerUrl, '_blank')
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('mirror-node-data'))
	}
}

function* uploadDocumentFile(action) {
	try {
		const { uploadPayload, successMsg } = action

		const requestUrl = `${getCoreEndPoint()}fileentities`

		const options = {
			method: 'POST',
			body: JSON.stringify(uploadPayload),
		}

		yield call(request, requestUrl, options)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'success',
				message: successMsg,
			})
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('upload-document-file'))
	}
}

function* getBankStatementDetails() {
	try {
		yield put(
			AppDuc.creators.showGlobalLoader('get-bank-statement-details')
		)
		const queryObj = yield select(
			TradeDocDuc.selectors.getBankStatementQuery
		)
		let query = ''
		if (!isEmptyObject(queryObj)) {
			query = `?${Object.entries(queryObj)
				.map(
					([key, value]) =>
						`${encodeURIComponent(key)}=${encodeURIComponent(
							value
						)}`
				)
				.join('&')}`
		}
		const url = `${getCoreEndPoint()}bankstatements${query}`
		const options = {
			method: 'GET',
		}
		const bankDetails = yield call(request, url, options)
		yield put(
			TradeDocDuc.creators.setBankStatementDetails(
				bankDetails?.data?.list
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(
			AppDuc.creators.hideGlobalLoader('get-bank-statement-details')
		)
	}
}

function* addNewBankStatement(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('add-bank-statement'))
		const { values, successToast } = action
		const requestUrl = `${getCoreEndPoint()}bankstatements`
		const options = {
			method: 'POST',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'bankStatements',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('add-bank-statement'))
	}
}

function* deleteBankStatement(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('delete-bank-statement'))
		const { orgID, statementID, successToast } = action

		const requestUrl = `${getCoreEndPoint()}bankstatements/organizations/${orgID}/id/${statementID}`
		const options = {
			method: 'DELETE',
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'bankStatements',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('delete-bank-statement'))
	}
}

function* updateBankStatement(action) {
	try {
		yield put(AppDuc.creators.showGlobalLoader('update-bank-statement'))
		const { values, id, successToast } = action
		const requestUrl = `${getCoreEndPoint()}bankstatements/organizations/${
			values?.organizationID
		}/id/${id}`
		const options = {
			method: 'PUT',
			body: JSON.stringify(values),
		}
		yield call(request, requestUrl, options)
		yield Toast({
			type: 'success',
			message: successToast,
		})
		yield put(
			MainRouteDuc.creators.switchPage(
				MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
				{
					rootModule: 'bankStatements',
				}
			)
		)
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('update-bank-statement'))
	}
}

function* fetchGlobalOrganizations() {
	try {
		const requestUrl = `${getIAMEndPoint()}clients/organizations/list-no-pagination`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)
		yield put(TradeDocDuc.creators.setGlobalOrganizations(data.list))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* TradeDocManagerSaga() {
	try {
		yield all([
			takeLatest(
				TradeDocDuc.creators.fetchDashboardDocuments().type,
				fetchUserDocuments
			),
			takeLatest(
				TradeDocDuc.creators.fetchGeneralDocuments().type,
				fetchGeneralDocuments
			),
			takeLatest(
				TradeDocDuc.creators.changeDocumentStatus().type,
				changeDocumentStatus
			),
			takeLatest(
				TradeDocDuc.creators.fetchDashboardStats().type,
				fetchDashboardStatuses
			),
			takeLatest(
				TradeDocDuc.creators.fetchDocumentListing().type,
				fetchDocumentListing
			),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentStep().type,
				initiateDocumentStep
			),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentView().type,
				initiateDocumentView
			),
			takeLatest(
				TradeDocDuc.creators.initiateGeneralDocumentView().type,
				initiateGeneralDocumentView
			),
			takeLatest(
				TradeDocDuc.creators.createTransaction().type,
				createTransaction
			),
			takeLatest(
				TradeDocDuc.creators.saveAsDraftTransaction().type,
				saveAsDraftTransaction
			),
			takeLatest(
				TradeDocDuc.creators.updateTransaction().type,
				updateTransaction
			),
			takeLatest(
				TradeDocDuc.creators.updateTransactionRemarks().type,
				updateTransactionRemarks
			),
			takeLatest(
				TradeDocDuc.creators.searchAssociatedDocs().type,
				searchAssociatedDocs
			),
			takeLatest(
				TradeDocDuc.creators.fetchDocumentAttachments().type,
				fetchDocumentAttachments
			),
			takeLatest(
				TradeDocDuc.creators.fetchActiveDocument().type,
				fetchActiveDocument
			),
			takeLatest(TradeDocDuc.creators.fetchAProduct().type, fetchProduct),
			takeLatest(
				TradeDocDuc.creators.initiateDocumentSave().type,
				saveDocument
			),
			takeLatest(
				TradeDocDuc.creators.fetchParentSource().type,
				fetchParentSource
			),
			takeLatest(TradeDocDuc.creators.addNewRemark().type, addNewRemark),
			takeLatest(
				TradeDocDuc.creators.fetchTimelineData().type,
				fetchTimelineData
			),
			takeLatest(
				TradeDocDuc.creators.getDashboardActiveTDMRules().type,
				handleActiveTDMRulesFetch
			),
			takeLatest(
				TradeDocDuc.creators.fetchAuditReport().type,
				fetchAuditReport
			),
			takeLatest(
				TradeDocDuc.creators.appendAuditReport().type,
				appendAuditReport
			),
			takeLatest(
				TradeDocDuc.creators.saveAuditReportAsPDF().type,
				saveAuditReportAsPDF
			),
			takeLatest(
				TradeDocDuc.creators.fetchDealerGeneralReports().type,
				fetchDealerGeneralReports
			),
			takeLatest(
				TradeDocDuc.creators.createEntityAttachment().type,
				createEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.updateEntityAttachment().type,
				updateEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.deleteEntityAttachment().type,
				deleteEntityAttachment
			),
			takeLatest(
				TradeDocDuc.creators.deleteDraftEntity().type,
				deleteDraftEntity
			),
			takeLatest(
				TradeDocDuc.creators.fetchProductDetailsBasedOnSupplyChainModel()
					.type,
				fetchProductDetailsBasedOnSupplyChainModel
			),
			takeLatest(
				TradeDocDuc.creators.searchEntityRef().type,
				searchEntityRef
			),
			takeLatest(
				TradeDocDuc.creators.getHederaMessages().type,
				getHederaMessages
			),
			takeLatest(
				TradeDocDuc.creators.checkIfSupplyChainIsEnforced().type,
				checkIfSupplyChainIsEnforced
			),
			takeLatest(TradeDocDuc.creators.createWBSlip().type, createWBSlip),
			takeLatest(
				TradeDocDuc.creators.uploadDocumentFile().type,
				uploadDocumentFile
			),
			takeLatest(
				TradeDocDuc.creators.fetchMirrorNodeData().type,
				fetchMirrorNodeData
			),
			takeLatest(
				TradeDocDuc.creators.getBankStatementDetails().type,
				getBankStatementDetails
			),
			takeLatest(
				TradeDocDuc.creators.addNewBankStatement().type,
				addNewBankStatement
			),
			takeLatest(
				TradeDocDuc.creators.deleteBankStatement().type,
				deleteBankStatement
			),
			takeLatest(
				TradeDocDuc.creators.updateBankStatement().type,
				updateBankStatement
			),
			takeLatest(
				TradeDocDuc.creators.fetchGlobalOrganizations().type,
				fetchGlobalOrganizations
			),
			takeLatest(
				TradeDocDuc.creators.getChatMessagesList().type,
				getChatMessagesList
			),
		])
	} catch (e) {
		logger.error(e)
	}
}
