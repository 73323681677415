import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-multi-email/dist/style.css'
import { useDispatch, useSelector } from 'react-redux'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Button } from 'ui-lib/components/Button'
import { Card } from 'ui-lib/components/Card'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Input, SuggestionsInput } from 'ui-lib/components/Input'
import { Label } from 'ui-lib/components/Label'
import { MultiSelectWithSearch } from 'ui-lib/components/Select'
import { TextArea } from 'ui-lib/components/TextArea'
import { SmallText } from 'ui-lib/components/Typography'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { isEmptyObject, useDebouncedCallback } from 'ui-tdm-app/utils/helpers'
import * as yup from 'yup'
import { filter } from 'lodash'
import styled from 'styled-components'
import { Title } from './Title'
import FileUploadComponent from './upload'

const MAX_TOTAL_SIZE_MB = 250

const AddIcon = styled.img(() => ({
	alignSelf: 'center',
	height: '22px',
	width: '22px',
	margin: '0px 2px',
}))

const CreateGeneralDocument = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const location = useSelector(TradeDocDuc.selectors.location)
	const { payload } = location
	const { action, rootModule } = payload
	const loggedInUserProfile = useSelector(AuthDuc.selectors.getUserProfile)
	const { fullName, orgRole } = loggedInUserProfile
	const partnerList = useSelector(AuthDuc.selectors.getAvailablePartners)
	const currentOrgDetails = useSelector(
		AuthDuc.selectors.getCurrentOrganization
	)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getGeneralDocumentActiveRecord
	)
	const { results = [], refMessage } = useSelector(
		TradeDocDuc.selectors.getAssociatedList
	)

	const [initialValues, setInitialValues] = useState({
		docType: 'doc-transaction',
		initiatingParty: {
			orgID: getIn(currentOrgDetails, ['id']),
			name: getIn(currentOrgDetails, ['name']),
		},
		receivingParty: {},
		files: [],
		meta: {
			issueDate: new Date(),
			receiverOrgIDs: [],
			receiverEmailIDs: [],
			authorization: {
				reportedBy: fullName,
				reportedByRole: orgRole,
				authorisedSignatory: fullName,
			},
		},
	})
	const validationSchema = yup.object().shape({
		initiatingParty: yup.object().shape({
			orgID: yup.string().required(),
			name: yup.string().required(),
		}),
		transactionNumber: yup
			.string()
			.required(t('generalDocument.transactionNumberError')),
		files: yup.array().test(
			'valid-files',
			t('generalDocument.onefileError'),
			// eslint-disable-next-line func-names
			function(files) {
				// Use timm's getIn to access file.status and filter out files with 'deleted' or 'removed' status

				const validFiles = filter(files, file => {
					const status = getIn(file, ['status'])
					const id = getIn(file, ['id'])

					return (
						status !== 'deleted' &&
						status !== 'removed' &&
						status !== 'failed' &&
						typeof id === 'string' &&
						id.trim() !== ''
					)
				})

				const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024

				const totalSize = validFiles.reduce((total, file) => {
					const fileSize = getIn(file, ['sizeBytes'])

					return total + (fileSize || 0)
				}, 0)

				if (totalSize > MAX_TOTAL_SIZE_BYTES) {
					// eslint-disable-next-line react/no-this-in-sfc
					return this.createError({
						message: t('generalDocument.sizeExceededError', {
							size: MAX_TOTAL_SIZE_MB,
						}),
					})
				}

				return validFiles.length > 0
			}
		),
		meta: yup.object().shape({
			receiverOrgIDs: yup.array().of(yup.string()),
			receiverEmailIDs: yup
				.array()
				.of(yup.string().email(t('generalDocument.invalidEmail'))),
			authorization: yup.object().shape({
				reportedBy: yup
					.string()
					.required(t('generalDocument.reportedByError')),
				reportedByRole: yup
					.string()
					.required(t('generalDocument.roleError')),
				authorisedSignatory: yup
					.string()
					.required(t('generalDocument.authoriseError')),
			}),
		}),
	})

	const partners = []

	Object.values(partnerList).forEach(list => {
		partners.push({
			id: list.id,
			name: list.name,
			primaryAddress: list.primaryAddress,
		})
	})

	const {
		handleSubmit,
		values,
		submitForm,
		handleBlur,
		setFieldValue,
		setTouched,
		errors,
		isSubmitting,
	} = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (_values, { setSubmitting }) => {
			// Remove failed files
			const updatedValues = {
				..._values,
				files: _values.files.filter(file => file.status !== 'failed'),
			}
			dispatch(
				TradeDocDuc.creators.setActiveGeneralDocuments(updatedValues)
			)

			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER$GENERALACTION,
					{
						rootModule: 'general-document',
						action:
							action === 'edit-doc'
								? 'edit-preview'
								: 'preview-document',
					}
				)
			)
			setSubmitting(false)
		},
	})

	const handleSearch = useDebouncedCallback(value => {
		const selectOrgs = values.meta.receiverOrgIDs.toString()
		const orgID = `${getIn(currentOrgDetails, ['id'])}${
			selectOrgs ? `,${selectOrgs}` : ''
		}`
		dispatch(TradeDocDuc.creators.searchAssociatedDocs(value, orgID))
	}, 800)

	useEffect(() => {
		if (Object.keys(activeDocument).length > 0) {
			setInitialValues(activeDocument)
			const referenceName = getIn(activeDocument, [
				'meta',
				'referenceName',
			])
			if (referenceName) {
				handleSearch(referenceName)
			}
		}
		dispatch(TradeDocDuc.creators.setAssociatedDocs({ results: [] }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [action, activeDocument, dispatch])

	const breadCrumbs = [
		{
			label: t('breadcrumb.home'),
			name: 'home',
			isActive: true,
		},
		{
			label: t('breadcrumb.tradeDocManager'),
			name: 'general-document',
			isActive: true,
		},
		{
			label: t('generalDocument.shareNewDocument'),
			name: 'general-document',
			isActive: false,
		},
	]

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
		if (target === 'trade-doc-dashboard') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule: 'incoming',
					}
				)
			)
		}
	}

	const saveAsDraft = () => {
		dispatch(
			TradeDocDuc.creators.saveAsDraftTransaction(
				values,
				t('common.updateSuccess')
			)
		)
	}

	const entityrefList = () => {
		const list = []
		if (results) {
			results.forEach(item => {
				list.push({
					label: item.transactionNumber,
					value: item.transactionNumber,
					docID: item.docID,
				})
			})
		}

		return list
	}

	const disableCTA =
		!isEmptyObject(errors) || values?.meta?.receiverOrgIDs?.length === 0

	const receiverOrgIDError = getIn(errors, ['meta', 'receiverOrgIDs'])
	const filesError = getIn(errors, ['files'])
	const receiverOrgIDs = getIn(values, ['meta', 'receiverOrgIDs']) || []
	const files = getIn(values, ['files']) || []

	const isDraftDisabled =
		receiverOrgIDError !== undefined ||
		receiverOrgIDs.length === 0 ||
		filesError !== undefined ||
		files.length === 0

	const isEdit = action === 'edit-doc'
	const isDraft = action === 'draft-edit'

	const selectedOrg = isEdit
		? [getIn(values, ['receivingParty', 'orgID']) || '']
		: values?.meta?.receiverOrgIDs || []

	return (
		<>
			<Box style={{ padding: '10px 5px' }}>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box>
				<Title
					title={t('generalDocument.shareNewDocument')}
					icon={LeftArrowIcon}
					onClick={() => {
						dispatch(
							MainRouteDuc.creators.switchPage(
								MainRouteDuc.types
									.TRADE_DOCUMENT_MANAGER$GENERALACTION,
								{
									rootModule: 'general-document',
									action: 'listing',
								}
							)
						)
					}}
				/>
			</Box>
			<Box row={!isMobile} justifyContent="space-between">
				<Box
					style={{
						width: '100%',
						background: '#fff',
						padding: '22px',
						marginRight: '20px',
					}}
				>
					<form onSubmit={handleSubmit}>
						<Label
							style={{
								width: '94%',
								color: theme.color.accent2,
								fontSize: '18px',
								fontWeight: 500,
							}}
						>
							{t('generalDocument.receipients')}
						</Label>

						<Box style={{ width: '100%' }}>
							<Box
								style={{ justifyContent: 'space-between' }}
								row
							>
								<Card
									style={{
										padding: '20px',
										position: 'relative',
										overflow: 'visible',
										background: '#FAFBFF',
										border: '1px solid #D9DEE5',
										borderRadius: '6px',
										borderTopRightRadius: '0px',
										borderBottomRightRadius: '0px',
										marginTop: '15px',
									}}
									textAlign="left"
								>
									<Box margin="0px 0px 20px 0px" width={350}>
										<Label
											style={{
												fontSize: '14px',
												color: '#242845',
												marginBottom: 8,
											}}
											required
										>
											{t('generalDocument.recipientName')}
										</Label>
										<SmallText>
											{t('generalDocument.recipientDesc')}
										</SmallText>

										<MultiSelectWithSearch
											selected={selectedOrg}
											value={selectedOrg}
											options={partners}
											onChange={value => {
												setFieldValue(
													'meta.receiverOrgIDs',
													value
												)
											}}
											returnOnlyValue
										/>
									</Box>
								</Card>
								<Card
									style={{
										padding: '20px',
										position: 'relative',
										overflow: 'visible',
										background: '#FAFBFF',
										border: '1px solid #D9DEE5',
										borderRadius: '6px',
										borderTopLeftRadius: '0px',
										borderBottomLeftRadius: '0px',
										marginTop: '15px',
									}}
									textAlign="left"
								>
									<Box margin="0px 0px 20px 0px" width={350}>
										<SmallText>
											{t('generalDocument.globalDesc')}
										</SmallText>
									</Box>
									<Box
										style={{
											maxHeight: '180px',
											overflow: 'scroll',
										}}
									>
										{(
											getIn(values, [
												'meta',
												'receiverEmailIDs',
											]) || []
										).map((email, index) => (
											<div
												key={index}
												style={{
													display: 'flex',
													alignItems: 'baseline',
													marginBottom: '8px',
													width: 330,
												}}
											>
												<Input
													name={`receiverEmailID-${index}`}
													type="text"
													value={email}
													onChange={e => {
														const updatedEmailIDs = [
															...values.meta
																.receiverEmailIDs,
														]
														updatedEmailIDs[index] =
															e.target.value
														setFieldValue(
															'meta.receiverEmailIDs',
															updatedEmailIDs
														)
													}}
													error={
														getIn(errors, [
															'meta',
															'receiverEmailIDs',
															index,
														]) || ''
													}
													// disabled={isEdit}
													extendStyles={{
														border: 'none',
														borderRadius: '0px',
														borderBottom: `1px solid ${theme.color.blue8}`,
														flex: 1,
													}}
												/>
												<button
													type="button"
													onClick={() => {
														const updatedEmailIDs = values.meta.receiverEmailIDs.filter(
															(_, i) =>
																i !== index
														)
														setFieldValue(
															'meta.receiverEmailIDs',
															updatedEmailIDs
														)
													}}
													style={{
														background: 'none',
														border: 'none',
														color: '#FF0000',
														cursor: 'pointer',
														marginLeft: '8px',
													}}
												>
													&minus;{' '}
													{/* Using a minus sign as a text icon */}
												</button>
											</div>
										))}
									</Box>
									<Label
										onClick={() => {
											const updatedEmailID = [
												...values.meta.receiverEmailIDs,
												'',
											]
											setFieldValue(
												'meta.receiverEmailIDs',
												updatedEmailID
											)
										}}
										style={{
											fontSize: '14px',
											color: '#1683DF',
											marginBottom: 8,
											cursor: 'pointer',
										}}
									>
										<AddIcon
											src="/images/addIcon.png"
											alt="addIcon"
										/>
										{t('generalDocument.addEmailAddress')}
									</Label>
								</Card>
							</Box>
						</Box>

						<Spacer size={32} />
						<Card
							style={{
								padding: '20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
							}}
							textAlign="left"
						>
							<Box justifyContent="space-between" flex row>
								<Box width={270}>
									<Label
										style={{
											fontSize: '14px',
											color: '#242845',
											marginBottom: 8,
										}}
										required
									>
										{t('generalDocument.date')}
									</Label>
									<DatePicker
										name="startDate"
										placeholder={t(
											'tdmDocumentListing.choose'
										)}
										hideError
										value={new Date()}
										maxDate={new Date()}
										onChange={value => {
											setFieldValue(
												'meta.issueDate',
												value
											)
										}}
									/>
								</Box>
								<Box width={270}>
									<Label
										style={{
											fontSize: '14px',
											color: '#242845',
											marginBottom: 8,
										}}
										required
									>
										{t('generalDocument.transactionNumber')}
									</Label>
									<Input
										name="transactionNumber"
										value={getIn(values, [
											'transactionNumber',
										])}
										onChange={e => {
											setFieldValue(
												'transactionNumber',
												e.target.value
											)
										}}
										extendStyles={{
											border: 'none',
											borderRadius: '0px',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
									/>
								</Box>
								<Box width={270}>
									<Label
										style={{
											fontSize: '14px',
											color: '#242845',
											marginBottom: 8,
										}}
									>
										{t(
											'generalDocument.associatedDocument'
										)}
									</Label>
									<SuggestionsInput
										value={getIn(values, [
											'meta',
											'referenceName',
										])}
										placeholder={`${t(
											'schedulerListing.search'
										)} ${t('tdmDocumentListing.one')}`}
										onChange={e =>
											handleSearch(e.target.value)
										}
										suggestions={entityrefList()}
										onSelect={selected => {
											setFieldValue(
												'meta.referenceName',
												selected.value
											)
											setFieldValue(
												'meta.referenceValue',
												selected.docID
											)
										}}
										extendStyles={{
											width: '445px',
											border: 'none',
											borderBottom: `1px solid ${theme.color.blue8}`,
										}}
										error={refMessage}
									/>
								</Box>
							</Box>
						</Card>
						<FileUploadComponent
							files={values?.files}
							setFiles={file => {
								setTouched('files', true)
								setFieldValue('files', file)
							}}
							isEdit={isEdit}
						/>
						{errors.files && (
							<div style={{ paddingTop: 10, color: 'red' }}>
								{errors.files}
							</div>
						)}
						<Spacer size={32} />
						<Label
							style={{
								width: '94%',
								color: theme.color.accent2,
								fontSize: '18px',
								fontWeight: 500,
							}}
						>
							{t('generalDocument.authorization')}
						</Label>
						<Card
							style={{
								padding: '20px',
								position: 'relative',
								overflow: 'visible',
								background: '#FAFBFF',
								border: '1px solid #D9DEE5',
								borderRadius: '6px',
								marginTop: '15px',
							}}
							textAlign="left"
						>
							<Box row={!isMobile} justifyContent="space-between">
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											required
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmDetailsEntry.createdBy')}
										</Label>
										<Input
											name="reportedBy"
											value={getIn(values, [
												'meta',
												'authorization',
												'reportedBy',
											])}
											onChange={e => {
												setFieldValue(
													[
														'meta',
														'authorization',
														'reportedBy',
													],
													e.target.value
												)
											}}
											extendStyles={{
												border: 'none',
												borderRadius: '0px',
												borderBottom: `1px solid ${theme.color.blue8}`,
												marginTop:
													rootModule ===
													'delivery-order'
														? '12px'
														: '7px',
											}}
										/>
									</Box>
								</Box>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											required
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t('tdmViewDocument.role')}
										</Label>
										<Input
											name="reportedByRole"
											value={getIn(values, [
												'meta',
												'authorization',
												'reportedByRole',
											])}
											onChange={e => {
												setFieldValue(
													[
														'meta',
														'authorization',
														'reportedByRole',
													],
													e.target.value
												)
											}}
											extendStyles={{
												border: 'none',
												borderRadius: '0px',
												borderBottom: `1px solid ${theme.color.blue8}`,
												marginTop:
													rootModule ===
													'delivery-order'
														? '12px'
														: '7px',
											}}
										/>
									</Box>
								</Box>
								<Box width={isMobile ? '100%' : '30%'}>
									<Box>
										<Label
											required
											style={{
												width: '94%',
												color: theme.color.accent2,
												fontSize: '14px',
												fontWeight: 500,
											}}
										>
											{t(
												'tdmPreviewDetails.authorisedSignatory'
											)}
										</Label>
										<Input
											name="authorisedSignatory"
											value={getIn(values, [
												'meta',
												'authorization',
												'authorisedSignatory',
											])}
											onChange={e => {
												setFieldValue(
													[
														'meta',
														'authorization',
														'authorisedSignatory',
													],
													e.target.value
												)
											}}
											extendStyles={{
												border: 'none',
												borderRadius: '0px',
												borderBottom: `1px solid ${theme.color.blue8}`,
												marginTop:
													rootModule ===
													'delivery-order'
														? '12px'
														: '7px',
											}}
										/>
									</Box>
								</Box>
							</Box>
						</Card>

						<Spacer size={32} />
						<Box>
							<Label
								style={{
									width: '94%',
									color: theme.color.accent2,
									fontSize: '18px',
									fontWeight: 500,
									marginBottom: '15px',
								}}
							>
								{t('generalDocument.remarks')}
							</Label>
							<TextArea
								placeholder={t(
									'generalDocument.addyourremarks'
								)}
								name="remarks"
								value={getIn(values, ['meta', 'remarks'] || '')}
								onBlur={handleBlur}
								onChange={e => {
									setFieldValue(
										'meta.remarks',
										e.target.value
									)
								}}
							/>
						</Box>
					</form>
				</Box>
			</Box>
			<Box row={!isMobile} justifyContent="flex-end" margin="36px 0 0">
				<Box
					direction="row"
					style={{
						flexDirection: 'row',
						marginTop: isMobile ? '20px' : '0px',
						alignItems: isMobile ? 'center' : 'normal',
					}}
				>
					{!(isDraft || isEdit) && (
						<Button
							isLoading={isSubmitting}
							label={t('generalDocument.saveAsDraft')}
							primary
							disabled={isDraftDisabled}
							onClick={() => saveAsDraft()}
							extendStyles={{ width: 212, margin: '0px 16px' }}
						/>
					)}
					<Button
						isLoading={isSubmitting}
						label={t('generalDocument.cancel')}
						primary
						onClick={() => {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.TRADE_DOCUMENT_MANAGER$GENERALACTION,
									{
										rootModule: 'general-document',
										action: 'listing',
									}
								)
							)
						}}
						extendStyles={{ width: 212, margin: '0px 16px' }}
					/>
					<Button
						isLoading={isSubmitting}
						label={t('generalDocument.previewSend')}
						primary
						disabled={disableCTA}
						onClick={() => submitForm()}
						extendStyles={{ width: 212, margin: '0px 16px' }}
					/>
				</Box>
			</Box>
		</>
	)
}

export { CreateGeneralDocument }
