import { Drop } from 'grommet'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import {
	ButtonIconWrapper,
	ButtonWithDrop,
	ButtonWithNoBorder,
} from 'ui-lib/components/Button'
import { BoxShadowWrapper } from 'ui-lib/components/Card'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { FileLists, FileUpload } from 'ui-lib/components/FileUpload'
import { Input } from 'ui-lib/components/Input'
import { Modal } from 'ui-lib/components/Modal'
import { TransparentBgSpinner } from 'ui-lib/components/Spinner/TransparentBgSpinner'
import { TabButtons } from 'ui-lib/components/TabButtons'
import { Timeline } from 'ui-lib/components/Timeline/index'
import { H4, P } from 'ui-lib/components/Typography'
import ChatIcon from 'ui-lib/icons/chat_icon.svg'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import DOIcon from 'ui-lib/icons/delivery-order.svg'
import EditIcon from 'ui-lib/icons/edit.svg'
import InvoiceIcon from 'ui-lib/icons/invoice.svg'
import backArrow from 'ui-lib/icons/left-arrow.svg'
import PlusIcon from 'ui-lib/icons/plus.svg'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { isIframeForTeamsApp } from 'ui-lib/utils/helpers'
import { Spacer } from 'ui-lib/utils/Spacer'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { MessagesDuc } from 'ui-tdm-app/modules/Messages/duc'
import { CollapsibleDocStacks } from 'ui-tdm-app/modules/TradeDocumentManager/components/CollapsibleDocStacks'
import { DataDisplayBlock } from 'ui-tdm-app/modules/TradeDocumentManager/components/DataDisplayBlock'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import {
	getStateBasedTargetActions,
	mapLabelsToStatusActions,
} from 'ui-tdm-app/modules/TradeDocumentManager/helpers'
import { WebTour } from 'ui-tdm-app/modules/WebTour'
import { WebTourDuc } from 'ui-tdm-app/modules/WebTour/duc'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { ChatModalComponentTab } from '../../../../Messages/components/ChatModalComponentTab'
import { DocumentView } from '../../../components/DocumentView'
import { RemarksConfirmation } from '../../../components/RemarksConfirmation'
import { MessagesBlock } from '../Shared/MessagesBlock'
import { NAME_ALIASES } from '../../../config'

const HeaderWrapper = styled.div`
	display: flex;
	margin-top: 24px;
	margin-bottom: 24px;
`
const Header = styled.div`
	font-size: 30px;
	font-weight: 500;
	margin-top: -6px;
	padding-left: 28px;
	color: ${theme.color.accent2};
`
const SubText = styled.div`
	padding-top: 3px;
	padding-left: 10px;
	color: ${theme.color.blue8};
`

const SubWrapper = styled.div`
	display: flex;
	cursor: pointer;
	color: ${theme.color.blue8};
`

const ViewDocument = () => {
	const dispatch = useDispatch()
	const tourModule = useSelector(WebTourDuc.selectors.activeTourModule)

	const { t } = useTranslation()
	const locationState = useSelector(TradeDocDuc.selectors.location)

	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const { id: userID } = useSelector(AuthDuc.selectors.getUserProfile)
	const activeDocument = useSelector(
		TradeDocDuc.selectors.getDocumentActiveRecord
	)

	const { initiatingParty = {}, receivingParty = {} } = activeDocument

	const { isLoading } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)
	const getCurrentOrgID = useSelector(AuthDuc.selectors.getUserOrgId)
	const usersList = useSelector(AuthDuc.selectors.getUsersData)

	const actor = useSelector(AuthDuc.selectors.getActor)[0] || []

	const attachmentDoc = useSelector(TradeDocDuc.selectors.getAttachmentDoc)
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)
	const paymentDetails =
		activeDocument?.meta?.paymentSlip?.length > 0
			? activeDocument?.meta?.paymentSlip[0]
			: {}

	const messages = useSelector(TradeDocDuc.selectors.getRemarks)

	const paymentSlipAttachments = {
		attachmentID: paymentDetails?.id,
		file: [paymentDetails?.file],
		paymentSlipData: paymentDetails,
	}

	const attachments =
		activeDocument?.attachments?.length > 0
			? activeDocument.attachments
			: []

	const [showRemarkModal, setShowRemarkModal] = useState(false)
	const [showFileUploadModal, setShowFileUploadModal] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [selectedFile, setSelectedFile] = useState({})
	const [newFileName, setNewFileName] = useState('')
	const [renameModal, setRenameModal] = useState(false)
	const [showChatBox, setShowChatBox] = useState(false)
	const [attachfile, setAttachfile] = useState({})
	const [currentAttachmentType, setCurrentAttachmentType] = useState(null)
	const [showCreateTradeDoc, setShowCreateTradeDoc] = useState(false)
	const [targetActiveStatus, setTargetActiveStatus] = useState('')
	const [isDOEditable, setIsDOEditable] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const purchaseOrderRef = useRef()
	const invoiceRef = useRef()
	const rfqRef = useRef()

	const hasInitiatedByCurrentOrg = getCurrentOrgID === initiatingParty.orgID

	const receivingOrgName = getIn(receivingParty, ['name']) || '---'
	const initiatingOrgName = getIn(initiatingParty, ['name']) || '---'

	const { payload = {}, query = {} } = locationState
	const { rootModule } = payload
	const _activeTab = useSelector(TradeDocDuc.selectors.getDocumentActiveTab)

	const chatWithName = hasInitiatedByCurrentOrg
		? receivingOrgName
		: initiatingOrgName
	const chatAssociatedList = useSelector(
		TradeDocDuc.selectors.getChatAssociatedList
	)
	const messagesList = useSelector(TradeDocDuc.selectors.getChatMessagesList)
	const documentId = getIn(activeDocument, ['docID'])
	const currentUnread =
		chatAssociatedList.length > 0 &&
		chatAssociatedList?.find(item => item.documentID === documentId)
			?.unReadCount
	const [currentUnreadCount, setCurrentUnreadCount] = useState(
		currentUnread || 0
	)

	const VIEW_TAB_CONFIG = [
		{
			name: 'original',
			label: t('tdmViewDocument.original'),
		},
		{
			name: 'chat',
			label: `${t('common.messages')} ${
				currentUnreadCount > 0 ? `(${currentUnreadCount})` : ''
			}`,
		},
		{
			name: 'associated',
			label: t('tdmViewDocument.associatedDocs'),
		},

		{
			name: 'timeline',
			label: t('tdmViewDocument.timeline'),
		},

		{
			name: 'attachments',
			label: t('tdmViewDocument.attachments'),
		},
		...(rootModule === 'invoice'
			? [
					{
						name: 'paymentReport',
						label: t('tdmViewDocument.paymentReport'),
					},
			  ]
			: ''),
	]

	const chatBoxData = data => {
		setShowChatBox(data)
	}

	const activeTabs = useMemo(() => {
		let tabs = [...VIEW_TAB_CONFIG]
		if (!activeDocument.versions) {
			tabs = tabs.filter(tab => tab.name !== 'version')
		}

		return tabs
	}, [VIEW_TAB_CONFIG, activeDocument.versions])

	const [
		originalTabKey,
		chatTabKey,
		associatedTabKey,
		timelineTabKey,
		attachmentTabKey,
		paymentReportKey,
	] = VIEW_TAB_CONFIG.map(k => k.name)
	const [activeTab, setActiveTab] = useState(_activeTab)

	useEffect(() => {
		setActiveTab(_activeTab)
	}, [_activeTab])

	const INVInitiatorSubDoc = [
		{
			label: t('tdmViewDocument.paymentReport'),
			name: 'payment-report',
		},
	]

	const getNameById = id => {
		let name = ''

		if (id === getIn(receivingParty, ['orgID'])) {
			name = getIn(receivingParty, ['name'])
		} else if (id === getIn(initiatingParty, ['orgID'])) {
			name = getIn(initiatingParty, ['name'])
		}

		return name
	}

	const typeFormat = item => {
		const firstName =
			item
				.split('-')[0]
				.charAt(0)
				.toUpperCase() + item.split('-')[0].slice(1)
		const secondName =
			item
				.split('-')[1]
				.charAt(0)
				.toUpperCase() + item.split('-')[1].slice(1)

		return `${firstName}-${secondName}`
	}

	const statusLogs = activeDocument?.statusLog?.map(status => {
		return {
			date: getDateByFormat(
				status.createdAt,
				'dd MMM yyyy hh:mm a',
				activeLocale
			),
			status: `${
				status?.docType?.includes('-')
					? typeFormat(status?.docType)
					: status?.docType?.charAt(0).toUpperCase() +
					  status?.docType?.slice(1)
			} ${status.state.charAt(0).toUpperCase() + status.state.slice(1)}`,
			statusDetail: status.organizationID
				? `by ${status.createdBy},  ${getNameById(
						status.organizationID
				  )}`
				: '',
			version: status.version === 0 ? '0' : status.version,
		}
	})

	const getBreadCrumbsList = useCallback(
		({ title }) => [
			{
				label: t('breadcrumb.home'),
				name: 'home',
				isActive: true,
			},
			{
				label: t('breadcrumb.tradeDocManager'),
				name: 'tradeDocManager',
				isActive: true,
			},
			{
				label: `${t(title)}`,
				name: 'document-view',
				isActive: false,
			},
		],
		[t]
	)

	const title = NAME_ALIASES[rootModule]
	const breadCrumbs = useMemo(() => getBreadCrumbsList({ title }), [
		getBreadCrumbsList,
		title,
	])

	const breadCrumRootModule = getIn(locationState, [
		'prev',
		'payload',
		'rootModule',
	])
		? getIn(locationState, ['prev', 'payload', 'rootModule'])
		: null

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			if (isIframeForTeamsApp()) {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
						{
							rootModule:
								breadCrumRootModule === 'incoming' ||
								breadCrumRootModule === 'outgoing' ||
								breadCrumRootModule === 'all'
									? getIn(locationState, [
											'prev',
											'payload',
											'rootModule',
									  ])
									: 'incoming',
						}
					)
				)
			} else {
				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types.DASHBOARD
					)
				)
			}
		}
		if (target === 'tradeDocManager') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.TRADE_DOCUMENT_MANAGER,
					{
						rootModule:
							breadCrumRootModule === 'incoming' ||
							breadCrumRootModule === 'outgoing' ||
							breadCrumRootModule === 'all'
								? getIn(locationState, [
										'prev',
										'payload',
										'rootModule',
								  ])
								: 'incoming',
					}
				)
			)
		}
	}

	const isOriginalTabActive = activeTab === originalTabKey
	const isChatTabActive = activeTab === chatTabKey
	const isTimelineTabActive = activeTab === timelineTabKey
	const isHistoryTabActive = activeTab === associatedTabKey
	const isAttachmentTabActive = activeTab === attachmentTabKey
	const isPaymentReportTabActive = activeTab === paymentReportKey

	const entityFiles = getIn(activeDocument, ['meta', 'files']) || []

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'finalize_remark_status') {
				const { remarks } = meta

				const documentPropsList = {
					status: targetActiveStatus,
					docID: activeDocument.docID,
					docType: activeDocument.docType,
					docNumber: activeDocument.docNumber,
					remarks,
					successMsg: t('common.statusUpdateSuccess'),
					postChange: () => {
						// retrigger the current route so new data is fetched again.
						dispatch(
							MainRouteDuc.creators.redirect(
								locationState.type,
								locationState.payload,
								locationState.query
							)
						)
					},
				}
				dispatch(
					TradeDocDuc.creators.changeDocumentStatus(documentPropsList)
				)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			activeDocument.docID,
			activeDocument.type,
			targetActiveStatus,
			t,
			actor,
			locationState.type,
			locationState.payload,
			locationState.query,
		]
	)

	const docStatus = getIn(activeDocument, ['status'])

	const activeActions = mapLabelsToStatusActions(
		t,
		hasInitiatedByCurrentOrg,
		getStateBasedTargetActions(
			rootModule,
			docStatus,
			hasInitiatedByCurrentOrg
		)
	)

	const associatedDocs = useSelector(TradeDocDuc.selectors.getDocumentTrace)

	const getSubDocumentDropDown = () => {
		return (
			<>
				{rootModule === 'purchase-order' && !hasInitiatedByCurrentOrg && (
					<>
						<Box
							onClick={() =>
								setShowCreateTradeDoc(!showCreateTradeDoc)
							}
							style={{
								cursor: 'pointer',
								background: '#3F56C4',
								width: '40px',
								height: '40px',
								boxShadow: '2px 8px 16px #2428453D',
								borderRadius: '6px',
								color: '#3F56C4',
								margin: '0px 20px',
							}}
							ref={purchaseOrderRef}
							center
						>
							<IconWrapper
								size={25}
								style={{ color: theme.color.white }}
							>
								<Icon glyph={PlusIcon} />
							</IconWrapper>
						</Box>
					</>
				)}
				{showCreateTradeDoc && (
					<Box>
						<Drop
							target={purchaseOrderRef.current}
							align={{
								top: 'bottom',
								right: 'right',
							}}
							onEsc={() => setShowCreateTradeDoc(false)}
							onClickOutside={() => setShowCreateTradeDoc(false)}
						>
							<Box
								alignItems="flex-start"
								padding="10px 10px"
								style={{
									width: '300px',
									borderBottom: `1px solid ${theme.color.grey4}`,
									cursor:
										docStatus === 'accepted'
											? 'pointer'
											: 'default',
									opacity: docStatus === 'accepted' ? 1 : 0.4,
								}}
								row
								onClick={() => {
									if (docStatus === 'accepted') {
										setShowCreateTradeDoc(false)
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER$WACTION,
												{
													rootModule: 'invoice',
													action: 'attachDetails',
												},
												{
													parentDocRef: `${rootModule}~${activeDocument.docID}`,
												}
											)
										)
									}
								}}
							>
								<Box
									padding="10px 10px"
									style={{
										background: theme.color.blue0,
									}}
								>
									<IconWrapper size={25}>
										<Icon
											color={theme.color.primary}
											glyph={InvoiceIcon}
										/>
									</IconWrapper>
								</Box>
								<Box flex overflow padding="0px 20px">
									<H4 color={theme.color.primary}>
										{t('tdmColumnHeaders.createAnInvoice')}
									</H4>
									<P color={theme.color.grey15}>
										{t('tdmColumnHeaders.POSubText')}
									</P>
								</Box>
							</Box>

							<Box
								alignItems="flex-start"
								padding="10px 10px"
								row
								onClick={() => {
									if (docStatus === 'accepted') {
										setShowCreateTradeDoc(false)
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER$WACTION,
												{
													rootModule:
														'delivery-order',
													action: 'attachDetails',
												},
												{
													parentDocRef: `${rootModule}~${activeDocument.docID}`,
												}
											)
										)
									}
								}}
								style={{
									width: '300px',
									cursor:
										docStatus === 'accepted'
											? 'pointer'
											: 'default',
									opacity: docStatus === 'accepted' ? 1 : 0.4,
									borderBottom: `1px solid ${theme.color.grey4}`,
								}}
							>
								<Box
									padding="10px 10px"
									style={{
										background: '#fff3e5',
									}}
								>
									<IconWrapper size={25}>
										<Icon color="#e99f52" glyph={DOIcon} />
									</IconWrapper>
								</Box>
								<Box flex overflow padding="0px 20px">
									<H4 color="#e99f52">
										{t(
											'tdmColumnHeaders.createDeliveryOrder'
										)}
									</H4>
									<P color={theme.color.grey15}>
										{t('tdmColumnHeaders.DOSubtext')}
									</P>
								</Box>
							</Box>

							<Box
								alignItems="flex-start"
								padding="10px 10px"
								row
								onClick={() => {
									setShowCreateTradeDoc(false)
									setShowFileUploadModal(true)
								}}
								style={{
									width: '300px',
									cursor: 'pointer',
								}}
							>
								<Box
									padding="10px 10px"
									style={{
										background: '#fff3e5',
									}}
								>
									<IconWrapper size={25}>
										<Icon
											color="#e99f52"
											glyph={ChatIcon}
										/>
									</IconWrapper>
								</Box>
								<Box flex overflow padding="0px 20px">
									<H4 color="#e99f52">
										{t(
											'tdmColumnHeaders.attachAnotherFile'
										)}
									</H4>
									<P color={theme.color.grey15}>
										{t('tdmColumnHeaders.attachSubText')}
									</P>
								</Box>
							</Box>
						</Drop>
					</Box>
				)}
				{rootModule === 'invoice' && hasInitiatedByCurrentOrg && (
					<>
						<Box
							onClick={() =>
								setShowCreateTradeDoc(!showCreateTradeDoc)
							}
							style={{
								cursor: 'pointer',
								background: '#3F56C4',
								width: '40px',
								height: '40px',
								boxShadow: '2px 8px 16px #2428453D',
								borderRadius: '6px',
								color: '#3F56C4',
								margin: '0px 20px',
							}}
							ref={invoiceRef}
							center
						>
							<IconWrapper
								size={25}
								style={{ color: theme.color.white }}
							>
								<Icon glyph={PlusIcon} />
							</IconWrapper>
						</Box>
					</>
				)}
				{showCreateTradeDoc && (
					<Box>
						<Drop
							target={invoiceRef.current}
							align={{
								top: 'bottom',
								right: 'right',
							}}
							onEsc={() => setShowCreateTradeDoc(false)}
							onClickOutside={() => setShowCreateTradeDoc(false)}
						>
							<Box
								alignItems="flex-start"
								padding="10px 10px"
								row
								onClick={() => {
									if (docStatus === 'accepted') {
										setShowCreateTradeDoc(false)
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER$WACTION,
												{
													rootModule:
														'delivery-order',
													action: 'attachDetails',
												},
												{
													parentDocRef: `${rootModule}~${activeDocument.docID}`,
												}
											)
										)
									}
								}}
								style={{
									width: '300px',
									cursor:
										docStatus === 'accepted'
											? 'pointer'
											: 'default',
									opacity: docStatus === 'accepted' ? 1 : 0.4,
									borderBottom: `1px solid ${theme.color.grey4}`,
								}}
							>
								<Box
									padding="10px 10px"
									style={{
										background: '#fff3e5',
									}}
								>
									<IconWrapper size={25}>
										<Icon color="#e99f52" glyph={DOIcon} />
									</IconWrapper>
								</Box>
								<Box flex overflow padding="0px 20px">
									<H4 color="#e99f52">
										{t(
											'tdmColumnHeaders.createDeliveryOrder'
										)}
									</H4>
									<P color={theme.color.grey15}>
										{t('tdmColumnHeaders.InvSubtext')}
									</P>
								</Box>
							</Box>
						</Drop>
					</Box>
				)}
				{rootModule === 'delivery-order' && (
					<Box id="DOAddSubDoc">
						<ButtonWithDrop
							padding="8px 20px"
							items={[
								{
									label: t('tdmPreviewDetails.billOfLading'),
									name: 'bill-of-lading',
								},
								{
									label: t('common.attachFile'),
									name: 'file-attachment',
								},
							]}
							label={t('tdmViewDocument.addSubDocument')}
							noBackground
							disabled={
								activeDocument.docStatus === 'rejected' ||
								activeDocument.docStatus === 'hold'
							}
							onChange={name => {
								if (
									name === 'file-attachment' ||
									name === 'bill-of-lading'
								) {
									if (name === 'bill-of-lading') {
										setCurrentAttachmentType(
											'bill-of-lading'
										)
									} else {
										setCurrentAttachmentType(null)
									}
									setShowFileUploadModal(true)
								} else {
									dispatch(
										name === 'weighbridge-slip'
											? MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.WEIGHBRIDGE$WACTION,
													{
														action: 'create',
													},
													{
														parentDocRef:
															activeDocument.docID,
													}
											  )
											: MainRouteDuc.creators.switchPage(
													MainRouteDuc.types
														.MILL$WACTION,
													{
														rootModule: name,
														action: 'create',
													},
													{
														parentDocRef:
															activeDocument.docID,
													}
											  )
									)
									dispatch(
										TradeDocDuc.creators.setActiveTab(
											'original'
										)
									)
								}
							}}
						/>
					</Box>
				)}
				{rootModule === 'request-for-quote' &&
					!hasInitiatedByCurrentOrg && (
						<>
							<Box
								onClick={() =>
									setShowCreateTradeDoc(!showCreateTradeDoc)
								}
								style={{
									cursor: 'pointer',
									background: '#3F56C4',
									width: '40px',
									height: '40px',
									boxShadow: '2px 8px 16px #2428453D',
									borderRadius: '6px',
									color: '#3F56C4',
									margin: '0px 20px',
								}}
								ref={rfqRef}
								center
							>
								<IconWrapper
									size={25}
									style={{ color: theme.color.white }}
								>
									<Icon glyph={PlusIcon} />
								</IconWrapper>
							</Box>
						</>
					)}
				{showCreateTradeDoc && (
					<Box>
						<Drop
							target={rfqRef.current}
							align={{
								top: 'bottom',
								right: 'right',
							}}
							onEsc={() => setShowCreateTradeDoc(false)}
							onClickOutside={() => setShowCreateTradeDoc(false)}
						>
							<Box
								alignItems="flex-start"
								padding="10px 10px"
								style={{
									width: '300px',
									borderBottom: `1px solid ${theme.color.grey4}`,
									cursor:
										docStatus === 'accepted'
											? 'pointer'
											: 'default',
									opacity: docStatus === 'accepted' ? 1 : 0.4,
								}}
								row
								onClick={() => {
									if (docStatus === 'accepted') {
										setShowCreateTradeDoc(false)
										dispatch(
											MainRouteDuc.creators.switchPage(
												MainRouteDuc.types
													.TRADE_DOCUMENT_MANAGER$WACTION,
												{
													rootModule: 'quote',
													action: 'attachDetails',
												},
												{
													parentDocRef: `${rootModule}~${activeDocument.docID}`,
												}
											)
										)
									}
								}}
							>
								<Box
									padding="10px 10px"
									style={{
										background: theme.color.blue0,
									}}
								>
									<IconWrapper size={25}>
										<Icon
											color={theme.color.primary}
											glyph={InvoiceIcon}
										/>
									</IconWrapper>
								</Box>
								<Box flex overflow padding="0px 20px">
									<H4 color={theme.color.primary}>
										Create a Quote
									</H4>
									<P color={theme.color.grey15}>
										Create a Quote from the current RFQ
									</P>
								</Box>
							</Box>
						</Drop>
					</Box>
				)}
			</>
		)
	}

	const paymentReportContent = () => {
		const paymentSlipAttachments = {
			attachmentID: paymentDetails?.id,
			file: [paymentDetails?.file],
			paymentSlipData: paymentDetails,
		}

		const currentPaymentMeta = {
			...paymentSlipAttachments?.paymentSlipData,
		}
		const currentDate = paymentSlipAttachments?.paymentSlipData?.date
		const paymentSlipAttachmentsData = { ...paymentSlipAttachments }
		currentPaymentMeta.file = paymentSlipAttachments?.file
		currentPaymentMeta.date = getDateByFormat(
			currentDate,
			'dd MMM yyyy',
			activeLocale
		)
		paymentSlipAttachmentsData.meta = currentPaymentMeta

		const paymentSlipAttachmentsArray = paymentSlipAttachments?.attachmentID
			? [paymentSlipAttachmentsData]
			: []

		const handlePaymentSlipOnChange = (key, change) => {
			switch (key) {
				case 'delete': {
					setShowDeleteModal(change)

					break
				}
				case 'edit': {
					dispatch(
						MainRouteDuc.creators.switchPage(
							MainRouteDuc.types
								.TRADE_DOCUMENT_MANAGER$PAYMENT_REPORT,
							{
								rootModule,
								documentReference: activeDocument.docID,
							}
						)
					)

					break
				}

				default:
					break
			}
		}

		return (
			<Box width="100%">
				{paymentSlipAttachmentsArray.length > 0 ? (
					paymentSlipAttachmentsArray.map(slip => (
						<div>
							{slip.attachmentID ? (
								<DataDisplayBlock
									isMobile={isMobile}
									id={slip.attachmentID}
									t={t}
									dataDetails={slip.meta}
									isOnlyOneStatementAvailable
									handleOnChange={(key, change, id) => {
										handlePaymentSlipOnChange(
											key,
											change,
											id
										)
									}}
									type="payment-slip"
									noEdit={
										userID !==
										slip?.file[0]?.meta?.createdBy
									}
									noDelete={
										userID !==
										slip?.file[0]?.meta?.createdBy
									}
								/>
							) : (
								<TransparentBgSpinner />
							)}
						</div>
					))
				) : (
					<Box style={{ minHeight: 300 }}>
						<ErrorBlock
							hideButton
							status="empty"
							message={t('common.errorBlockMessage')}
						/>
					</Box>
				)}
			</Box>
		)
	}

	const addAttachmentToRFQ = () => {
		setUploadedFiles([])
		dispatch(
			TradeDocDuc.creators.createEntityAttachment(
				rootModule,
				activeDocument.docID,
				receivingParty,
				uploadedFiles,
				'Success',
				getCurrentOrgID
			)
		)
	}
	const editFileName = () => {
		const { attachmentID, entityID } = selectedFile
		const newFiles = {
			...selectedFile,
			meta: {
				...selectedFile?.meta,
				name: newFileName,
			},
		}
		dispatch(
			TradeDocDuc.creators.updateEntityAttachment(
				rootModule,
				entityID,
				attachmentID,
				newFiles,
				'updated Success'
			)
		)
	}

	const getText = data => {
		let text = ''

		if (data === 'main-header') {
			if (rootModule === 'purchase-order') {
				text = `${t('common.view')} ${t(
					'tdmColumnHeaders.purchase-order'
				)}`
			} else if (rootModule === 'invoice') {
				text = `${t('common.view')} ${t('tdmColumnHeaders.invoice')}`
			} else if (rootModule === 'delivery-order') {
				text = `${t('common.view')} ${t(
					'tdmColumnHeaders.delivery-order'
				)}`
			}
		}

		return text
	}

	const getAssociatedRef = type => {
		let data = ''
		if (
			getIn(activeDocument, ['meta', 'docReference']) &&
			getIn(activeDocument, ['meta', 'docReference']).length > 0
		) {
			getIn(activeDocument, ['meta', 'docReference']).forEach(item => {
				if (item.referenceDocType === type) {
					data = item.referenceValue
				}
			})
		}

		return data
	}

	useEffect(() => {
		if (rootModule !== 'delivery-order') {
			setIsDOEditable(true)
		}
	}, [rootModule])

	useEffect(() => {
		if (attachmentDoc && attachmentDoc.length > 0) {
			attachmentDoc.forEach(item => {
				if (
					getIn(item, ['type']) === 'file' &&
					getIn(item, ['organizationID']) === getCurrentOrgID
				) {
					setAttachfile(getIn(item, ['file']))
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachmentDoc])

	useEffect(() => {
		return () => {
			dispatch(AppDuc.creators.hideGlobalLoader('document-view'))
		}
	}, [dispatch])

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('tdmViewDocument.deletePaymentReport')}
				body={<div>{t('tdmViewDocument.deletePaymentReportNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => {
					setShowDeleteModal(false)
				}}
				onConfirm={() => {
					setShowDeleteModal(false)

					const currentMeta = {
						paymentSlip: {
							id: paymentSlipAttachments.attachmentID,
						},
					}

					dispatch(
						TradeDocDuc.creators.createEntityAttachment(
							rootModule,
							activeDocument.docID,
							receivingParty,
							[], // file
							t('toast.deleteSuccess'),
							getCurrentOrgID,
							'paymentReportDelete',
							currentMeta
						)
					)
				}}
			/>
			<Modal
				forceCloseviaButton
				show={renameModal}
				heading="Rename File!"
				body={
					<Box>
						<Input
							name="uploadedFileName"
							value={
								selectedFile?.file?.meta?.name
									? selectedFile?.file?.meta?.name
									: ''
							}
							onChange={event =>
								setNewFileName(event.target.value)
							}
							required
						/>
					</Box>
				}
				closelabel={t('common.back')}
				confirmlabel="Rename"
				onClose={() => {
					setRenameModal(false)
				}}
				onConfirm={() => {
					setRenameModal(false)
					editFileName()
				}}
			/>
			<Modal
				forceCloseviaButton
				size="large"
				show={showFileUploadModal}
				heading={
					currentAttachmentType === 'bill-of-lading'
						? `${t('breadcrumb.attach')} ${t(
								'tdmDetailsEntry.billOfLading'
						  )}`
						: t('common.import')
				}
				body={
					<>
						<FileUpload
							type="private"
							name="file"
							size="5242880"
							label="Upload Attachment"
							required
							supportedFileType=".jpg,.png,.pdf"
							outline
							modalTriggerBtnLabel={t('common.upload')}
							currentUploads={uploadedFiles}
							onDocumentChange={async uploadedDoc => {
								setUploadedFiles(uploadedDoc)
							}}
							disableUpload={
								currentAttachmentType === 'bill-of-lading'
							}
							onUploadFailure={({ message }) => {
								dispatch(
									AppDuc.creators.showToast({
										messageType: 'error',
										message,
									})
								)
							}}
						/>
					</>
				}
				confirmlabel={t('common.proceed')}
				isDisabled={uploadedFiles.length === 0}
				closelabel={t('common.close')}
				onClose={() => {
					setUploadedFiles([])
					setShowFileUploadModal(false)
				}}
				onConfirm={() => {
					setShowFileUploadModal(false)
					addAttachmentToRFQ()
				}}
			/>
			<Box
				width="100%"
				height="100%"
				style={{ marginLeft: isIframeForTeamsApp() ? '20px' : 'auto' }}
			>
				{isLoading && <TransparentBgSpinner />}
				<Box style={{ padding: '0 5' }}>
					<Breadcrumb
						links={breadCrumbs}
						onClick={target => handleBreadCrumbClick(target)}
					/>
				</Box>

				<Box
					row
					justifyContent="space-between"
					alignItems="baseline"
					style={{
						padding: '10px 0 15px',
					}}
				>
					<HeaderWrapper>
						<SubWrapper
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.TRADE_DOCUMENT_MANAGER,
										{
											rootModule: 'incoming',
										},
										{
											activeTab: query.activeTab,
										}
									)
								)
							}
						>
							<IconWrapper
								size={24}
								style={{
									color: theme.color.blue8,
									cursor: 'pointer',
								}}
							>
								<Icon glyph={backArrow} />
							</IconWrapper>
							<SubText>{t('common.back')}</SubText>
						</SubWrapper>

						<Header>{getText('main-header')}</Header>
					</HeaderWrapper>
				</Box>

				<Box
					row
					justifyContent="flex-end"
					alignItems="baseline"
					wrap
					style={{ paddingRight: isMobile ? '10px' : '0px' }}
				>
					<Box row overflow>
						{
							<>
								{isDOEditable && hasInitiatedByCurrentOrg && (
									<>
										<ButtonWithNoBorder
											label={t('common.edit')}
											action
											rounded
											customIcon={
												<ButtonIconWrapper lightBG>
													<Icon glyph={EditIcon} />
												</ButtonIconWrapper>
											}
											extendStyles={{
												display:
													activeDocument.docStatus ===
														'complete' ||
													activeDocument.docStatus ===
														'accepted' ||
													activeDocument.docStatus ===
														'rejected' ||
													activeDocument.docStatus ===
														'delivered' ||
													activeDocument.docStatus ===
														'hold' ||
													activeDocument.docStatus ===
														'intransit'
														? 'none'
														: '',
											}}
											onClick={() => {
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.TRADE_DOCUMENT_MANAGER$STATUSDOCREFERENCE,
														{
															rootModule,
															action:
																'attachDetails',
															documentReference: getIn(
																activeDocument,
																['docID']
															),
															documentStatus:
																'clone',
														}
													)
												)
											}}
										/>
									</>
								)}
								<Spacer size={30} horizontal />
								{!!activeActions.length && (
									<Box
										center
										padding="0 24px"
										alignItems={
											isMobile ? 'center' : 'flex-end'
										}
										style={{
											marginTop: isMobile
												? '10px'
												: '0px',
										}}
									>
										<ButtonWithDrop
											padding="8px 20px"
											primary
											noBackground
											label={t(
												'tdmViewDocument.action'
											).toUpperCase()}
											onChange={newStatus => {
												setTargetActiveStatus(newStatus)
												setShowRemarkModal(true)
											}}
											items={activeActions}
										/>
									</Box>
								)}

								{getSubDocumentDropDown()}
							</>
						}
						{rootModule === 'invoice' &&
							!paymentSlipAttachments?.attachmentID && (
								<>
									<ButtonWithDrop
										items={INVInitiatorSubDoc}
										label={t(
											'tdmViewDocument.addSubDocument'
										)}
										noBackground
										disabled={
											activeDocument.docStatus ===
												'rejected' ||
											activeDocument.docStatus === 'hold'
										}
										onChange={name => {
											if (name === 'payment-report') {
												dispatch(
													MainRouteDuc.creators.switchPage(
														MainRouteDuc.types
															.TRADE_DOCUMENT_MANAGER$PAYMENT_REPORT,
														{
															rootModule,
															documentReference:
																activeDocument.docID,
														}
													)
												)
											}
										}}
									/>
								</>
							)}
					</Box>
				</Box>
				<Spacer size={8} />
				<Box center padding="20px 0px">
					<TabButtons
						fontSize={theme.fontSize.xl}
						list={activeTabs}
						key={activeTab}
						id="DOAttachments"
						activeIndex={VIEW_TAB_CONFIG.findIndex(
							q => q.name === activeTab
						)}
						onChange={({ name }) => {
							setActiveTab(name)
							if (name === 'chat' && messagesList?.threadID) {
								setCurrentUnreadCount(0)
								dispatch(
									MessagesDuc.creators.sendMessageAction(
										messagesList.threadID,
										'reset-unread'
									)
								)
							}
							dispatch(TradeDocDuc.creators.setActiveTab(name))
						}}
						borderRadius={10}
						isMobile={isMobile}
					/>
				</Box>
				{isOriginalTabActive && (
					<>
						<DocumentView
							rootModule={rootModule}
							partner={
								hasInitiatedByCurrentOrg
									? [receivingParty]
									: [initiatingParty]
							}
							receiverEmailIDs={getIn(activeDocument, [
								'meta',
								'receiverEmailIDs',
							])}
							associatedPORef={
								getAssociatedRef('purchase-order') || ''
							}
							associatedDORef={
								getAssociatedRef('delivery-order') || ''
							}
							associatedInvoiceRef={
								getAssociatedRef('invoice') || ''
							}
							productDetails={
								getIn(activeDocument, ['product']) || []
							}
							user={
								getIn(activeDocument, [
									'meta',
									'authorization',
									'reportedBy',
								]) || ''
							}
							role={
								getIn(activeDocument, [
									'meta',
									'authorization',
									'reportedByRole',
								]) || ''
							}
							issueDate={
								getIn(activeDocument, ['createdAt']) || ''
							}
							attachedFiles={attachfile || {}}
							status={
								getIn(activeDocument, ['status'])
									? getIn(activeDocument, [
											'status',
									  ]).toUpperCase() || ''
									: ''
							}
							documentNumber={
								getIn(activeDocument, ['docNumber']) || ''
							}
							activeDocument={activeDocument}
							isMobile={isMobile}
							actor={actor}
							hasInitiatedByCurrentOrg={hasInitiatedByCurrentOrg}
						/>
						<MessagesBlock messages={messages} readOnly />
					</>
				)}
				{isChatTabActive && (
					<ChatModalComponentTab
						documentId={
							getIn(activeDocument, ['docID'])
								? getIn(activeDocument, ['docID'])
								: getIn(activeDocument, ['id'])
						}
						documentNumber={
							getIn(activeDocument, ['docNumber']) || ''
						}
						sellerName={
							rootModule === 'purchase-order'
								? receivingOrgName
								: initiatingOrgName
						}
						buyerName={
							rootModule === 'purchase-order'
								? initiatingOrgName
								: receivingOrgName
						}
						buyerId={
							rootModule === 'purchase-order'
								? getIn(activeDocument, [
										'initiatingParty',
										'orgID',
								  ])
								: getIn(activeDocument, [
										'receivingParty',
										'orgID',
								  ])
						}
						sellerId={
							rootModule === 'purchase-order'
								? getIn(activeDocument, [
										'receivingParty',
										'orgID',
								  ])
								: getIn(activeDocument, [
										'initiatingParty',
										'orgID',
								  ])
						}
						tradeId={getIn(activeDocument, ['transactionId'])}
						docThreadID={messagesList?.threadID || null}
						docType={rootModule}
						chatBoxData={data => chatBoxData(data)}
						isMobile={isMobile}
						usersList={usersList}
					/>
				)}
				{isTimelineTabActive && (
					<div
						style={{
							zIndex: 0,
							borderRadius: 10,
							padding: isMobile
								? '30px 7px 100px'
								: '30px 50px 100px',
							background: theme.color.white,
							marginLeft: isMobile ? '10px' : '0px',
							marginRight: isMobile ? '10px' : '0px',
							border: `1px solid ${theme.color.grey4}`,
						}}
					>
						<Timeline
							title={t('tdmViewDocument.historyOfEvents')}
							data={statusLogs || []}
							isMobile={isMobile}
						/>
					</div>
				)}
				<br />
				{isHistoryTabActive && (
					<>
						<BoxShadowWrapper
							style={{
								background: theme.color.white,
								padding: isMobile ? '10px' : '16px',
							}}
						>
							<CollapsibleDocStacks
								documents={associatedDocs}
								activeDocumentKey={activeDocument?.id}
							/>
						</BoxShadowWrapper>
					</>
				)}
				{isAttachmentTabActive && attachments?.length > 0 && (
					<div
						style={{
							background: '#fff',
							marginLeft: 30,
							marginBottom: 8,
							padding: 16,
						}}
					>
						<p
							style={{
								fontSize: 20,

								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmPreviewDetails.attachments')}
						</p>
						<Box row style={{ flexWrap: 'wrap' }}>
							{isAttachmentTabActive &&
								attachments.length > 0 && (
									<FileLists
										documents={attachments || []}
										isReadOnly
									/>
								)}
						</Box>
					</div>
				)}
				{isAttachmentTabActive &&
					attachments?.length === 0 &&
					entityFiles.length === 0 && (
						<ErrorBlock
							hideButton
							small
							status="empty"
							message={t('tdmViewDocument.noAttachmentFound')}
						/>
					)}
				<Box row>
					{isPaymentReportTabActive ? paymentReportContent() : ''}
				</Box>
				<RemarksConfirmation
					showModal={showRemarkModal}
					handleOnConfirm={remarks => {
						setShowRemarkModal(false)
						handleOnChange('finalize_remark_status', { remarks })
					}}
					currentStatus={targetActiveStatus}
					handleOnSkip={() => {
						setShowRemarkModal(false)
						handleOnChange('finalize_remark_status')
					}}
					handleOnClose={() => {
						setShowRemarkModal(false)
					}}
				/>
			</Box>
			{(tourModule === 'tdmDOView' ||
				tourModule === 'tdmPOView' ||
				tourModule === 'tdmInvoiceView') && <WebTour />}
		</>
	)
}

export { ViewDocument }
