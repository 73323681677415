import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getIn } from 'timm'
import { AppDuc } from 'ui-tdm-app/modules/App/duc'
import Documents from 'ui-lib/icons/documents.svg'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import theme from 'ui-lib/utils/base-theme'
import { getDateByFormat } from 'ui-tdm-app/utils/date'
import { getProductTotal } from 'ui-tdm-app/utils/helpers'
import { ShippingStaticSection } from '../containers/DocumentControl/Shared/ShippingSection'
import { AdditionalInfoStaticBlock } from '../containers/DocumentControl/Shared/AdditionalInformation'
import { BankDetailStaticBlock } from '../containers/DocumentControl/Shared/BankDetails'

const textAreaPreviewStyles = {
	padding: 20,
	marginBottom: 24,
	borderRadius: 10,
	border: `1px solid ${theme.color.grey4}`,
	wordWrap: 'break-word', // Ensures long words wrap to the next line
	overflowWrap: 'break-word', // Alternative to wordWrap for better browser compatibility
	whiteSpace: 'normal',
}

const DocumentView = ({
	partner = [],
	associatedPORef = '',
	associatedDORef = '',
	associatedInvoiceRef = '',
	productDetails = [],
	user = '',
	role = '',
	issueDate = '',
	attachedFiles = {},
	status = '',
	documentNumber = '',
	activeDocument = {},
	isMobile = false,
	rootModule,
	hasInitiatedByCurrentOrg = false,
}) => {
	const { t } = useTranslation()
	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	const displayedRole = !hasInitiatedByCurrentOrg
		? t('tdmDetailsEntry.sender')
		: t('tdmDetailsEntry.recipient')

	const getAttachedDocuments = () => {
		const docArray = []

		if (attachedFiles?.id) {
			docArray.push(
				<div style={{ display: 'flex', cursor: 'pointer' }}>
					<div
						style={{
							width: 60,
							height: 60,
							marginRight: 23,
							cursor: 'pointer',
							alignItems: 'center',
							display: 'inline-flex',
							justifyContent: 'center',
							backgroundColor: theme.color.themeGrey2,
						}}
						title={getIn(attachedFiles, ['meta', 'name']) || ''}
						aria-hidden="true"
						onClick={() =>
							window.open(
								getIn(attachedFiles, ['meta', 'fullURL'])
							)
						}
					>
						<Icon
							style={{
								height: 46,
								width: 33,
							}}
							glyph={Documents}
							color={theme.color.blue8}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							color: theme.color.themeRed3,
						}}
						aria-hidden="true"
						onClick={() =>
							window.open(
								getIn(attachedFiles, ['meta', 'fullURL'])
							)
						}
					>
						{getIn(attachedFiles, ['meta', 'name']) || ''}
					</div>
				</div>
			)
		}

		return docArray
	}

	const getProductDetails = () => {
		const productArray = []
		if (productDetails && productDetails.length > 0) {
			productDetails.forEach((item, index) => {
				productArray.push(
					<div
						id="productDetails"
						style={{
							padding: 20,
							borderRadius: 10,
							border: `1px solid ${theme.color.grey4}`,
							marginBottom:
								index !== productDetails.length - 1
									? '24px'
									: '',
						}}
					>
						<Box row justifyContent="space-between">
							<Box>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmDetailsEntry.productName')}
								</div>
								<div
									style={{
										fontSize: 16,
										fontWeight: 500,
										marginBottom: 12,
										color: theme.color.accent2,
									}}
								>
									{getIn(item, ['name'])}
								</div>
							</Box>
							<Box>
								<div
									style={{
										fontSize: 14,
										fontWeight: 500,
										marginBottom: 5,
										color: theme.color.themeGrey1,
									}}
								>
									{`${t(
										'tdmViewDocument.quantity'
									)} in ${getIn(item, ['uom'])}`}
								</div>

								<div
									style={{
										fontSize: 16,
										fontWeight: 500,
										marginBottom: 12,
										color: theme.color.accent2,
									}}
								>
									<span>{getIn(item, ['quantity'])}</span>
								</div>
							</Box>
							{getIn(item, ['currency', 'code']) !== undefined &&
							rootModule !== 'delivery-order' ? (
								<Box>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										Currency
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 500,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										<span>
											{getIn(item, ['currency', 'code'])}
										</span>
									</div>
								</Box>
							) : (
								''
							)}
						</Box>
						<Box
							row
							justifyContent="space-between"
							style={{ marginTop: 12 }}
						>
							{getIn(item, ['price']) !== undefined &&
							rootModule !== 'delivery-order' ? (
								<Box>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{t('tdmDetailsEntry.unitPrice')}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 500,
											marginBottom: 12,
											color: theme.color.accent2,
										}}
									>
										{getIn(item, ['price']) || ''}
									</div>
								</Box>
							) : (
								''
							)}

							{getIn(item, ['taxes', 0, 'value']) !== undefined &&
							rootModule !== 'delivery-order' ? (
								<Box>
									<div
										style={{
											fontSize: 14,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.themeGrey1,
										}}
									>
										{t('tdmDetailsEntry.taxPrice')}
									</div>
									<div
										style={{
											fontSize: 16,
											fontWeight: 500,
											marginBottom: 5,
											color: theme.color.accent2,
										}}
									>
										{getIn(item, ['taxes', 0, 'value']) ||
											''}
									</div>
								</Box>
							) : (
								''
							)}

							{rootModule !== 'delivery-order' &&
								rootModule !== 'request-for-quote' && (
									<Box>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{t('tdmColumnHeaders.totalPrice')}
										</div>
										<div
											style={{
												fontSize: 16,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.accent2,
											}}
										>
											{getProductTotal(item)}
										</div>
									</Box>
								)}
						</Box>
					</div>
				)
			})
		}

		return productArray
	}

	return (
		<Box>
			<div
				style={{
					padding: '35px',
					borderRadius: 5,
					background: theme.color.white,
					border: `1px solid ${theme.color.grey4}`,
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '24px',
					}}
				>
					<div id="left" style={{ width: isMobile ? '100%' : '48%' }}>
						<div
							style={{
								marginBottom: 24,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{displayedRole}
						</div>
						<div
							id="recipient"
							style={{
								marginBottom: 24,
								borderRadius: 10,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<div
								style={{
									padding: 20,
								}}
							>
								{partner?.length > 0 && (
									<>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{displayedRole}
										</div>
										{partner.map(p => {
											return (
												<>
													<div
														style={{
															fontSize: 16,
															fontWeight: 600,
															marginBottom: 8,
															color:
																theme.color
																	.accent2,
														}}
													>
														{getIn(p, ['name']) ||
															''}
													</div>
													<div
														style={{
															fontSize: 16,
															fontWeight: 400,
															marginBottom: 12,
															color:
																theme.color
																	.accent2,
														}}
													>
														<div>
															{getIn(p, [
																'primaryAddress',
																'line1',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'street1',
																]) ||
																''}
															{getIn(p, [
																'primaryAddress',
																'line1',
															]) ||
															getIn(p, [
																'PrimaryAddress',
																'street1',
															])
																? ', '
																: ''}
															{getIn(p, [
																'primaryAddress',
																'line2',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'street2',
																]) ||
																''}
														</div>
														<div>
															{getIn(p, [
																'primaryAddress',
																'city',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'city',
																]) ||
																''}
															{getIn(p, [
																'primaryAddress',
																'city',
															]) ||
															getIn(p, [
																'PrimaryAddress',
																'city',
															])
																? ', '
																: ''}
															{getIn(p, [
																'primaryAddress',
																'state',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'state',
																]) ||
																''}
														</div>
														<div>
															{getIn(p, [
																'primaryAddress',
																'country',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'country',
																]) ||
																''}
															{getIn(p, [
																'primaryAddress',
																'country',
															]) ||
															getIn(p, [
																'PrimaryAddress',
																'country',
															])
																? ', '
																: ''}
															{getIn(p, [
																'primaryAddress',
																'postalCode',
															]) ||
																getIn(p, [
																	'PrimaryAddress',
																	'pincode',
																]) ||
																''}
														</div>
													</div>
												</>
											)
										})}
										<Spacer size={10} />{' '}
									</>
								)}
								{activeDocument?.meta?.receiverEmailIDs
									?.length > 0 && (
									<>
										<div
											style={{
												marginBottom: 8,
												fontWeight: 500,
												color: theme.color.themeGrey1,
											}}
										>
											Email
										</div>
										<div
											style={{
												fontSize: 14,
												fontWeight: 500,
												marginBottom: 5,
												color: theme.color.themeGrey1,
											}}
										>
											{getIn(activeDocument, [
												'meta',
												'receiverEmailIDs',
											]).map(email => (
												<span key={email}>
													{email}
													<br />
												</span>
											))}
										</div>
									</>
								)}
							</div>
							{associatedPORef !== '' ||
							associatedDORef !== '' ||
							associatedInvoiceRef !== '' ? (
								<div
									style={{
										borderTop: `1px solid ${theme.color.grey4}`,
										padding: 20,
									}}
								>
									{associatedPORef !== '' &&
									rootModule !== 'purchase-order' ? (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t(
													'tdmDetailsEntry.associatedPurchaseOrderReference'
												)}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													marginBottom: 12,
													color: theme.color.accent2,
												}}
											>
												{associatedPORef}
											</div>
											<Spacer size={10} />
										</>
									) : (
										''
									)}
									{associatedDORef !== '' &&
									rootModule === 'invoice' ? (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{t(
													'tdmDetailsEntry.associatedDeliveryOrderReference'
												)}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													color: theme.color.accent2,
												}}
											>
												{associatedDORef}
											</div>
										</>
									) : (
										''
									)}
									{associatedInvoiceRef !== '' &&
									(rootModule === 'delivery-order' ||
										rootModule ===
											'incoming-delivery-order') ? (
										<>
											<div
												style={{
													fontSize: 14,
													fontWeight: 500,
													marginBottom: 5,
													color:
														theme.color.themeGrey1,
												}}
											>
												{`${t(
													'tdmDetailsEntry.associated'
												)} ${t(
													'tdmColumnHeaders.invoice'
												)} ${t(
													'tdmDetailsEntry.reference'
												)}`}
											</div>
											<div
												style={{
													fontSize: 16,
													fontWeight: 400,
													color: theme.color.accent2,
												}}
											>
												{associatedInvoiceRef}
											</div>
										</>
									) : (
										''
									)}
								</div>
							) : (
								''
							)}
						</div>
						<div>
							<div
								style={{
									marginBottom: 8,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.productDetails')}
							</div>
							{getProductDetails()}
						</div>
					</div>
					<div
						id="right"
						style={{ width: isMobile ? '100%' : '45%' }}
					>
						<div
							style={{
								fontWeight: 500,
								marginBottom: 8,
								marginTop: isMobile ? 24 : 0,
								color: theme.color.themeGrey1,
							}}
						>
							{`${t('common.document')} ${t(
								'tdmViewDocument.number'
							)}`}
						</div>
						<div
							id="status"
							style={{
								padding: 20,
								borderRadius: 10,
								marginBottom: 24,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<div
								style={{
									fontSize: 14,
									fontWeight: 500,
									color: theme.color.accent2,
								}}
							>
								{documentNumber || ''}
							</div>
						</div>
						<div
							style={{
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.documentStatus')}
						</div>
						<div
							id="status"
							style={{
								padding: 20,
								borderRadius: 10,
								marginBottom: 24,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<div
								style={{
									fontSize: 18,
									fontWeight: 500,
									marginBottom: 5,
									color: theme.color.themeRed3,
								}}
							>
								{status}
							</div>
							<div
								style={{
									fontSize: 14,
									fontWeight: 500,
									color: theme.color.themeGrey1,
								}}
							>
								{getDateByFormat(
									issueDate,
									'dd MMM yyyy',
									activeLocale
								)}
							</div>
						</div>
						{attachedFiles?.id ? (
							<>
								<div
									style={{
										marginBottom: 8,
										fontWeight: 500,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmDetailsEntry.documentAttached')}
								</div>
								<div
									id="status"
									style={{
										padding: 20,
										display: 'flex',
										borderRadius: 10,
										marginBottom: 24,
										border: `1px solid ${theme.color.grey4}`,
									}}
								>
									{getAttachedDocuments()}
								</div>
							</>
						) : (
							''
						)}
						{!getIn(activeDocument, ['meta', 'attachmentFlow']) &&
						getIn(activeDocument, ['bankDetails']) &&
						Object.keys(activeDocument.bankDetails).length > 0 ? (
							<>
								<div
									style={{
										marginBottom: 8,
										fontWeight: 500,
										color: theme.color.themeGrey1,
									}}
								>
									{t('tdmPreviewDetails.plantationDetails')}
								</div>
								<div
									id="bankDetails"
									style={{
										padding: 20,
										marginBottom: 24,
										borderRadius: 10,
										border: `1px solid ${theme.color.grey4}`,
									}}
								>
									<BankDetailStaticBlock
										newDocView
										isMobile={isMobile}
										values={activeDocument.bankDetails}
									/>
								</div>
							</>
						) : (
							''
						)}
						{!getIn(activeDocument, ['meta', 'attachmentFlow']) &&
						getIn(activeDocument, ['additionalInfo']) ? (
							<>
								<div
									style={{
										marginBottom: 8,
										fontWeight: 500,
										color: theme.color.themeGrey1,
									}}
								>
									{t(
										'tdmPreviewDetails.additionalInformation'
									)}
								</div>
								<div
									id="additionalDelivery"
									style={{
										padding: 20,
										marginBottom: 24,
										borderRadius: 10,
										border: `1px solid ${theme.color.grey4}`,
									}}
								>
									<AdditionalInfoStaticBlock
										newDocView
										values={getIn(activeDocument, [
											'additionalInfo',
										])}
									/>
								</div>
							</>
						) : (
							''
						)}

						<div
							style={{
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.createdBy')}
						</div>
						<div
							id="createdBy"
							style={{
								padding: 20,
								marginBottom: 24,
								borderRadius: 10,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<div
								style={{
									fontSize: 14,
									fontWeight: 500,
									marginBottom: 5,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.user')}
							</div>
							<div
								style={{
									fontSize: 16,
									fontWeight: 500,
									marginBottom: 12,
									color: theme.color.accent2,
								}}
							>
								{user}
							</div>
							<Spacer size={10} />
							<div
								style={{
									fontSize: 14,
									fontWeight: 500,
									marginBottom: 5,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tdmDetailsEntry.role')}
							</div>
							<div
								style={{
									fontSize: 16,
									fontWeight: 500,
									marginBottom: 12,
									color: theme.color.accent2,
								}}
							>
								{role}
							</div>
							<Spacer size={10} />
							<div
								style={{
									fontSize: 14,
									fontWeight: 500,
									marginBottom: 5,
									color: theme.color.themeGrey1,
								}}
							>
								{t('tripsListing.date')}
							</div>
							<div
								style={{
									fontSize: 16,
									fontWeight: 500,
									color: theme.color.accent2,
								}}
							>
								{getDateByFormat(
									issueDate,
									'dd MMM yyyy',
									activeLocale
								)}
							</div>
						</div>
					</div>
				</div>
				{rootModule !== 'invoice' && (
					<>
						<div
							style={{
								marginBottom: 8,
								fontWeight: 500,
								color: theme.color.themeGrey1,
							}}
						>
							{t('tdmDetailsEntry.shippingAndDelivery')}
						</div>
						<div
							id="shippingAndDelivery"
							style={{
								marginBottom: 24,
								borderRadius: 10,
								border: `1px solid ${theme.color.grey4}`,
							}}
						>
							<div
								style={{
									padding: 20,
								}}
							>
								<div
									style={{
										fontSize: 16,
										fontWeight: 400,
										marginBottom: 12,
										color: theme.color.accent2,
									}}
								>
									<Box>
										<ShippingStaticSection
											newDocView
											isMobile={isMobile}
											activeRecord={
												getIn(activeDocument, [
													'meta',
													'shipping',
													'address',
												]) || {}
											}
											activeDocument={activeDocument}
										/>
									</Box>
								</div>
							</div>
						</div>
					</>
				)}
				<div
					style={{
						marginBottom: 8,
						fontWeight: 500,
						color: theme.color.themeGrey1,
					}}
				>
					{t('rfq.termsAndConditions')}
				</div>
				<div id="createdBy" style={textAreaPreviewStyles}>
					{getIn(activeDocument, ['meta', 'termsAndConditions'])}
				</div>
				<div
					style={{
						marginBottom: 8,
						fontWeight: 500,
						color: theme.color.themeGrey1,
					}}
				>
					{t('rfq.paymentTerms')}
				</div>
				<div id="createdBy" style={textAreaPreviewStyles}>
					{getIn(activeDocument, ['meta', 'payment'])}
				</div>
				<div
					style={{
						marginBottom: 8,
						fontWeight: 500,
						color: theme.color.themeGrey1,
					}}
				>
					{t('rfq.additionalDetails')}
				</div>
				<div id="createdBy" style={textAreaPreviewStyles}>
					{getIn(activeDocument, ['senderRequirements'])}
				</div>
			</div>
		</Box>
	)
}

DocumentView.defaultProps = {
	editable: false,
	onChange: () => {},
}

export { DocumentView }
