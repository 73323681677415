import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MainRouteDuc } from 'ui-tdm-app/routes/duc'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { DocumentTreeBlock } from 'ui-tdm-app/modules/TradeDocumentManager/components/DocumentTreeBlock'

const CollapsibleDocStacks = ({
	getExplorerLink,
	documents = {},
	activeDocumentKey = '',
	expandedDocumentKeys = [],
	activeDocument = {},
	hederaMessages = {},
}) => {
	const dispatch = useDispatch()
	const [openKeys, setOpenKeys] = useState({})

	const handleOnChange = useCallback(
		(actionType, meta = {}) => {
			if (actionType === 'open-document') {
				dispatch(TradeDocDuc.creators.setActiveTab('original'))

				dispatch(
					MainRouteDuc.creators.switchPage(
						MainRouteDuc.types
							.TRADE_DOCUMENT_MANAGER$VIEWWDOCREFERENCE,
						{
							rootModule: meta.type,
							documentReference: meta.id,
						},
						{},
						{},
						true
					)
				)
			}
		},
		[dispatch]
	)

	const toggleCollapse = id => {
		setOpenKeys(prevKeys => ({
			...prevKeys,
			[id]: !prevKeys[id],
		}))
	}

	const renderDocumentTree = doc => (
		<DocumentTreeBlock
			key={doc.id}
			isOpen={!!openKeys[doc.id]}
			config={doc.document}
			handleChange={handleOnChange}
			onCollapseAction={() => toggleCollapse(doc.id)}
			isActive={doc.id === activeDocumentKey}
			activeDocument={activeDocument}
			hederaMessages={hederaMessages}
			currentEntityID={doc.document?.initiatingParty?.orgID || ''}
			getExplorerLink={getExplorerLink}
		>
			{doc.trace && doc.trace.length > 0 && (
				<>
					{doc.trace.map(traceDoc => (
						<CollapsibleDocStacks
							key={traceDoc.id}
							documents={traceDoc}
							activeDocumentKey={activeDocumentKey}
							expandedDocumentKeys={expandedDocumentKeys}
							handleOnChange={handleOnChange}
							hederaMessages={hederaMessages}
							getExplorerLink={getExplorerLink}
						/>
					))}
				</>
			)}
		</DocumentTreeBlock>
	)

	return documents && renderDocumentTree(documents)
}

export { CollapsibleDocStacks }
