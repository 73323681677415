import React from 'react'
import { useSelector } from 'react-redux'
import { back } from 'redux-first-router'
import { UnexpectedErrorBlock } from 'ui-tdm-app/modules/App/components/Error'
import { CreateGeneralDocument } from './CreateGeneralDocument'
import { ViewGeneralDocument } from './ViewGeneralDocument'
import { TradeDocDuc } from '../../duc'
import { GeneralDocListing } from './GeneralDocListing'
import { PreviewGeneralDocument } from './PreviewGeneralDocument'

const GeneralDocument = () => {
	const { payload = {} } = useSelector(TradeDocDuc.selectors.location)
	const { action, documentReference, rootModule = '' } = payload

	const { hasError } = useSelector(
		TradeDocDuc.selectors.getDocumentLoadingState
	)

	if (action === 'listing') return <GeneralDocListing />
	if (
		action === 'create-doc' ||
		action === 'edit-doc' ||
		action === 'draft-edit'
	)
		return <CreateGeneralDocument />
	if (action === 'preview-document' || action === 'edit-preview')
		return <PreviewGeneralDocument />
	if (documentReference) return <ViewGeneralDocument />

	// if errored, then return the status back.
	if (hasError) {
		return <UnexpectedErrorBlock status={401} onClick={back} />
	}

	return <GeneralDocListing />
}

export { GeneralDocument }
