import React, { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useDateByFormat } from 'ui-tdm-app/utils/date'
import { TradeDocDuc } from 'ui-tdm-app/modules/TradeDocumentManager/duc'
import { MessagesDuc } from 'ui-tdm-app/modules/Messages/duc'
import { AuthDuc } from 'ui-tdm-app/modules/Auth/duc'
import { ChatModalTab } from 'ui-lib/components/ChatModalTab'

const ChatModalComponentTab = ({
	documentId,
	tradeId,
	sellerName,
	buyerName,
	buyerId,
	sellerId,
	chatBoxData,
	isMobile,
	documentNumber,
	docThreadID = null,
	docType = 'request-for-quote',
	usersList = [],
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const orgId = useSelector(AuthDuc.selectors.getUserOrgId)

	const { orgRole, id: userId } = useSelector(
		AuthDuc.selectors.getUserProfile
	)

	const chatAssociatedList = useSelector(
		TradeDocDuc.selectors.getChatAssociatedList
	)

	const getChatMessagesList = useSelector(
		TradeDocDuc.selectors.getChatMessagesList
	)
	const msgDataLoader = useSelector(TradeDocDuc.selectors.getMsgDataLoader)

	const handleSendMsg = (
		sendType,
		msgText,
		msgThreadID = null,
		refID = null,
		replyTextData = null
	) => {
		const receiverID = orgId === buyerId ? sellerId : buyerId
		const params = {
			sendType,
			msgText,
			documentId,
			receiverID,
			docType,
			documentNumber,
			tradeId,
			msgThreadID,
			refID,
			replyTextData,
		}
		dispatch(TradeDocDuc.creators.setMsgDataLoader(true))
		dispatch(MessagesDuc.creators.sendMessage(params))
	}

	const getNewMessageList = threadID => {
		dispatch(TradeDocDuc.creators.setMsgDataLoader(true))
		dispatch(TradeDocDuc.creators.getChatMessagesList(threadID))
	}

	return (
		<>
			<ChatModalTab
				documentId={documentId}
				docThreadID={docThreadID}
				sellerName={sellerName}
				buyerName={buyerName}
				sellerId={sellerId}
				docType={docType}
				chatBoxData={data => chatBoxData(data)}
				isMobile={isMobile}
				dispatch={dispatch}
				handleTranslation={data => t(data)}
				orgId={orgId}
				userId={userId}
				MessagesDuc={MessagesDuc}
				TradeDocDuc={TradeDocDuc}
				handleSendMsg={(
					sendType,
					msgText,
					msgThreadID,
					refID,
					replyTextData
				) =>
					handleSendMsg(
						sendType,
						msgText,
						msgThreadID,
						refID,
						replyTextData
					)
				}
				getNewMessageList={threadID => getNewMessageList(threadID)}
				chatAssociatedList={chatAssociatedList}
				messagesList={getChatMessagesList}
				msgDataLoader={msgDataLoader}
				useDateByFormat={useDateByFormat}
				partnerId={orgId === buyerId ? sellerId : buyerId}
				usersList={usersList}
			/>
		</>
	)
}

export { ChatModalComponentTab }
